import React, { Fragment,useEffect } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
  useHistory,
} from "react-router-dom";

import Footer from "../../components/footer";
import Header from "../../components/header";
import PageSideBar from "../../components/PageSidebar";

import Service from "./components/service";



function Homepage() {
  const history = useHistory();
  window.scrollTo(0, 0);

  useEffect(() => {
    // let isLoggedIn = localStorage.getItem("id_token");

    // if (!isLoggedIn)
    //   history.push("/login");
    window.scrollTo(0, 0);
  }, []);

  return (
    <Fragment>
      <div className="single-page about-page">
        <Header />
        {/* <!-- Home --> */}
        <Service />




        <Footer />
      </div>
    </Fragment>
  );
}

export default Homepage;
