import React, { Fragment, useState, useEffect, useParams } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
  useHistory,
} from "react-router-dom";

// MEDIA FILES

import Logo01 from "../../../assets/images/logos/Logo-2.png";
import Logo02 from "../../../assets/images/logos/Logo-4.png";
import Logo03 from "../../../assets/images/logos/Logo-5.png";

// FOR APIS
import {
  deleteStartupAPI,
  getStartupsAPI,
  getStartups,
  getStartupsPaginationAPI
} from "../../../APIs/startupApis";

import queryString from "query-string";
import CStartup from '../../../components/startups/cStartup';

function Module() {
  const history = useHistory();
  const [startupsTable, setStartupsTable] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);

  useEffect(() => {
    handleGetStartups();
  }, []);


  return (
    <Fragment>
        <div className="events page_section">
          <div className="container">
            
            <div className="row">
              <div className="col">
                <div className="section_title text-center">
                  <h1>Our Portfolio</h1>
                </div>
              </div>
            </div>


            
            <div className="cls-startup-card ">
              <div className="row ">
                {startupsTable != undefined &&
                  startupsTable.map((row) => {   
                    {
                      return (   
                        <div className="col-md-4 col-xs-12">
                          <CStartup data={row} />
                        </div>  
                        )
                    }
                  })
                }
              </div>
              
            <div className="row text-center mt-5">
              <div className="col-12">
                <Link to="/startups" className="button button_primary trans_200" >
                  <span className="p-2">View All Startups</span>
                </Link>
              </div>
            </div>
            </div>
          </div>
        </div>
    </Fragment>
  );

  async function handleGetStartups() {
    let RC = false;
    setIsFormLoading(true);
    let page = 1;
    let limit = 3;

    // let queries = queryString.parse(history.location.search);
    // let page = queries.page != undefined ? queries.page : 1;
    // let limit = queries.limit != undefined ? queries.limit : 9;
    let iFeaturedOnly = true;
    let startups = await getStartupsPaginationAPI(limit, page,{isFeatured: true});
    if (startups != undefined) {
      console.log("<handleGetStartups> Startups Found Successfully ::", startups.length);
      setStartupsTable(startups.data);
      setIsFormLoading(false);
      setPage(page);
      setLimit(limit);
    } else {
      console.log("<handleGetStartups> Startup Not found");
      setIsFormLoading(false);
    }
  }



}

export default Module;
