import React, { Fragment, useRef, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import queryString from "query-string";
import IsoTopeGrid from "react-isotope";
// COMPONENTS
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import PageSidebar from "../../../components/PageSidebar.js";
import CStartup from "../../../components/startups/cStartup";
import CustomPaginationBar from "../../../components/Controls/CustomPaginationBar";

// APIs
import { CauseID_ARR } from "../../../Macros";
// FOR APIS
import {
  deleteStartupAPI,
  getStartupsAPI,
  getStartups,
  getStartupsPaginationAPI,
  getTagsAPI,
} from "../../../APIs/startupApis";

import {
  getParentLevelServicesAPI
} from "../../../APIs/serviceApis";


// CUSTOM COMPONENTS
import CircularStatic from '../../../components/Controls/CircularProgressBar';

// IMAGE/MEDIA
import StartupImage from "../../../assets/images/User-Profile/User-1.jpg";
import { resendOTPs } from "../../../context/userContext";

import { getLocaleShortDateString, trimString } from "./../../../helpers";
import { map } from "jquery";

var cardsLayout = [
  {
    "id": "1",
    "content": "Application & Data Consulting and Implementation Services",
    "detail": "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias",
    "filter": ["Startup", "Fintech"]
  },
  {
    "id": "2",
    "content": "Content Delivery Services",
    "detail": "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias",
    "filter": ["Fintech", "AeroSpace"]
  },
  {
    "id": "3",
    "content": "Cloud and Hosting Services",
    "detail": "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias",
    "filter": ["AeroSpace", "Startup"]
  },
  {
    "id": "4",
    "content": "CPE",
    "detail": "At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias",
    "filter": ["Startup", "Agriculture", "AeroSpace"]
  }
];


var filtersList = [
  { "label": "all", "isChecked": true },
  { "label": "Startup", "isChecked": false },
  { "label": "Agriculture", "isChecked": false },
  { "label": "Aerospace", "isChecked": false },
  { "label": "Fintech", "isChecked": false },
];



function Homepage() {

  const history = useHistory();

  const [tagsTable, setTagsTable] = useState(undefined);
  const [filters, updateFilters] = useState(filtersList);
  const [pagination, setPagination] = useState(undefined);

  const [servicesTable, setServicesTable] = useState(undefined);


  const [startupsTable, setStartupsTable] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [curPage, setCurPage] = useState(undefined);
  const example1 = useRef(null);


  // Filter change handler
  const onTagFilter = (event, iIsChecked) => {
    event.persist();
    const {
      target: { id, isChecked }
    } = event;
    console.log("Event :: ", event, iIsChecked);
    setTagsTable((state) =>
      state.map((f) => {
        if (f.name === id) {
          return {
            ...f,
            isChecked: iIsChecked
          };
        }
        return f;
      })
    );
    console.log("State After OnFilter ::", tagsTable);
  };



  useEffect(() => {
    window.scrollTo(0, 0);

    handleGetStartups();
    handleGetAllTags();
    handleGetAllServices();

    // let queries = queryString.parse(history.location.search);
    // console.log("<QUERY STRING > ::", queries);
    // if (queries.example1) {
    //   example1.current.scrollIntoView();
    // }
  }, []);

  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center", }}>
          {/* <CircularProgress variant="indeterminate" value={75} /> */}
          <CircularStatic value={25} />
        </div>
      ) : (
        <Fragment>


          <div className="d-flex flex-row overflow-auto cls-filter-tags">
            {
              tagsTable != undefined && tagsTable.map((row) => {
                return (
                  <div value={row?.isChecked} id={row?.name} key={row?.name + '_key'} onClick={event => { onTagFilter(event, !row.isChecked) }} className={(row.isChecked) ? "cls-startup-tag cls-startup-tag-selected" : "cls-startup-tag"}>{row.name}</div>
                )
              })}
          </div>

          <div className="cls-filter-sidebar d-lg-none">
            <input type="checkbox" className="openSidebarMenu toggle-checkbox" id="openSidebarMenu" />
            <label for="openSidebarMenu" className="sidebarIconToggle">
              <div className="cls-toggle-icon d-flex flex-row">
                <div className="cls-icons">
                  <div className="spinner diagonal part-1"></div>
                  <div className="spinner horizontal"></div>
                  <div className="spinner diagonal part-2"></div>
                </div>
                <div className="mt-3 text-primary">
                  <h3 className="d-inline-block">Filter</h3>
                </div>
              </div>
            </label>
            <div id="sidebarMenu">
              <div className="h3">Filters</div><hr />
              <ul className="sidebarMenuInner">
                <li className="mt-1">
                  <div className="h5 text-primary">Profiles</div>
                  <div className="checkbox checkbox-warning">
                    {/* <span value={row.isChecked} id={row.name} key={row.name + '_key'}  onClick={event => { onProfileFilter(event, !row.isChecked)}} className={(row.isChecked)?"cls-startup-tag cls-startup-tag-selected":"cls-startup-tag" }>{row.name}</span> */}
                    {tagsTable != undefined && tagsTable.map((row) => {
                      return (
                        <div className="p-2">
                          <input className="styled mb-2" id={row.name} key={row.name + '_key'} value={row.isChecked} onClick={event => { onTagFilter(event, !row.isChecked) }} type="checkbox" />
                          {"  "}<label for={row.name} className="text-dark">
                            {row.name}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </li>
                <li className="mt-1">
                  <div className="h5  text-primary">Services</div>
                  <div className="checkbox checkbox-warning">
                    {/* <span value={row.isChecked} id={row.name} key={row.name + '_key'}  onClick={event => { onProfileFilter(event, !row.isChecked)}} className={(row.isChecked)?"cls-startup-tag cls-startup-tag-selected":"cls-startup-tag" }>{row.name}</span> */}
                    {servicesTable != undefined && servicesTable.map((row) => {
                      return (
                        <div className="p-2">
                          <input className="styled mb-2" id={row.title} key={row.title + '_key'} value={row.isChecked} onClick={event => { onTagFilter(event, !row.isChecked) }} type="checkbox" />
                          {"  "}<label for={row.title} className="text-dark">
                            {row.title}
                          </label>
                        </div>
                      )
                    })}
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row ">
            <div className="col-lg-2 d-none d-lg-block mt-4">
              <div className="h3">Filters</div><hr />
              <div className="mt-3">
                <div className="h5 text-primary">Profiles</div>
                <div className="checkbox checkbox-warning">
                  {/* <span value={row.isChecked} id={row.name} key={row.name + '_key'}  onClick={event => { onProfileFilter(event, !row.isChecked)}} className={(row.isChecked)?"cls-startup-tag cls-startup-tag-selected":"cls-startup-tag" }>{row.name}</span> */}
                  {tagsTable != undefined && tagsTable.map((row) => {
                    return (
                      <div className="p-2">
                        <input className="styled mb-2" id={row.name} key={row.name + '_key'} checked={row.isChecked ? "checked" : undefined} value={row.isChecked} onClick={event => { onTagFilter(event, !row.isChecked) }} type="checkbox" />
                        {"  "}<label for={row.name} className="text-dark">
                          {row.name}
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>
              <div className="mt-3">
                <div className="h5  text-primary">Services</div>
                <div className="checkbox checkbox-warning">
                  {/* <span value={row.isChecked} id={row.name} key={row.name + '_key'}  onClick={event => { onProfileFilter(event, !row.isChecked)}} className={(row.isChecked)?"cls-startup-tag cls-startup-tag-selected":"cls-startup-tag" }>{row.name}</span> */}
                  {servicesTable != undefined && servicesTable.map((row) => {
                    return (
                      <div className="p-2">
                        <input className="styled mb-2" id={row.title} key={row.title + '_key'} value={row.isChecked} onClick={event => { onTagFilter(event, !row.isChecked) }} type="checkbox" />
                        {"  "}<label for={row.title} className="text-dark">
                          {row.title}
                        </label>
                      </div>
                    )
                  })}
                </div>
              </div>
            </div>
            <div className="col-lg-10">
              <div className="row cls-startup-card ">
                {
                  startupsTable != undefined && startupsTable.map((row) => {
                    if (getFilterSelected(row.filter))
                      return (
                        <div className="col-xs-12 col-md-4">
                          <CStartup data={row} />
                        </div>
                      )
                  })}
              </div>
              <div className="paginationBar">
                <ul className="pagination modal-4">
                  {pagination != undefined && pagination.map((item, index) => {
                    return (<CustomPaginationBar totalPages={pagination.length} item={item} id={index} key={index} getStartUps={handleGetStartups} pagination={pagination} page={curPage} />)
                  })}
                </ul>
              </div>

            </div>
          </div>


        </Fragment>
      )}
    </>
  );

  function getFilterSelected(iArrFilter) {
    // if(iArrFilter == undefined || iArrFilter.length <=0)
    //   return true;

    let obj = tagsTable?.find(o => {
      if (o.name == 'all' && o.isChecked == true)
        return true;
    });
    if (obj != undefined && obj != false)
      return true;

    console.log("Tags in Current statup", iArrFilter);


    if (iArrFilter != undefined) {
      for (let index = 0; index < iArrFilter.length; index++) {
        // alert(iArrFilter[index]);
        let obj = tagsTable?.find(o => {
          if (o.name == iArrFilter[index] && o.isChecked == true) {
            // alert(o.name);
            console.log("Tag Selected in Tab table", o.name);
            return true;
          }
        }
        );
        if (obj != undefined && obj != false) {
          console.log("Node Selected");
          return true;
        }
      }
    }
    console.log("Node Not Selected");

    // iArrFilter.forEach(filterText => {
    //     let obj = tagsTable.find(o => { 
    //       if(o.name == filterText && o.isChecked==true)
    //         return true; 
    //       } 
    //     );
    //     if(obj!=undefined && obj!=false)      
    //       return true;
    // });  

  }

  function handleViewDetail() {
    history.push("/Startup");
  }

  function handleBookPartner() {
    history.push("/members");
  }

  async function handleGetAllServices() {
    let RC = false;
    setIsFormLoading(true);
    let { status, response } = await getParentLevelServicesAPI(history);
    if (status == "S_OK") {
      let tmp = { 'id': 0, 'detail': "All", 'isChecked': true, 'title': "All" };
      response.unshift(tmp);
      console.log("Services Found Successfully ::", response.length);
      setServicesTable(response);
      setIsFormLoading(false);
    } else {
      console.log("Services Not found");
      setIsFormLoading(false);
    }
  }

  async function handleGetAllTags() {
    let RC = false;
    setIsFormLoading(true);
    let tags = await getTagsAPI();
    if (tags != undefined && tags.length > 0) {
      let tmp = { 'id': 0, 'detail': "all", 'isChecked': true, 'name': "all" };
      tags.push(tmp);
      console.log("tags Found Successfully ::", tags.length);
      setTagsTable(tags);
      setIsFormLoading(false);
      console.log("tags Table Data ", tags);
    } else {
      console.log("tags Not found");
      setIsFormLoading(false);
    }
  }

  async function handleGetStartups(curPage = undefined) {
    let RC = false;
    setIsFormLoading(true);
    setStartupsTable(undefined);
    let page = (curPage != undefined) ? curPage : 1;
    let limit = 9;
    console.log("page > " + page)
    let startups = await getStartupsPaginationAPI(limit, page);
    console.log("<handleGetStartups> Startups Found Successfully ::", startups);

    if (startups != undefined) {
      setStartupsTable(startups.data);
      setPagination(startups.links)
      setIsFormLoading(false);
      console.log("<handleGetStartups> Startups Table Data ", startups.data);
      setCurPage(page);
    } else {
      console.log("<handleGetStartups> Startup Not found");
      setIsFormLoading(false);
    }
  }

  async function handleForwardBtn(id, location) {
    let isLoggedIn = localStorage.getItem("id_token");

    let searchString = "Startup_id=" + id;
    let redirectString = searchString + "&location=" + location;

    console.log("<pathname> :: ", location);
    console.log("<search> :: ", searchString);

    if (isLoggedIn) {
      localStorage.setItem('CURRENT_Startup', id);
      history.push(location);
      // history.push({
      //   pathname: location,
      //   search: "?" + searchString,
      //   state: { Startup_id: id }
      // });
    } else
      history.push({
        pathname: "login",
        search: redirectString,
      });
  }

  function handleNextBtn() {
    let queries = queryString.parse(history.location.search);
    let page = (queries.page != undefined) ? queries.page : 1;
    let limit = (queries.limit != undefined) ? queries.limit : 9;
    page = +page + 1;
    let searchString = "?limit=" + limit + "&page=" + page;
    console.log("<search> :: ", searchString);
    console.log("<search> :: ", window.location.href);


    window.location.href = "http://localhost:3000/#/Startups?limit=" + limit + "&page=" + page
    window.location.reload();
    // + searchString;


    // history.replace({
    //   pathname: "/Startups",
    //   search: searchString,
    // });
    // <Redirect to={searchString} />;
  }
}

export default Homepage;
