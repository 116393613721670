import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";
import { displayResponseError } from "./ApiUtils";
// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export {
  deleteDonorAPI,
  getDonorsAPI,
  createDonorAPI,
  editDonorAPI,
  getDonorAPI,
  getOrdersAPI,
  getDonationAPI,
  addDonationAPI,
  getCauseAPI,
};

async function getCauseAPI(causeID) {
  let token = localStorage.getItem("id_token");
  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("cause/" + causeID);
    if (response.status == 200) {
      console.log(
        "Response from  getCause API *:: ",
        JSON.stringify(response.data.response)
      );
      return response.data.response.cause;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getDonorsAPI() {
  let donors = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    let response = await API.get("donor");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.donors.map((donor) => {
        let tmp = {};
        Object.entries(donor).map(([key, value]) => {
          tmp[key] = value;
        });
        donors.push(tmp);
      });
      return donors;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return donors;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function createDonorAPI(history, param) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("donor", param);
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Donor Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function editDonorAPI(id, param) {
  let token = localStorage.getItem("id_token");
  let id_donor = id;

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post(
      "donor/" + id_donor + "?_method=PUT",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Member Edited Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteDonorAPI(history, id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("donor/" + id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      //toast.success("Donor Deleted Successfully");
      return true;
    } else {
      // DISPLAY RESPONSE WITH TOAST MESSAGES
      displayResponseError(response);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function downloadFileAPI(param) {
  let token = localStorage.getItem("id_token");
  console.log(param);

  // let formData = new FormData();

  // Object.entries(param).map(([key,value]) => {formData.append(key, value)});

  // console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("download", param, { responseType: "blob" });
    if (response != undefined) {
      console.log("Response from API :: ", response.data);
      if (response.status == 200 && response.data.status == "success") {
        // window.open('/download/'+id);
        //toast.success("Donor Document downloaded Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getDonorAPI(id) {
  let donor = undefined;
  let token = localStorage.getItem("id_token");
  let id_donor = id;

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("donor/" + id_donor);
    if (response.status == 200) {
      console.log(
        "Response from  getDonor API *:: ",
        JSON.stringify(response.data.response)
      );
      donor = response.data.response.donor;
      if (response.data.response.donor[0] != undefined)
        donor["donor_id"] = response.data.response.donor[0].id;
      return donor;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return donor;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getOrdersAPI(param) {
  let donor = undefined;
  let token = localStorage.getItem("id_token");
  let id_donor = param.id;

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("donor/orders/" + id_donor);
    if (response.status == 200) {
      console.log("Response from getOrders API :: ", response.data.response);
      return response.data.response.orders;
    } else {
      // DISPLAY RESPONSE WITH TOAST MESSAGES
      displayResponseError(response);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getDonationAPI(param) {
  let token = localStorage.getItem("id_token");
  let id_order = param.id;

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("order/" + id_order);
    if (response.status == 200) {
      console.log("Response from getOrders API :: ", response.data.response);
      return response.data.response.order;
    } else {
      // DISPLAY RESPONSE WITH TOAST MESSAGES
      displayResponseError(response);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function addDonationAPI(param) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("order", param);
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Donation Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}
