import React from "react";
import { Component } from "react";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveIcon from "@material-ui/icons/Remove";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import DownloadIcon from "@material-ui/icons/GetApp";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import axios from "axios";
import API, { API_Param } from "../../API";
import {
  getMembersAPI,
  getMemberAPI,
  getMemberServicesAPI,
  setMemberServicesAPI,
} from "../../APIs/memberApis";

import {
  getServicesAPI,
} from "../../APIs/serviceApis";



// COMPONENTS

import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";

import { Trans } from "react-i18next";
import i18n from "../../locales/locales_config";

import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
} from "../../APIs/ApiUtils";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

export default class AddMemberServices extends Component {
  constructor() {
    super();
    this.state = {
      memberInfo: [],
      loader: false,
      deleteDialog: false,
      isSubmitLoading: false,
      isError: false,
    };
  }

  componentDidMount() {
    this.setStateVariables("price", {checkEmpty: true,minLength: 0,maxLength: 20,});
    this.setStateVariables("service_id",{ checkEmpty: false },"SelectField","ACTIVE",);
    this.handleGetServices();
    this.handleGetMember();    
  }

  setStateVariables = (
    param,
    validation = { checkEmpty: false },
    type = "TextField",
    value = "",
    error = false,
  ) => {
    let paramLabel = param + "Label";
    let paramID = param + "ID";
    let paramPlaceHolder = param + "PlaceHolder";
    let paramValue = param + "Value";
    let paramErrorStatus = param + "ErrorStatus";
    let paramErrorMsg = param + "ErrorMsg";
    let paramFieldType = param + "FieldType";
    let paramFieldValidation = param + "FieldValidation";

    let transLabel = "CommonField." + param + "Label";
    let transPlaceHolder = "CommonField." + param + "PlaceHolder";
    let transErrorMsg = "CommonField." + param + "ErrorMsg]";

    this.setState({
      [paramLabel]: <Trans i18nKey={transLabel}></Trans>,
      [paramID]: param,
      [paramPlaceHolder]: <Trans i18nKey={transPlaceHolder}></Trans>,
      [paramValue]: value,
      [paramErrorStatus]: error,
      [paramErrorMsg]: <Trans i18nKey={transErrorMsg}></Trans>,
      [paramFieldType]: type,
      [paramFieldValidation]: validation,
    });
  };

  render() {
    const components = {
      // ExpandButton: function(props) {
      //   return <DeleteIcon {...props} />;
      // },
      icons: {
        SearchIcon,
        PrintIcon,
        DownloadIcon,
        ViewColumnIcon,
        FilterIcon,
      },
    };

    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={4}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>AddServicePage.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/profile/member/edit/" + this.props.match.params.itemId);
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid item xs={12} className={{ justifyContent: "center" }}>
                <Paper style={{ padding: 16 }}>
                  <Grid item xs={12}>
                    <Typography
                      color="primary"
                      variant="h5"
                      size="sm"
                      style={{ borderBottom: "1px solid #000" }}
                    >
                      Partner Portfolio Services
                    </Typography>
                  </Grid>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >

                      <Grid item xs={12} sm={6} md={6} className={{ justifyContent: "flex-start" }} >
                        <CustomSelectField
                          paramValue="service_id"
                          state={this.state}
                          onChange={this.handleParam}
                          ItemValues={(this.state.servicesList!=undefined)?this.state.servicesList:[]}
                        />
                      </Grid> 
                      
                    </Grid>
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="price"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    
                  </Grid>

                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>
                        {this.state.isSubmitLoading ? (
                          <CircularProgress size={26} />
                        ) : (
                          <Button
                            onClick={this.handleSubmit}
                            // disabled={
                            //   loginValue.length === 0 ||
                            // }
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{
                              justifySelf: "center",
                              alignSelf: "center",
                            }}
                          >
                            Add Service To Profile
                          </Button>
                        )}
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
        {/* <Grid item xs={12}>
            <Widget title="Material-UI Table" upperTitle noBodyPadding bodyClass={classes.tableOverflow}>
              <Table data={mock.table} />
            </Widget>
          </Grid> */}
      </>
    );
  }

  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  handleGetMember = async () => {
    let RC = false;
    this.setState({ loader: true, memberInfo: [] });
    let member_id = this.props.match.params.itemId;

    let memberInfo = await getMemberAPI(member_id);
    if (memberInfo != undefined) {
      console.log("Member Found Successfully");
      this.setState({
        memberInfo: memberInfo,
        loader: false,
      });
    } else {
      console.log("Member Not found");
      this.setState({ loader: false });
    }
  };

  handleGetServices = async () => {
    let RC = false;
    let tmpServices = [];
    this.setState({ loader: true, servicesList: [] });
    let {status, response} = await getServicesAPI();
    if (status == "S_OK" ) {
      response.forEach(service => {
        let tmpArr = {label:service.title,value:service.id};
        tmpServices.push(tmpArr);
      });

      this.setState({
        servicesList: tmpServices,
        loader: false,
      });
    } else {
      console.log("Services Not found");
      this.setState({ loader: false });
    }
  };

  handleCreateMemberServices = async (param) => {
    console.log("createMember API Started");
    let member_id = this.props.match.params.itemId;
    param.member_id = member_id;
    let rc = await setMemberServicesAPI(param);
    if (rc == true) {
      console.log("Member Document Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/profile/partner/edit/" + this.props.match.params.itemId);
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Member Document  Not Added");
    }
    console.log("createMemberServices API ENDED");
  };

  handleSubmit = () => {
    let param = {};

    // GET THE VALUES ONLY
    Object.entries(this.state).map(([key, value]) => {
      if (key.includes("Value")) param[key.replace("Value", "")] = value;
    });

    console.log("<INFO> Param Value ", param);
    let errors = ValidateFields(this);
    console.log("Error exist of Not ", errors);

    if (errors) {
      return false;
    }
    this.setState({ isSubmitLoading: true });

    this.handleCreateMemberServices(param);
  };

  handleParam = (e, paramKey, directValue = false) => {
    let value = e;
    if (!directValue)
      if (e.target != null && e.target.value != undefined)
        value = e.target.value;

    let paramKeyError = paramKey + "ErrorStatus";

    this.setState((prevState, props) => ({
      ...prevState,
      [paramKey + "Value"]: value,
      [paramKeyError]: false,
    }));

    let isExist = isErrorExist(this.state);
    if (this.state.isError && !isExist) this.setState({ isError: false });
  };

  handleFileClose = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: false,
    });
  };

  handleFileSave = (param, files) => {
    console.log("Current File List :: ", param, files[0]);
    this.setState({
      ...this.state,
      [param + "Value"]: files[0],
      [param + "MediaOpen"]: false,
    });
  };

  handleFileOpen = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: true,
    });
  };
}
