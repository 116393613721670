import React, { Fragment, useState, useEffect, useParams } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
  useHistory,
} from "react-router-dom";
// import OwlCarousel from 'react-owl-carousel';  
// import 'owl.carousel/dist/assets/owl.carousel.css';  
// import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import ReactDOM from 'react-dom';

// MEDIA FILES
import imgBackgroundTestimonials from "../../../assets/images/testimonials_background.jpg";
import imgTestimonialUser from "../../../assets/images/testimonials_user.jpg";
//carsole
import 'bootstrap/dist/css/bootstrap.css';
import Carousel from 'react-bootstrap/Carousel';
//testimonials
import testimonia1 from '../../../assets/images/logos/Logo-2.png'
import testimonia2 from '../../../assets/images/logos/Logo-3.png'
import testimonia3 from '../../../assets/images/logos/Logo-4.png'
import testimonia4 from '../../../assets/images/logos/Logo-5.png'
import ads from '../../../assets/images/Ads.png';


// FOR APIS
import {
  deleteStartupAPI,
  getStartupsAPI,
  getStartups,
  getStartupsPaginationAPI
} from "../../../APIs/startupApis";

import queryString from "query-string";
import CStartup from '../../../components/startups/cStartup';


function Testimonials() {
  const images = [testimonia1, testimonia2, testimonia3, testimonia4]; // Replace with your image URLs
  const history = useHistory();
  const [startupsTable, setStartupsTable] = useState([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);

  useEffect(() => {
    handleGetStartups();
  }, []);

  return (
    <Fragment>
      <div className="testimonials page_section">
        {/* <div className="testimonials_background" style="background-image:url(images/testimonials_background.jpg)"></div> */}
        {/* <div className="testimonials_background_container prlx_parent">
            <div className="testimonials_background prlx" >
              <img src={imgBackgroundTestimonials}></img>
            </div>
          </div> */}
        <div className="container">


          <div className="row">
            <div className="col-md-2 d-none d-md-block">
              <div className="testimonials_slider_container">
                <Carousel className="left-slider">
                  {startupsTable.map((item, index) => {
                    return (<Carousel.Item interval={9000}>
                      {index}
                      <div className="d-flex flex-row justify-content-center">
                        <div className="mx-3">
                          <img
                            className="d-block w-100"
                            width="200px"
                            height="400px"
                            src={ads}
                            alt={`Image ${index}`}
                          />
                        </div>

                      </div>
                    </Carousel.Item>)
                  })}
                </Carousel>
              </div>
            </div>
            <div className="col-md-6 offset-md-1">
              <div className="row">
                <div className="col">
                  <div className="section_title text-center">
                    <h1>Our Basket Of Startups</h1>
                  </div>
                </div>
              </div>

              <div className="testimonials_slider_container">
                {/* Testimonials Slider */}
                <Carousel>
                  {startupsTable.map((item, index) => {
                    return (<Carousel.Item interval={3000}>
                      {index}
                      <div className="d-flex flex-row justify-content-center">
                          <div className="mx-3 d-none d-md-block">
                            <img
                              className="d-block w-100"
                              width="200px"
                              height="auto"
                              src={startupsTable[index]}
                              alt={`Image ${index}`}
                            />
                          </div>

                        <div className="mx-3">
                          <img
                            className="d-block w-100"
                            width="200px"
                            height="auto"
                            src={(index >= (startupsTable.length - 1)) ? startupsTable[index - startupsTable.length + 1] : startupsTable[index + 1]}
                            alt={`Image ${index + 1}`}
                          />
                        </div>

                          <div className="mx-3 d-none d-md-block">
                            <img
                              className="d-block w-100"
                              width="200px"
                              height="auto"
                              src={(index >= (startupsTable.length - 2)) ? startupsTable[index - startupsTable.length + 2] : startupsTable[index + 2]}
                              alt={`Image ${index + 2}`}
                            />
                          </div>

                      </div>
                      {/* <Carousel.Caption>
                          <h3>Label for first slide</h3>
                          <p>Sample Text for Image One</p>
                        </Carousel.Caption> */}
                    </Carousel.Item>)
                  })}
                </Carousel>
              </div>
              {/* </Carousel> */}
            </div>
            <div className="col-md-2 d-none d-md-block offset-md-1">
              <div className="testimonials_slider_container">
                <Carousel className="right-slider">
                  {startupsTable.map((item, index) => {
                    return (<Carousel.Item interval={10000}>
                      {index}
                      <div className="d-flex flex-row justify-content-center">
                        <div className="mx-3">
                          <img
                            className="d-block w-100"
                            width="200px"
                            height="400px"
                            src={ads}
                            alt={`Image ${index}`}
                          />
                        </div>
                      </div>
                    </Carousel.Item>)
                  })}
                </Carousel>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );

  async function handleGetStartups() {
    let RC = false;
    setIsFormLoading(true);
    let page = 1;
    let limit = 3;

    // let queries = queryString.parse(history.location.search);
    // let page = queries.page != undefined ? queries.page : 1;
    // let limit = queries.limit != undefined ? queries.limit : 9;
    let iFeaturedOnly = true;
    let startups = await getStartupsPaginationAPI(limit, page, { isPortfolioStartups: true });
    if (startups != undefined) {
      console.log("<handleGetStartups> Startups Found Successfully ::", startups.length);
      var tmpProfileThumb = [];
      startups.data.map((item) => {
        if (item.profilethumbnail != undefined) {
          tmpProfileThumb.push(item.profilethumbnail);
        }
      });
      setStartupsTable(tmpProfileThumb);
      setIsFormLoading(false);
      setPage(page);
      setLimit(limit);
    } else {
      console.log("<handleGetStartups> Startup Not found");
      setIsFormLoading(false);
    }
  }

}

export default Testimonials;
