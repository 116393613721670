import React, { Children, Fragment, useEffect, useState } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
  useHistory, useLocation, useParams, withRouter
} from "react-router-dom";

import PageSideBar from "../../../components/PageSidebar";

// IMAGE/MEDIA
import ServiceImage from "../../../assets/images/User-Profile/User-1.jpg";
import Img01 from "../../../assets/images/User-Profile/User-1.jpg"
import Img02 from "../../../assets/images/User-Profile/User-2.jpg"
import Img03 from "../../../assets/images/User-Profile/User-3.jpg"
import Img04 from "../../../assets/images/User-Profile/User-4.jpg"

import singleBlogImage1 from "../../../assets/images/photos/singleBlogImage1.jpg"
import singleBlogImage2 from "../../../assets/images/photos/singleBlogImage2.jpg"
import singleBlogImage3 from "../../../assets/images/photos/singleBlogImage3.jpg"

// FOR APIS
import {
  getServiceAPI,
  getServiceMembersAPI,
  getChildServicesAPI
} from "../../../APIs/serviceApis";


import { showImageAPI } from "../../../APIs/ApiUtils";
// CUSTOM COMPONENTS
import CircularStatic from '../../../components/Controls/CircularProgressBar';
import CustomPaginationBar from "../../../components/Controls/CustomPaginationBar";
import SingleMember from '../../../components/members/single_member'
import IMAGE_BG from "../../../assets/images/teachers_background.jpg";


import {
  createMarkup,
} from '../../../helpers';
import { isNull } from "lodash";

function Homepage() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  let startupID = localStorage.getItem("persona_id");
  const [serviceID, setServiceID] = useState(undefined);
  const [serviceInfo, setServiceInfo] = useState(undefined);
  const [servicesTable, setServicesTable] = useState(undefined);
  const [membersTable, setMembersTable] = useState(undefined);
  const [memberID, setMemberID] = useState(5);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [pagination, setPagination] = useState(undefined);
  const [curPage, setCurPage] = useState(undefined);
  useEffect(() => {
    // let isLoggedIn = localStorage.getItem("id_token");
    // if (!isLoggedIn)
    //   history.push("/login");
    window.scrollTo(0, 0);

    setServiceID(params.serviceId);
    initialize(params.serviceId);
    imageLoad();
    window.scrollTo(0, 0);
  }, []);


  async function initialize(iServiceID) {
    handleGetService(iServiceID);
    handleGetChildServices(iServiceID);
    handleGetServiceMembers(iServiceID);
  }


  async function imageLoad() {
    var imageData = await showImageAPI({ filePath: "member//1671967506_memberprofilefile.jpg" });
    console.log("Image Load Data", imageData);
  }

  function handleViewAllMembers() {
    history.push("/members");
  }

  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center", }}>
          {/* <CircularProgress variant="indeterminate" value={75} /> */}
          <CircularStatic value={25} />
        </div>
      ) : (
        <Fragment>
          <div className="home innerpage">
            <div className="home_background_container prlx_parent">
              <div className="home_background prlx" >
                <img src={IMAGE_BG} />
              </div>
            </div>
            <div className="home_content">
              <h1>{serviceInfo?.title}</h1>
            </div>
          </div>

          <div className="container bg-white">
            <div className="row">
              <div className="col-12 p-1">
                <div className="container single-service">
                  <div className="row single-service-content">
                    <div className="col-lg-12 ">
                      {(serviceInfo?.description.length != 0 && serviceInfo?.description != "<p></p>") ?

                        <div className="row">
                          {/* <div className="col-12 col-lg-2 p-1 text-center">
                              <div className="title">
                                  {serviceInfo!=undefined ? serviceInfo.title :""} 
                                  <span className="highlight ml-2">{"  "}</span>
                              </div>
                          </div> */}
                          <div className="col-12 p-1 align-center">
                            <div className="text">
                              <p dangerouslySetInnerHTML={createMarkup(serviceInfo != undefined ? serviceInfo.description : "")} ></p>
                            </div>
                          </div>
                        </div> : ""
                      }

                      {(servicesTable != undefined && servicesTable.length > 0) &&
                        <div className="row single-service-listing">
                          <div className="col-lg-12 ">
                            <div className="row case-section">
                              <div className="col-12 p-1 ">
                                <div className="case-title text-center">
                                  {(serviceInfo?.title != "Advisory") ? serviceInfo?.title : serviceInfo?.title}
                                  {/* <span className="highlight">Services</span> */}
                                </div>
                              </div>
                              <div className="col-12 p-1 ">
                                <div className="text text-center mt-3 mb-3">
                                  {/* Developing a successful digital product is a complex process that requires choosing the right partner, applying innovative solutions, and following reliable processes. */}
                                </div>
                              </div>
                            </div>
                            <div className="row service-related-study">
                              {
                                servicesTable?.map(row => {
                                  // if(row.id != serviceID ){
                                  return (
                                    <div className="col-lg-3 ">
                                      <div className="card mb-5">
                                        <Link onClick={() => { history.push("/service/" + row.id); initialize(row.id); }} className="mt-3"  >
                                          <img className="card-icon-top" src={row.profilethumbnail} alt={row.title} />
                                          <div className="card-body pb-2">
                                            <div className="card-title text-center">{row.title}</div>
                                            <div className="card-text text-center">{row.excerpt}</div>
                                            <div className="button button_primary_outline rounded trans_200 mt-3"  >
                                              <span>View Service</span>
                                            </div>
                                          </div>
                                        </Link>
                                      </div>
                                    </div>
                                  )
                                  // }
                                })
                              }
                            </div>
                          </div>
                        </div>
                      }

                      {/* <div className="row case-section">
                          <div className="col-12 ">
                              <div className="case-title text-center">
                              {serviceInfo!=undefined ? serviceInfo.title :""} <span className="highlight">case studies</span>
                              </div>
                          </div>
                          <div className="col-12 p-1 ">
                              <div className="text text-center mt-3">
                              {serviceInfo!=undefined ? serviceInfo.excerpt :""}
                              </div>
                          </div>
                      </div> */}
                      {/* <div className="row service-case-study">
                          <div className="col-lg-4 p-1">
                            <Link to="/singleblog">

                            <div className="card card-bg-1">
                            <img className="card-img-top" src={singleBlogImage1} alt="https://unsplash.com/@kellybrito" />
                            <div className="card-body pb-2">
                                <div className="card-title">Habes, inquam, Cato, formam eorum, de quibus</div>
                                <div className="card-text">n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n het...</div>
                                <Link className="button button_white_outline rounded"><span>Read More</span></Link>
                            </div>  
                            </div>
                            </Link> 
                          </div>
                          <div className="col-lg-4  p-1">
                          <Link to="/singleblog">

                              <div className="card card-bg-2">
                              <img className="card-img-top" src={singleBlogImage2} alt="https://unsplash.com/@kellybrito" />
                              <div className="card-body pb-2">
                                <div className="card-title">Habes, inquam, Cato, formam eorum, de quibus</div>
                                <div className="card-text">n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n het...</div>
                                <Link className="button button_white_outline rounded"><span>Read More</span></Link>

                              </div>  
                              </div>
                              </Link> 

                          </div>
                          <div className="col-lg-4  p-1">
                              <Link to="/singleblog">
                                <div className="card card-bg-3">
                                  <img className="card-img-top" src={singleBlogImage3} alt="https://unsplash.com/@kellybrito" />
                                  <div className="card-body pb-2">
                                    <div className="card-title">Habes, inquam, Cato, formam eorum, de quibus</div>
                                    <div className="card-text">n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n het...</div>
                                    <Link className="button button_white_outline rounded"><span>Read More</span></Link>

                                  </div> 
                                </div>
                              </Link> 

                          </div>                                        
                      </div> */}
                    </div>
                  </div>
                  {
                    <div className="row single-service-mentors">
                      <div className="col-lg-12 ">
                        <div className="popular page_section">
                          {
                            (serviceInfo?.id != "10" && serviceInfo?.parent_id != undefined) ?
                              <div className="container">
                                <div className="row">
                                  <div className="col">
                                    <div className="section_title text-center">
                                      <h1>Partners For
                                        {(serviceInfo?.title != "Advisory") ? " " + serviceInfo?.title : " " + serviceInfo?.title}
                                        {/* {(serviceInfo?.title!="Advisory")? " " + serviceInfo?.title + " Advisory": " " + serviceInfo?.title}   */}
                                      </h1>

                                    </div>
                                  </div>
                                </div>

                                <div className="row cls-partner-card">
                                  {
                                    membersTable?.map(row => {
                                      row["serviceid"] = serviceID;
                                      return (
                                        <div className="col-lg-4 course_box">
                                          <SingleMember data={row} />
                                        </div>
                                      )
                                    })
                                  }
                                </div>
                                <div className="row text-center mt-5">
                                  <div className="paginationBar">
                                    <ul className="pagination modal-4">
                                      {pagination != undefined && pagination.map((item, index) => {
                                        return (<CustomPaginationBar totalPages={pagination.length} item={item} id={index} key={index} getServiceMembers={handleGetServiceMembers} serviceId={serviceID} pagination={pagination} page={curPage} />)
                                      })}
                                    </ul>
                                  </div>
                                  {/* <div className="col-12">
                                    <Link to={"/members/" + serviceID} className="button button_primary trans_200" >
                                      <span className="p-2">View All Partners</span>
                                    </Link>
                                  </div> */}
                                </div>
                              </div>
                              : (serviceInfo?.id == "10") ?
                                <div className="d-flex justify-content-center">
                                  <Link className="button button_primary btn-sm mb-2" to={"/fund-raising-form"}>
                                    <span>Click to Proceed</span>
                                  </Link>
                                </div> : ""
                          }
                        </div>
                      </div>
                    </div>
                  }

                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );

  // $(window).bind("pageshow", function (event) {
  //   if (event.originalEvent.persisted) {
  //     window.location.reload();
  //   }
  // });


  // /* If browser back button was used, flush cache */
  // (function () {
  //   window.onpageshow = function (event) {
  //     if (event.persisted) {
  //       window.location.reload();
  //     }
  //   };
  // })();

  function handleOnClickService(iServiceID) {
    history.replace("/service/" + iServiceID);
    // setServiceID(iServiceID);
  }

  async function handleGetService(iServiceID) {
    let RC = false;
    setIsFormLoading(true);
    setServiceInfo(undefined);

    let { status, response } = await getServiceAPI(iServiceID)
    if (status == "S_OK") {
      console.log("Service Found Successfully ::", response);
      setServiceInfo(response);
      setIsFormLoading(false);
    } else {
      console.log("Service Not found");
      setIsFormLoading(false);
    }
  }

  async function handleGetChildServices(iServiceID) {
    let RC = false;
    setIsFormLoading(true);
    setServicesTable(undefined);
    // let queries = queryString.parse(history.location.search);
    // let page = queries.page != undefined ? queries.page : 1;
    // let limit = queries.limit != undefined ? queries.limit : 9;
    let { status, response } = await getChildServicesAPI(history, { parentId: iServiceID });
    if (status == "S_OK") {
      console.log("Services Found Successfully ::", response.length);
      setServicesTable(response);
      setIsFormLoading(false);
      // setPage(page);
      // setLimit(limit);
    } else {
      console.log("Service Not found");
      setIsFormLoading(false);
    }
  }

  async function handleGetServiceMembers(iServiceID, iPage) {
    let RC = false;
    setIsFormLoading(true);
    setMembersTable(undefined);

    let page = iPage != undefined ? iPage : 1;
    let iParam = {
      service_id: iServiceID,
      page: curPage
    };
    let { status, response } = await getServiceMembersAPI(iParam);
    if (status == "S_OK") {
      console.log("Members Found Successfully ::", response.members);
      setMembersTable(response?.members);
      setPagination(response.members.links)
      setIsFormLoading(false);
      // setPage(page);
      // setLimit(limit);
    } else {
      console.log("Members Not found");
      setIsFormLoading(false);
    }
  }

}

export default Homepage;
