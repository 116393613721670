import React, { Fragment } from "react";
import { HashRouter, Route, Switch, Redirect, Link } from "react-router-dom";


import Footer from "../../components/footer";
import Header from "../../components/header";
import PageSideBar from "../../components/PageSidebar.js";
import Members from "./components/members";
import IMAGE_BG from "../../assets/images/teachers_background.jpg";


function Homepage() {
  window.scrollTo(0, 0);
  return (
    <Fragment>
      <div className="single-page about-page">
        <Header />
          <div className="home innerpage">
          <div className="home_background_container prlx_parent">
          <div className="home_background prlx" >
                {/* <div
                  style={{
                    height: "450px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "90px",
                      width: "290px",
                      backgroundColor: "yellow",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: 58,
                      }}
                    >
                      About Us
                    </h1>
                  </div>
                </div> */}
              </div>
          </div>
          <div className="home_content">
            <h1>Our Partners</h1>
          </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <Members />
              </div>
            </div>
          </div>
        <Footer />
      </div>
    </Fragment>
  );
}

export default Homepage;
