import React, {useContext} from "react";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import Homepage from "../pages/homepage/homepage";

// components
import Layout from "./Layout";

// pages
import AboutUs from "../pages/aboutus/aboutus";
import TermsCondition from "../pages/faqs/terms-conditions";
import News from "../pages/blogs/blogs";
import SingleBlog from "../pages/blogs/singleblog";
import ContactUs from "../pages/contactus/Contact";
import Error from "../pages/error";
import FAQs from "../pages/faqs/FAQs.js";

import Login from "../pages/login";
import ForgetPassword from "../pages/login/forgetpassword";
import ResetPassword from "../pages/login/resetpassword";
import RegisterUser from "../pages/login/registeruser";


import Members from "../pages/members/members";
import MembersByService from "../pages/members/membersbyservice";

import Member from "../pages/members/member";
import MemberSchedule from "../pages/members/member_schedule";
import Services from "../pages/services/services";
import Service from "../pages/services/service";
import Startups from "../pages/startups/startups";
import Startup from "../pages/startups/startup";

import MemberPaymentInfo from "../pages/membership/paymentinfo";
import MemberPaymentSuccess from "../pages/membership/paymentsuccess";

import CheckoutMember from "../pages/checkout/checkout";
import DonationInfo from "../pages/orders/order_info";
import PaymentInfo from "../pages/orders/payment_info";
import PaymentSuccess from "../pages/checkout/checkout_success";


// context
import { useUserState } from "../context/userContext";
import { SiteInfoProvider } from "../context/siteInfoContext";
import FundRaisingForm from "../pages/services/fund-raising-form";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
toast.configure();


export default function App() {
  // global
  var { isAuthenticated } = useUserState();
  // const siteInfoContext = createContext()

  return (
    <HashRouter>
      <Switch>
        <Route exact path="/" component={Homepage} />

        {/* AUTH ROUTES */}
        <PublicRoute path="/login" component={Login} />
        <PublicRoute path="/forgetpassword" component={ForgetPassword} />        
        <PublicRoute path="/resetPassword" component={ResetPassword} />
        <PublicRoute path="/registeruser" component={RegisterUser} />

        {/* SERVICES ROUTES */}
        <Route path="/services" component={Services} exact />
        <Route path="/service/:serviceId" component={Service} />
        <Route path="/fund-raising-form" component={FundRaisingForm} />

        {/* MEMBERS ROUTES */}
        <Route path="/members/:serviceId" component={MembersByService} exact />
        <Route path="/members" component={Members} exact />
        <Route path="/member/:memberId" component={Member} />

        {/* STARTUPS ROUTES */}
        <Route path="/startups" component={Startups} />
        <Route path="/startup/:startupId" component={Startup} />

        <PrivateRoute path="/profile" component={Layout} />
        <PrivateRoute path="/memberschedule/:memberId/:serviceId" component={MemberSchedule} />
        <PrivateRoute path="/checkout/:memberId/:serviceId/:appointmentId" component={CheckoutMember} />        
        <PrivateRoute path="/orderinfo" component={DonationInfo} />
        <PrivateRoute path="/checkoutpaymentsuccess/:bookingId" component={PaymentSuccess} />
       
        <PrivateRoute path="/membership/payment/:startupId" component={MemberPaymentInfo} exact />
        <PrivateRoute path="/membership/paymentstatus/:startupId/:amount/:paymentId/:orderId/:orderDate" component={MemberPaymentSuccess} exact />

        <PrivateRoute path="/paymentinfo" component={PaymentInfo} />
        <Route exact path="/aboutus" component={AboutUs} />
        <Route path="/news" component={News} />
        <Route path="/FAQs" component={FAQs} />        
        <Route path="/contactus" component={ContactUs} />
        <Route path="/terms-condition" component={TermsCondition} />
        <Route path="/singleblog/:blogId" component={SingleBlog} />
        
      </Switch>
    </HashRouter>
  );

  // #######################################################################

  function PrivateRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <SiteInfoProvider >
              { React.createElement(component, props) } 
            </SiteInfoProvider>
          ) : (
            <Redirect
              to={{
                pathname: "/login",
                state: {
                  from: props.location,
                },
              }}
            />
          )
        }
      />
    );
  }

  function PublicRoute({ component, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Redirect
              to={{
                pathname: "/",
              }}
            />
          ) : (
            <SiteInfoProvider >
            { React.createElement(component, props) } 
            </SiteInfoProvider>            
          )
        }
      />
    );
  }
}
