import React from "react";
import axios from "axios";
import API, { API_Param } from "../API";
import { toast } from "react-toastify";

export  { createOrderAPI, 
          getOrderAPI, 
          createPaymentAPI, 
          getPaymentAPI,
          updateOrderAPI,
          createWalletAPI,
          getWalletsByUserAPI
        };

async function getOrderAPI(order_id) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("order/" + order_id);
    if (response.status == 200) {
      console.log("Response from  Payment Order API :: ", response.data);
      return response.data.response.order;
    } else {
      console.log("Payment Order API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("Payment Order API Request Failed With Status :: ", error);
  }
  return false;
}

async function getPaymentAPI(data) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("payment/" + data.payment_id);
    if (response.status == 200) {
      console.log("Response from  Payment  API :: ", response.data);
      return response.data;
    } else {
      console.log("Payment Order API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("Payment Order API Request Failed With Status :: ", error);
  }
  return false;
}

async function createPaymentAPI(data) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.post(
      "payment", data
    );
    if (response.status == 200) {
      console.log("Response from  Payment API :: ", response.data);
      return response.data.data;
    } else {
      console.log("Payment API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("payment API Request Failed With Status :: ", error);
    return false;
  }
}

async function createOrderAPI(data) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.post(
      "order", data
    );
    console.log('<INFO> Response from verify :: ',response);
    return response.data.response;
  } catch (error) {
    console.log("payment API Request Failed With Status :: ", error);
    return false;
  }
}

async function updateOrderAPI(iOrderId, data) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    let response = await API.post(
      "order/" + iOrderId + "?_method=PUT" , data
    );
    console.log('<INFO> Response from verify :: ',response);
    return response.data.response;
  } catch (error) {
    console.log("Order API Request Failed With Status :: ", error);
    return false;
  }
}


async function getWalletsByUserAPI(data) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("wallet/user/" + data.startup_id, {params: data});
    if (response.status == 200) {
      console.log("Response from  Wallets  API :: ", response.data);
      return response.data.response;
    } else {
      console.log("Wallet API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("Wallet API Request Failed With Status :: ", error);
  }
  return false;
}



async function createWalletAPI(data) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.post(
      "wallet", data
    );
    console.log('<INFO> Response from Wallet API :: ',response);
    return response.data.response;
  } catch (error) {
    console.log("payment API Request Failed With Status :: ", error);
    return false;
  }
}


