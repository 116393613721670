import React, { Fragment, useState, useEffect } from "react";
import { HashRouter, Route, Switch, Redirect, Link, useHistory, useParams } from "react-router-dom";
import { Tab, Tabs, Box, Typography } from '@material-ui/core';
import MUIDataTable from "mui-datatables";

import ProfileIcon from "@material-ui/icons/Person";
import CompanyIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/GroupAdd";
import DocumentsIcon from "@material-ui/icons/NoteAdd";


import Footer from "../../components/footer";
import Header from "../../components/header";
import PageSideBar from "../../components/PageSidebar.js";
import IMAGE_BG from "../../assets/images/User-Profile/User-1.jpg";

// CUSTOM COMPONENTS
import CircularStatic from '../../components/Controls/CircularProgressBar';

// FOR APIS
import {
  getStartupAPI,
  getCoFoundersAPI,
  getStartupDocumentAPI
} from "../../APIs/startupApis";


import {
  getImageAPI,
} from "../../APIs/ApiUtils";

import {
  downloadFile,
  createMarkup
} from "../../helpers";


import queryString from "query-string";
import { PanoramaSharp } from "@material-ui/icons";

function Homepage() {
  window.scrollTo(0, 0);
  const history = useHistory();
  const params = useParams();
  const [startupId, SetStartupId] = useState(undefined);
  const [startupInfo, SetStartupInfo] = useState(undefined);
  const [cofoundersInfo, SetCofoundersInfo] = useState(undefined);
  const [documentsInfo, SetDocumentsInfo] = useState(undefined);
  const [profileImage, SetProfileImage] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);
  const [tabIndex, setTabIndex] = React.useState(0);

  useEffect(() => {
    handleGetStartup();
    handleGetCoFounders();
    handleGetStartupDocument();
  }, []);


  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };


  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center", }}>
          <CircularStatic value={25} />
        </div>
      ) : (
        <Fragment>
          <div className="container emp-profile">
            <form method="post">
              <div className="row">
                <div className="col-md-3">
                  <div className="profile-img">
                    <img src={(profileImage != undefined) ? profileImage : IMAGE_BG} alt="" />
                    {/* <div className="file btn btn-lg btn-primary">
                          Change Photo
                          <input type="file" name="file"/>
                      </div> */}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="profile-head">
                    <h5>{startupInfo?.companyname}</h5>
                    <h6>{startupInfo?.tagline}</h6>
                    <p className="proile-rating">Rating : <span>8/10</span></p>

                  </div>
                </div>
                <div className="col-md-2">
                  <button onClick={() => { history.push("/profile/startup/edit/" + startupInfo.id); }} id="contact_send_btn" type="button" className="contact_send_btn trans_200" value="Submit">Update Info</button>
                </div>

              </div>
              <div className="row">
                <div className="col-md-12 col-lg-3 order-2 order-lg-1">
                  <div className="profile-work">
                    <p>DOCUMENTS</p>
                    <div className="cls-doc-download mb-1" onClick={() => { downloadFile(startupInfo?.ndadoc, "NDA-Document") }}><i className="fa fa-file-text" aria-hidden="true"></i>{"  "}NDA Document</div>
                    <div className="cls-doc-download mb-1" onClick={() => { downloadFile(startupInfo?.businessplan, "Business-Plan") }}><i className="fa fa-file" aria-hidden="true"></i>{"  "}Business Plan</div>
                    <div className="cls-doc-download mb-1" onClick={() => { downloadFile(startupInfo?.contractdoc, "Contract-File") }}><i className="fa fa-file-text" aria-hidden="true"></i>{"  "}Contract Document</div>
                    <p>TAGS</p>
                    {(startupInfo != undefined) ? (startupInfo.tags.map(tag => {
                      return <span><a href="#">{tag.name}</a><br /></span>
                    })) : ''}

                  </div>
                </div>
                <div className="col-md-12 col-lg-9 order-1 order-lg-2">
                  <Tabs
                    value={tabIndex}
                    onChange={handleChange}
                    variant="scrollable"
                    scrollButtons="auto"
                    aria-label="scrollable auto tabs example"
                  >
                    <Tab style={{ color: '#88BDBC' }} icon={<ProfileIcon />} label="Profile Info" />
                    <Tab icon={<CompanyIcon />} iconPosition="start" label="Company Info" />
                    <Tab icon={<PersonIcon />} iconPosition="end" label="Co-Founder/Team" />
                    <Tab icon={<DocumentsIcon />} iconPosition="bottom" label="Documents" />
                  </Tabs>
                  <div className="profile-tab">
                    <div
                      role="tabpanel"
                      hidden={tabIndex !== 0}
                      id={`simple-tabpanel-0`}
                      aria-labelledby={`simple-tab-0`}
                    >
                      <div className="p-2">
                        <div className="row">
                          <div className="col-md-4">
                            <label>Contact Name</label>
                          </div>
                          <div className="col-md-8">
                            <p>{(startupInfo != undefined) ? startupInfo.contactname : ""}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label>Contact Email</label>
                          </div>
                          <div className="col-md-8">
                            <p>{(startupInfo != undefined) ? startupInfo.contactemail : ""}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label>Phone</label>
                          </div>
                          <div className="col-md-8">
                            <p>{(startupInfo != undefined) ? startupInfo.contactphone : ""}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label>About Founder</label>
                          </div>
                          <div className="col-md-8">
                            <p dangerouslySetInnerHTML={createMarkup(startupInfo?.aboutcompany)}></p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      role="tabpanel"
                      hidden={tabIndex !== 1}
                      id={`simple-tabpanel-1`}
                      aria-labelledby={`simple-tab-1`}
                    >
                      <div className="p-2">
                        <div className="row">
                          <div className="col-md-4">
                            <label>Company Name</label>
                          </div>
                          <div className="col-md-8">
                            <p>{startupInfo?.companyname}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label>Category</label>
                          </div>
                          <div className="col-md-8">
                            <p>{startupInfo?.category?.title}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label>About Company</label>
                          </div>
                          <div className="col-md-8">
                            <p dangerouslySetInnerHTML={createMarkup(startupInfo?.aboutcompany)}></p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label>Operating Country</label>
                          </div>
                          <div className="col-md-8">
                            <p>{(startupInfo != undefined) ? startupInfo.operatingin : ""}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label>URL</label>
                          </div>
                          <div className="col-md-8">
                            <p>{(startupInfo != undefined) ? startupInfo.companyurl : ""}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label>Incorporation date</label>
                          </div>
                          <div className="col-md-8">
                            <p>{(startupInfo != undefined) ? startupInfo.incorporationdate : "NA"}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <label>Registration Address</label>
                            <p>{(startupInfo != undefined) ? startupInfo.address_id : "NA"}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      role="tabpanel"
                      hidden={tabIndex !== 2}
                      id={`simple-tabpanel-2`}
                      aria-labelledby={`simple-tab-2`}
                    >
                      <div className="p-2">
                        <MUIDataTable
                          title="Co-Founders"
                          data={cofoundersInfo}
                          columns={[
                            {
                              name: "id",
                              options: {
                                filter: false,
                                sort: false,
                                empty: true,
                                display: "excluded",
                              },
                            },
                            {
                              name: "name",
                              label: "Name",
                            },
                            {
                              name: "designation",
                              label: "Designation",
                            },
                            {
                              name: "partnershippercentage",
                              label: "Equity",
                            },
                            {
                              name: "aboutcofounder",
                              label: "About",
                            }
                          ]}
                          options={{
                            tableBodyMaxHeight: "400px",
                            selectableRows: false,
                            filter: false,
                            print: false,
                            download: false,
                            elevation: 0,
                            fixedHeader: true,
                            filter: false,
                            filterType: "dropdown",
                            responsive: "standard",
                            expandableRows: false,
                          }}
                        // components={components}
                        />
                      </div>
                    </div>
                    <div
                      role="tabpanel"
                      hidden={tabIndex !== 3}
                      id={`simple-tabpanel-3`}
                      aria-labelledby={`simple-tab-3`}
                    >
                      <div className="p-2">
                        <MUIDataTable
                          title="Uploaded Documents"
                          data={documentsInfo}
                          columns={[
                            {
                              name: "id",
                              options: {
                                filter: false,
                                sort: false,
                                empty: true,
                                display: "excluded",
                              },
                            },
                            {
                              name: "title",
                              label: "Title",
                            },
                            {
                              name: "description",
                              label: "Description",
                            },
                          ]}
                          options={{
                            tableBodyMaxHeight: "400px",
                            selectableRows: false,
                            fixedHeader: true,
                            filter: false,
                            print: false,
                            download: false,
                            elevation: 0,
                            filterType: "dropdown",
                            responsive: "standard",
                            expandableRows: false,
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Fragment>
      )}
    </>
  );




  async function handleGetCoFounders() {
    let RC = false;
    setIsFormLoading(true);
    let startup_id = params.itemId;
    let cofounders = await getCoFoundersAPI(startup_id);
    if (cofounders != undefined) {
      console.log("Co-Founders Found Successfully", cofounders);
      SetCofoundersInfo(cofounders);
      setIsFormLoading(false);
    } else {
      console.log("Co-Founders Not found");
      setIsFormLoading(false);
    }
  };

  async function handleGetStartupDocument() {
    let RC = false;
    setIsFormLoading(true);
    let startup_id = params.itemId;
    let documents = await getStartupDocumentAPI(startup_id);
    if (documents != undefined) {
      console.log("Documents Found Successfully", documents);
      SetDocumentsInfo(documents);
      setIsFormLoading(false);
    } else {
      console.log("Startup Document Not found");
      setIsFormLoading(false);
    }
  };

  async function handleGetStartup() {
    let RC = false;
    setIsFormLoading(true);

    let queries = queryString.parse(history.location.search);
    let page = queries.page != undefined ? queries.page : 1;
    let limit = queries.limit != undefined ? queries.limit : 9;
    let startup_id = params.itemId;
    let startupInfo = await getStartupAPI(startup_id);
    if (startupInfo != undefined) {
      console.log("Startup Found Successfully :: ",startupInfo);
      let param = {
        filePath: startupInfo["profileimage"],
        fileName: "profileimage",
      };
      console.log("param for getImageAPI:: ",startupInfo["profileimage"]);
      let blob = await getImageAPI(param);

      console.log("response from getImage is", blob);
      SetProfileImage(blob);
      SetStartupInfo(startupInfo);
      setIsFormLoading(false);
      setPage(page);
      setLimit(limit);
    } else {
      console.log("Startup Not found");
      setIsFormLoading(false);
    }
  }

}

export default Homepage;
