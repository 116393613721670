import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";
import { displayResponseError } from "./ApiUtils";
// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export {
  deleteInvestorAPI,
  getInvestorsPaginationAPI,
  getInvestorsAPI,
  createInvestorAPI,
  editInvestorAPI,
  getInvestorAPI,
};


async function getInvestorsPaginationAPI(limit, page) {
  let investors = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("investor/pagination/" + limit + "/" + page);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.investors.map((investor) => {
        let tmp = {};
        Object.entries(investor).map(([key, value]) => {
          tmp[key] = value;
        });
        investors.push(tmp);
      });
      return investors;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return investors;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}


async function deleteInvestorAPI(history, id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("investor/" + id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      //toast.success("investor Deleted Successfully");
      return true;
    } else {
      // DISPLAY RESPONSE WITH TOAST MESSAGES
      displayResponseError(response);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function createInvestorAPI(history, param) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("investor", param);
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("investor Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getInvestorsAPI() {
  let investors = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    let response = await API.get("investor");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.investors.map((investor) => {
        let tmp = {};
        Object.entries(investor).map(([key, value]) => {
          tmp[key] = value;
        });
        investors.push(tmp);
      });
      return investors;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return investors;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editInvestorAPI(id_investor, param) {
  let token = localStorage.getItem("id_token");
  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post(
      "investor/" + id_investor + "?_method=PUT",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("investor Edited Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function downloadFileAPI(param) {
  let token = localStorage.getItem("id_token");
  console.log(param);

  // let formData = new FormData();

  // Object.entries(param).map(([key,value]) => {formData.append(key, value)});

  // console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("download", param, { responseType: "blob" });
    if (response != undefined) {
      console.log("Response from API :: ", response.data);
      if (response.status == 200 && response.data.status == "success") {
        // window.open('/download/'+id);
        //toast.success("investor Document downloaded Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getInvestorAPI(id_investor) {
  let investor = undefined;
  let token = localStorage.getItem("id_token");
  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("investor/" + id_investor);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response);
      investor = response.data.response.investor;
      if (response.data.response.investor[0] != undefined)
        investor["investor_id"] = response.data.response.investor[0].id;
      return investor;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return investor;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}
