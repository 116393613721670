import React, { Fragment, useState, useEffect } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
  useHistory,
} from "react-router-dom";
// MEDIA FILES
// FOR APIS
import {
  sendContactUS
} from "../../../APIs/ApiUtils";
import CircularStatic from "../../../components/Controls/CircularProgressBar";




function Module() {
  const history = useHistory();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("Error Message");
  const [successMsg, setSuccessMsg] = useState("Success Message");
  const [nameUser, setNameUser] = useState("");
  const [emailUser, setEmailUser] = useState("");
  const [phoneUser, setPhoneUser] = useState("");
  const [messageUser, setMessageUser] = useState("");

  useEffect(() => {
  }, []);

  return (
    <Fragment>
        { /* Register */ }
        <div className="register">

          <div className="container-fluid">
            
            <div className="row row-eq-height">
              <div className="col-lg-6 nopadding">
                
                { /* Register */ }

                <div className="register_section d-flex flex-column align-items-center justify-content-center">
                  <div className="register_content text-center">
                    <h1 className="register_title"><span> </span>If you have any questions about Startup Basket <br/><span>Please connect with us !</span>
                    </h1>
                    {/* <p className="register_text">In aliquam, augue a gravida rutrum, ante nisl fermentum nulla, vitae tempor nisl ligula vel nunc. Proin quis mi malesuada, finibus tortor fermentum. Aliquam, augue a gravida rutrum, ante nisl fermentum nulla, vitae tempo.</p> */}
                  </div>
                </div>


              </div>

              <div className="col-lg-6 nopadding">
                
                { /* Search */ }

                <div className="search_section d-flex flex-column align-items-center justify-content-center">
                <div className="search_background" ></div>
                <div className="search_content text-center">
                  <h1 className="search_title">Contact Us</h1>
                  <form id="search_form" className="search_form" action="post" onSubmit={handleSubmit}>
                    <input name="search_form_name" id="search_form_name" className="input_field search_form_name" value={nameUser} onChange={(e) => setNameUser(e.target.value)} type="text" placeholder="Name" required="required" data-error="Service name is required." />
                    <input name="search_form_phone" id="search_form_phone" className="input_field search_form_category" value={phoneUser} onChange={(e) => setPhoneUser(e.target.value)} type="text" placeholder="Phone number" />
                    <input name="search_form_email" id="search_form_email" className="input_field search_form_degree" value={emailUser} onChange={(e) => setEmailUser(e.target.value)} type="text" placeholder="Email ID" />
                    <input name="search_form_degree" id="search_form_degree" className="input_field search_form_degree" value={messageUser} onChange={(e) => setMessageUser(e.target.value)} type="text" placeholder="Message" />

                    {isSubmitLoading ? 
                    (
                      <CircularStatic size={26} />
                    ) : (
                      <button id="search_submit_button" type="submit" className="search_submit_button trans_200" value="Submit">Submit</button>
                    )}
                    {/* <div className="test-danger">{errorMsg}</div>
                    <div className="test-success">{successMsg}</div> */}
                  </form>
                </div> 
              </div>

              </div>
            </div>
          </div>
        </div>


    </Fragment>
  );

  async function handleSubmit(event) {
    setIsSubmitLoading(true);
    event.preventDefault()
    let params = {
      'name' : nameUser, 
      'email' : emailUser, 
      'phone' : phoneUser, 
      'message' : messageUser 
    };
    let RC = await sendContactUS(params);
    if (RC) {
      console.log("Contact Us email send");
        setEmailUser("");
        setNameUser("");
        setPhoneUser("");
        setMessageUser("");
        setIsSubmitLoading(false);
      setIsSubmitLoading(false);
      setErrorMsg("");
      setSuccessMsg("Thank you for your message. Our team will get back to you shortly");
    
    } else {
      console.log("Contact Us email Not send");
      setIsSubmitLoading(false);
      setErrorMsg("Error coming while sending contact us Query");
      setSuccessMsg("");
    }
  }

  }

export default Module;
