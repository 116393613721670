import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";

import axios from 'axios';
import logo from "./../assets/images/logo.png";

import PlaceholderIcon from "./..//assets/images/placeholder.svg";
import SmartphoneIcon from "./..//assets/images/smartphone.svg";
import EnvelopeIcon from "./..//assets/images/envelope.svg";

// FOR APIS
import {
  sendSubscribe,
  browserDetection,
  getGeneralSettings
} from "./../APIs/ApiUtils";
import CircularStatic from "./../components/Controls/CircularProgressBar";

function Footer() {
  const history = useHistory();
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [emailSubscribeUser, setEmailSubscribeUser] = useState(undefined);
  const [emailSubscribeUserError, setEmailSubscribeUserError] = useState(false);
  const recaptchaRefSubscribe = React.createRef();
  const [generalSettings, setGeneralSettings] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  
  useEffect(() => {
    handleGetFooterInfo();
  }, []);

  async function handleGetFooterInfo() {
    let RC = false;
    setIsFormLoading(true);
    let generalSettings = await getGeneralSettings();
    if (generalSettings != undefined ) {
      console.log("General settings found successfully ::", generalSettings);
      setGeneralSettings(generalSettings);
      setIsFormLoading(false);
    } else {
      console.log("General settings Not found");
      setIsFormLoading(false);
    }
  }


  return (
    <div>
      <footer className="footer">
        <div className="container">
          
          { /* Newsletter */ }

          <div className="newsletter">
            <div className="row">
              <div className="col">
                <div className="section_title text-center">
                  <h1>Subscribe to newsletter</h1>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col text-center">
                <div className="newsletter_form_container mx-auto">
                  {/* <form action="post" onSubmit={handleSubmit}> */}
                    <div className="newsletter_form d-flex flex-md-row flex-column flex-xs-column align-items-center justify-content-center ">
                      <ReCAPTCHA
                        ref={recaptchaRefSubscribe}
                        size="invisible"
                        sitekey="6LckcawkAAAAAPexqGu1JN3ehseH0fQmIsYCLVrf"
                        onChange={()=>{}}
                      />
                      <input value={emailSubscribeUser} onChange={(e) => setEmailSubscribeUser(e.target.value)} name="newsletter_email" id="newsletter_email" className="newsletter_email mt-3" type="email" placeholder="Email Address" required="required" data-error="Valid email is required." />
                      {isSubmitLoading ? 
                      (
                        <CircularStatic size={26} />
                      ): 
                      (
                        <button onClick={handleSubmit} id="newsletter_submit" type="submit" className="newsletter_submit_btn trans_300  mt-3" value="Submit">Subscribe</button>
                      )}                
                    </div>
                  {/* </form> */}
                </div>
              </div>
            </div>

          </div>

          { /* Footer Content */ }

          <div className="footer_content">
            <div className="row">

              { /* Footer Column - About */ }
              <div className="col-xs-12  col-lg-3 footer_col">

                { /* Logo */ }
                <div className="logo_container">
                  <div className="logo">
                    <img src={logo} alt="" />
                    <span>StartupBasket</span>
                  </div>
                </div>

                <p className="footer_about_text">
                  { generalSettings?.admin_aboutusshort} 
                </p>

              </div>

              { /* Footer Column - Menu */ }
{/* 
              <div className="col-xs-6 col-lg-3 footer_col">
                <div className="footer_column_title">Menu</div>
                <div className="footer_column_content">
                  <ul>
                    <li className="footer_list_item"><Link to="/aboutus">About Us</Link></li>
                    <li className="footer_list_item"><Link to="/services">Services</Link></li>
                    <li className="footer_list_item"><Link to="/members">Partners</Link></li>
                    <li className="footer_list_item"><Link to="/contactus">Contact</Link></li>
                  </ul>
                </div>
              </div> */}

              { /* Footer Column - Usefull Links */ }

              <div className="col-xs-6 col-lg-3 footer_col">
                <div className="footer_column_title">Useful Links</div>
                <div className="footer_column_content">
                  <ul>
                    <li className="footer_list_item"><Link to="/contactus#faq-section">FAQ</Link></li>
                    <li className="footer_list_item"><Link to="/news">Blogs</Link></li>
                    <li className="footer_list_item"><Link to="/startups">Our Mentored Startups</Link></li>
                    <li className="footer_list_item"><Link to="/terms-condition">Terms & Conditions</Link></li>
                  </ul>

                </div>
              </div>

              <div className="col-xs-6 col-lg-3 footer_col">
                <div className="footer_column_title">Attribution Links</div>
                <div className="footer_column_content">
                  <ul>
                    <li className="footer_list_item">
                      <a href="https://www.freepik.com/free-vector/startup-landing-page-with-rocket_5481824.htm#page=2&query=startups&position=10&from_view=search&track=sph">Image by pikisuperstar</a> on Freepik
                    </li>
                  </ul>
                </div>
              </div>

              { /* Footer Column - Contact */ }

              <div className="col-xs-12 col-lg-3 footer_col">
                <div className="footer_column_title">Contact</div>
                <div className="footer_column_content">
                  <ul>
                    <li className="footer_contact_item">
                      <div className="footer_contact_icon">
                        <img src={PlaceholderIcon} alt="https://www.flaticon.com/authors/lucy-g" />
                      </div>
                      { generalSettings?.admin_address} 
                    </li>
                    <li className="footer_contact_item">
                      <div className="footer_contact_icon">
                        <img src={SmartphoneIcon} alt="https://www.flaticon.com/authors/lucy-g" />
                      </div>
                      { generalSettings?.admin_phone} 
                    </li>
                    <li className="footer_contact_item">
                      <div className="footer_contact_icon">
                        <img src={EnvelopeIcon} alt="https://www.flaticon.com/authors/lucy-g" />
                      </div>
                      { generalSettings?.admin_email_address} 
                    </li>
                  </ul>
                </div>
              </div>

            </div>
          </div>

          { /* Footer Copyright */ }

          <div className="footer_bar d-flex flex-column flex-sm-row align-items-center">
            <div className="footer_copyright">
              <span>
                Copyright &copy;<script>document.write(new Date().getFullYear());</script> All rights reserved 
              </span>
            </div>
            <div className="footer_social ml-sm-auto">
              <ul className="menu_social">
                <li className="menu_social_item"><a target="_blank" href={ generalSettings?.admin_linkedin_link}><i className="fa fa-linkedin"></i></a></li>
                <li className="menu_social_item"><a target="_blank" href={ generalSettings?.admin_twitter_link}><i className="fa fa-instagram"></i></a></li>
                <li className="menu_social_item"><a target="_blank" href={ generalSettings?.admin_facebook_link}><i className="fa fa-facebook-f"></i></a></li>
                <li className="menu_social_item"><a target="_blank" href={ generalSettings?.admin_instagram_link}><i className="fa fa-twitter"></i></a></li>
              </ul>
            </div>
          </div>

        </div>
      </footer>
    </div>
  );

  async function handleSubmit() {
    // let varRecaptcha = recaptchaRefSubscribe.current.execute();
    // const token = await recaptchaRefSubscribe.current.executeAsync();
    // alert(token);
    // console.log(token);
    // if(token) {
      if (emailSubscribeUser == undefined || emailSubscribeUser?.length == 0) 
        setEmailSubscribeUserError(true);
      else 
        handleSubscribe();
    // }
  }

  async function handleSubscribe() {  
    setIsSubmitLoading(true);

    const res = await axios.get('https://geolocation-db.com/json/');

    let params = {
      'email' : emailSubscribeUser, 
      'ip' : res.data.IPv4 , 
      'browser' : browserDetection() 
    };
    let RC = await sendSubscribe(params);
    if (RC) {
      console.log("Subscribe save");
      setIsSubmitLoading(false);
      setEmailSubscribeUser("");
      setEmailSubscribeUserError("");
    } else {
      console.log("Subscribe Failed");
      setIsSubmitLoading(false);
    }
  }


}

export default Footer;
