import React, { Fragment, useRef, useState, useEffect } from "react";
import { useHistory, Link } from "react-router-dom";
import queryString from "query-string";

// COMPONENTS
import Footer from "../../../components/footer";
import Header from "../../../components/header";
import PageSidebar from "../../../components/PageSidebar.js";

// APIs
import { CauseID_ARR } from "../../../Macros";
// FOR APIS
import {
  getServices,
  getParentLevelServicesAPI
} from "../../../APIs/serviceApis";

// CUSTOM COMPONENTS
import CircularStatic from '../../../components/Controls/CircularProgressBar';

// IMAGE/MEDIA
import ServiceImage from "../../../assets/images/User-Profile/User-1.jpg";

function Homepage() {
  window.scrollTo(0, 0);

  const history = useHistory();
  const [servicesTable, setServicesTable] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);

  const example1 = useRef(null);

  useEffect(() => {
    handleGetServices();
    // let queries = queryString.parse(history.location.search);
    // console.log("<QUERY STRING > ::", queries);
    // if (queries.example1) {
    //   example1.current.scrollIntoView();
    // }
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center", }}>
          {/* <CircularProgress variant="indeterminate" value={75} /> */}
          <CircularStatic value={25} />
        </div>
      ) : (
        <Fragment>
          <section className="section cls-services-page" id="services">
            <div className="container">
              <div className="row">
                <div className="col-lg-12">
                  <div className="section-title">
                    <h2>Services</h2>
                    <p>We help startups from ideation to funding through our extensive network of subject matter expert partners</p>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center mb-5">
                {
                  servicesTable != undefined && servicesTable.map((row) => {
                    return (
                      <div className="col-sm-6 col-lg-4">
                        <div className="feature-box-1">
                          <div className="icon">
                            <img src={row.profilethumbnail} alt="" />
                          </div>
                          <div className="feature-content">
                            <h3>{row.title}</h3>
                            <p>{row.excerpt}</p>
                          </div>
                          <div className="d-flex flex-row align-items-center justify-content-between mt-2">
                            <Link to={"/service/" + row.id} id="contact_send_btn" className="button button_primary_outline trans_200 service-btn">
                             View Detail <i className="fa fa-regular fa-eye" aria-hidden="true"></i> 
                            </Link>
                            <button className="button button_primary service-btn" onClick={() => handleBookPartner(row.id)} id="contact_send_btn" type="button" value="Submit">
                              Click to Proceed <i className="fa fa-arrow-right" aria-hidden="true"></i> 
                            </button>
                          </div>
                        </div>
                      </div>
                    )
                  })
                }
              </div>
            </div>
          </section>
        </Fragment>
      )}
    </>
  );


  function handleViewDetail(service_id) {
    history.push('/service/' + service_id);
  }

  function handleBookPartner(service_id) {
    if (service_id == 10) {
      history.push('/fund-raising-form');
    } else {
      history.push('/members/' + service_id);
    }

  }

  async function handleGetServices() {
    let RC = false;
    setIsFormLoading(true);

    // let queries = queryString.parse(history.location.search);
    // let page = queries.page != undefined ? queries.page : 1;
    // let limit = queries.limit != undefined ? queries.limit : 9;

    let { status, response } = await getParentLevelServicesAPI(history);
    if (status == "S_OK") {
      console.log("Services Found Successfully ::", response.length);
      setServicesTable(response);
      setIsFormLoading(false);
      // setPage(page);
      // setLimit(limit);
    } else {
      console.log("Service Not found");
      setIsFormLoading(false);
    }
  }

  async function handleForwardBtn(id, location) {
    let isLoggedIn = localStorage.getItem("id_token");

    let searchString = "Service_id=" + id;
    let redirectString = searchString + "&location=" + location;

    console.log("<pathname> :: ", location);
    console.log("<search> :: ", searchString);

    if (isLoggedIn) {
      localStorage.setItem('CURRENT_Service', id);
      history.push(location);
      // history.push({
      //   pathname: location,
      //   search: "?" + searchString,
      //   state: { Service_id: id }
      // });
    } else
      history.push({
        pathname: "login",
        search: redirectString,
      });
  }

  function handleNextBtn() {
    let queries = queryString.parse(history.location.search);
    let page = (queries.page != undefined) ? queries.page : 1;
    let limit = (queries.limit != undefined) ? queries.limit : 9;
    page = +page + 1;
    let searchString = "?limit=" + limit + "&page=" + page;
    console.log("<search> :: ", searchString);
    console.log("<search> :: ", window.location.href);


    window.location.href = "http://localhost:3000/#/Services?limit=" + limit + "&page=" + page
    window.location.reload();
    // + searchString;


    // history.replace({
    //   pathname: "/Services",
    //   search: searchString,
    // });
    // <Redirect to={searchString} />;
  }
}

export default Homepage;
