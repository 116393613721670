import React, { Fragment, useEffect, useState } from "react";
import { Link, useHistory, useLocation, withRouter, useParams } from "react-router-dom";
import PageSideBar from "../../../components/PageSidebar";
// import { AppointmentPicker } from 'react-appointment-picker';
import { AppointmentPicker } from './../../../components/Appointment-Picker-Users/AppointmentPicker';
// import Calendar from 'moedim';
import Calendar from 'react-calendar';
import 'react-calendar/dist/Calendar.css';

import mouseClickSVG from "./../../../assets/images/icons/mouse-pointer-clicksvg.svg"

// MEDIA FILES
import Img01 from "../../../assets/images/User-Profile/User-1.jpg"
import {
  convertTime, addDays
} from "../../../APIs/ApiUtils"

import CircularStatic from '../../../components/Controls/CircularProgressBar';

// FOR APIS
import {
  getMemberAPI,
  getSlotsAPI,
  getMemberServicesAPI
} from "../../../APIs/memberApis";
import { getServiceAPI } from "../../../APIs/serviceApis";

import { currencySymbol, getLocaleShortDateString } from "./../../../helpers";

const days = [

];

function Homepage() {

  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const [dateValue, setDateValue] = useState(addDays(new Date(), 1))

  const [selectedSlots, setSelectedSlots] = useState([]);
  const [availableDays, setAvailableDays] = useState(undefined);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currentDate, setCurrentDate] = useState(addDays(new Date(), 1));

  const [memberInfo, setMemberInfo] = useState(undefined);
  const [serviceInfo, setServiceInfo] = useState(undefined);
  const [slotsInfo, setSlotsInfo] = useState(undefined);
  const [appointmentID, setAppointmentID] = useState([]);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    let isLoggedIn = localStorage.getItem("id_token");
    if (!isLoggedIn)
      history.push("/login");
    else {
      //let isMembershipPaid = localStorage.getItem("IS_MEMBERSHIP_PAID");
      //if (!isMembershipPaid || isMembershipPaid==0 || isMembershipPaid=='0' ){
      //  alert("Please pay your membership to proceed");
      //  history.push("/profile/startup/edit/"+localStorage.getItem("persona_id"));
      //}

      // var today = new Date();
      var today = addDays(new Date(), 1);

      // let tmpDays = [];

      // for (let index = 0; index < 7; index++) {
      //     var tmpDate = new Date(today.setDate(today.getDate() + index));
      //     let tmpDateString = tmpDate.getFullYear() + '-' + (tmpDate.getMonth() + 1) + '-' + tmpDate.getDate();    
      //     var tmpHours = [];
      //     for (let indexHours = 0; indexHours < 24; indexHours++) {
      //       tmpHours.push({ id: 0, number: indexHours, isReserved: true }); 
      //     }  
      //     // console.log("Current Available hours List" , tmpHours);
      //     tmpDays.push({ key: tmpDateString, value: tmpHours }); 
      // }
      // console.log("Current Available days List" , tmpDays);

      let start_date = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ((today.getDate() < 10) ? '0' + today.getDate() : today.getDate());

      // alert(start_date);
      var date7Days = new Date(today.setDate(today.getDate() + 7));
      let end_date = date7Days.getFullYear() + '-' + ('0' + (date7Days.getMonth() + 1)).slice(-2) + '-' + ((date7Days.getDate() < 10) ? '0' + date7Days.getDate() : date7Days.getDate());
      let currentDateTime = new Date(start_date + " 00:00");

      setCurrentDate(currentDateTime);
      setStartDate(start_date);
      setEndDate(start_date);
      // setAvailableDays(tmpDays)
      handleGetSlots(currentDateTime);
      handleGetMember();
      handleGetMemberServices();

    }


  }, []);


  async function addAppointmentCallback({
    addedAppointment: { day, number, time, id },
    addCb
  }) {
    setIsSubmitLoading(true);
    await A({ day, number, time, id }, addCb);

  };


  async function A({ day, number, time, id }, addCb) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    console.log(
      `Added appointment ${number}, day ${day}, time ${time}, id ${id}`
    );
    let arrSlots = [];
    var dateParts = day.split("/");
    // month is 0-based, that's why we need dataParts[1] - 1
    var lSelectedDay = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
    let lSelectedDayPHP = lSelectedDay.getFullYear() + '-' + ('0' + (lSelectedDay.getMonth() + 1)).slice(-2) + '-' + ((lSelectedDay.getDate() < 10) ? '0' + lSelectedDay.getDate() : lSelectedDay.getDate());

    selectedSlots.push({
      key: id, value: {
        slotnumber: number,
        member_id: params.itemId,
        date: lSelectedDayPHP,
        starttime: convertTime(time),
        endtime: convertTime(time, 1),
        isReserved: 1,
        isactive: 1
      }
    });
    setSelectedSlots(selectedSlots);

    console.log("Current Selected Slots :: ", selectedSlots);

    addCb(day, number, time, id);
    setIsSubmitLoading(false);
    appointmentID.push({ day: day, time: time, id: id });
    setAppointmentID(appointmentID);
  }

  async function removeAppointmentCallback({ day, number, time, id }, removeCb) {
    setIsSubmitLoading(true);
    B({ day, number, time, id }, removeCb);

  };


  async function B({ day, number, time, id }, removeCb) {
    await new Promise((resolve) => setTimeout(resolve, 1000));
    console.log(`Removed appointment ${number}, day ${day}, time ${time}, id ${id}`);
    removeCb(day, number);

    // let arrSlots = [];
    // arrSlots = selectedSlots.filter(slot => {
    //   return slot.id !== id;
    // });
    setSelectedSlots(selectedSlots =>
      selectedSlots.filter(slot => {
        console.log(slot.key, id);
        return slot.key != id;
      }),
    );

    console.log("Current Selected Slots :: ", selectedSlots);
    setIsSubmitLoading(false);

    setAppointmentID(appointmentID.filter((appointment) => {

      return appointment.id !== id
    }));
  }

  async function handleDateChange(d) {
    setDateValue(d);
    console.log("Current selected date ", new Date(d));
    let today = new Date(d);
    let start_date = today.getFullYear() + '-' + ('0' + (today.getMonth() + 1)).slice(-2) + '-' + ((today.getDate() < 10) ? '0' + today.getDate() : today.getDate());
    setStartDate(start_date);
    setEndDate(start_date);
    setCurrentDate(today);
    handleGetSlots(today);

  }

  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center", }}>
          <CircularStatic value={25} />
        </div>
      ) : (
        <Fragment>

          <div className="container single-mentor">
            {/* <!-- ======= About Section ======= --> */}
            <section id="about" className="about-mf sect-pt4 route">
              <div className="container">
                <div className="box-shadow-full">
                  <div className="row">
                    <div className="col-sm-12 col-md-12 col-lg-3">
                      <div className="row">
                        <div className="col-sm-12 col-md-12">
                          <div className="about-img">
                            <img src={(memberInfo && memberInfo.profilethumbnail) ? memberInfo.profilethumbnail : Img01} className="img-fluid rounded b-shadow-a" alt="" />
                          </div>
                          <div className="about-info">
                            <p ><small >Name: </small> <span className="title-s">{(memberInfo) ? memberInfo.membername : ""}</span></p>
                            {/* <p><small >Profile: </small> <span className="title-s">{(memberInfo)?memberInfo.memberprofile:""}</span></p>
                          <p><small >Email: </small> <span className="title-s">{(memberInfo)?memberInfo.memberemail:""}</span></p>
                          <p><small >Phone: </small> <span className="title-s">{(memberInfo)?memberInfo.memberphone:""}</span></p> */}
                          </div>
                        </div>
                      </div>

                      <div className="skill-mf">
                        <p className="title-s text-dark text-bold mt-4"><b>Service Information</b></p>
                        <span >Service Name</span> <span className="pull-right text-tertiary">{serviceInfo?.title}</span>
                        <div className="progress">
                        </div>
                        <span>Price</span> <span className="pull-right text-tertiary">{currencySymbol() + serviceInfo?.pivot?.price + " per hour" ?? "5000 per hour"}</span>
                        <div className="progress">
                          <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                        <span>About</span> <span className="pull-right text-tertiary">{serviceInfo?.excerpt}</span>
                        <div className="progress">
                          <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                        </div>
                      </div>
                    </div>
                    <div className="col-sm-12 col-md-12 col-lg-9">
                      <div className="about-me pt-4 pt-md-0">
                        <div className="title-box-2">
                          <div className="row">
                            <div className="col">
                              <div className="section_title text-center">
                                <h1>Schedule Session</h1>
                              </div>
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-md-3  col-lg-1">
                              <img src={mouseClickSVG} className="cls-schedule-click-icon" />
                            </div>
                            <div className="col-md-9 col-lg-11">
                              <p className="mt-2 text-dark text-bold img-wrap inline-block">
                                <h3>Select date and time to schedule</h3>
                              </p>
                            </div>
                          </div>
                        </div>
                        <div className="cls-appointment cls-appointment-startup">
                          <div className="row">
                            <div className="col-sm-12 col-md-7 col-lg-6 mb-5">
                              <Calendar minDate={addDays(new Date(), 1)} value={dateValue} onChange={(d) => handleDateChange(d)} />
                            </div>
                            <div className="col-sm-12  col-md-3 col-lg-4 text-center px-2">

                              <div>
                                <h3>{currentDate != undefined ? getLocaleShortDateString(currentDate, 'text') : ''}</h3>
                              </div>
                              <AppointmentPicker
                                addAppointmentCallback={addAppointmentCallback}
                                removeAppointmentCallback={removeAppointmentCallback}
                                initialDay={currentDate}
                                days={(availableDays != undefined ? availableDays : days)}
                                maxReservableAppointments={10}
                                unitTime={60 * 60 * 1000}
                                selectedByDefault
                                loading={isSubmitLoading}
                                local="en-IN"
                              />
                              {
                                (availableDays == undefined) &&
                                <div>
                                  <h4 className="text-danger ">No slots available for the selected date</h4>
                                </div>
                              }
                            </div>
                            <div className="col-sm-12  col-md-2 col-lg-2 text-center px-1">
                            <div className=" align-right text-center mt-100px">
                                {
                                  (appointmentID != undefined && appointmentID.length >= 0)
                                    ? <div onClick={() => { handleBookSubmit() }} className="button button_primary" ><span>Book Now</span></div>
                                    : <div onClick={() => { alert("Please select slot") }} className="button button_disable "><span>Book Now</span></div>
                                }
                              </div>

                            
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>


          </div>

        </Fragment>
      )}
    </>
  );

  function handleBookSubmit() {

    console.log("/checkout/" + params.memberId + "/" + params.serviceId, 'appointmentID :: ', appointmentID);

    history.push({
      pathname: "/checkout/" + params.memberId + "/" + params.serviceId + "/" + appointmentID,
      search: '?query=abc',
      state: { appointments: appointmentID }
    });
  }

  function handleCheckourRedirect() {
    history.push(
      {
        pathname: '/checkout',
        state: { member_id: 1 }
      });
  }

  async function handleGetMember() {
    let RC = false;
    setIsFormLoading(true);
    let member = await getMemberAPI(params.memberId);
    if (member != undefined) {
      console.log("Members Found Successfully ::", member);
      setMemberInfo(member);
      setIsFormLoading(false);
    } else {
      console.log("Member Not found");
      setIsFormLoading(false);
    }
  }

  async function handleGetService() {
    let RC = false;
    setIsFormLoading(true);
    let { status, response } = await getServiceAPI(params.serviceId)
    if (status == "S_OK") {
      console.log("Services Found Successfully ::", response);
      setServiceInfo(response);
      setIsFormLoading(false);
    } else {
      console.log("Service Not found");
      setIsFormLoading(false);
    }
  }

  async function handleGetMemberServices() {
    let RC = false;
    let serviceInfoLocal = {};
    setIsFormLoading(true);
    let iParam = { member_id: params.memberId };
    let services = await getMemberServicesAPI(iParam);
    if (services != undefined && services.length > 0) {
      console.log("Services Found Successfully ::", services.length);
      services.map(row => {
        if (row.pivot.service_id == params.serviceId)
          setServiceInfo(row);
      });
      setIsFormLoading(false);
      console.log("Services Table Data ", services);
    } else {
      console.log("Services Not found");
      setIsFormLoading(false);
    }
  }

  async function handleGetSlots(currentDate) {
    let RC = false;
    setIsFormLoading(true);
    setAvailableDays(undefined);

    var today = currentDate; //new Date(startDate);
    let tmpDays = [];
    let tmpDateIncrease = 0;

    for (let index = 0; index < 7; index++) {
      var tmpDate = new Date(today.setDate(today.getDate() + tmpDateIncrease));
      let tmpDateString = tmpDate.getFullYear() + '-' + ('0' + (tmpDate.getMonth() + 1)).slice(-2) + '-' + ((tmpDate.getDate() < 10) ? '0' + tmpDate.getDate() : tmpDate.getDate());
      var tmpHours = [];
      for (let indexHours = 0; indexHours < 24; indexHours++) {
        tmpHours.push({ id: 0, number: indexHours, isVisible: false, isReserved: true, isactive: false });
      }
      // console.log("Current Available hours List" , tmpHours);
      tmpDays.push({ key: tmpDateString, value: tmpHours });
      tmpDateIncrease = 1;
      break;
    }
    // console.log("Current Available days List" , tmpDays);

    let iParams = { member_id: params.memberId, start_data: startDate, end_date: endDate };
    let slots = await getSlotsAPI(iParams);
    let noSlotAvailable = true;
    if (slots != undefined) {
      console.log("slots Found Successfully ::", slots);
      setSlotsInfo(slots);

      slots.map(row => {
        let slotDate = row.date;
        tmpDays.map(data => {
          if (data.key == slotDate) {
            let arrHours = data.value;
            for (let hourIndex = 0; hourIndex < arrHours.length; hourIndex++) {
              if ((arrHours[hourIndex].number == row.slotnumber) && (row.isactive != '0')) {
                arrHours[hourIndex] = { id: row.id, number: row.slotnumber, isVisible: true, isReserved: row.isReserved != '0', isactive: true };
                console.log("Current Slot Number :: ", row.slotnumber, " row.id  :: ", row.id, " :: row.isReserved :: ", row.isReserved, " arrHours ::", arrHours);
                noSlotAvailable = false;
              }
              // else
              //   if(arrHours[hourIndex].isactive == false)
              //     arrHours[hourIndex] = null;
            }
          }
        });
      });
      let tmpDaysData = []
      tmpDays.map(row => {
        tmpDaysData.push(row.value);
      });
      if (!noSlotAvailable)
        setAvailableDays(tmpDaysData);

      console.log("Availble Days ::", tmpDaysData);
      setIsFormLoading(false);
    } else {
      console.log("slots Not found");
      setIsFormLoading(false);
    }
  }

}

export default Homepage;
