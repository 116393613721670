import React, { Fragment } from "react";

// MEDIA FILES
import milestone_1 from "../../../assets/images/milestone_1.svg";
import milestone_2 from "../../../assets/images/milestone_2.svg";
import milestone_3 from "../../../assets/images/milestone_3.svg";
import milestone_4 from "../../../assets/images/milestone_4.svg";


import imgBackgroundMilestones from "../../../assets/images/milestones_background.jpg";


function Module() {
  return (
    <Fragment>
        {/* <!-- Milestones --> */}        
        <div className="milestones">
          <div className="milestones_container">
            <div className="milestones_background" ><img src={imgBackgroundMilestones}/></div>
            
            <div className="container">
              <div className="row">
                
                {/* <!-- Milestone --> */}
                <div className="col-lg-4 pb-4">
                  <div className="milestone text-center">
                    <div className="milestone_icon"><img src={milestone_1} alt="https://www.flaticon.com/authors/zlatko-najdenovski" /></div>
                    <div className="milestone_counter" data-end-value="750">750</div>
                    <div className="milestone_text">Registered Startups</div>
                  </div>
                </div>

                {/* <!-- Milestone --> */}
                <div className="col-lg-4  pb-4">
                  <div className="milestone text-center">
                    <div className="milestone_icon"><img src={milestone_2} alt="https://www.flaticon.com/authors/zlatko-najdenovski" /></div>
                    <div className="milestone_counter" data-end-value="120">120</div>
                    <div className="milestone_text">Certified Partners</div>
                  </div>
                </div>

                {/* <!-- Milestone --> */}
                <div className="col-lg-4  pb-4">
                  <div className="milestone text-center">
                    <div className="milestone_icon">
                      <img src={milestone_4} alt="https://www.flaticon.com/authors/zlatko-najdenovski" ></img>
                    </div>
                    <div className="milestone_counter" data-end-value="3500" data-sign-before="+">3500</div>
                    <div className="milestone_text">Graduated Startups</div>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>

    </Fragment>
  );
}

export default Module;
