// IMPORT PACKAGES
import React, { Fragment, useState, useEffect } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
  useHistory,
  useParams
} from "react-router-dom";

// MEDIA FILES
import MemberImage from "../../../assets/images/Member-1.jpg";

// CUSTOM COMPONENTS
import  CircularStatic from '../../../components/Controls/CircularProgressBar';



// FOR APIS
import {
  getServiceMembersAPI 
} from "../../../APIs/serviceApis";

import queryString from "query-string";
import { resetPassword } from "../../../context/userContext";

import SingleMember from '../../../components/members/single_member'

function MembersByService(props) {
  window.scrollTo(0, 0);

  const history = useHistory();
  const params = useParams();
  
  const [membersTable, setMembersTable] = useState(undefined);
  const [serviceInfo, setServiceInfo] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);

  useEffect(() => {
    handleGetServiceMembers();
  }, []);

  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center",  }}>
          <CircularStatic value={25} />
        </div>
      ) : (
        <Fragment>
          <div className="container cls-partner-card">
            <div className="row">
                <div className="col-sm-12 col-lg-12">
                    <div className="section-title">
                        <h2>
                        Book the partner to serve your startup needs          
                          {/* <i className="fa fa-quote-left" aria-hidden="true"></i> {" "} */}
                          {/* { serviceInfo?.title }{" "} */}
                          {/* <i className="fa fa-quote-right" aria-hidden="true"></i>{" "} */}
                        </h2>
                        <p>
                          {/* I design and develop services for customers of all sizes, specializing in creating stylish, modern websites */}
                        </p>
                    </div>
                </div>
            </div>
            
            <div className="row  justify-content-center">
              {membersTable != undefined &&
              membersTable.map((row) => {   
                {
                  return(
                  <div className="col-lg-4 course_box">
                    <SingleMember data={row} />
                  </div>
                  )
                  // return (  
                    // <div className="col-xl-3 col-sm-6">
                    //   <div className="card">
                    //       <div className="card-body">
                    //           <div className="d-flex align-items-center">
                    //               <div><img src={row.profilethumbnail} alt="" className="avatar-md rounded-circle img-thumbnail" /></div>
                    //               <div className="flex-1 ms-3">
                    //                   <h5 className="cls-name mb-1"><a href="#" className="text-dark">{row.membername}</a></h5>
                    //                   <span className="badge badge-soft-success mb-0">{(row.profile)?row.profile:"Finance Adviser"}</span>
                    //               </div>
                    //           </div>
                    //           <div className="mt-3 pt-1 ">
                    //               <p className="text-excerpt mb-0">
                    //               {row.memberexcerpt}
                    //               </p>
                    //           </div>
                    //           <div className="mt-3 pt-1">
                    //               <p className="text-muted mb-0"><i className="fa fa-phone font-size-15 align-middle pe-2 text-primary"></i> 070 2860 5375</p>
                    //               <p className="text-muted mb-0 mt-2"><i className="fa fa-envelope font-size-15 align-middle pe-2 text-primary"></i> PhyllisGatlin@spy.com</p>
                    //               <p className="text-muted mb-0 mt-2"><i className="fa fa-graduation-cap font-size-15 align-middle pe-2 text-primary"></i> 52 Ilchester MYBSTER 9WX</p>
                    //           </div>
                    //           <div className="d-flex gap-2 pt-4">
                    //               <Link to={"/member/" + row.id} className="btn btn-soft-primary btn-sm w-50"><i className="fa fa-user-o me-1"></i> Profile</Link>
                    //               <Link to={"/member/" + row.id} className="btn btn-primary btn-sm w-50"><i className="fa fa-calendar-check-o me-1"></i> Book</Link>
                    //           </div>
                    //       </div>
                    //   </div>
                    // </div>             
                  // ) 
              }                       
              })}
            </div>
            <div className="row m-5">
                <div className="col-12">
                <div
                    onClick={handleNextBtn}                    
                    className="button button_primary_outline gradient-bg mt-3 px-4 py-3 float-right pull-right"
                >
                    Next
                </div>
                </div>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );

  async function handleGetServiceMembers() {
    let RC = false;
    setIsFormLoading(true);
    // let queries = queryString.parse(history.location.search);
    // let page = queries.page != undefined ? queries.page : 1;
    // let limit = queries.limit != undefined ? queries.limit : 9;
    let iParam = { service_id : params.serviceId };
    let {status, response} = await getServiceMembersAPI(iParam);
    if (status == "S_OK" && response != undefined) {
      console.log("Members Found Successfully ::", response?.members?.data?.length);
      setMembersTable(response.members.data);
      setServiceInfo(response);
      setIsFormLoading(false);
      // setPage(page);
      // setLimit(limit);
    } else {
      console.log("Members Not found");
      setIsFormLoading(false);
    }
  }

  async function handleForwardBtn(id, location) {
    let isLoggedIn = localStorage.getItem("id_token");

    let searchString = "Member_id=" + id;
    let redirectString = searchString + "&location=" + location;

    console.log("<pathname> :: ", location);
    console.log("<search> :: ", searchString);

    if (isLoggedIn) {
      localStorage.setItem('CURRENT_Member',id);
      history.push(location);
      // history.push({
      //   pathname: location,
      //   search: "?" + searchString,
      //   state: { Member_id: id }
      // });
    } else
      history.push({
        pathname: "login",
        search: redirectString,
      });
  }

  function handleNextBtn() {
    let queries = queryString.parse(history.location.search);
    let page = (queries.page!=undefined)?queries.page:1;
    let limit = (queries.limit!=undefined)?queries.limit:9;
    page = +page + 1;
    let searchString = "?limit=" + limit + "&page=" + page;    
    console.log("<search> :: ", searchString);
    console.log("<search> :: ", window.location.href);
    

    window.location.href = "http://localhost:3000/#/Members?limit="+limit+"&page="+page
    window.location.reload();
    // + searchString;


    // history.replace({
    //   pathname: "/Members",
    //   search: searchString,
    // });
    // <Redirect to={searchString} />;
  }
}

export default MembersByService;
