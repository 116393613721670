import React from "react";
import { Component } from "react";

import themeAPP from "../../themes";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link } from "react-router-dom";

// MATERIAL UI DEPENDENCIES
import { Button, Grid } from "@material-ui/core";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import { jssPreset, makeStyles } from "@material-ui/styles";
import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import Avatar from "@material-ui/core/Avatar";

// MATERIAL ICONS
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import DownloadIcon from "@material-ui/icons/GetApp";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import {
  getNotificationsAPI,
  getNotificationByUserIdAPI,
  viewNotificationByIdAPI,
} from "../../APIs/notificationsApis";

// COMPONENTS

import PageTitle from "../../components/PageTitle/PageTitle";

// data

import Slide from "@material-ui/core/Slide";
import { Trans } from "react-i18next";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

export default class Notifications extends Component {
  constructor() {
    super();
    this.state = {
      notificationsTable: [],
      loader: false,
    };
  }

  render() {
    const components = {
      icons: {
        SearchIcon,
        PrintIcon,
        DownloadIcon,
        ViewColumnIcon,
        FilterIcon,
      },
    };

    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid xs={12} container spacing={2}>
              <Grid
                item
                xs={12}
                className={{ justifyContent: "space-between" }}
              >
                <MuiThemeProvider theme={themeAPP.default}>
                  <MUIDataTable
                    title="Notifications"
                    data={this.state.notificationsTable}
                    columns={[
                      {
                        name: "notification_id",
                        options: {
                          filter: false,
                          sort: false,
                          empty: true,
                          display: "excluded",
                        },
                      },
                      {
                        name: "user_id",
                        options: {
                          filter: false,
                          sort: false,
                          empty: true,
                          display: "excluded",
                        },
                      },
                      {
                        name: "title",
                        label: "Title",
                      },
                      {
                        name: "description",
                        label: "Description",
                      },
                      {
                        name: "redirect_link",
                        label: "Redirect_link",
                      },
                      {
                        name: "Actions",
                        options: {
                          filter: true,
                          sort: false,
                          empty: true,
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return this.customActionRender(
                              value,
                              tableMeta,
                              updateValue,
                            );
                          },
                        },
                      },
                    ]}
                    options={{
                      selectableRows: false,
                      fixedHeader: true,
                      filter: true,
                      filterType: "dropdown",
                      responsive: "standard",
                      tableBodyHeight: "400px",
                      tableBodyMaxHeight: "400px",
                    }}
                    // components={components}
                  />
                </MuiThemeProvider>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  componentDidMount() {
    this.handleGetNotifications();
  }

  handleClose = () => {
    this.setState({ deleteDialog: false });
  };

  handleGetNotifications = async () => {
    let RC = false;
    this.setState({ loader: true, notificationsTable: [] });
    let details = await getNotificationByUserIdAPI();
    if (details != undefined && details.status === "success") {
      console.log("Notifications Found Successfully");
      for (let i = 0; i < details.response.notifications.length; i++) {
        let temp = {};
        temp.notification_id =
          details.response.notifications[i].notification_id;
        temp.user_id = details.response.notifications[i].user_id;
        temp.title = details.response.notifications[i].notification.title;
        temp.description =
          details.response.notifications[i].notification.description;
        temp.redirect_link = (
          <a
            href={details.response.notifications[i].notification.redirect_link}
          >
            {details.response.notifications[i].notification.redirect_link}
          </a>
        );

        this.state.notificationsTable.push(temp);
      }
      console.log(
        "data in file :" + JSON.stringify(this.state.notificationsTable),
      );

      this.setState({
        loader: false,
      });
    } else {
      console.log("Notifications Not found");
      this.setState({ loader: false });
    }
  };

  viewNotification = async (value, tableMeta, updateValue) => {
    localStorage.setItem("NOTIFICATION_ID", tableMeta.rowData[0]);
    console.log("rowdata[0] is", tableMeta.rowData[0]);
    //localStorage.setItem("user_id",tableMeta.rowData[1]);
    let responsedetails = await viewNotificationByIdAPI();
    console.log(
      "response of view notification api in file :" +
        JSON.stringify(responsedetails),
    );
    this.handleGetNotifications();
  };

  handleWorkerExpend = (rowData, rowMeta) => {
    const colSpan = rowData.length;
    let rowDataStr = JSON.stringify(rowData);
    return (
      <div>
        <TableRow>
          <TableCell colSpan={colSpan}>Name : {rowData[0]}</TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={colSpan}>Name : {rowData[1]}</TableCell>
        </TableRow>
      </div>
    );
  };

  handleClickOpen = () => {
    this.setState({ ...this.state });
  };

  customActionRender = (value, tableMeta, updateValue) => {
    return (
      <div style={{ display: "inline-flex", margin: "0px auto" }}>
        <IconButton
          color="primary"
          aria-label="upload picture"
          onClick={() => this.viewNotification(value, tableMeta, updateValue)}
          style={themeAPP.default.button}
        >
          <Tooltip title="View Notification" aria-label="add">
            <Visibility />
          </Tooltip>
        </IconButton>
      </div>
    );
  };
}
