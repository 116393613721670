import React from "react";
import ReactPlayer from "react-player";
import { Close } from "@material-ui/icons";
function VideoModal(props) {

  return (
    <div className={"react-player-home"} style={{display: props.value===true ? "block" : "none"}}>
          <Close onClick={()=>props.close()} className={"close-Player-Icon"}/>
          <ReactPlayer
              playing={props.flag}
              url="https://www.youtube.com/watch?v=ysz5S6PUM-U"
              muted={true}
              controls={true}
              height={"30vh"}
              width={"100%"}
          />

          <div className="d-flex flex-row justify-content-between align-items-center">
            <h5 className="cls-video-title">Title for data</h5>
            <h5 className="cls-video-button">Know More</h5>
          </div>      
        </div>
  );
}

export default VideoModal;
