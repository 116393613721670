import React, { Fragment, useState, useEffect } from "react";

import { Link, useHistory } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";

import profileImage from "../../assets/images/Member-dummy.png";
import MemberImage from "../../assets/images/Member-1.jpg";


import Footer from "../../components/footer";
import Header from "../../components/header";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import { CircularProgress, TextField, FormControl } from "@material-ui/core";

import { getWorkerAPI, getDonationForWorkerAPI } from "../../APIs/workerApis";

import { getDonorAPI, editDonorAPI } from "../../APIs/donorApis";

import { getImageAPI } from "../../APIs/ApiUtils";
import queryString from "query-string";

function Homepage(props) {
  const history = useHistory();
  const [MemberID, setMemberID] = useState(localStorage.getItem('CURRENT_Member'));
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [workerInfo, setWorkerInfo] = useState([]);
  // const [donorInfo, setDonorInfo] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [profilePicture, setProfilePicture] = useState(profileImage);
  const [orderList, setDonationList] = useState(undefined);

  const [donorname, setDonorName] = useState("");
  const [donoremail, setDonorEmail] = useState("");
  const [donorphone, setDonorPhone] = useState("");
  const [donoraddress, setDonorAddress] = useState("");
  const [donorpincode, setDonorPincode] = useState("");
  const [donorcity, setDonorCity] = useState("");
  const [donorstate, setDonorState] = useState("");
  const [donorcountry, setDonorCountry] = useState("");

  const [orderAmount, setDonationAmount] = useState("500");
  const [orderType, setDonationType] = useState("ONETIME");

  useEffect(() => {
    // let id = history.location.Member_id;
    // if (id != undefined) setMemberID(id);
    // else {
    //   let id = history.location.state.Member_id;
    //   setMemberID(id);
    // }
    // let queries = queryString.parse(history.location.search);
    // let Member_id = queries.Member_id;

    // if (Member_id != undefined) setMemberID(Member_id);

    handleGetWorker();
    handleGetDonor();
    handleGetOrders();
  }, []);

  const handleChange = (event) => {
    setDonationType(event.target.value);
  };

  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center" }}>
          <CircularProgress variant="indeterminate" value={75} />
        </div>
      ) : (
        <Fragment>
          <div className="single-page about-page">
            <Header />
            <div className="page-Member  userprofile-page">
              <div className="container">
                <div className="main-body">
                  <div className="row gutters-sm">
                    <div className="col-md-3 mb-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex flex-column align-items-center text-center">
                            <img
                              src={profilePicture}
                              alt=""
                              className="rounded-circle"
                              width="150"
                            />
                            <div className="mt-3">
                              <h5>
                                {workerInfo["firstname"] +
                                  " " +
                                  workerInfo["lastname"]}
                              </h5>
                              <p className="text-secondary mb-1">
                                {workerInfo["occupation"]}
                              </p>
                              <Link
                                to={
                                  "/Member?Member_id=" + MemberID
                                }
                                className="btn gradient-bg mt-2 px-3 py-2"
                              >
                                View Profile
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="card mt-3">
                        <h6 className="d-flex align-items-center mb-1 p-2">
                          <i className="material-icons text-primary mr-2">
                            Dream Progress
                          </i>
                        </h6>
                        <div className="fund-raised w-100 px-2">
                          <div className="fund-raised-bar-4 barfiller">
                            <LinearProgress variant="determinate" value={20} />
                          </div>

                          <div className="fund-raised-details d-flex flex-wrap justify-content-between align-items-center">
                            <div className="fund-raised-total mt-2">
                              Raised: ₹10,000
                            </div>

                            <div className="fund-raised-goal mt-2">
                              Goal: ₹70,000
                            </div>
                          </div>
                        </div>
                        <hr></hr>
                        <ul className="list-group list-group-flush">
                          {orderList != undefined &&
                            orderList.map((row) => {
                              return (
                                <li className="list-group-item d-flex justify-content-between align-items-center flex-wrap">
                                  <h6 className="mb-0">{row.amount}</h6>
                                  <span className="text-secondary">
                                    {row.donor != undefined
                                      ? row.donor.name
                                      : "NA"}
                                  </span>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-9">
                      <div className="card mb-3">
                        <div className="card-body">
                          <div className="row">
                            <div className="col-sm-3">
                              <h6 className="mb-0">For</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              {workerInfo["firstname"] +
                                " " +
                                workerInfo["lastname"]}
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <h6 className="mt-2">Amount</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              <TextField
                                variant="outlined"
                                id="amount"
                                margin="normal"
                                placeholder="Enter Amount"
                                type="number"
                                onChange={(e) => {
                                  setDonationAmount(e.target.value);
                                }}
                                value={orderAmount}
                                error={isError}
                                helperText={isError ? "Set Amount" : ""}
                              />
                            </div>
                          </div>
                          <hr />
                          <div className="row">
                            <div className="col-sm-3">
                              <h6 className="mb-0">Payment Type</h6>
                            </div>
                            <div className="col-sm-9 text-secondary">
                              <FormControl component="fieldset">
                                <RadioGroup
                                  aria-label="paymentype"
                                  name="paymentype"
                                  value={orderType}
                                  onChange={handleChange}
                                >
                                  <FormControlLabel
                                    value="ONETIME"
                                    control={<Radio />}
                                    label="One Time"
                                  />
                                  <FormControlLabel
                                    value="RECURRING"
                                    control={<Radio />}
                                    label="Recurring"
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                          </div>
                          <hr />
                          <Link
                            to={
                              "/paymentinfo?Member_id=" +
                              MemberID +
                              "&orderAmount=" +
                              orderAmount +
                              "&orderType=" +
                              orderType
                            }
                            className="btn gradient-bg mt-2 px-3 py-2"
                          >
                            Proceed With Payment
                          </Link>
                        </div>
                      </div>

                      <div className="row gutters-sm">
                        <div className="col-sm-12 mb-3">
                          <div className="card h-100">
                            <div className="card-body">
                              <h5 className="d-flex align-items-center mb-4">
                                <i className="material-icons text-primary mr-2">
                                  View/Update Profile
                                </i>
                              </h5>
                              <div className="row  ">
                                <div className="col-12 text-left">
                                  <div className="p-1">
                                    <div className="row">
                                      <div className="col-md-12">
                                        <label className="labels">Name</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="first name"
                                          name="donorname"
                                          onChange={(e) => {
                                            setDonorName(e.target.value);
                                          }}
                                          value={donorname}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="col-md-6">
                                        <label className="labels">Phone</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="phone"
                                          name="donorphone"
                                          value={donorphone}
                                          onChange={(e) => {
                                            setDonorPhone(e.target.value);
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label className="labels">Email</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="email"
                                          name="donoremail"
                                          value={donoremail}
                                          onChange={(e) => {
                                            setDonorEmail(e.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="row">
                                      <div className="col-md-12">
                                        <label className="labels">
                                          Address
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Address"
                                          name="donoraddress"
                                          onChange={(e) => {
                                            setDonorAddress(e.target.value);
                                          }}
                                          value={donoraddress}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="col-md-6">
                                        <label className="labels">
                                          PIN/Zipcode
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="enter pin/zipcode"
                                          name="donorpincode"
                                          value={donorpincode}
                                          onChange={(e) => {
                                            setDonorPincode(e.target.value);
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label className="labels">City</label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={donorcity}
                                          name="donorcity"
                                          placeholder="city"
                                          onChange={(e) => {
                                            setDonorCity(e.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="row mt-3">
                                      <div className="col-md-6">
                                        <label className="labels">
                                          Country
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="country"
                                          name="donorcountry"
                                          value={donorcountry}
                                          onChange={(e) => {
                                            setDonorCountry(e.target.value);
                                          }}
                                        />
                                      </div>
                                      <div className="col-md-6">
                                        <label className="labels">
                                          State/Region
                                        </label>
                                        <input
                                          type="text"
                                          className="form-control"
                                          value={donorstate}
                                          name="donorstate"
                                          placeholder="state"
                                          onChange={(e) => {
                                            setDonorState(e.target.value);
                                          }}
                                        />
                                      </div>
                                    </div>
                                    <div className="mt-5 text-center">
                                      <button
                                        className="btn"
                                        type="button"
                                        onClick={handleEditDonor}
                                      >
                                        Update Profile
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </Fragment>
      )}
    </>
  );

  async function handleGetWorker() {
    let RC = false;
    setWorkerInfo({});
    // let queries = queryString.parse(history.location.search);
    // let Member_id = queries.Member_id;

    if (MemberID != undefined) {
      // setMemberID(Member_id);
      let workerInfo = await getWorkerAPI(MemberID);
      if (workerInfo != undefined) {
        console.log(
          "Member Found Successfully in response",
          JSON.stringify(workerInfo)
        );
        setWorkerInfo(workerInfo);
        handleProfilePicture(workerInfo);
        return true;
      }
    }
    console.log("Member Not found");
  }

  async function handleProfilePicture(workerInfo) {
    if (workerInfo["profileimage"] != undefined) {
      let param = {
        filePath: workerInfo["profileimage"],
        fileName: "profileimage",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);
      setProfilePicture(blob);
    }
    else
    setProfilePicture(MemberImage);
  }

  async function handleGetDonor() {
    let RC = false;

    let id = localStorage.getItem("donor_id");
    if (id != undefined) {
      let donorInfo = await getDonorAPI(id);
      if (donorInfo != undefined) {
        setDonorName(donorInfo.name);
        setDonorEmail(donorInfo.email);
        setDonorPhone(donorInfo.phone);
        setDonorAddress(donorInfo.address);
        setDonorCity(donorInfo.city);
        setDonorState(donorInfo.state);
        setDonorCountry(donorInfo.country);
        console.log(
          "Donor Found Successfully in response",
          JSON.stringify(donorInfo)
        );
        return true;

        // let param = {
        //   filePath: donorInfo["profileimage"],
        //   fileName: "profileimage",
        // };

        // let blob = await getImageAPI(param);
        // console.log("response from getImage is" + blob);

        // setProfilePicture(blob);
      }
    }
    console.log("Donor Not found");
  }

  async function handleEditDonor() {
    console.log("Edit Donor API Started");

    let param = {
      name: donorname,
      phone: donorphone,
      email: donoremail,
      country: donorcountry,
      state: donorstate,
      city: donorcity,
      address: donoraddress,
    };
    let id = localStorage.getItem("donor_id");

    let rc = await editDonorAPI(id, param);
    if (rc == true) {
      console.log("Donor Added Successfully");
      setIsSubmitLoading(false);
    } else {
      setIsSubmitLoading(false);
      console.log("Donor Not Added");
    }
    console.log("Edit Donor API ENDED");
  }

  async function handleGetOrders() {
    let RC = false;
    setIsFormLoading(true);
    setDonationList(undefined);

    // let queries = queryString.parse(history.location.search);
    // let Member_id = queries.Member_id;
    if (MemberID != undefined) {
      let orderList = await getDonationForWorkerAPI(MemberID);
      if (orderList != undefined) {
        console.log(
          "Donation List Found Successfully in response",
          JSON.stringify(orderList)
        );
        setDonationList(orderList);
        setIsFormLoading(false);
        // setMemberID(Member_id);
        return true;
      }
    }
    console.log("Donation List Not found");
    setIsFormLoading(false);
  }
}

export default Homepage;
