import React, { Fragment, useState,  useEffect } from "react";
import { HashRouter, Route, Switch, Redirect, Link, useHistory, useParams  } from "react-router-dom";
import {Tab, Tabs, Box, Typography} from '@material-ui/core';

import ProfileIcon from "@material-ui/icons/Person";
import CompanyIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/GroupAdd";
import DocumentsIcon from "@material-ui/icons/NoteAdd";


import Footer from "../../components/footer";
import Header from "../../components/header";
import PageSideBar from "../../components/PageSidebar.js";
import IMAGE_BG from "../../assets/images/User-Profile/User-1.jpg";

// CUSTOM COMPONENTS
import  CircularStatic from '../../components/Controls/CircularProgressBar';

// FOR APIS
import {
  deleteMemberAPI,
  getMembersAPI,
  getMemberAPI,
  getMemberServicesAPI
} from "../../APIs/memberApis";

import {
  getImageAPI,
} from "../../APIs/ApiUtils";

import {
  downloadFile,
} from "../../helpers";


import CircularProgress from "@material-ui/core/CircularProgress";

import queryString from "query-string";

function Homepage() {
  window.scrollTo(0, 0);
  const history = useHistory();
  const params = useParams();
  
  const [memberInfo, SetMemberInfo] = useState(undefined);
  const [profileImage, SetProfileImage] = useState(undefined);
  const [servicesTable, setServicesTable] = useState(undefined);

  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [isDownloading, setDownloading] = useState(false);

  useEffect(() => {
    handleGetMember();
    handleGetMemberServices();
  }, []);

  
  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };


  return (
    <>
    {isFormLoading ? (
      <div className={{ alignContents: "center",  }}>
        <CircularStatic value={25} />
      </div>
    ) : (
    <Fragment>
      <div className="container emp-profile">
      <form method="post">
          { 
            memberInfo?.status == "PENDING" || memberInfo?.status == "INACTIVE" ? ( 
              <div className="alert alert-lightred alert-white rounded">
              <h4 className="text-dark ">
                Your profile is in pending state, Please fill all required Information.
                Profile will be activated once all documents reviewed. 
              </h4>
              {/* 
              <strong >
                <Link className="button button_primary rounded ml-3" to={"/membership/payment/" + this.props.match.params.itemId}>
                <span>Pay Membership</span>  
                </Link>
              </strong>  
              */}
              </div>  
            ):''
          }


          <div className="row">
              <div className="col-md-3">
                  <div className="profile-img mb-3">
                      <img src={memberInfo?.profilethumbnail} alt=""/>
                      {/* <div className="file btn btn-lg btn-primary">
                          Change Photo
                          <input type="file" name="file"/>
                      </div> */}
                  </div>
              </div>
              <div className="col-md-5 col-lg-7">
                <div className="profile-head">
                  <h5>
                  {(memberInfo!= undefined)?memberInfo.membername:''}
                  </h5>
                  <h6>
                  {(memberInfo!= undefined)?memberInfo.memberemail:''}
                  </h6>
                  <p className="proile-rating">Rating : <span>8/10</span></p>
                </div>
              </div>
              <div className="col-md-4 col-lg-2 pull-right">
                <button onClick={()=> { history.push("/profile/partner/edit/"+params.itemId); }} id="contact_send_btn" type="button" className="button button_primary" value="Submit">
                  <span>Update Info</span>
                </button>
              </div>
          </div>
          <div className="row">
              <div className="col-md-12 col-lg-3 order-2 order-lg-1">
                  <div className="profile-work">
                      <p>DOCUMENTS</p>
                      <div>
                        {/* {isDownloading ? (
                          <CircularProgress size={26} />
                        ) : ( */}
                        <div className={"mb-1 " + (memberInfo.memberndafile)?"cls-doc-download":"cls-disabled"}  onClick={() => {downloadFile(memberInfo?.memberndafile, "Contrcat-File")}}><span className="fa fa-cloud-download text-success"></span>{"  "}NDA Document</div>
                        <div className={"mb-1 " + (memberInfo.membercontractfile)?"cls-doc-download":"cls-disabled"} onClick={() => {downloadFile(memberInfo?.membercontractfile, "Contrcat-File")}}><span className="fa fa-cloud-download text-success"></span>{"  "}Contract Document</div>
                        {/* )} */}
                      </div>
                  </div>
              </div>
              <div className="col-md-12 col-lg-9 order-1 order-lg-2">
                <Tabs
                  value={tabIndex}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"                
                >
                  <Tab style={{ color: '#88BDBC' }}  icon={<ProfileIcon />} label="Profile Info" />
                  <Tab icon={<CompanyIcon />} iconPosition="start" label="Company Info" />
                  <Tab icon={<PersonIcon />} iconPosition="end" label="Services
                  " />
                  {/* <Tab icon={<DocumentsIcon />} iconPosition="bottom" label="Documents" /> */}
                </Tabs>
                <div className="profile-tab">
                <div
                  role="tabpanel"
                  hidden={tabIndex !== 0}
                  id={`simple-tabpanel-0`}
                  aria-labelledby={`simple-tab-0`}
                >
                  <Box p={1}>
                    <Typography>
                    <div className="row">
                              <div className="col-md-4">
                                  <label>Contact Name</label>
                              </div>
                              <div className="col-md-8">
                                  <p>{memberInfo?.membername}</p>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-md-4">
                                  <label>Contact Email</label>
                              </div>
                              <div className="col-md-8">
                                  <p>{memberInfo?.memberemail}</p>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-md-4">
                                  <label>Specialization</label>
                              </div>
                              <div className="col-md-8">
                                  <p>{memberInfo?.memberspecialization}</p>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-md-4">
                                  <label>Phone</label>
                              </div>
                              <div className="col-md-8">
                                  <p>{memberInfo?.memberphone}</p>
                              </div>
                          </div>
                          <div className="row">
                              <div className="col-md-4">
                                  <label>About Me</label>
                              </div>
                              <div className="col-md-8">
                                  <p>{memberInfo?.memberexcerpt}</p>
                              </div>
                          </div>
                    </Typography>
                  </Box>
                </div>
                <div
                  role="tabpanel"
                  hidden={tabIndex !== 1}
                  id={`simple-tabpanel-1`}
                  aria-labelledby={`simple-tab-1`}
                >
                  <Box p={1}>
                    <Typography>
                    <div className="row">
                                <div className="col-md-4">
                                    <label>Company Name</label>
                                </div>
                                <div className="col-md-8">
                                    <p>{memberInfo?.membercompany}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label>About Company</label>
                                </div>
                                <div className="col-md-8">
                                    <p>{memberInfo?.membersummary}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label>LinkedIn URL</label>
                                </div>
                                <div className="col-md-8">
                                    <p>{memberInfo?.memberlinkedin}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4">
                                    <label>Facebook URL</label>
                                </div>
                                <div className="col-md-8">
                                    <p>{memberInfo?.memberfacebook}</p>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <label>Address</label><br/>
                                    <p>{memberInfo?.address_id}</p>
                                </div>
                            </div>                  
                    </Typography>
                  </Box>
                </div>
                <div
                  role="tabpanel"
                  hidden={tabIndex !== 2}
                  id={`simple-tabpanel-2`}
                  aria-labelledby={`simple-tab-2`}
                >
                  {/* <!-- ======= Services Section ======= --> */}
                  <section id="service-options" className="services-mf route">
                      <div className="row">
                        <div className="col-sm-12">
                          <div className="title-box">
                            <p className="subtitle-a">
                              Service Provided
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="row single-service ">
                      <div className="row single-service-listing">
                          <div className="col-lg-12 ">
                              <div className="row service-related-study">
                              {
                              servicesTable != undefined && servicesTable.map(row => {
                                return ( 
                                  <div className="col-md-12 col-lg-6">
                                      <div className="card">
                                      <img className="card-icon-top" src={row.profilethumbnail} alt="https://unsplash.com/@kellybrito" />
                                      <div className="card-body pb-2">
                                        <div className="card-title text-center">{row.title}</div>
                                        <div className="card-text">{row.excerpt}</div>
                                        <div className="card-footer">{"Price : " + row.pivot.price}</div>
                                        <Link to={"/memberschedule/"+ params.itemId  +"/" + row.id } className="button button_primary_outline trans_200" >
                                          <span>View</span>
                                        </Link>                            
                                      </div>  
                                      </div>
                                  </div>
                                  )
                                  })
                                }           
                              </div>
                              {
                                servicesTable == undefined && <div>No service added for current member</div>
                              }
                          </div>
                        </div>
                      </div>
                  </section>                  
                </div>
              </div>
            </div>
          </div>
      </form>           
      </div>
    </Fragment>
    ) }
    </>   
  );


  // async function handleFileDownload() {
  //   setDownloading(true);
  //   let param = {
  //     filePath: props.state[props.paramValue + "Value"],
  //     fileName: props.paramValue,
  //   };
  //   console.log("<INFO> Following Param for Download File API",param);
  //   await downloadFileAPI(param);

  //   setDownloading(false);
  // }

  async function handleGetMember() {
    let RC = false;
    setIsFormLoading(true);

    let queries = queryString.parse(history.location.search);
    let page = queries.page != undefined ? queries.page : 1;
    let limit = queries.limit != undefined ? queries.limit : 9;
    let partner_id = params.itemId
    let memberInfo = await getMemberAPI(partner_id);
    if (memberInfo != undefined ) {
      console.log("Member Found Successfully ::");

      let param = {
        filePath: memberInfo["memberprofilefile"],
        fileName: "memberprofilefile",
      };

      let blob = await getImageAPI(param);
      // let tmp = 'data:image/bmp;base64,' + Base64.encode(blob);
      // const encodedString = Buffer.from(blob).toString('base64');
      // console.log("response from getImage is" + encodedString);

      SetProfileImage(blob);
      SetMemberInfo(memberInfo);
      setIsFormLoading(false);
      setPage(page);
      setLimit(limit);
    } else {
      console.log("Member Not found");
      setIsFormLoading(false);
    }
  }


  async function handleGetMemberServices() {
    let RC = false;
    setIsFormLoading(true);
    // let queries = queryString.parse(history.location.search);
    // let page = queries.page != undefined ? queries.page : 1;
    // let limit = queries.limit != undefined ? queries.limit : 9;
    let partner_id = params.itemId
    let iParam = { member_id : partner_id };
    // setMemberID(params.memberId);
    let services = await getMemberServicesAPI(iParam);
    if (services != undefined && services.length > 0) {
      console.log("Services Found Successfully ::", services.length);
      setServicesTable(services);
      setIsFormLoading(false);
      console.log("Services Table Data ", servicesTable);
      // setPage(page);
      // setLimit(limit);
    } else {
      console.log("Services Not found");
      setIsFormLoading(false);
    }
  }





}

export default Homepage;
