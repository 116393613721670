import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
// import Moment from 'react-moment';
import moment from "moment";

import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";
import Calendar from "../../components/Calender/calender";

// Picker
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import { makeStyles } from "@material-ui/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveIcon from "@material-ui/icons/Remove";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import axios from "axios";
import API, { API_Param } from "../../API";


import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
  getImageAPI,
} from "../../APIs/ApiUtils";

import userimage from "./../../assets/images/userimage.png";

import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";

// COMPONENTS
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { Trans } from "react-i18next";
import i18n from "../../locales/locales_config";
import { BluetoothDisabledRounded } from "@material-ui/icons";

// FOR APIS
import {
  getMemberBookingsAPI,
} from "../../APIs/memberApis";

import { getLocaleShortDateString, currencySymbol } from "./../../helpers";

class EditDonor extends Component {

  constructor() {
    super();
    this.state = {
      donorInfo: [],
      orderList: undefined,
      isSubmitLoading: false,
      isError: false,
      profilePicture: "",
      eventList: undefined
    };
  }
  componentDidMount() {
      this.init();
  }

  async init() {
    await this.handleGetBooking();
  }

  render() {
    return (
      <>
        {this.state.isFormLoading ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} className={{ justifyContent: "center" }}>
                <Paper style={{ padding: 16 }}>
                  <div className="cls-service-bookings  cls-calender">
                    { (this.state?.eventList != undefined) && 
                    <Calendar
                      month={((new Date()).getMonth() + 1)}
                      year={(new Date()).getFullYear()}
                      preloadedEvents={this.state?.eventList} />
                    }
                  </div>
                  <MUIDataTable
                    title="Bookings"
                    data={this.state.orderList}
                    columns={[
                      {
                        name: "id",
                        options: {
                          filter: false,
                          sort: false,
                          empty: true,
                          display: "excluded",
                        },
                      },
                      {
                        name: "appointment",
                        label: "Date",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return <div>{getLocaleShortDateString(value.date)}</div>;
                          },
                        },
                      },
                      {
                        name: "service",
                        label: "Service",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return <div>{value.title}</div>;
                          },
                        },
                      },
                      {
                        name: "appointment",
                        label: "Timing",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return <div>{value.starttime.slice(0, 5) + " - " + value.endtime.slice(0, 5)}</div>;
                          },
                        },
                      },
                      {
                        name: "amount",
                        label: "Amount",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                              currencySymbol() + value
                            );
                          },
                        },
                      },
                      {
                        name: "user",
                        label: "Booked By",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (<div>{JSON.stringify(value)?.name}</div>);
                          },
                        },
                      },
                      {
                        name: "status",
                        label: "Status",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return (
                              <div className="badge badge-soft-success">
                                {value}
                              </div>
                            );
                          },
                        },
                      },
                      {
                        name: "Actions",
                        options: {
                          filter: false,
                          sort: false,
                          empty: true,
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return this.customActionRenderDocument(
                              value,
                              tableMeta,
                              updateValue
                            );
                          },
                        },
                      },
                    ]}
                    options={{
                      tableBodyMaxHeight: "600px",
                      selectableRows: false,
                      fixedHeader: true,
                      filter: true,
                      print: false,
                      download: false,
                      elevation: 0,
                      filterType: "dropdown",
                      responsive: "standard",
                      expandableRows: false,
                    }}
                  // components={components}
                  />
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  handleGetBooking = async () => {
    try {
      let RC = false;
      let persona_id = localStorage.getItem("persona_id");
      let param = { member_id: persona_id, persona_type: "partner" };
  
      this.setState({ isFormLoading: true, orderList: [] });
      let orderArrs = await getMemberBookingsAPI(param);
      if (orderArrs != undefined) {
        console.log("Booking Found Successfully", orderArrs['bookings']);
  
        let eventListTmp = [];
        orderArrs['bookings']?.forEach((booking) => {
          if (
            booking?.appointment?.member?.membername &&
            booking?.appointment?.starttime &&
            booking?.appointment?.endtime
          ) {
            eventListTmp.push(
              {
                id: booking.id,
                name:
                  booking?.appointment?.member?.membername +
                  ", " +
                  booking?.appointment?.starttime.slice(0, 5) +
                  " - " +
                  booking?.appointment?.endtime.slice(0, 5),
                dateFrom: booking?.appointment?.date + 'T' + booking?.appointment?.starttime.slice(0, 5),
                dateTo: booking?.appointment?.date + 'T' + booking?.appointment?.endtime.slice(0, 5),
                meta: booking?.service?.title,
                type: booking?.status,
                // url:"/profile/startup/order/"+row.id
              });
          }
        });
  
        console.log("<handleGetBooking> Event List ", eventListTmp);
  
        this.setState({
          isFormLoading: false,
          orderList: orderArrs['bookings'],
          eventList: eventListTmp
        });
      } else {
        console.log("Orders Not found");
        this.setState({ isFormLoading: false });
      }
    } catch (error) {
      console.log("error:: " + error);
    }
  };
  

  handleSubmit = () => {
    let param = {};

    // GET THE VALUES ONLY
    Object.entries(this.state).map(([key, value]) => {
      if (key.includes("Value")) param[key.replace("Value", "")] = value;
    });

    console.log("<INFO> Param Value ", param);
    let errors = ValidateFields(this);
    console.log("Error exist of Not ", errors);

    if (errors) {
      return false;
    }
    this.setState({ isSubmitLoading: true });

    this.handleEditDonor(param);
  };

  handleParam = (e, paramKey, directValue = false) => {
    let value = e;
    if (!directValue)
      if (e.target != null && e.target.value != undefined)
        value = e.target.value;

    let paramKeyError = paramKey + "ErrorStatus";

    this.setState((prevState, props) => ({
      ...prevState,
      [paramKey + "Value"]: value,
      [paramKeyError]: false,
    }));

    let isExist = isErrorExist(this.state);
  };

  handleViewBooking = (value, tableMeta, updateValue) => {
    this.props.history.push("/profile/partner/order/" + tableMeta.rowData[0]);
  };

  customActionRenderDocument = (value, tableMeta, updateValue) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() =>
              this.handleViewBooking(value, tableMeta, updateValue)
            }
          >
            <Tooltip title="View Booking" aria-label="add">
              <Visibility />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
    );
  };
}

export default EditDonor;
