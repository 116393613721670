import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory, useLocation, useParams } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import CircularStatic from '../../../components/Controls/CircularProgressBar';
import logo from "../../../assets/images/logo.png";
import queryString from "query-string";

import { currencySymbol, calculateTaxes, otherCharges, getRandomString, getLocaleShortDateString } from "./../../../helpers";

// FOR APIS
import { getMemberAPI, getMemberServicesAPI } from "../../../APIs/memberApis";
import { createOrderAPI, getOrderAPI, createPaymentAPI, getPaymentAPI, createWalletAPI } from "../../../APIs/paymentApis";
import { addBookingAPI } from "../../../APIs/bookingApis";

function Checkout() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const [serviceID, setServiceID] = useState(0);
  const [serviceInfo, setServiceInfo] = useState(undefined);

  const [memberID, setMemberID] = useState(0);
  const [memberInfo, setMemberInfo] = useState(undefined);
  const [orderID, setOrderID] = useState(undefined);
  const [orderInfo, setOrderInfo] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [servicecostAmount, setServicecostAmount] = useState("1000");
  const [servicecostType, setServicecostType] = useState("");
  const [servicecostMethod, setServicecostMethod] = useState(undefined);
  const [paymentInfo, setPaymentInfo] = useState(undefined);



  useEffect(() => {
    window.scrollTo(0, 0);

    let isLoggedIn = localStorage.getItem("id_token");
    if (!isLoggedIn)
      history.push("/login");
    else {
      if (history.location.state == undefined)
      history.goBack();
      console.log("History Object ", history);
      handleGetMemberServices();
      handleGetMember();
    }


  }, []);

  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center", }}>
          <CircularStatic value={25} />
        </div>
      ) : (
        <Fragment>
          {/* <!-- Checkout --> */}
          <div className="container">
            <div className="row">
              <div className="col-12">
                <div className="checkout">
                  <div className="container">
                    <div className="wrapper wrapper-content animated fadeInRight">
                      <div className="row">
                        <div className="col-sm-12 col-md-7 col-lg-9">
                          <div className="ibox">
                            <div className="ibox-title">
                              <span className="pull-right">(<strong>{history?.location?.state?.appointments?.length}</strong>) Session(s)</span>
                              <h3 className="text-tertiary">{"Items in your cart" + (orderInfo != undefined ? " ( Order-" + orderInfo.id + " )" : "")}</h3>
                            </div>
                            <div className="ibox-content">
                              <div className="row shoping-cart-table">
                                <div className="col-sm-12 col-md-4 col-lg-2">
                                  <div className="about-img">
                                    <img src={(memberInfo != undefined) ? memberInfo.profilethumbnail : ""} className="img-fluid rounded b-shadow-a" alt="" />
                                  </div>
                                </div>
                                <div className="col-sm-12 col-md-8 col-lg-6 desc">
                                  <h3>
                                    <Link to={'/member/' + memberID} className="text-primary">
                                      {(memberInfo) ? memberInfo.membername : ""}
                                    </Link>
                                  </h3>
                                  <p className="small">
                                    {(memberInfo) ? memberInfo.memberexcerpt : ""}
                                  </p>
                                  <dl className="small m-b-none">
                                    <dt>Description lists</dt>
                                    <dd>{(memberInfo) ? memberInfo.membersummary : ""}</dd>
                                  </dl>

                                  {/* <div className="m-t-sm">                                                                 |
                                                  <div onClick={() => {}} className="text-danger">
                                                    <i className="fa fa-trash text-danger"></i>Remove item
                                                  </div>
                                              </div> */}
                                </div>
                                <div className="col-xs-3 col-lg-1">
                                  {currencySymbol() + (serviceInfo?.pivot?.price + "/hour") ?? '0'}
                                </div>
                                <div className="col-xs-6 col-lg-2 text-center">
                                  <span>{" X "}</span><span>{history?.location?.state?.appointments?.length + " sessions"} </span>
                                </div>
                                <div className="col-xs-3 col-lg-1 text-center">
                                  <h4>
                                    {currencySymbol() + (serviceInfo?.pivot?.price * history?.location?.state?.appointments?.length) ?? '0'}
                                  </h4>
                                </div>
                              </div>
                              <div className="mt-5">
                                <h3 className="text-tertiary">Booking Scehdules Selected</h3>
                                <div className="table-responsive">
                                  <table className="table project-list-table table-nowrap align-middle table-borderless">
                                    <thead>
                                      <tr>
                                        <th scope="col">Date</th>
                                        <th scope="col">Time</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {
                                        history?.location?.state?.appointments != undefined && history.location.state.appointments?.map((appointment) => {
                                          return (
                                            <tr>
                                              <td>{(appointment.day)}</td>
                                              <td>{"At : " + appointment.time}</td>
                                            </tr>
                                          )
                                        })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-sm-12 col-md-5 col-lg-3">
                          <div className="ibox">
                            <div className="ibox-title">
                              <h3 className="p-2 border-bottom">
                                Cart Summary
                              </h3>
                            </div>
                            <div className="ibox-content">
                              <span>
                                Total
                              </span>
                              <h2 className="font-bold">
                                {currencySymbol() + (serviceInfo?.pivot?.price * history?.location?.state?.appointments?.length) ?? '0'}
                              </h2>
                              <span className="text-muted small">
                                *As per location sales tax will be applied
                              </span>
                              <div className="mt-4">
                                <div className="btn-group">
                                  {isSubmitLoading ? (
                                    <div className={{ alignContents: "center" }}>
                                      <CircularProgress
                                        variant="indeterminate"
                                        value={75}
                                      />
                                      Payment Under Progress
                                    </div>
                                  ) : (
                                    <div>
                                      <div onClick={displayRazorpay} className="button button_primary btn-sm mb-1 width_150">
                                        <span><i className="fa fa-credit-card mx-1"></i>Pay Securely</span>
                                      </div>
                                      <div d-flex align-items-stretch >
                                        <div onClick={payByWallet} className="button button_primary btn-sm width_150">
                                          <span><i className="fa fa-shopping-cart mx-1"></i>By Wallet</span>
                                        </div>
                                        <div>
                                          <Link to="/members" className="button button_primary_outline btn-sm width_150">
                                            <span><i className="fa fa-trash mx-1"></i>Cancel</span>
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className="ibox">
                            <div className="ibox-title">
                              <h3 className="p-2 border-bottom">
                                Support</h3>
                            </div>
                            <div className="ibox-content text-center">
                              <h3><i className="fa fa-phone"></i> +12 X1330 7X3 X1</h3>
                              <span className="">
                                Please contact with us if you have any questions.
                              </span>
                            </div>
                          </div>

                          <div className="ibox">
                            <div className="ibox-content">

                              <h4 className="p-1 border-bottom">
                                Other services you may be interested
                              </h4>
                              <div className="mt-4">
                                <Link to="/service/9" className="product-name mb-2 text-tertiary">
                                  Investment Banking Services</Link>
                                <div className="small mb-2">
                                  Startup Basket funds and finds funds
                                </div>
                                <div className="m-t text-righ">
                                  <Link to="/service/9" className="button button_secondary_outline" >
                                    <span>
                                      Info <i className="fa fa-long-arrow-right"></i>
                                    </span>
                                  </Link>
                                </div>
                              </div>
                              <div className="mt-4">
                                <Link href="/service/10" className="product-name mb-2 text-tertiary">Strategic Business Advisory</Link>
                                <div className="small mb-2">
                                  Help your company maximize value with our strategic business advisory
                                </div>
                                <div className="m-t text-righ">
                                  <Link to="/service/9" className="button button_secondary_outline" >
                                    <span>
                                      Info <i className="fa fa-long-arrow-right"></i>
                                    </span>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </>
  );

  async function handleGetMember() {
    let RC = false;
    setIsFormLoading(true);
    setMemberID(params.memberId);
    let member = await getMemberAPI(params.memberId);
    if (member != undefined) {
      console.log("Members Found Successfully ::", member);
      setMemberInfo(member);
      setIsFormLoading(false);
    } else {
      console.log("Member Not found");
      setIsFormLoading(false);
    }
  }

  async function handleGetMemberServices() {
    let RC = false;
    let serviceInfoLocal = {};
    let servicecost = 0;
    setIsFormLoading(true);
    let iParam = { member_id: params.memberId };
    setMemberID(params.memberId);
    let services = await getMemberServicesAPI(iParam);
    if (services != undefined && services.length > 0) {
      console.log("Services Found Successfully ::", services.length);

      services.map(row => {
        if (row.pivot.service_id == params.serviceId) {
          serviceInfoLocal = row;
          setServiceInfo(row);
          servicecost = row?.pivot?.price * history?.location?.state?.appointments?.length;
          let servicecostType = "";
          setServicecostAmount(servicecost);
        }
      });
      setIsFormLoading(false);
      console.log("Services Table Data ", services);
      // setPage(page);
      // setLimit(limit);
    } else {
      console.log("Services Not found");
      setIsFormLoading(false);
    }
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Payment Gateway failed to load. Are you online?");
      return;
    }
    let queries = queryString.parse(history.location.search);
    let data = {
      amount: servicecostAmount,
      user_id: localStorage.getItem("persona_id"),
      user_type: "STARTUP",
      currency: "INR",
      type: "EXTERNAL"
    };

    // creating a new order
    const result = await createOrderAPI(data);
    console.log("<INFO> Response of addNewOrder", result["order"]);
    if (!result) {
      alert("Payment Gateway error. Are you online?");
      setIsFormLoading(false);
      return;
    }
    else {

      setOrderInfo(result["order"]);
    }

    let orderInfoLocal = result["order"];

    let order_id = result["order"]["id"];
    setOrderID(order_id);

    const options = {
      key: "rzp_test_te58r0UFlw7ZyG", // Enter the Key ID generated from the Dashboard
      amount: servicecostAmount * 100,
      currency: orderInfoLocal?.currency,
      name: "StartupBasket",
      description: "Service cost",
      image: { logo },
      //   order_id: order_id,
      handler: async function (response) {
        console.log("<displayRazorpay> Response payment ID", response.razorpay_payment_id);
        console.log("<displayRazorpay> Response ORDER ID", response.razorpay_order_id);
        console.log(
          "<displayRazorpay> Response subscription ID",
          response.razorpay_signature
        );
        const data = {
          user_id: orderInfoLocal.user_id,
          amount: orderInfoLocal.amount,
          taxes: calculateTaxes(orderInfoLocal).amount_taxes,
          charges: otherCharges(orderInfoLocal).charges,
          transaction_id: response.razorpay_payment_id,
          order_id: orderInfoLocal.id,
          status: 'SUCCESS',
          type: "WALLET"
          // razorpayPaymentId: response.razorpay_payment_id,
          // razorpayOrderId: response.razorpay_order_id,
          // razorpaySignature: response.razorpay_signature,
        };
        setIsSubmitLoading(true);

        const result = await createPaymentAPI(data);

        console.log("<displayRazorpay> Response of createPaymentAPI", result);
        if (result) {
          setPaymentInfo(result);
          handleAddBooking(result['id'], orderInfoLocal);
        }
        else {
          console.log("Create Payment API Failed");
          setIsSubmitLoading(false);
        }
      },
      prefill: {
        name: localStorage.getItem("user_name"),
        email: localStorage.getItem("user_email"),
        contact: localStorage.getItem("user_phone"),
      },
      notes: {
        address: "E-2/63, Arera Colony, Bhopal, INDIA",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  async function payByWallet() {
    // if (!res) {
    //   alert("Payment Gateway failed to load. Are you online?");
    //   return;
    // }
    let queries = queryString.parse(history.location.search);
    let data = {
      amount: servicecostAmount,
      user_id: localStorage.getItem("persona_id"),
      user_type: "STARTUP",
      currency: "INR",
      type: "WALLET"
    };

    // creating a new order
    const result = await createOrderAPI(data);
    console.log("<INFO> Response of addNewOrder", result["order"]);
    if (!result) {
      alert("Order creation failed. Are you online?");
      setIsFormLoading(false);
      return;
    }
    else {
      setOrderInfo(result["order"]);
    }

    let orderInfoLocal = result["order"];
    let order_id = result["order"]["id"];
    let wallet_payment_id = "wal_" + getRandomString(14);
    setOrderID(order_id);
    {
      const data = {
        user_id: orderInfoLocal.user_id,
        amount: orderInfoLocal.amount,
        taxes: calculateTaxes(orderInfoLocal).amount_taxes,
        charges: otherCharges(orderInfoLocal).charges,
        transaction_id: wallet_payment_id,
        order_id: orderInfoLocal.id,
        type: "WALLET",
        status: 'SUCCESS'
      };
      setIsSubmitLoading(true);

      const result = await createPaymentAPI(data);

      console.log("<INFO> Response of createPaymentAPI", result);
      if (result) {
        setPaymentInfo(result);

        const dataWallet = {
          user_id: orderInfoLocal.user_id,
          amount: orderInfoLocal.amount,
          payment_id: result['id'],
          order_id: orderInfoLocal.id,
          type: "WITHDRAWAL",
          status: 'SUCCESS'
        };
        setIsSubmitLoading(true);

        const resultWallet = await createWalletAPI(dataWallet);
        // if(resultWallet)
        handleAddBooking(result['id'], orderInfoLocal);
      }
      else {
        console.log("Create Payment API Failed");
        setIsSubmitLoading(false);
      }
    }
  }

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function handleAddBooking(iParam, iOrderInfo) {

    let startup_id = localStorage.getItem("persona_id");
    let RC = false;
    let param = {
      'appointment_id': history.location.state.appointments,
      'service_id': params.serviceId,
      'user_id': localStorage.getItem("user_id"),
      'member_id': params.memberId,
      'seats_booked': 1,
      'amount': iOrderInfo.amount,
      'payment_id': (paymentInfo != undefined) ? paymentInfo.id : iParam,
      'status': 'BOOKED'
    };

    console.log("<Response> Param Variable", param);

    let bookingInfo = await addBookingAPI(param);
    if (bookingInfo) {
      console.log(
        "bookingInfo created Successfully in response",
        bookingInfo
      );
      setIsSubmitLoading(false);

      // PUSH TO PAYMENT SUCCESS
      history.push("/checkoutpaymentsuccess/" + bookingInfo[0].id);
    } else {
      console.log("Booking  Not Created");
    }
  }

  async function handleOrderCreation() {

    setIsFormLoading(true);

    let servicecostAmount = serviceInfo?.pivot?.price * history?.location?.state?.appointments?.length;
    let servicecostType = "";

    setServicecostAmount(servicecostAmount);

    let data = {
      amount: servicecostAmount * 100,
      user_id: localStorage.getItem("persona_id"),
      user_type: "STARTUP",
      currency: "INR",
    };

    // creating a new order
    const result = await createOrderAPI(data);
    console.log("<INFO> Response of addNewOrder", result["order"]);
    if (!result) {
      alert("Payment Gateway error. Are you online?");
      setIsFormLoading(false);

      return;
    }
    else {
      setOrderInfo(result["order"]);
    }

    let order_id = result["order"]["id"];
    setOrderID(order_id);
    setIsFormLoading(false);

  }
}

export default Checkout;

