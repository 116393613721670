import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";

import { displayResponseError } from "./ApiUtils";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export {
  createBlogAPI,
  deleteBlogAPI,
  editBlogAPI,
  getBlogAPI,
  getBlogsAPI,
  getBlogsPaginationAPI
};

async function getBlogsPaginationAPI(iCategory, limit, page) {
  let blogs = [];
  let token = localStorage.getItem("id_token");
  const arrMonth = ["Jan","Feb","Mar","Apr","May","Jun","Jul","Aug","Sep","Oct","Nov","Dec"];
  var params = { category:iCategory };

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("blog/pagination/" + limit + "/" + page, { params: params } );
    if (response.status == 200 && response.data.status!="failed" ) {
      console.log("Response from  API :: ", response.data);
      response.data.response.blogs.data.map((blog) => {
        let tmp = {};
        Object.entries(blog).map(([key, value]) => {
          if(key == "created_at")
          {
            const d = new Date(value);
            let month = d.getMonth();
            tmp['month'] = arrMonth[month];
            let day = d.getDay();
            tmp['day'] = day;
          }
          tmp[key] = value;
        });
        blogs.push(tmp);
      });
      response.data.response.blogs.data = blogs;
      return response.data.response.blogs;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return blogs;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}


async function getBlogsAPI(iIsFeatured) {
  let blogs = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("blog", {params:{isFeatured : iIsFeatured}});
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.blog.map((blog) => {
        let tmp = {};
        Object.entries(blog).map(([key, value]) => {
          tmp[key] = value;
        });
        blogs.push(tmp);
      });
      return blogs;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return blogs;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}




async function getBlogAPI(blog_id) {
  let blog = undefined;
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("blog/" + blog_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response);
      blog = response.data.response.blog;
      if (response.data.response.volunteer != undefined && response.data.response.volunteer[0] != undefined)
        blog["volunteer_id"] = response.data.response.volunteer[0].id;
      return blog;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return blog;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function createBlogAPI(param) {
  let token = localStorage.getItem("id_token");
  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("blog", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.blogactivated == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Blog Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editBlogAPI(blog_id, param) {
  let token = localStorage.getItem("id_token");

  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    if (value != null) formData.append(key, value);
  });
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("blog/" + blog_id + "?_method=PUT", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.blogactivated == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Blog Edited Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteBlogAPI(blog_id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("blog/" + blog_id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}
