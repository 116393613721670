import React, { Fragment } from "react";
import { useNavigate, useParams, Link } from "react-router-dom";

import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";

import {
  Tab, Tabs,
  Typography,
  Paper,
  TextField,
  MenuProps,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";

import { DropzoneDialog } from "material-ui-dropzone";

// Picker
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import { makeStyles } from "@material-ui/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";
import ProfileIcon from "@material-ui/icons/Person";
import CompanyIcon from "@material-ui/icons/Business";
import DocumentsIcon from "@material-ui/icons/NoteAdd";


// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveIcon from "@material-ui/icons/Remove";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import axios from "axios";
import API, { API_Param } from "../../API";
import {
  getStartupsAPI,
  getStartupAPI,
  createStartupAPI,
  editStartupAPI,
  getCoFoundersAPI,
  deleteCoFounderAPI,
  createStartupDocumentAPI,
  getStartupDocumentAPI,
  EditStartupDocumentAPI,
  deleteStartupDocumentAPI,
  getTagsAPI,
  getOpenAPIResponse
} from "../../APIs/startupApis";

import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
  getImageAPI,
  isMembershipPaid,
  getCategoriesAPI,
} from "../../APIs/ApiUtils";

import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";

// COMPONENTS
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "../dashboard/components/Table/Table";

import { Trans } from "react-i18next";
import i18n from "../../locales/locales_config";
//const userimage = require('./../../assets/images/userimage.png')
import userimage from "./../../assets/images/userimage.png";
var requiredParam = [
  "id",
  "companyname",
  "companyurl",
  "producturl",
  "demolink",
  "tagline",
  "aboutcompany",
  "operatingin",
  // "address_id",
  "contactname",
  "contactemail",
  "contactphone",
  "category_id",
  "isproductused",
  "isrevenue",
  "isalreadyparticipate",
  "previouscompany",
  "islegalentity",
  "isalreadyinvestment",
  "companylogo",
  "profileimage",
  "businessplan",
  "ndadoc",
  "contractdoc",
  "incorporationdate",
  'filter',
  'tags',
];

const names = [
  'Oliver Hansen',
  'Van Henry',
  'April Tucker',
  'Ralph Hubbard',
  'Omar Alexander',
  'Carlos Abbott',
  'Miriam Wagner',
  'Bradley Wilkerson',
  'Virginia Andrews',
  'Kelly Snyder',
];


export default class EditStartup extends Component {
  constructor() {
    super();
    this.state = {
      name: [],
      pitchDeckTitle: "Pitch Deck for fishery industry topics",
      openAIResponse: [],
      openAIControls: [],
      searchAIData: "",
      volunteersTable: [],
      catagoriesList: [],
      tagsList: [],
      isFormLoading: false,
      isSubmitLoading: false,
      isError: false,
      deleteDialogCoFounder: false,
      deleteDialogStartupDocument: false,
      tabIndex: 0,
      // membershipPaid:0,
      cofounders: [],
      documents: [],
      ismembershippaid: false,
      is_active: false,

      category_idLabel: <Trans>CommonField.category_idLabel</Trans>,
      category_idID: "category_id",
      category_idPlaceHolder: (
        <Trans>CommonField.category_idPlaceHolder</Trans>
      ),
      category_idValue: "",
      category_idErrorStatus: false,
      category_idErrorMsg: "Error in Selection",
      category_idFieldType: "SelectField",
      category_idFieldValidation: {
        maxLength: 20,
        minLength: 0,
        checkEmpty: true,
      },
      contactnameLabel: <Trans>CommonField.contactnameLabel</Trans>,
      contactnameID: "contactname",
      contactnamePlaceHolder: <Trans>CommonField.contactnamePlaceHolder</Trans>,
      contactnameValue: "",
      contactnameErrorStatus: false,
      contactnameErrorMsg: "Error in Name",
      contactnameFieldType: "TextField",
      contactnameFieldValidation: {
        maxLength: 20,
        minLength: 0,
        checkEmpty: true,
      },

      contactemailLabel: <Trans>CommonField.contactemailLabel</Trans>,
      contactemailID: "contactemail",
      contactemailPlaceHolder: <Trans>CommonField.contactemailPlaceHolder</Trans>,
      contactemailValue: "",
      contactemailErrorStatus: false,
      contactemailErrorMsg: "Error in Name",
      contactemailFieldType: "TextField",
      contactemailFieldValidation: {
        maxLength: 120,
        minLength: 0,
        checkEmpty: true,
      },

      contactphoneLabel: <Trans>CommonField.contactphoneLabel</Trans>,
      contactphoneID: "contactphone",
      contactphonePlaceHolder: <Trans>CommonField.contactphonePlaceHolder</Trans>,
      contactphoneValue: "",
      contactphoneErrorStatus: false,
      contactphoneErrorMsg: "Error in Phone",
      contactphoneFieldType: "TextField",
      contactphoneFieldValidation: {
        maxLength: 12,
        minLength: 0,
        checkEmpty: true,
      },

      incorporationdateLabel: <Trans>CommonField.incorporationdateLabel</Trans>,
      incorporationdateID: "incorporationdate",
      incorporationdatePlaceHolder: <Trans>CommonField.incorporationdatePlaceHolder</Trans>,
      incorporationdateValue: "",
      incorporationdateErrorStatus: false,
      incorporationdateErrorMsg: "Error in incorporationdate",

      profileimageLabel: <Trans>CommonField.profileimageLabel</Trans>,
      profileimageID: "profileimage",
      profileimagePlaceHolder: (
        <Trans>CommonField.profileimagePlaceHolder</Trans>
      ),
      profileimageValue: "",
      profileimageErrorStatus: false,
      profileimageErrorMsg: "Error in profileimage",

      // statusLabel: <Trans>CommonField.statusLabel</Trans>,
      // statusID: "status",
      // statusPlaceHolder: <Trans>CommonField.statusPlaceHolder</Trans>,
      // statusValue: "",
      // statusErrorStatus: false,
      // statusErrorMsg: "Error in status",

      isproductusedLabel: <Trans>CommonField.isproductusedLabel</Trans>,
      isproductusedID: "isproductused",
      isproductusedPlaceHolder: <Trans>CommonField.isproductusedPlaceHolder</Trans>,
      isproductusedValue: "",
      isproductusedErrorStatus: false,
      isproductusedErrorMsg: "Error in isproductused",

      is_permanent_addresLabel: (
        <Trans>CommonField.is_permanent_addresLabel</Trans>
      ),
      is_permanent_addresID: "is_permanent",
      is_permaneant_addresPlaceHolder: (
        <Trans>CommonField.is_permanent_addresPlaceHolder</Trans>
      ),
      is_permanent_addresValue: "",
      is_permanent_addresErrorStatus: false,
      is_permanent_addresErrorMsg: "Error in Is_permanent_addres Name",

      previouscompanyLabel: <Trans>CommonField.previouscompanyLabel</Trans>,
      previouscompanyID: "previouscompany",
      previouscompanyPlaceHolder: <Trans>CommonField.previouscompanyPlaceHolder</Trans>,
      previouscompanyValue: "",
      previouscompanyErrorpreviouscompany: false,
      previouscompanyErrorMsg: "Error in previouscompany",

      aboutcompanyLabel: <Trans>CommonField.aboutcompanyLabel</Trans>,
      aboutcompanyID: "aboutcompany",
      aboutcompanyPlaceHolder: <Trans>CommonField.aboutcompanyPlaceHolder</Trans>,
      aboutcompanyValue: "",
      aboutcompanyErroraboutcompany: false,
      aboutcompanyErrorMsg: "Error in aboutcompany",
      aboutcompanyFieldValidation: {
        maxLength: 200,
        minLength: 2,
        checkEmpty: true,
      },

      companynameLabel: <Trans>CommonField.companynameLabel</Trans>,
      companynameID: "companyname",
      companynamePlaceHolder: <Trans>CommonField.companynamePlaceHolder</Trans>,
      companynameValue: "",
      companynameErrorStatus: false,
      companynameErrorMsg: "Error in companyname",
      companynameFieldValidation: {
        maxLength: 40,
        minLength: 1,
        checkEmpty: true,
      },

      taglineLabel: <Trans>CommonField.taglineLabel</Trans>,
      taglineID: "tagline",
      taglinePlaceHolder: <Trans>CommonField.taglinePlaceHolder</Trans>,
      taglineValue: "",
      taglineErrorStatus: false,
      taglineErrorMsg: "Error in tagline",
      taglineFieldValidation: {
        maxLength: 120,
        minLength: 0,
        checkEmpty: true,
      },

      operatinginLabel: <Trans>CommonField.operatinginLabel</Trans>,
      operatinginID: "operatingin",
      operatinginPlaceHolder: <Trans>CommonField.operatinginPlaceHolder</Trans>,
      operatinginValue: "",
      operatinginErrorStatus: false,
      operatinginErrorMsg: "Error in operatingin",

      producturlLabel: <Trans>CommonField.producturlLabel</Trans>,
      producturlID: "producturl",
      producturlPlaceHolder: <Trans>CommonField.producturlPlaceHolder</Trans>,
      producturlValue: "",
      producturlErrorStatus: false,
      producturlErrorMsg: "Error in City",

      isrevenueLabel: <Trans>CommonField.isrevenueLabel</Trans>,
      isrevenueID: "isrevenue",
      isrevenuePlaceHolder: <Trans>CommonField.isrevenuePlaceHolder</Trans>,
      isrevenueValue: "",
      isrevenueErrorStatus: false,
      isrevenueErrorMsg: "Error in City",

      demolinkLabel: <Trans>CommonField.demolinkLabel</Trans>,
      demolinkID: "demolink",
      demolinkPlaceHolder: <Trans>CommonField.demolinkPlaceHolder</Trans>,
      demolinkValue: "",
      demolinkErrorStatus: false,
      demolinkErrorMsg: "Error in City",

      // address_idLabel: <Trans>CommonField.address_idLabel</Trans>,
      // address_idID: "address_id",
      // address_idPlaceHolder: <Trans>CommonField.address_idPlaceHolder</Trans>,
      // address_idValue: "",
      // address_idErrorStatus: false,
      // address_idErrorMsg: "Error in Family ID",

      businessplanLabel: <Trans>CommonField.businessplanLabel</Trans>,
      businessplanID: "businessplan",
      businessplanPlaceHolder: <Trans>CommonField.businessplanPlaceHolder</Trans>,
      businessplanValue: "",
      businessplanErrorbusinessplan: false,
      businessplanErrorMsg: "Error in businessplan",

      ndadocLabel: <Trans>CommonField.ndadocLabel</Trans>,
      ndadocID: "ndadoc",
      ndadocPlaceHolder: (
        <Trans>CommonField.ndadocPlaceHolder</Trans>
      ),
      ndadocValue: "",
      ndadocError: false,
      ndadocErrorMsg: "Error in ndadoc",

      contractdocLabel: <Trans>CommonField.contractdocLabel</Trans>,
      contractdocID: "contractdoc",
      contractdocPlaceHolder: (
        <Trans>CommonField.contractdocPlaceHolder</Trans>
      ),
      contractdocValue: "",
      contractdocErrorcontractdoc: false,
      contractdocErrorMsg: "Error in contractdoc",

      companylogoLabel: <Trans>CommonField.companylogoLabel</Trans>,
      companylogoID: "companylogo",
      companylogoPlaceHolder: <Trans>CommonField.companylogoPlaceHolder</Trans>,
      companylogoValue: "",
      companylogoErrorcompanylogo: false,
      companylogoErrorMsg: "Error in companylogo",

      profilePicture: "",
      profilePicSize:"",
      profilePicDate:"",
      companyLogoDate:"",
      companyLogoSize:"",
      extNdaDoc:"",
      ndaDocSize:"",
      ndaDocDate:"",
      extBusinessPlan:"",
      businessPlanDate:"",
      businessPlanSize:"",
      extContractDoc:"",
      contractDocDate:"",
      contractDocSize:"",
    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    this.initialize();
    window.addEventListener('resize', this.handleWindowResize);
    this.handleWindowResize();
  }
  componentWillUnmount() {
    window.removeEventListener('resize', this.handleWindowResize);
  }
  handleWindowResize = () => {
    const isSmallScreen = window.innerWidth < 600; // Define your small screen breakpoint here
    this.setState({ isSmallScreen });
  };

  handleChange = event => {
    let tmpTagsList = [];
    event.target.value.forEach(tagID => {
      let newelement = this.state.tagsList.find(obj => {
        console.log("Current event target value :: ", obj.id, " :: ", tagID);
        if (obj.id == tagID)
          return obj;
      });
      console.log("newelement ::", newelement);
      tmpTagsList.push(newelement);
    });
    this.setState({ tagsValue: tmpTagsList });

  };

  initialize = async () => {
    this.setState({
      startupId: this.props.match.params.itemId
    });
    await this.handleGetAllCategories();
    await this.handleGetAllTags();
    await this.handleGetStartup();
    // await this.handlePitchDeck();

  };




  handleTabChange =  async (event, newValue)  => {
    // setTabIndex(newValue);
    this.setState({
      tabIndex: newValue
    });

    if(newValue==1) {
      await this.handleGetCoFounders();
    }
    else if(newValue==2){
      await this.handleGetStartupDocument();
    }
  };


  render() {
    const { t } = this.props;
    const { isSubmitLoading, isSmallScreen } = this.state;
    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: 20,
      }),
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 200,
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      },
    };




    return (
      <>
        {this.state.isFormLoading ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <Fragment>
            <div className="bg-white p-2">
              {!this.state?.ismembershippaid ? (
                <div className="alert alert-lightred alert-white rounded">
                  {/* <div className="icon float-left">
                    <i className="fa fa-times-circle"></i>
                </div> */}
                  <h4 className="text-dark ">Your Membership is not paid, Please click to paid membership</h4>
                  <strong >
                    <Link className="button button_primary rounded ml-3" to={"/membership/payment/" + this.props.match.params.itemId}>
                      <span>Pay Membership</span>
                    </Link>
                  </strong>
                </div>) : ''
              }
            </div>
            <>
              {this.state?.status}
              {(this.state?.ismembershippaid && this.state?.is_active == "INACTIVE") ? (
                <div className="alert alert-lightred alert-white rounded">
                  <h4 className="text-dark ">Please complete your profile, We will review and contact you soon</h4>
                </div>) : ''
              }
            </>
            <div className="bg-white p-2">
              <Tabs
                value={this.state.tabIndex}
                onChange={this.handleTabChange}
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                <Tab style={{ color: '#88BDBC' }} icon={<ProfileIcon />} label="Profile Info" />
                {/* <Tab icon={<CompanyIcon />} iconPosition="start" label="Company Info" /> */}
                <Tab icon={<PersonIcon />} iconPosition="end" label="Co-Founder/Team" />
                <Tab icon={<DocumentsIcon />} iconPosition="bottom" label="Documents" />
                <Tab icon={<DocumentsIcon />} iconPosition="bottom" label="Pitch Deck" />

              </Tabs>

                <div className="profile-tab">
                  <div
                    role="tabpanel"
                    hidden={this.state.tabIndex !== 0}
                    id={`simple-tabpanel-0`}
                    aria-labelledby={`simple-tab-0`}
                  >
                    <div className="row profile-data-row">
                      <div className="col-12">
                        <h2>Personal Info</h2>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <CustomSelectField paramValue="category_id" required="required" state={this.state} onChange={this.handleParam} ItemValues={(this.state.catagoriesList!=undefined)?this.state.catagoriesList:[]} />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <CustomTextField  paramValue="contactname" required="required"  state={this.state} onChange={this.handleParam} />
                      </div>

                      <div className="col-sm-12 col-md-6">
                          <CustomTextField
                           required="required" 
                            paramValue="contactemail"
                            state={this.state}
                            onChange={this.handleParam}
                          />
                      </div>
                      <div className="col-sm-12 col-md-6">

                      {
                        <CustomUploadButton
                          paramValue="profileimage"
                          tittle="Profile Image"
                          fileName={this.state.contactnameValue + "_profileimage"}
                          state={this.state}
                          ext={typeof this.state.profileimageValue === 'string'?this.state.profileimageValue?.slice(this.state.profileimageValue?.lastIndexOf('.') + 1):this.state.profileimageValue?.name?.slice(this.state.profileimageValue?.name?.lastIndexOf('.') + 1) }
                          size={this.state.profilePicSize}
                          date={this.state.profilePicDate}
                          img={this.state.profilePicture}
                          showDownload={true}
                          acceptedFiles={[
                            "image/jpeg",
                            "image/png",
                            "image/bmp",
                          ]}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                        }
                      </div>
                      <div className="col-sm-12 col-md-6">
                          <CustomTextField
                           required="required" 
                            paramValue="contactphone"
                            state={this.state}
                            onChange={this.handleParam}
                          />
                      </div>
                      <div className="col-12">
                        <h2>Company Info</h2>
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <CustomTextField
                          required="required" 
                          paramValue="companyname"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      </div> 
                      <div className="col-sm-12 col-md-6">
                        <CustomTextField
                          paramValue="tagline"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      </div>                                    
                      <div className="col-sm-12 col-md-6">
                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                          <KeyboardDatePicker
                            id="incorporationdate"
                            name="incorporationdate"
                            format="yyyy-MM-dd"
                            openTo="year"
                            views={["year", "month", "date"]}
                            label="Enter Incorporation Date"
                            minDate={new Date("1920-03-01")}
                            selected={this.state.incorporationdateValue}
                            value={this.state.incorporationdateValue}
                            onChange={(e) => {
                              let value = moment(e).format("yyyy-MM-DD");
                              console.log("Current Date", e, value);
                              this.handleParam(value, "incorporationdate", true);
                            }}
                            KeyboardButtonProps={{
                              "aria-label": "change date",
                            }}
                            error={this.state.incorporationdateErrorStatus}
                            helperText={
                              this.state.incorporationdateErrorStatus
                                ? this.state.incorporationdateErrorMsg
                                : ""
                            }
                          />
                        </MuiPickersUtilsProvider>
                      </div>
                      <div className="col-sm-12">
                        <CustomTextField
                        required="required" 
                          paramValue="aboutcompany"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      </div>
                      <div className="col-sm-12 col-md-6">
                        <CustomSelectField
                          paramValue="operatingin"
                          state={this.state}
                          onChange={this.handleParam}
                          ItemValues={[
                            {
                              value: "INDIA",
                              label: "India",
                              selected: "selected",
                            },
                            {
                              value: "USA",
                              label: "USA",
                            },
                          ]}
                        />
                      </div>

                    <div className="col-sm-12 col-md-6">
                      <FormControl
                        fullWidth
                        required={true}
                        error={false}
                      >
                        <InputLabel id="TagsValueID">
                          Select Tags For your Startup
                        </InputLabel>
                        <Select
                          required="required"
                          multiple
                          value={(this.state.tagsValue != undefined) ? this.state.tagsValue.map((item) => item.id) : []}
                          onChange={this.handleChange}
                          input={<Input id="select-multiple" />}
                        >
                          {this.state.tagsList.map(tag => (
                            <MenuItem
                              key={tag.name}
                              value={tag.id}
                            >
                              {tag.name}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </div>

                    {/* <div className="col-sm-12 col-md-6">
                      <CustomRadioButton
                        paramValue="is_permanent_addres"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: 1,
                            label: "Same as above",
                          },
                        ]}
                      />
                      </div> */}
                    <div className="col-sm-12 col-md-6">
                      <CustomRadioButton
                        paramValue="isrevenue"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "YES",
                            label: "Yes",
                          },
                          {
                            value: "NO",
                            label: "No",
                          },
                        ]}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <CustomRadioButton
                        paramValue="isproductused"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "YES",
                            label: "Yes",
                          },
                          {
                            value: "NO",
                            label: "No",
                          },
                        ]}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <CustomTextField
                        paramValue="producturl"
                        state={this.state}
                        onChange={this.handleParam}
                      />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <CustomTextField
                        paramValue="demolink"
                        state={this.state}
                        onChange={this.handleParam}
                      />
                    </div>
                    {/* <div className="col-sm-12 col-md-6">
                        <CustomTextField
                          paramValue="address_id"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      </div> */}
                  </div>
                  <Grid
                    container
                    direction="row"
                    justify="center"
                    alignItems="flex-end"
                    mt={2}
                  >
                    {/* <Grid item style={{ marginTop: 25 }} xs={3}>
                        <div>
                          {this.state.isSubmitLoading ? (
                            <CircularProgress size={26} />
                          ) : (
                            <Button
                              onClick={()=>{ 
                                this.props.history.push( {
                                  pathname:"/memberpaymentinfo",
                                  state:{ startup_id: this.state.idValue}
                                }) 
                              }}
                              type="submit"
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{
                                justifySelf: "center",
                                alignSelf: "center",
                              }}
                            >
                              Pay Membership
                            </Button>
                          )}
                        </div>
                      </Grid> */}

                    <Grid item style={{ marginTop: 25 }} xs={3}>
                      <div>
                        {this.state.isSubmitLoading ? (
                          <CircularProgress size={26} />
                        ) : (
                          <Button
                            onClick={this.handleSubmit}
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{
                              justifySelf: "center",
                              alignSelf: "center",
                            }}
                          >
                            Update Startup
                          </Button>
                        )}
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={12}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </div>
                <div
                  role="tabpanel"
                  hidden={this.state.tabIndex !== 1}
                  id={`simple-tabpanel-2`}
                  aria-labelledby={`simple-tab-2`}
                >
                  <div className="row profile-data-row">
                    <h2>Co-Founder/Team</h2>
                    <Grid
                      container
                      direction="row"
                      justify="space-between"
                      alignItems="center"
                      spacing={2}
                    >
                      <Grid
                        item
                        xs={12}
                        className={{ justifyContent: "space-between" }}
                      >
                        <MUIDataTable
                          data={this.state.cofounders}
                          columns={[
                            {
                              name: "id",
                              options: {
                                filter: false,
                                sort: false,
                                empty: true,
                                display: "excluded",
                              },
                            },
                            {
                              name: "name",
                              label: "Name",
                            },
                            {
                              name: "designation",
                              label: "Designation",
                            },
                            {
                              name: "partnershippercentage",
                              label: "Equity",
                            },
                            {
                              name: "aboutcofounder",
                              label: "About",
                            },
                            {
                              name: "Actions",
                              options: {
                                filter: true,
                                sort: false,
                                empty: true,
                                customBodyRender: (
                                  value,
                                  tableMeta,
                                  updateValue,
                                ) => {
                                  return this.customActionRender(
                                    value,
                                    tableMeta,
                                    updateValue,
                                  );
                                },
                              },
                            },
                          ]}
                          options={{
                            tableBodyMaxHeight: "400px",
                            selectableRows: false,
                            filter: false,
                            print: false,
                            download: false,
                            elevation: 0,
                            fixedHeader: true,
                            filter: false,
                            filterType: "dropdown",
                            responsive: "standard",
                            expandableRows: false,
                          }}
                        // components={components}
                        />

                        <Grid
                          container
                          direction="column"
                          justify="center"
                          alignItems="flex-end"
                        >
                          <Button
                            style={{ marginTop: 10 }}
                            variant="contained"
                            color="primary"
                            size="small"
                            onClick={() => {
                              this.props.history.push("/profile/startup/cofounder/add/" + this.props.match.params.itemId);
                            }}
                          >
                            Add Co-Founder
                          </Button>
                        </Grid>


                          </Grid>
                      </Grid>
                    </div>
                  </div>
                  <div
                    role="tabpanel"
                    hidden={this.state.tabIndex !== 2}
                    id={`simple-tabpanel-3`}
                    aria-labelledby={`simple-tab-3`}
                  >
                    <div className="row profile-data-row">
                      <h2>Documents</h2>
                      <div className="cls-required-documents">
                        <h3 className="mt-3">Required Documents</h3>
                        <div className="row mt-3 mx-3 justify-content-center" >
                          <div className="col-xs-12 col-sm-6 col-md-3 justify-content-xs-center  p-2">
                            <CustomUploadButton
                              paramValue="companylogo"
                              tittle="Company Logo"
                              fileName={this.state.contactnameValue + "_uploadedform"}
                              maxFileNo={2}
                              state={this.state}
                              ext={typeof this.state.companylogoValue === 'string'?this.state.companylogoValue?.slice(this.state.companylogoValue?.lastIndexOf('.') + 1):this.state.companylogoValue?.path.slice(this.state.companylogoValue?.path.lastIndexOf('.') + 1) }
                              size={this.state.companyLogoSize}
                              date={this.state.companyLogoDate}
                              img={this.state.profilePicture}
                              showDownload={true}
                              acceptedFiles={[
                                "image/jpeg",
                                "image/png",
                                "image/bmp",
                              ]}
                              handleFileOpen={this.handleFileOpen}
                              handleFileClose={this.handleFileClose}
                              handleFileSave={this.handleFileSave}
                            />
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-3 justify-content-xs-center mt-xs-3 p-2">
                              <CustomUploadButton
                                paramValue="ndadoc"
                                tittle="NDA Doc"
                                fileName={this.state.contactnameValue + "_ndadoc"}
                                state={this.state}
                                ext={typeof this.state.ndadocValue === 'string'?this.state.ndadocValue?.slice(this.state.ndadocValue?.lastIndexOf('.') + 1):this.state.ndadocValue?.path.slice(this.state.ndadocValue?.path.lastIndexOf('.') + 1) }
                                currExt={this.state.extNdaDoc!==''?this.state.extNdaDoc.slice(this.state.extNdaDoc.lastIndexOf('.')+1):undefined}
                                size={this.state.ndaDocSize}
                                date={this.state.ndaDocDate}
                                showDownload={true}
                                acceptedFiles={[
                                  "application/pdf",
                                  "application/msword",
                                  "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                  "application/vnd.ms-powerpoint",
                                  "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                                ]}                                
                                handleFileOpen={this.handleFileOpen}
                                handleFileClose={this.handleFileClose}
                                handleFileSave={this.handleFileSave}
                              />
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-3 justify-content-xs-center p-2">
                            <CustomUploadButton
                              paramValue="businessplan"
                              tittle="Business Plan"
                              fileName={this.state.contactnameValue + "_businessplan"}
                              state={this.state}
                              ext={typeof this.state.businessplanValue === 'string'?this.state.businessplanValue?.slice(this.state.businessplanValue?.lastIndexOf('.') + 1):this.state.businessplanValue?.path.slice(this.state.businessplanValue?.path.lastIndexOf('.') + 1) }
                              currExt={this.state.extBusinessPlan!==''?this.state.extBusinessPlan.slice(this.state.extBusinessPlan.lastIndexOf('.')+1):undefined}
                              size={this.state.businessPlanSize}
                              date={this.state.businessPlanDate}
                              showDownload={true}
                              acceptedFiles={[
                                "application/pdf",
                                "application/msword",
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                "application/vnd.ms-powerpoint",
                                "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                              ]}                              
                              handleFileOpen={this.handleFileOpen}
                              handleFileClose={this.handleFileClose}
                              handleFileSave={this.handleFileSave}
                            />
                          </div>
                          <div className="col-xs-12 col-sm-6 col-md-3 justify-content-xs-center mt-xs-3 p-2">
                            <CustomUploadButton
                              paramValue="contractdoc"
                              tittle="Contract Doc"
                              fileName={this.state.contactnameValue + "_rationcard"}
                              state={this.state}
                              ext={typeof this.state.contractdocValue === 'string'?this.state.contractdocValue?.slice(this.state.contractdocValue?.lastIndexOf('.') + 1):this.state.contractdocValue?.path.slice(this.state.contractdocValue?.path.lastIndexOf('.') + 1) }
                              currExt={this.state.extContractDoc!==''?this.state.extContractDoc.slice(this.state.extContractDoc.lastIndexOf('.')+1):undefined}
                              size={this.state.contractDocSize}
                              date={this.state.contractDocDate}
                              showDownload={true}
                              acceptedFiles={[
                                "application/pdf",
                                "application/msword",
                                "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                                "application/vnd.ms-powerpoint",
                                "application/vnd.openxmlformats-officedocument.presentationml.presentation"
                              ]}                              
                              handleFileOpen={this.handleFileOpen}
                              handleFileClose={this.handleFileClose}
                              handleFileSave={this.handleFileSave}
                            />
                          </div>
                        </div>
                        <Grid
                        container
                        direction="column"
                        alignItems={isSmallScreen ? 'center' : 'flex-end'}
                        className='mt-3'
                      >
                        <div>
                          {this.state.isSubmitLoading ? (
                            <CircularProgress size={26} />
                          ) : (
                            <Button
                              onClick={this.handleSubmit}
                              type="submit"
                              variant="contained"
                              color="primary"
                              size="small"
                              style={{
                                justifySelf: "center",
                                alignSelf: "center",
                              }}
                            >
                              Update Documents
                            </Button>
                          )}
                        </div>
                      </Grid>
                    </div>
                    <div className="cls-extra-document mt-5">
                      <h3 className="">Extra Documents</h3>
                      <MUIDataTable
                        // title="Uploaded Documents"
                        data={this.state.documents}
                        columns={[
                          {
                            name: "id",
                            options: {
                              filter: false,
                              sort: false,
                              empty: true,
                              display: "excluded",
                            },
                          },
                          {
                            name: "title",
                            label: "Title",
                          },
                          {
                            name: "description",
                            label: "Description",
                          },

                          {
                            name: "Actions",
                            options: {
                              filter: false,

                              sort: false,
                              empty: true,
                              customBodyRender: (
                                value,
                                tableMeta,
                                updateValue,
                              ) => {
                                return this.customActionRenderDocument(
                                  value,
                                  tableMeta,
                                  updateValue,
                                );
                              },
                            },
                          },
                        ]}
                        options={{
                          tableBodyMaxHeight: "400px",
                          selectableRows: false,
                          fixedHeader: true,
                          filter: false,
                          print: false,
                          download: false,
                          elevation: 0,
                          filterType: "dropdown",
                          responsive: "standard",
                          expandableRows: false,
                        }}
                      />
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                        mt={2}
                      >
                        <Button
                          style={{ marginTop: 10 }}
                          variant="contained"
                          color="primary"
                          size="small"
                          mt={2}
                          onClick={() => {
                            this.props.history.push("/profile/startup/document/add/" + this.props.match.params.itemId);
                          }}
                        >
                          Add Document
                        </Button>
                      </Grid>
                    </div>


                  </div>
                </div>
                <div
                  role="tabpanel"
                  hidden={this.state.tabIndex !== 3}
                  id={`simple-tabpanel-4`}
                  aria-labelledby={`simple-tab-4`}
                >
                  <div className="row profile-data-row">
                    <h2>Pitch Deck</h2>
                    <div className="col-sm-12 col-md-6">
                      <input name="searchTitle" value={this.state.pitchDeckTitle} onChange={(event) => { this.setState({ pitchDeckTitle: event.target.value }) }} placeholder="Enter Pitch Deck Title" />
                    </div>
                    <div className="col-sm-12 col-md-6">
                      <button className="button button_primary " onClick={() => { this.handlePitchDeckTitle(this.state.pitchDeckTitle) }}>
                        <span>
                          Create Pitch Deck
                        </span>
                      </button>
                    </div>
                    <div>
                      {this.state?.openAIResponse?.text?.map((row, index) => {
                        return (
                          <>
                            <i className="fa fa-user" onClick={() => { this.handleOpenAIControl(index, row?.key) }}></i>
                            <p>{row?.key}</p>
                            <textarea style={{ width: 1000, minHeight: 300 }} className="cls-textarea" name="control" value={this.state.openAIControls[index]} ></textarea>
                            <hr />
                          </>
                        );
                      })}

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>
        )}
      </>
    );
  }


  handleOpenAIControl = async (index, iSearchData) => {
    let textData = await this.handlePitchDeck(iSearchData);
    let tmpopenAIControls = this.state.openAIControls;
    tmpopenAIControls[index] = textData;
    this.setState({ openAIControls: tmpopenAIControls });

  }

  handlePitchDeck = async (iSearchData) => {
    let RC = false;
    this.setState({ isFormLoading: true });
    let rc = await getOpenAPIResponse(iSearchData);
    if (rc != undefined) {
      this.setState({ isFormLoading: false });
      if (!Array.isArray(rc?.result.text))
        return rc?.result.text
      else {
        let arrResult = [];
        rc?.result?.text?.map((row) => {
          arrResult.push(row?.key);
          arrResult.push(row?.value);
        });
        console.log("Return from OPEN AI Data ::", arrResult);
        return arrResult.toString();
      }
    } else {
      console.log("Startup Not found");
      this.setState({ isFormLoading: false });
    }
  };


  handlePitchDeckTitle = async (iSearchData) => {
    let RC = false;
    this.setState({ isFormLoading: true, openAIResponse: [] });
    let rc = await getOpenAPIResponse(iSearchData);
    if (rc != undefined) {
      this.setState({
        openAIResponse: rc?.result,
        isFormLoading: false,
      });
      return rc?.result.text
    } else {
      console.log("Startup Not found");
      this.setState({ isFormLoading: false });
    }
  };



  handleGetStartup = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, startupInfo: {}, startupId: this.props.match.params.itemId });
    let startup_id = this.props.match.params.itemId;
    let startupInfo = await getStartupAPI(startup_id);
    if (startupInfo != undefined) {
      let tmp = {};
      Object.entries(startupInfo).map(([key, value]) => {
        if (requiredParam.includes(key)) tmp[key + "Value"] = value;
        // if (key == "volunteers") tmp["category_id" + "Value"] = value[0].id;
      });
      console.log(
        "Startup Found Successfully in edit response",
        tmp,
      );

      let param = {
        filePath: tmp["profileimageValue"],
        fileName: "profileimage",
      };
      console.log("param for getImageAPI:: ",tmp["profileimageValue"]);
      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);
      localStorage.setItem("IS_MEMBERSHIP_PAID", startupInfo.ismembershippaid);
      this.setState({
        ...tmp,
        ismembershippaid: startupInfo.ismembershippaid,
        is_active: startupInfo.status,
        isFormLoading: false,
        profilePicture: blob,
      });

    } else {
      console.log("Startup Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleGetAllCategories = async () => {
    let RC = false;
    let arrCategories = [];

    this.setState({ isFormLoading: true, catagoriesList: {} });
    let categories = await getCategoriesAPI();
    if (categories != undefined) {

      categories.forEach(element => {
        let tmp = {};
        tmp['value'] = element['id'];
        tmp['label'] = element['title'];
        arrCategories.push(tmp);
      });
      console.log("Categories found successfully", arrCategories);

      this.setState({
        catagoriesList: arrCategories,
        isFormLoading: false,
      });

    } else {
      console.log("Categories Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleGetAllTags = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, tagsList: [] });

    let tags = await getTagsAPI();
    if (tags != undefined && tags.length > 0) {
      // console.log("tags Found Successfully ::", tags.length);
      // const result = Object.values(tags);      
      // let result = Object.keys(tags).map((key) => tags[key]);
      // console.log("tags Found Successfully ::", result);
      this.setState({
        tagsList: tags,
        isFormLoading: false,
      });
      console.log("tags Table Data ", tags);
    } else {
      console.log("tags Not found");
      this.setState({ isFormLoading: false });
    }
  }

  handleEditStartup = async (param) => {
    console.log("createStartup API Started");
    let startup_id = this.props.match.params.itemId;
    let startup = await editStartupAPI(startup_id, param);
    if (startup) {
      console.log("Startup Updated Successfully");
      this.setState({ isSubmitLoading: false });
      if (this.state.ismembershippaid)
        this.props.history.push("/profile/startup/edit/" + startup_id);
      else
        this.props.history.push("/membership/payment/" + startup_id);
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Startup Not Updated");
    }
  };

  handleGetCoFounders = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, startupInfo: {} });
    let startup_id = this.props.match.params.itemId;

    let cofounders = await getCoFoundersAPI(startup_id);
    if (cofounders != undefined) {
      console.log("Co-Founders Found Successfully", cofounders);

      this.setState({
        cofounders: cofounders,
        isFormLoading: false,
      });
    } else {
      console.log("Co-Founders Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleEditCoFounder = (value, tableMeta, updateValue) => {
    this.props.history.push("/profile/startup/cofounder/edit/" + this.props.match.params.itemId + "/" + tableMeta.rowData[0]);
  };

  handleDeleteCoFounder = async (value, tableMeta, updateValue) => {
    console.log("tableMeta :: ", tableMeta.rowData);
    this.handleCloseCoFounder();
    let rc = await deleteCoFounderAPI(tableMeta.rowData[0]);
    if (rc == true) {
      console.log("CoFounder Deleted Successfully");
      this.handleGetCoFounders();
      //toast.success("CoFounder Deleted");

      const Page = () => {
        <div>
          <ToastContainer />
          <button onClick={() => toast("Toast Message")}>
            show notification
          </button>
        </div>;
      };
    } else console.log("CoFounder Not Deleted");
  };

  handleCreateStartupDocument = async (param) => {
    
    console.log("createStartup API Started");
    let startup_id = this.props.match.params.itemId;
    let rc = await createStartupDocumentAPI(startup_id, param);
    if (rc == true) {
      console.log("Startup Document Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/profile/startup/edit/" + startup_id);
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Startup document not Added");
    }
    console.log("createStartup API ENDED");
  };

  handleGetStartupDocument = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, documents: [] });
    let startup_id = this.props.match.params.itemId;

    let documents = await getStartupDocumentAPI(startup_id);
    if (documents != undefined) {
      console.log("Documents Found Successfully", documents);
      this.setState({
        documents: documents,
        isFormLoading: false,
      });
    } else {
      console.log("Startup Document Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleEditStartupDocumentAPI = async (value, tableMeta, updateValue) => {
    this.props.history.push("/profile/startup/document/edit/" + this.props.match.params.itemId + "/" + tableMeta.rowData[0]);
  };

  handleDeleteStartupDocument = async (value, tableMeta, updateValue) => {
    console.log("tableMeta :: ", tableMeta.rowData);
    this.handleCloseStartupDocument();
    let rc = await deleteStartupDocumentAPI(tableMeta.rowData[0]);
    if (rc == true) {
      this.handleGetStartupDocument();

      console.log("Startup Document Deleted Successfully");
      //toast.success("Document Deleted");

      const Page = () => {
        <div>
          <ToastContainer />
          <button onClick={() => toast("Toast Message")}>
            show notification
          </button>
        </div>;
      };
    } else console.log("Document Not Deleted");
  };

  handleSubmit = () => {
    let param = {};

    let errors = ValidateFields(this);
    console.log("Error exist of Not ", errors);
    if (errors) {
      return false;
    }

    // GET THE VALUES ONLY
    Object.entries(this.state).map(([key, value]) => {
      if (key.includes("Value")) {
        if (key == 'tagsValue') {     //if(typeof value === 'object'){     
          let tmpTagsArr = [];
          value.forEach(tagElem => {
            if (typeof value === 'object') {
              tmpTagsArr.push(tagElem.id);
            }
            // console.log("Current Element :: ",JSON.stringify(tagElem));
          });
          console.log("Current tmpTagsArr :: ", tmpTagsArr);
          param[key.replace("Value", "")] = tmpTagsArr;
        }
        else
          param[key.replace("Value", "")] = value;
      }
    });
    console.log("<INFO> Param Value ", param);

    this.setState({ isSubmitLoading: true });

    this.handleEditStartup(param);
  };

  handleParam = (e, paramKey, directValue = false) => {
    let value = e;
    if (!directValue)
      if (e.target != null && e.target.value != undefined)
        value = e.target.value;

    let paramKeyError = paramKey + "ErrorStatus";

    this.setState((prevState, props) => ({
      ...prevState,
      [paramKey + "Value"]: value,
      [paramKeyError]: false,
    }));

    let isExist = isErrorExist(this.state);
    if (this.state.isError && !isExist) this.setState({ isError: false });
  };

  handleFileClose = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: false,
    });
  };

  handleFileSave = (param, files) => {
    console.log("Current File List :: ", param, files[0]);
    const size=files[0].size/1024;
    if(param==="profileimage"){
      const imageUrl = URL.createObjectURL(files[0]);
      this.setState({
        ...this.state,
        [param + "Value"]: files[0],
        profilePicture:imageUrl,
        profilePicDate:files[0].lastModifiedDate,
        profilePicSize:size,
        [param + "MediaOpen"]: false,
      });
      console.log("type of file in save func:: ",typeof this.state.profileimageValue);
    }else if(param==="companylogo"){
      const imageUrl = URL.createObjectURL(files[0]);
      this.setState({
        ...this.state,
        [param + "Value"]: files[0],
        profilePicture:imageUrl,
        companyLogoDate:files[0].lastModifiedDate,
        companyLogoSize:size,
        [param + "MediaOpen"]: false,
      });
    }else if(param==='ndadoc'){
      this.setState({
        ...this.state,
        [param + "Value"]: files[0],
        ndaDocDate:files[0].lastModifiedDate,
        ndaDocSize:size,
        extNdaDoc:files[0].path,
        [param + "MediaOpen"]: false,
      });
    }else if(param==='businessplan'){
      this.setState({
        ...this.state,
        [param + "Value"]: files[0],
        businessPlanDate :files[0].lastModifiedDate,
        businessPlanSize:size,
        extBusinessPlan:files[0].path,
        [param + "MediaOpen"]: false,
      });
    }else if(param==='contractdoc'){
      this.setState({
        ...this.state,
        [param + "Value"]: files[0],
        contractDocDate:files[0].lastModifiedDate,
        contractDocSize:size,
        extContractDoc:files[0].path,
        [param + "MediaOpen"]: false,
      });
    }
  };

  handleFileOpen = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: true,
    });
  };

  customActionRender = (value, tableMeta, updateValue) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() =>
              this.handleEditCoFounder(value, tableMeta, updateValue)
            }
          >
            <Tooltip title="Edit CoFounder" aria-label="add">
              <EditIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => {
              var retVal = window.confirm(
                'Are You Sure to Delete Member "' + tableMeta.rowData[1] + '"',
              );
              if (retVal == true) {
                this.handleDeleteCoFounder(value, tableMeta, updateValue);
                return true;
              } else {
                return false;
              }
            }}
          // onClick={() =>
          //   this.handleDeleteDialogMember(value, tableMeta, updateValue)
          // }
          >
            <Tooltip title="Delete Current CoFounder" aria-label="add">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Grid>

        {/* <Dialog
          open={this.state.deleteDialogCoFounder}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseCoFounder}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Deleting CoFounder
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {'Are You Sure to Delete "' + tableMeta.rowData[1] + '" '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseCoFounder} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.handleDeleteCoFounder(value, tableMeta, updateValue);
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog> */}
      </Grid>
    );
  };

  customActionRenderDocument = (value, tableMeta, updateValue) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() =>
              this.handleEditStartupDocumentAPI(value, tableMeta, updateValue)
            }
          >
            <Tooltip title="Edit StartupDocument" aria-label="add">
              <EditIcon />
            </Tooltip>
          </IconButton>
        </Grid>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() => {
              var retVal = window.confirm(
                'Are You Sure to Delete Document "' +
                tableMeta.rowData[1] +
                '"',
              );
              if (retVal == true) {
                this.handleDeleteStartupDocument(value, tableMeta, updateValue);
                return true;
              } else {
                return false;
              }
            }}
          // onClick={() =>
          //   this.handleDeleteDialogDocument(value, tableMeta, updateValue)
          // }
          >
            <Tooltip title="Delete Current StartupDocument" aria-label="add">
              <DeleteIcon />
            </Tooltip>
          </IconButton>
        </Grid>

        {/* <Dialog
          open={this.state.deleteDialogStartupDocument}
          TransitionComponent={Transition}
          keepMounted
          onClose={this.handleCloseStartupDocument}
          aria-labelledby="alert-dialog-slide-title"
          aria-describedby="alert-dialog-slide-description"
        >
          <DialogTitle id="alert-dialog-slide-title">
            Deleting StartupDocument
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-slide-description">
              {'Are You Sure to Delete "' + tableMeta.rowData[1] + '" '}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleCloseStartupDocument} color="primary">
              Disagree
            </Button>
            <Button
              onClick={() => {
                this.handleDeleteStartupDocument(value, tableMeta, updateValue);
              }}
              color="primary"
            >
              Agree
            </Button>
          </DialogActions>
        </Dialog> */}
      </Grid>
    );
  };

  handleDeleteDialogDocument = (value, tableMeta, updateValue) => {
    this.setState({ deleteDialogStartupDocument: true });
  };

  handleCloseStartupDocument = () => {
    this.setState({ deleteDialogStartupDocument: false });
  };

  handleCloseCoFounder = () => {
    this.setState({ deleteDialogCoFounder: false });
  };

  handleDeleteDialogMember = (value, tableMeta, updateValue) => {
    this.setState({ deleteDialogCoFounder: true });
  };
}
