import React, { Fragment,useRef, useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import queryString from "query-string";
import Calendar from "../../components/Calender/calender";
import logo from "../../assets/images/logo.png";

// FOR APIS
import {otherCharges,  getLocaleShortDateString, currencySymbol, calculateTaxes } from "../../helpers";
import { getWalletsByUserAPI, createOrderAPI, getOrderAPI, createPaymentAPI, getPaymentAPI, createWalletAPI } from "../../APIs/paymentApis";

// CUSTOM COMPONENTS
import  CircularStatic from '../../components/Controls/CircularProgressBar';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


// The "main" component, our actual calendar
const Orders = () => {
  window.scrollTo(0, 0);

  const history = useHistory();
  const params = useParams();
  const [eventList, setEventList] = useState(undefined);
  const [amountWallet, setAmountWallet] = useState(undefined);
  const [walletList, setWalletList] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);
  const example1 = useRef(null);
  const [amountToAdd, setAmountToAdd] = useState(0);

  useEffect(() => {
  	console.log("Date has changed... Let's load some fresh data")
    handleGetWallets();
  }, [])

  async function handleGetWallets()  {
    let RC = false;
    let persona_id = params.itemId;
    let param = { startup_id: persona_id, persona_type : "startup" };
    setIsFormLoading(true);
    setWalletList(undefined);
    let walletArrs = await getWalletsByUserAPI(param);
    if (walletArrs != undefined) {
      console.log("Wallet Found Successfully", walletArrs);
        setWalletList(walletArrs?.wallets);
        setAmountWallet(walletArrs?.user?.wallet)
        setIsFormLoading(false);
    } else {
      console.log("Orders Not found");
      setIsFormLoading(false);
    }
  };

  async function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function displayRazorpay() {
    setIsFormLoading(true);
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Payment Gateway failed to load. Are you online?");
      return;
    }
    let data = {
      amount: amountToAdd ,
      user_id: localStorage.getItem("persona_id"),
      user_type:"STARTUP",
      currency: "INR",
      type: "WALLET"
    };

    // creating a new order
    let result = await createOrderAPI(data);
    console.log("<INFO> Response of addNewOrder", result["order"]);
    if (!result) {
      alert("Payment Gateway error. Are you online?");
      setIsFormLoading(false);
      return;
    }


    let orderInfoLocal = result["order"];
    let order_id = result["order"]["id"];

    const options = {
      key: "rzp_test_te58r0UFlw7ZyG", // Enter the Key ID generated from the Dashboard
      amount: amountToAdd * 100,
      currency: orderInfoLocal?.currency,
      name: "StartupBasket",
      description: "Wallet Deposit",
      image: { logo },
    //   order_id: order_id,
      handler: async function (response) {
        console.log("<INFO> Response payment ID", response.razorpay_payment_id);
        console.log("<INFO> Response ORDER ID", response.razorpay_order_id);
        console.log(
          "<INFO> Response subscription ID",
          response.razorpay_signature
        );
        const data = {
          user_id: orderInfoLocal.user_id,
          amount: orderInfoLocal.amount,
          taxes: calculateTaxes(orderInfoLocal).amount_taxes,
          charges: otherCharges(orderInfoLocal).charges,
          transaction_id: response.razorpay_payment_id,
          order_id: orderInfoLocal.id,
          status:'SUCCESS',
          type:"EXTERNAL"
          // razorpayPaymentId: response.razorpay_payment_id,
          // razorpayOrderId: response.razorpay_order_id,
          // razorpaySignature: response.razorpay_signature,
        };
        setIsSubmitLoading(true);

        const result = await createPaymentAPI(data);

        console.log("<INFO> Response of createPaymentAPI", result);
        if (result) {
          const dataWallet = {
            user_id: orderInfoLocal.user_id,
            amount: orderInfoLocal.amount,
            payment_id: result['id'],
            order_id: orderInfoLocal.id,
            type:"DEPOSIT",
            status:'SUCCESS'
          };
          const resultWallet = await createWalletAPI(dataWallet);  
          if(resultWallet) {
            //toast.success("Successfully updated your wallet");
            handleGetWallets();
            setIsSubmitLoading(false);
            // window.location.reload();
          }
        }
        else{
          console.log("Create Payment API Failed");
          toast.error("Payment is failed, Please try again");
          setIsSubmitLoading(false);
        }
      },
      prefill: {
        name: localStorage.getItem("user_name"),
        email: localStorage.getItem("user_email"),
        contact: localStorage.getItem("user_phone"),
      },
      notes: {
        address: "E-2/63, Arera Colony, Bhopal, INDIA",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  

  return (
    <>
      <>
        {isFormLoading ? (
          <div className={{ alignContents: "center",  }}>
            {/* <CircularProgress variant="indeterminate" value={75} /> */}
            <CircularStatic value={25} />
          </div>
        ) : (
        <>
          <div className="cls-wallet-payment">
            <div className="container ">
              <div className="row">
                <div className="col-md-12 card card-white p-3">                
                  <div className="d-flex flex-row">
                    <h3 className="text-dark font-weight-bold mt-1"><i className="fa fa-money text-green mx-2"></i> Wallet Amount : </h3>
                    <span className="font-weight-bold ">
                      <h3 className="cls-wallet-amount">
                        {" " + currencySymbol() + amountWallet}
                      </h3>
                    </span> 
                  </div>
                  <div className="d-flex flex-row align-items-center">
                    <div className="mx-3">
                      <input className="input_field" value={amountToAdd} onChange={(event)=>{setAmountToAdd(event.target.value)}} ></input>
                    </div>
                    <div className="button button_primary"  onClick={displayRazorpay}>
                      <span>Add Money to Wallet</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            
            <div className="container ">
            <div className="row">
              <div className="col-md-12 p-0">
                <div className="card card-white mb-5">
                  <div className="card-heading clearfix bwallet-bottom mb-4">
                      <h4 className="card-title mx-4">Wallet Payments</h4>
                  </div>
                  <div className="card-body">
                    <div className="container">
                      <div className="row">
                        <div className="col-xl-12">
                          <div className="card">
                            <div className="card-body">
                              <div className="table-responsive">
                                <table className="table table-hover mb-0">
                                  <thead>
                                    <tr className="align-self-center">
                                      <th>User Name</th>
                                      <th>Transacion ID</th>
                                      <th>Type</th>
                                      <th>Paid Date</th>
                                      <th>Amount</th>
                                      <th>Status</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {
                                      walletList != undefined && walletList?.map((row) => {
                                      return (                                
                                        <tr>
                                        <td><img src="https://bootdey.com/img/Content/avatar/avatar2.png" alt="" className="thumb-sm rounded-circle mx-2" />
                                        {/* {row.user.name} */}
                                        </td>
                                        <td>{row?.payment.transaction_id}</td>                                        
                                        <td><span className={(row?.type=="WITHDRAWAL")?"badge badge-boxed badge-soft-withdrawal":"badge badge-boxed badge-soft-deposit"} >{row?.type}</span></td>
                                        <td>{getLocaleShortDateString(row?.created_at)}</td>
                                        <td>{currencySymbol() + row?.amount}</td>
                                        <td><span className={(row?.status=="SUCCESS")?"badge badge-boxed badge-soft-success":"badge badge-boxed badge-soft-warning"}>{row?.status}</span></td>
                                    </tr>
                                    )
                                    })}
                                    {
                                      (walletList == undefined || walletList.length == 0 ) && 
                                      <div className="text-secondary">No Wallet Trasaction Done</div>
                                    }



                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>
            </div>
          </div>
        </>
        )}
      </>
    </>
  )
}

export default Orders;


