import React, { Fragment, useState, useEffect } from "react";

import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
  useHistory,
  useParams
} from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";

import memberImage from "../../assets/images/logo.png";
import CloudUploadIcon from "@material-ui/icons/CloudDownload";
import { Button } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import Footer from "../../components/footer";
import Header from "../../components/header";

import ReactPlayer from "react-player";
import {
  Grid,
  CircularProgress,
  TextField,
  Fade,
  FormControl,
  InputLabel,
  Input,
} from "@material-ui/core";



import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
  getImageAPI,
} from "../../APIs/ApiUtils";

import {
  getStartupAPI,
} from "../../APIs/startupApis";


import { getOrderAPI } from "../../APIs/paymentApis";

import {getLocalDateTimeString, currencySymbol} from "../../helpers";

function Homepage(props) {
  window.scrollTo(0, 0);

  const history = useHistory();
  const params = useParams();
  
  useEffect(() => {    
    handleGetStartup();
    handleGetOrder();
  }, []);

  const [isFormLoading, setIsFormLoading] = useState(false);
  const [orderInfo, setOrderInfo] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [startupInfo, setStartupInfo] = useState([]);
  const [isError, setIsError] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [transactionID, setTransactionID] = useState("");
  const [orderID, setOrderID] = useState("");
  
  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center" }}>
          <CircularProgress variant="indeterminate" value={75} />
        </div>
      ) : (
        <Fragment>
          <div className="single-page about-page">
            <Header />
            <div className="home innerpage">
                <div className="home_background_container prlx_parent">
                    <div className="home_background prlx" ></div>
                </div>
                <div className="home_content">
                    <h1>Payment</h1>
                </div>
            </div>
            <div className="page-member m-5">
              <div className="container">
                <div className="main-body">
                  <div className="row gutters-sm">
                    <div className="col-md-4 mb-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex flex-column align-items-center text-center">
                            <img
                              src={profilePicture}
                              alt=""
                              className="rounded-circle"
                              width="150"
                            />
                          </div>
                          <div className="mt-3 align-items-center text-center">
                            <div className="row">
                              <div className="col-sm-3">
                                <div className="mb-0">Person Name</div>
                              </div>
                              <div className="col-sm-9 text-tertiary">
                              {(startupInfo!=undefined)?startupInfo.contactname:''}
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-sm-3">
                                <div className="mb-0">Company</div>
                              </div>
                              <div className="col-sm-9 text-tertiary">
                              {(startupInfo!=undefined)?startupInfo.companyname:''}
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-sm-3">
                                <div className="mb-0">Amount</div>
                              </div>
                              <div className="col-sm-9 text-tertiary">
                              {(orderInfo!=undefined)?currencySymbol() + orderInfo.amount:''}  
                              </div>
                            </div>
                            <hr />
                            <hr />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="card mb-3">
                        <div className="card-body">
                          <div>
                            <div className="text-success h5">
                              Your Payment is successful, Thanks for membership payment
                              "
                              { currencySymbol() + params.amount}
                              ".
                            </div>
                          </div>
                          <div className="mt-4">
                            <table className="table table-striped">
                              <tbody>
                                <tr>
                                  <th scope="row">Order Type</th>
                                  <td>
                                  Membership Payment
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Transaction ID</th>
                                  <td>
                                  {(orderInfo!=undefined && orderInfo.payment!=undefined)?orderInfo.payment.transaction_id:''}
                                    </td>
                                </tr>
                                <tr>
                                  <th scope="row">Payment</th>
                                  <td>
                                  {(orderInfo!=undefined)?currencySymbol() + orderInfo.amount:''}  
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">When</th>
                                  <td>                                    
                                    {(orderInfo!=undefined)?getLocalDateTimeString(orderInfo.updated_at):''}  
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <Link
                            to={"/profile/startup/view/" + params.startupId}
                            className="button button_primary px-3 py-2"
                          >
                            <span>
                            My Account
                            </span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <Footer />
          </div>
        </Fragment>
      )}
    </>
  );


  async function handleGetStartup () {
    let RC = false;
    setStartupInfo({});
    setProfilePicture(undefined);
    setIsFormLoading(false);    
    let startup_id = params.startupId;
    let startupInfo = await getStartupAPI(startup_id);
    if (startupInfo != undefined) {
      console.log(
        "Startup Found Successfully in edit response",
        startupInfo,
      );

      let param = {
        filePath: startupInfo["profileimageValue"],
        fileName: "profileimage",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);
      setStartupInfo(startupInfo);
      setProfilePicture(blob);
      setIsFormLoading(false);

    } else {
      console.log("Startup Not found");
      setIsFormLoading(false);
    }
  };


  async function handleGetOrder() {
    let RC = false;
    setOrderInfo({});
    setIsFormLoading(true);
    let order_id = params.orderId ; 
    let orderInfo = await getOrderAPI(order_id);
    if (orderInfo != undefined) {
      console.log(
        "Order Found Successfully in response",
        JSON.stringify(orderInfo)
      );
      setOrderInfo(orderInfo);
      setIsFormLoading(false);
    } else {
      console.log("Order Not found");
      setIsFormLoading(false);
    }
  }


}

export default Homepage;
