import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReCAPTCHA from "react-google-recaptcha";

// COMPONENTS
import Footer from "../../components/footer";
import Header from "../../components/header";

// APIS
import { sendContactUS } from "../../APIs/ApiUtils";

import PlaceholderIcon from "../../assets/images/placeholder.svg";
import SmartphoneIcon from "../../assets/images/smartphone.svg";
import EnvelopeIcon from "../../assets/images/envelope.svg";

import Faq from "react-faq-component";
import AnchorLink from "react-anchor-link-smooth-scroll";
import JoinUs from "../../components/JoinUs";

import {
	getGeneralSettings
  } from "../../APIs/ApiUtils";

// APIs
import {
	getFAQsAPI
  } from "../../APIs/faqsApis";

const data = {
  rows: [
    {
      title: "What is StartupBasket",
      content:
        "Team StartupBasket comprises of Individuals who learn from ethnic & rural communities & device ideas & strategies which these communities consider the way forward & go by their definitions of development in sync with ancient ethnic Indian learning systems, which the colonialists made every effort to dismantle. These ethnic communities have been living in forests & rural settings since time immemorial & have acquired skills to coexist with other life forms, be it plant life or animal life. Every global environment conference ends with unanimously agreeing to live alongside trees, animals, fresh air & water. However, this isn’t easy for modern urban dwellers surrounded by machines & devices & instant, readymade, polluting & decadent life styles. Hence, living with nature is a life style skill that we need to learn from forest dwelling & farming communities. StartupBasket does not believe much in shifting rural brothers & sisters to urban areas where they end up living in slums & are never able to comprehend nor enjoy the fruits of urban dwellings. Modern education & mass media have confused them into believing that their forest & farm lives are not smart & savvy enough for the progressive modern world hence trying to make them believe in ideas which they never believed in all these years. However, at StartupBasket we ensure that rural communities learn modern technical & business skills being in villages or forest environments & improve their livelihood levels adequately to be able to live healthy & dignified lives within their villages.",
    },
    {
      title: "How do you ensure village enterprise projects are successful ?",
      content:
        "We organise intensive training & hand holding support for new business entrepreneurs. The sessions include long active listening sessions where in, we listen to the apprehensions & reservations of budding rural entrepreneurs. Following which we, clarify business concepts & processes & create tailor-made  work arounds & solutions. We also involve the women & young girls of the household to be a part of the entire venture depending upon their skills, abilities, interests & aptitude. Every rural entrepreneur we support is assigned a successful.",
    },
    {
      title: "Besides donating is there any other way can I associate ?",
      content:
        "Yes. Depending upon your interest & aptitude you can choose projects to work upon remotely or with us on the ground. The epicentre of our operations is Bhopal. Bhopal is directly connected via air from Mumbai & Delhi. Many trains from South to North India run via Bhopal.",
    },
    {
      title: "Can I meet the children & families that I am donating for?",
      content:
        "Yes. We can organise virtual meetings & one on one meetings when you visit us",
    },
    {
      title: "Why have you chosen Madhya Pradesh to start this Project ?",
      content:
        "Madhya Pradesh is a Central Province of India . Strategically, creating partnerships & networks is easier being in the centre & then spreading out to different parts of the country . Besides that MP has the largest tribal population in India  of about 10.3 million , which need much attention & effective  grass roots campaigns to change the present unfortunate circumstances they are made to live in. ",
    },
    {
      title: "Why is enhancing Rural Enterprise your prime focus area?",
      content:
        "We all wish to ensure that children have safe & bright futures & focus on their education & health but most NGOs miss the point that this can be achieved when the entire family can fulfil its basic needs & not just the children living in it, which is possible by making families financially independent.This is where you can pitch in & become Hit Rakshaks ( Patrons ) & personally connect with rural families with entrepreneurial  zeal that are trying to do everything to ensure lives of dignity & respect for themselves.",
    },
    {
      title: "Which Rural enterprises does StartupBasket primarily focus on ?",
      content: (
        <div>
          <p>
            Though we do not follow a hard straight jacket approach, as
            different geographies & communities have different preferences &
            expertises. However, the following are the common ventures in almost
            all communities :
          </p>
          <ul>
            <li>Construction work</li>
          </ul>
          <p>
            These ventures are finalised after long in-depth discussion with
            local communities & successful business leaders from rural & urban
            areas.
          </p>
        </div>
      ),
    },
    {
      title:
        "How are individuals and families selected for rural entrepreneurship projects?",
      content:
        "Although all the below poverty line families need support but we identify family members who have discipline, motivation and zeal to succeed in order to ensure for themselves lives of respect and dignity. ",
    },
  ],
};

const styles = {
  bgColor: "transparent",
  titleTextColor: "#F88253",
  rowTitleColor: "#20247B",
  // titleTextSize: '48px',
  // rowTitleTextSize: 'medium',
  // rowContentColor: 'grey',
  rowContentTextSize: "17px",
  rowContentPaddingTop: "20px",
  rowContentPaddingBottom: "25px",
  rowContentPaddingLeft: "15px",
  // rowContentPaddingRight: '150px',
  // arrowColor: "red",
  //transitionDuration: "1s",
  // timingFunc: "ease"
};

const config = {
  animate: true,
  // arrowIcon: "V",
  tabFocus: true,
};

function ContactUs() {
  const [nameUser, setNameUser] = useState(undefined);
  const [nameUserError, setNameUserError] = useState(false);
  const [emailUser, setEmailUser] = useState(undefined);
  const [emailUserError, setEmailUserError] = useState(false);
  const [phoneUser, setPhoneUser] = useState(undefined);
  const [phoneUserError, setPhoneUserError] = useState(false);  
  const [messageUser, setMessageUser] = useState(undefined);
  const [messageUserError, setMessageUserError] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [reCAPCHADone, setReCAPCHADone] = useState(false);
  const recaptchaRef = React.createRef();
  const [errorMessage, setErrorMessage] = useState("");

  const [generalSettings, setGeneralSettings] = useState(undefined);
	const [isFormLoading, setIsFormLoading] = useState(false);
  const [FAQs, setFAQs] = useState(undefined);
	

	useEffect(() => {
		handleSiteInfo();
    handleGetFAQs();

	}, []);

	async function handleSiteInfo() {
	  let RC = false;
	  setIsFormLoading(true);
	  let generalSettings = await getGeneralSettings();
	  if (generalSettings != undefined ) {
		console.log("General settings found successfully ::", generalSettings);
		setGeneralSettings(generalSettings);
		setIsFormLoading(false);
	  } else {
		console.log("General settings Not found");
		setIsFormLoading(false);
	  }
	}

  async function handleGetFAQs() {
	  let RC = false;
	  setIsFormLoading(true);
	  let FAQs = await getFAQsAPI();
	  if (FAQs != undefined ) {
      console.log("FAQ found successfully ::", FAQs);
      let tmpFAQs = [];
      FAQs.map((row, index) => {
        tmpFAQs.push({title: row.question, content: row.answer});
      });
      let faqDataArr = {rows:tmpFAQs};
      console.log("<FAQ> List of FAQ data ::", faqDataArr);
      setFAQs(faqDataArr);
      setIsFormLoading(false);
	  } else {
      console.log("FAQ Not found");
      setIsFormLoading(false);
	  }
	}

  async function handleSubmit() {
    let RC = false;

    if(reCAPCHADone) {
      console.log("Recapche is successfull");
      if (
          !(nameUser?.length > 0) || 
          !(emailUser?.length> 0)  || 
          !(messageUser?.length > 0) ||
          !(phoneUser?.length > 0)
        )  
      { 
        setNameUserError(true); 
        setErrorMessage("Please complete the form before submit");
        return false
      }
      
  
      setIsSubmitLoading(true);
      let param = {
        email: emailUser,
        name: nameUser,
        message: messageUser,
        phone: phoneUser,
      };
  
      RC = await sendContactUS(param);
      if (RC == true) {
        console.log("Contact us Send Successfully");
        setEmailUser(undefined);
        setNameUser(undefined);
        setPhoneUser(undefined);
        setMessageUser(undefined);
        setIsSubmitLoading(false);
        return true;
      }
    }  
    setIsSubmitLoading(false);
    console.log("ContactUs Failed");
  }

  function onSubmit() {
    const recaptchaValue = recaptchaRef.current.getValue();
    this.props.onSubmit(recaptchaValue);
  }

  return (
    <Fragment>
      <div className="single-page about-page">
        <Header />

          {/* <!-- Home --> */}
          <div className="home innerpage">
            <div className="home_background_container prlx_parent">
              <div className="home_background prlx" ></div>
            </div>
            <div className="home_content">
              <h1>Contact</h1>
            </div>
          </div>

          {/* <!-- Contact --> */}
          <div className="contact">
            <div className="container">
              <div>
                <h5 className="contact-heading">
                {/* {generalSettings?.page_contactus_title} */}
                </h5>
              </div>
              <div className="row">
                <div className="col-lg-8">
                  
                  {/* <!-- Contact Form --> */}
                  <div className="contact_form">
                    <div className="contact_title">Get in touch</div>
                    <AnchorLink href="#faq-section" className="text-tertiary" >
                      <h3><i className="fa fa-quote-left text-primary"></i>{" "}{" "}
                      Click to check our FAQs section for any doubt{" "}{" "}
                      <i className="fa fa-quote-right  text-primary"></i></h3>                      
                    </AnchorLink>                                         
                    <div className="contact_form_container">
                      <form action="post">
                      <input
                            type="text"
                            placeholder="Name"
                            value={nameUser}
                            className="input_field contact_form_name"
                            onChange={(e) => setNameUser(e.target.value)}
                          />
                          <input
                            type="phone"
                            placeholder="Phone"
                            value={phoneUser}
                            className="input_field contact_form_phone"
                            onChange={(e) => setPhoneUser(e.target.value)}
                          />                          
                          <input
                            type="email"
                            placeholder="Email"
                            value={emailUser}
                            className="input_field contact_form_email"
                            onChange={(e) => setEmailUser(e.target.value)}
                          />
                          <textarea
                            rows="15"
                            cols="6"
                            placeholder="Message"
                            className="text_field contact_form_message"
                            onChange={(e) => setMessageUser(e.target.value)}
                          >
                            {messageUser}
                          </textarea>
                          <ReCAPTCHA
                            ref={recaptchaRef}
                            sitekey="6LckcawkAAAAAPexqGu1JN3ehseH0fQmIsYCLVrf"
                            onChange={()=>{setReCAPCHADone(true);}}
                          />
                          {isSubmitLoading ? (
                            <div className={{ alignContents: "center" }}>
                              <CircularProgress variant="indeterminate" value={75} />
                            </div>
                          ) : (
                            <>
                              <h3 className="text-danger my-3">{errorMessage}</h3 >
                              <span>
                                <button onClick={handleSubmit} id="contact_send_btn" type="button" className="contact_send_btn trans_200" value="Submit">send message</button>
                              </span>
                            </>
                          )}

                        {/* <input id="contact_form_name" className="input_field contact_form_name" type="text" placeholder="Name" required="required" data-error="Name is required." />
                        <input id="contact_form_email" className="input_field contact_form_email" type="email" placeholder="E-mail" required="required" data-error="Valid email is required." />
                        <textarea id="contact_form_message" className="text_field contact_form_message" name="message" placeholder="Message" required="required" data-error="Please, write us a message."></textarea>
                        <button id="contact_send_btn" type="button" className="contact_send_btn trans_200" value="Submit">send message</button > */}
                      </form>
                    </div>
                  </div>
                    
                </div>

                <div className="col-lg-4">
                  <div className="about">
                    {/* <div className="about_title">Join StartupBasket</div> */}
                    <p className="about_text">
                    {generalSettings?.admin_aboutusshort}
                    </p>

                    <div className="contact_info">
                      <ul>
                        <li className="contact_info_item">
                          <div className="contact_info_icon">
                            <img src={PlaceholderIcon} alt="https://www.flaticon.com/authors/lucy-g" />
                          </div>
                          { generalSettings?.admin_address} 
                        </li>
                        <li className="contact_info_item">
                          <div className="contact_info_icon">
                            <img src={SmartphoneIcon} alt="https://www.flaticon.com/authors/lucy-g" />
                          </div>
                          { generalSettings?.admin_phone} 
                        </li>
                        <li className="contact_info_item">
                          <div className="contact_info_icon">
                            <img src={EnvelopeIcon} alt="https://www.flaticon.com/authors/lucy-g" />
                          </div>
                          { generalSettings?.admin_email_address} 
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              {/* <!-- Google Map --> */}
              {/* <div className="row">
                <div className="col">
                  <div id="google_map">
                    <div className="map_container">
                      <div id="map"></div>
                    </div>
                  </div>
                </div>
              </div> */}
            </div>
          </div>

          <Fragment >
            <div id="faq-section" className="single-page about-page" >
              <div className="welcome-wrap">
                <div className="container">
                  <div className="row">
                    <div className="col-12 order-2 order-lg-1">
                      <div className="welcome-content">
                        <div className="row">
                          <div className="col">
                            <div className="section_title text-center">
                              <h1>FAQs</h1>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <p>
                    <div>
                      <Faq data={FAQs??''} styles={styles} config={config} />
                    </div>
                  </p>
                </div>
              </div>
              <JoinUs />
            </div>
          </Fragment>

        <Footer />
      </div>
    </Fragment>
  );
}

export default ContactUs;
