import React from "react";

import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";

export default function CustomRadioButton(props) {
  return (
    <FormControl
      error={props.state[props.paramValue + "ErrorStatus"]}
      component="fieldset"

      required={props.required}
    >
      <FormLabel component="legend">
        {props.state[props.paramValue + "Label"]}
      </FormLabel>
      <RadioGroup
        row
        aria-label={props.state[props.paramValue + "ID"]}
        name={props.state[props.paramValue + "ID"]}
        value={(props.state[props.paramValue + "Value"])?.toString()}
        onChange={(e) => {
          props.onChange(e, props.paramValue);
        }}
      >
        {props.ItemValues.map((item) => (
          <FormControlLabel
            value={item.value}
            {...item.checked}
            control={<Radio />}
            label={item.label}
          />
        ))}
      </RadioGroup>
      <FormHelperText>
        {props.state[props.paramValue + "ErrorStatus"]
          ? props.state[props.paramValue + "ErrorMsg"]
          : ""}{" "}
      </FormHelperText>
    </FormControl>
  );
}
