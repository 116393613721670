import React, { Fragment, useState, useEffect } from "react";
import {
  HashRouter,  Route,  Switch,  Redirect,  Link,  useHistory,  useLocation,  withRouter,
} from "react-router-dom";

import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "../../assets/images/logo.png";
import startupMoto from '../../assets/images/startup-moto.png'
import google from "../../assets/images/google.svg";
import Footer from "../../components/footer.js";
import Header from "../../components/header";

// context
import { EmailOutlined } from "@material-ui/icons";

import {
  useUserDispatch,
  loginUser,
  registerUser,
  isEmailExist,
  isPhoneExist,
  forgetPassword,
  verifyOTPs,
  resetPassword,
} from "../../context/userContext";

import {validateEmail, validatePassword, validatePhone, validateString} from "./../../helpers";


function ForgetPassword(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [email, setEmail] = useState("");
  var [phone, setPhone] = useState("");
  var [emailOTP, setEmailOTP] = useState("123456");
  var [phoneOTP, setPhoneOTP] = useState("");
  var [password, setPassword] = useState("");
  var [passwordconfirm, setPasswordConfirm] = useState("");
  
  var [errorEmail, setErrorEmail] = useState("EMPTY");
  var [errorPhone, setErrorPhone] = useState("");
  var [errorEmailOTP, setErrorEmailOTP] = useState("");
  var [errorPhoneOTP, setErrorPhoneOTP] = useState("");

  async function checkEmailExist(email) {
    if(!validateEmail(email))
    {
      setErrorEmail('INVALID');
      return;
    }
    let rc = await isEmailExist(email);
    console.log("<checkEmailExist> :: ", rc);
    if (rc) {
      setErrorEmail("NOTAVAIL");
      console.log("<ERROR> EMAIL exist in database");
    } else {
      setErrorEmail("VALID");
      console.log("<INFO> EMAIL Id available");
    }
  }

  async function checkPhoneExist(phone) {
    let rc = await isPhoneExist(phone);
    console.log("<checkPhoneExist> :: ", rc);
    if (rc == "success") {
      setErrorPhone("yes");
      console.log("<ERROR> Phone exist in database");
    } else {
      setErrorPhone("no");
      console.log("<INFO> Phone Id available");
    }
  }

  function handleForgetPassword() {
    let RC = forgetPassword(
      userDispatch,
      email,
      phone,
      props.history,
      setIsLoading,
      setError,
    );
    if (RC) setActiveTabId(1);
  }

  function handleresetPassword() {
    let RC = resetPassword(
      userDispatch,
      email,
      phone,
      emailOTP,
      phoneOTP,
      password,
      props.history,
      setIsLoading,
      setError,
    );
   
  }

  return (
    <Fragment>
    <div className="single-page about-page bg-white">
      <Header />
    <div className="row login-page">
      <div className="col-6 p-0" >
        <div className="logo-container">
          <Link to="/">
          <img src={startupMoto} alt="logo" className="logotypeImage" />
          <div className="logo-text">StartupBasket</div>
          </Link>
        </div>
      </div>
      <div className="col-6" >
      <div className="form-container">
          <div className="card card-signin ">
            <div className="card-body">
          <React.Fragment>
            <Typography variant="h3" className={classes.greeting}>
              Enter Your Email ID
              {/* Enter Your Email ID OR Phone number to get OTP */}
            </Typography>
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                Something is wrong with your email or phone :(
              </Typography>
            </Fade>
            <TextField
              id="email"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              onBlur={(e) => checkEmailExist(e.target.value)}
              margin="normal"
              placeholder="Email Adress"
              type="email"
              fullWidth
              helperText={
                errorEmail == "NOTAVAIL"
                  ? "Email exist, Please proceed"
                  : errorEmail == "VALID"
                  ? "Email not exist"
                  : errorEmail == "INVALID" ? "Email ID is not valid" : ""
              }
              error={errorEmail == "NOTAVAIL" || errorEmail == "EMPTY" ? 0 : 1}
            />
            {/* <TextField
              id="phone"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              margin="normal"
              placeholder="Phone Adress"
              type="text"
              fullWidth
            /> */}
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={!(email.length > 0 && errorEmail=="NOTAVAIL" )}
                  onClick={handleForgetPassword}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Send OTP
                </Button>
              )}
            </div>
          </React.Fragment>
          {activeTabId === 1 && (
            <React.Fragment>
              <Typography variant="h3" className={classes.greeting}>
              Enter OTP Send on your email id
                {/* Enter OTP Send on your email id or phone */}
              </Typography>
              <Fade in={error}>
                <Typography color="secondary" className={classes.errorMessage}>
                  Something is wrong with your OTP
                </Typography>
              </Fade>
              <TextField
                id="emailOTP"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={emailOTP}
                onChange={(e) => setEmailOTP(e.target.value)}
                margin="normal"
                placeholder="Email OTP"
                type="number"
                fullWidth
              />
              <TextField
                id="password"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                margin="normal"
                placeholder="Password"
                type="password"
                fullWidth
              />
              <TextField
                id="passwordconfirm"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={passwordconfirm}
                onChange={(e) => setPasswordConfirm(e.target.value)}
                margin="normal"
                placeholder="Password Confirm"
                type="password"
                fullWidth
              />                            
              {/* <TextField
                id="phoneOTP"
                InputProps={{
                  classes: {
                    underline: classes.textFieldUnderline,
                    input: classes.textField,
                  },
                }}
                value={phoneOTP}
                onChange={(e) => setPhoneOTP(e.target.value)}
                margin="normal"
                placeholder="Phone OTP"
                type="number"
                fullWidth
              /> */}
              <div className={classes.formButtons}>
                {isLoading ? (
                  <CircularProgress size={26} className={classes.loginLoader} />
                ) : (
                  <Button
                    disabled={!(emailOTP.length == 6 || phoneOTP.length == 6)}
                    onClick={handleresetPassword}
                    variant="contained"
                    color="primary"
                    size="large"
                  >
                    Verify OTP
                  </Button>
                )}
              </div>
            </React.Fragment>
          )}
          </div>
      </div>
      </div>
        </div>
      </div>
      </div>

    </Fragment>
  );
}

export default withRouter(ForgetPassword);
