// IMPORT PACKAGES
import React, { Fragment, useState, useEffect } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
  useHistory,
} from "react-router-dom";

// MEDIA FILES
import MemberImage from "../../../assets/images/Member-1.jpg";

// CUSTOM COMPONENTS
import  CircularStatic from '../../../components/Controls/CircularProgressBar';



// FOR APIS
import {
  deleteMemberAPI,
  getMembersAPI,
  getMembers,
  getMembersPaginationAPI,
  getProfilesAPI
} from "../../../APIs/memberApis";

import {
  getParentLevelServicesAPI
} from "../../../APIs/serviceApis";


import queryString from "query-string";
import { resetPassword } from "../../../context/userContext";

import SingleMember from '../../../components/members/single_member'


var filtersList = [
  { "label": "All", "isChecked": true },
  { "label": "Startup", "isChecked": false },
  { "label": "Agriculture", "isChecked": false },
  { "label": "Aerospace", "isChecked": false },
  { "label": "Fintech", "isChecked": false },
];


function Members(props) {

  const history = useHistory();
  const [profilesTable, setProfilesTable] = useState(undefined);
  const [servicesTable, setServicesTable] = useState(undefined);

  
  const [membersTable, setMembersTable] = useState(undefined);
  const [filters, updateFilters] = useState(filtersList);
  const [isFormLoading, setIsFormLoading] = useState(false);
  // const [displayNoContent, setDisplayNoContent] = useState(true);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);

    handleGetMembers();
    handleGetAllProfiles();
    handleGetAllServices();
  }, []);

  // Filter change handler
  const onProfileFilter = (event, iIsChecked) => {
    event.persist();
    const {
      target: { id, isChecked }
    } = event;
    console.log("Event :: ", event, iIsChecked);
    setProfilesTable((state) =>
      state.map((f) => {
        if (f.name === id) {
          return {
            ...f,
            isChecked: iIsChecked
          };
        }
        return f;
      })
    );
    console.log("State After OnFilter ::", profilesTable);
  };

  // Filter change handler
  const onFilter = (event) => {
    const {
      target: { value, checked }
    } = event;

    updateFilters((state) =>
      state.map((f) => {
        if (f.label === value) {
          return {
            ...f,
            isChecked: checked
          };
        }
        return f;
      })
    );
  };


  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center",  }}>
          <CircularStatic value={25} />
        </div>
      ) : (
        <Fragment>
          <div className="container cls-partner-card">

            <div className="row">
                <div className="col-lg-10">
                    <div className="section-title">
                        <h2>Best Partners for your help</h2>
                        {/* <p>I design and develop services for customers of All sizes, specializing in creating stylish, modern websites</p> */}
                    </div>
                </div>
            </div>

            <div className="d-flex flex-row justify-content-center">
              {profilesTable != undefined && profilesTable.map((row) => {
              return ( 
                <span value={row.isChecked} id={row.name} key={row.name + '_key'}  onClick={event => { onProfileFilter(event, !row.isChecked)}} className={(row.isChecked)?"cls-startup-tag cls-startup-tag-selected":"cls-startup-tag" }>{row.name}</span>
              )})}
            </div>

            <div className="cls-filter-sidebar d-lg-none">
              <input type="checkbox" className="openSidebarMenu toggle-checkbox" id="openSidebarMenu" />
              <label for="openSidebarMenu" className="sidebarIconToggle">
                <div className="cls-toggle-icon d-flex flex-row">
                  <div className="cls-icons">
                    <div className="spinner diagonal part-1"></div>
                    <div className="spinner horizontal"></div>
                    <div className="spinner diagonal part-2"></div>              
                  </div>
                  <div className="mt-3 text-primary">
                    <h3 className="d-inline-block">Filter</h3>
                  </div>
                </div>
              </label>
              <div id="sidebarMenu">
                <div className="h3">Filters</div><hr/>
                <ul className="sidebarMenuInner">
                  <li className="mt-1">
                    <div className="h5 text-primary">Profiles</div>
                    <div className="checkbox checkbox-warning">
                      {/* <span value={row.isChecked} id={row.name} key={row.name + '_key'}  onClick={event => { onProfileFilter(event, !row.isChecked)}} className={(row.isChecked)?"cls-startup-tag cls-startup-tag-selected":"cls-startup-tag" }>{row.name}</span> */}
                      {profilesTable != undefined && profilesTable.map((row) => {
                      return ( 
                        <div className="p-2">
                          <input className="styled mb-2"  id={row.name} key={row.name + '_key'} value={row.isChecked} onClick={event => { onProfileFilter(event, !row.isChecked)}} type="checkbox" />
                          {"  "}<label htmlFor={row.name} className="text-dark">
                            {row.name}
                          </label>
                        </div>
                      )})}
                    </div>
                  </li>
                  <li className="mt-1">
                    <div className="h5  text-primary">Services</div>
                    <div className="checkbox checkbox-warning">
                      {/* <span value={row.isChecked} id={row.name} key={row.name + '_key'}  onClick={event => { onProfileFilter(event, !row.isChecked)}} className={(row.isChecked)?"cls-startup-tag cls-startup-tag-selected":"cls-startup-tag" }>{row.name}</span> */}
                      {servicesTable != undefined && servicesTable.map((row) => {
                      return ( 
                        <div className="p-2">
                          <input className="styled mb-2"  id={row.title} key={row.title + '_key'} value={row.isChecked} onClick={event => { onProfileFilter(event, !row.isChecked)}} type="checkbox" />
                          {"  "}<label for={row.title} className="text-dark">
                            {row.title}
                          </label>
                        </div>
                      )})}
                    </div>
                  </li>            
                </ul>
              </div>
            </div>

            <div className="row ">
              <div className="col-lg-2 d-none d-lg-block mt-4">
                <div className="h3">Filters</div><hr/>
                <div className="mt-3">
                  <div className="h5 text-primary">Profiles</div>
                  <div className="checkbox checkbox-warning">
                    {/* <span value={row.isChecked} id={row.name} key={row.name + '_key'}  onClick={event => { onProfileFilter(event, !row.isChecked)}} className={(row.isChecked)?"cls-startup-tag cls-startup-tag-selected":"cls-startup-tag" }>{row.name}</span> */}
                    {profilesTable != undefined && profilesTable.map((row) => {
                    return ( 
                      <div className="p-2">
                        <input className="styled mb-2"  id={row.name} key={row.name + '_key'} value={row.isChecked} onClick={event => { onProfileFilter(event, !row.isChecked)}} type="checkbox" />
                        {"  "}<label htmlFor={row.name} className="text-dark">
                          {row.name}
                        </label>
                      </div>
                    )})}
                  </div>
                </div>
                <div className="mt-3">
                  <div className="h5  text-primary">Services</div>
                  <div className="checkbox checkbox-warning">
                    {/* <span value={row.isChecked} id={row.name} key={row.name + '_key'}  onClick={event => { onProfileFilter(event, !row.isChecked)}} className={(row.isChecked)?"cls-startup-tag cls-startup-tag-selected":"cls-startup-tag" }>{row.name}</span> */}
                    {servicesTable != undefined && servicesTable.map((row) => {
                    return ( 
                      <div className="p-2">
                        <input className="styled mb-2"  id={row.title} key={row.title + '_key'} value={row.isChecked} onClick={event => { onProfileFilter(event, !row.isChecked)}} type="checkbox" />
                        {"  "}<label for={row.title} className="text-dark">
                          {row.title}
                        </label>
                      </div>
                    )})}
                  </div>
                </div>
              </div>
              <div className="col-lg-10 mt-5">
                <div className="row  justify-content-center mt-4">
                  {membersTable != undefined &&
                   membersTable.map((row) => {  
                    if(getFilterSelected(row.filter))                    
                      return (                          
                        <div className="col-xs-12 col-sm-6 col-md-4">
                          <div className="card">
                              <div className="card-body">
                                  <div className="d-flex align-items-center">
                                      <div><img src={row.profilethumbnail} alt="" className="avatar-md rounded-circle img-thumbnail" /></div>
                                      <div className="flex-1 ms-3">
                                          <h5 className="cls-name mb-1"><a href="#" className="text-dark">{row?.membername}</a></h5>
                                          <span className="badge badge-soft-success mb-0">{(row?.filter)?row?.filter[0]:''}</span>
                                      </div>
                                  </div>
                                  <div className="mt-3 pt-1 ">
                                      <p className="text-excerpt mb-0">
                                      {row.memberexcerpt}
                                      </p>
                                  </div>
                                  <div className="mt-3 pt-1">
                                      <p className="text-muted mb-0"><i className="fa fa-phone font-size-15 align-middle pe-2 text-primary"></i> {row?.memberemail}</p>
                                      <p className="text-muted mb-0 mt-2"><i className="fa fa-envelope font-size-15 align-middle pe-2 text-primary"></i> {row?.memberphone}</p>
                                      <p className="text-muted mb-0 mt-2"><i className="fa fa-graduation-cap font-size-15 align-middle pe-2 text-primary"></i> {row?.address??'b-6, Deen dayal nagar'}</p>
                                  </div>
                                  <div className="d-flex gap-2 pt-4">
                                      <Link to={"/member/" + row.id} className="button button_secondary "><span><i className="fa fa-user-o mx-1"></i> Profile</span></Link>
                                      <Link to={"/member/" + row.id} className="button button_primary "><span><i className="fa fa-calendar-check-o mx-1"></i> Book</span></Link>
                                  </div>
                              </div>
                          </div>
                        </div>             
                    )})}
                    {/* {
                      (displayNoContent == true) ?
                      <div>
                        { "No Content Display" }
                      </div>                    
                      : ''                      
                    }                     */}
                </div>
                <div className="row m-5">
                    <div className="col-12">
                    <div
                        onClick={handleNextBtn}                    
                        className="button button_primary_outline gradient-bg mt-3 px-4 py-3 float-right pull-right"
                    >
                        Next
                    </div>
                    </div>
                </div>
              </div>
            </div>

          </div>
        </Fragment>
      )}
    </>
  );


  function getFilterSelected(iArrFilter){
    // if(iArrFilter == undefined || iArrFilter.length <=0)
    //   return true;
    console.log("getFilterSelected Called");

    let obj = profilesTable?.find(o => { 
                                    if(o.name == 'All' && o.isChecked==true)
                                      console.log("Filter All Selected IN Find Tag");
                                      return true; 
                                    return false;  
                                    } );

    if(obj!=undefined && obj.isChecked!=false) 
    {
      console.log("Filter All Selected", obj);
      // setDisplayNoContent(false);
      return true;
    }
    for(let index=0 ; index<iArrFilter.length; index++){
      let obj = profilesTable?.find(o => { 
        if(o.name == iArrFilter[index] && o.isChecked==true)
        {
          console.log("Filter Selected -->> " , o.name);
          // setDisplayNoContent(false);
          return true;
        }      
      });
      if(obj!=undefined && obj!=false && obj.isChecked==true) {
        console.log("Filter Selected -->> " , obj.name);
        // setDisplayNoContent(false);
        return true;
      }
    }
    console.log("NO Filter Selected");

    return false;

    // iArrFilter.forEach(filterText => {
    //     let obj = profilesTable.find(o => { 
    //       if(o.name == filterText && o.isChecked==true)
    //         return true; 
    //       } 
    //     );
    //     if(obj!=undefined && obj!=false)      
    //       return true;
    // });  

  }


  async function handleGetAllServices() {
    let RC = false;
    setIsFormLoading(true);
    let {status, response} = await getParentLevelServicesAPI();
    if (status == "S_OK" ) {
      let tmp = {'id':0,'detail':"All",'isChecked':true,'title':"All"};        
      response.unshift(tmp); 
      console.log("Services Found Successfully ::", response.length);
      setServicesTable(response);
      setIsFormLoading(false);
    } else {
      console.log("Services Not found");
      setIsFormLoading(false);
    }
  }

  async function handleGetAllProfiles() {
    let RC = false;
    setIsFormLoading(true);
    let Profiles = await getProfilesAPI();
    if (Profiles != undefined && Profiles.length > 0) {
      let tmp = {'id':0,'detail':"All",'isChecked':true,'name':"All"};        
      Profiles.unshift(tmp); 
      console.log("Profiles Found Successfully ::", Profiles.length);
      setProfilesTable(Profiles);
      setIsFormLoading(false);
      console.log("Profiles Table Data ", Profiles);
    } else {
      console.log("Profiles Not found");
      setIsFormLoading(false);
    }
  }

  async function handleGetMembers() {
    let RC = false;
    setIsFormLoading(true);

    let queries = queryString.parse(history.location.search);
    let page = queries.page != undefined ? queries.page : 1;
    let limit = queries.limit != undefined ? queries.limit : 9;

    let members = await getMembersPaginationAPI(history,limit, page);
    if (members != undefined && members.length > 0) {
      console.log("Members Found Successfully ::", members.length);
      setMembersTable(members);
      setIsFormLoading(false);
      setPage(page);
      setLimit(limit);
    } else {
      console.log("Member Not found");
      setIsFormLoading(false);
    }
  }

  async function handleForwardBtn(id, location) {
    let isLoggedIn = localStorage.getItem("id_token");

    let searchString = "Member_id=" + id;
    let redirectString = searchString + "&location=" + location;

    console.log("<pathname> :: ", location);
    console.log("<search> :: ", searchString);

    if (isLoggedIn) {
      localStorage.setItem('CURRENT_Member',id);
      history.push(location);
      // history.push({
      //   pathname: location,
      //   search: "?" + searchString,
      //   state: { Member_id: id }
      // });
    } else
      history.push({
        pathname: "login",
        search: redirectString,
      });
  }

  function handleNextBtn() {
    let queries = queryString.parse(history.location.search);
    let page = (queries.page!=undefined)?queries.page:1;
    let limit = (queries.limit!=undefined)?queries.limit:9;
    page = +page + 1;
    let searchString = "?limit=" + limit + "&page=" + page;    
    console.log("<search> :: ", searchString);
    console.log("<search> :: ", window.location.href);
    

    window.location.href = "http://localhost:3000/#/Members?limit="+limit+"&page="+page
    window.location.reload();
    // + searchString;


    // history.replace({
    //   pathname: "/Members",
    //   search: searchString,
    // });
    // <Redirect to={searchString} />;
  }
}

export default Members;
