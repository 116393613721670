import React from "react";

import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";

export default function CustomTextField(props) {
  return (
    <FormControl
      fullWidth
      required={props.required}
      error={props.state[props.paramValue + "ErrorStatus"]}
    >
      <InputLabel id={props.state[props.paramValue + "ID"]}>
        {props.state[props.paramValue + "Label"]}
      </InputLabel>
      <Select
        labelId={props.state[props.paramValue + "Label"]}
        id={props.state[props.paramValue + "ID"]}
        value={props.state[props.paramValue + "Value"]}
        onChange={(e) => {
          props.onChange(e, props.paramValue);
        }}
      >
        {props.ItemValues.map((item) => (
          <MenuItem value={item.value} selected={item.selected}>
            {item.label}
          </MenuItem>
        ))}
      </Select>
      <FormHelperText>
        {props.state[props.paramValue + "ErrorStatus"]
          ? props.state[props.paramValue + "ErrorMsg"]
          : ""}{" "}
      </FormHelperText>
    </FormControl>
  );
}
