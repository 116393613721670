import React from 'react';

function CustomPaginationBar(props) {
  let prevPage = props.page > 1 ? props.page - 1 : props.page;
  let nextPage = props.page < props.totalPages - 2 ? props.page + 1 : props.page;

  const styles = {
    pointerEvents: 'none',
  };
  return (
    <>
      {props.getBlogs !== undefined ? (
        <>
          {props.id === 0 ? (
            <li style={props.page === 1 ? styles : {}} onClick={() => props.getBlogs(props.category,prevPage)}>
              <a className="prev">&laquo; Prev</a>
            </li>
          ) : props.id === props.totalPages - 1 ? (
            <li style={props.page === nextPage ? styles : {}} onClick={() => props.getBlogs(props.category,nextPage)}>
              <a className="next">Next &raquo;</a>
            </li>
          ) : (
            <li onClick={() => props.getBlogs(props.category,props.item.label)}>
              <a className={props.item.active ? "active" : ""}>{props.item.label}</a>
            </li>
          )}
        </>
      ) :props.getServiceMembers !== undefined ?(
        <>
          {props.id === 0 ? (
            <li style={props.page === 1 ? styles : {}} onClick={() => props.getServiceMembers(props.serviceId,prevPage)}>
              <a className="prev">&laquo; Prev</a>
            </li>
          ) : props.id === props.totalPages - 1 ? (
            <li style={props.page === nextPage ? styles : {}} onClick={() => props.getServiceMembers(props.serviceId,nextPage)}>
              <a className="next">Next &raquo;</a>
            </li>
          ) : (
            <li onClick={() => props.getServiceMembers(props.serviceId,props.item.label)}>
              <a className={props.item.active ? "active" : ""}>{props.item.label}</a>
            </li>
          )}
        </>
      ): (
        <>
          {props.id === 0 ? (
            <li style={props.page === 1 ? styles : {}} onClick={() => props.getStartUps(prevPage)}>
              <a className="prev">&laquo; Prev</a>
            </li>
          ) : props.id === props.totalPages - 1 ? (
            <li style={props.page === nextPage ? styles : {}} onClick={() => props.getStartUps(nextPage)}>
              <a className="next">Next &raquo;</a>
            </li>
          ) : (
            <li onClick={() => props.getStartUps(props.item.label)}>
              <a className={props.item.active ? "active" : ""}>{props.item.label}</a>
            </li>
          )}
        </>
      )}
    </>
  );  
}

export default CustomPaginationBar;
