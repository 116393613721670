import React, { useEffect, useState, useRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";
import ReactCrop, { makeAspectCrop, centerCrop } from "react-image-crop";

import "react-image-crop/dist/ReactCrop.css";

// MATERIAL ICONS
import DownloadIcon from "@material-ui/icons/CloudDownloadRounded";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import { downloadFileAPI } from "./../../APIs/ApiUtils";
import userimage from "./../../assets/images/userimage.png";
import "../../assets/style.css";
import pdf from "../../assets/images/icons/svgtopng/pdf.png";
import docx from "../../assets/images/icons/svgtopng/docx.png";
import ppt from "../../assets/images/icons/svgtopng/ppt.png";

export default function CustomUploadButton(props) {
  const [isDownloading, setDownloading] = useState(false);
  const [crop, setCrop] = useState(null);
  const [isCropFixed, setCropFixed] = useState(false);
  const [croppedImage, setCroppedImage] = useState(null);
  const aspectRatio = 300 / 200; // Aspect ratio of 300px width and 200px height
  const imageRef = useRef(null);
  const ext = props.currExt !== undefined ? props.currExt : props.ext;

  useEffect(() => {
    setCropFixed(false);
    setCroppedImage(null);
  }, [props.img]);

  function onImageLoad(e) {
    const { naturalWidth: width, naturalHeight: height } = e.currentTarget;

    const crop = centerCrop(
      makeAspectCrop(
        {
          // You don't need to pass a complete crop into
          // makeAspectCrop or centerCrop.
          unit: "%",
          width: 90,
        },
        16 / 9,
        width,
        height
      ),
      width,
      height
    );

    setCrop(crop);
  }

  async function handleFileDownload() {
    setDownloading(true);
    let param = {
      filePath: props.state[props.paramValue + "Value"],
      fileName: props.paramValue,
    };
    console.log("<INFO> Following Param for Download File API", param);
    await downloadFileAPI(param);

    setDownloading(false);
  }

  const handleCropFix = () => {
    setCropFixed(true);
  };

  const getCroppedImage = (image, crop) => {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const width = crop.width * scaleX;
    const height = crop.height * scaleY;
    const x = crop.x * scaleX;
    const y = crop.y * scaleY;

    canvas.width = width;
    canvas.height = height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(image, x, y, width, height, 0, 0, width, height);

    return canvas.toDataURL("image/jpeg");
  };

  const handleCropSubmit = async () => {
    if (imageRef.current && crop) {
      const croppedImageUrl = await getCroppedImage(imageRef.current, crop);
      setCroppedImage(croppedImageUrl);
      console.log("Crop image url", croppedImageUrl);
      convertBlobUrlToImageFile(croppedImageUrl, "Cropped-Logo.jpeg")
        .then((file) => {
          console.log("file from response:: ", file);
          const files = [file];
          props.handleFileSave(props.paramValue, files);
        })
        .catch((e) => {
          console.log("error in converting blob to image :: ", e);
        });
    }
  };

  const convertBlobUrlToImageFile = async (blobUrl, fileName) => {
    const response = await fetch(blobUrl);
    const blob = await response.blob();
    const file = new File([blob], fileName, { type: blob.type });
    console.log("Cropped logo file:: ", file);
    // Now you have the image file and can perform further operations if needed

    return file;
  };

  return (
    <FormControl
      fullwidth
      required={props.required}
      error={props.state[props.paramValue + "ErrorStatus"]}
      className="custom-uplado-btn"
    >
      <Grid
        style={{ marginTop: "10px" }}
        className='custom-upload-header'
      >
        <Typography variant="h6">{props.tittle}</Typography>
        {props.ext === "jpeg" ||
        props.ext === "jpg" ||
        props.ext === "png" ||
        props.ext === "PNG" ||
        props.ext === "bmp" ? (
          <Grid className="react-crop"  >
                <ReactCrop
                  crop={crop}
                  onChange={(c) => setCrop(c)}
                  disabled={isCropFixed}
                  aspect={aspectRatio}
                  imageRef={imageRef}
                  onImageLoaded={onImageLoad} // Add this line
                >
                  <img
                    src={props.img === "" ? userimage : props.img}
                    ref={imageRef}
                    onLoad={onImageLoad} // Add this line
                    className="custom-upload-image"
                  />
                </ReactCrop>
                {!isCropFixed && (
                  <button className="btn btn-outline-secondary" onClick={() => {handleCropFix();handleCropSubmit()}}>Crop</button>
                )}
          </Grid>
        ) : (
          <img
            src={ext === "pdf" ? pdf : ext === "docx" ? docx : ext === "pptx" ? ppt : ""}
            className="custom-upload-doc"
          />
        )}
      </Grid>
      <Grid
        style={{ marginTop: "10px" }}
        className={props.size !== "" && props.size !== undefined ? "imgbar" : ""}
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
      >
        {props.size !== "" && props.size !== undefined ? (
          <Grid direction="row" justifyContent="center" alignItems="center">
            <span>Size:- {props?.size?.toFixed(2)}KB</span>{"    "}
            <span>
              Date:- {props?.date?.getDate()}/{props?.date?.getMonth() + 1}/{props?.date?.getFullYear()}
            </span>
          </Grid>
        ) : (
          ""
        )}
      </Grid>
      <Grid
        container
        direction="row"
        justifyContent="center"
        alignItems="center"
        style={{ marginTop: "10px" }}
      >
        <Button
          onClick={() => props.handleFileOpen(props.paramValue)}
          startIcon={
            <UploadIcon
              fontSize="large"
              color={
                props.state[props.paramValue + "Value"]
                  ? "primary"
                  : "secondary"
              }
            />
          }
        >
          Upload
        </Button>
        {props.showDownload != undefined &&
          props.state[props.paramValue + "Value"] && (
            <div>
              {isDownloading ? (
                <CircularProgress size={26} />
              ) : (
                <Button
                  onClick={handleFileDownload}
                  startIcon={
                    <DownloadIcon
                      fontSize="large"
                      color={
                        props.state[props.paramValue + "Value"]
                          ? "primary"
                          : "secondary"
                      }
                    />
                  }
                >
                  Download
                </Button>
              )}
            </div>
          )}
      </Grid>

      <FormHelperText>
        {props.state[props.paramValue + "ErrorStatus"]
          ? props.state[props.paramValue + "ErrorMsg"]
          : ""}
      </FormHelperText>
      <DropzoneDialog
        open={props.state[props.paramValue + "MediaOpen"]}
        onSave={(files) => props.handleFileSave(props.paramValue, files)}
        filesLimit={props.maxFileNo != undefined ? props.maxFileNo : 1}
        acceptedFiles={props.acceptedFiles}
        showPreviews={true}
        maxFileSize={props.maxFileLimit != undefined ? props.maxFileLimit : "104837600"}
        onClose={() => props.handleFileClose(props.paramValue)}
      />
    </FormControl>
  );
}
