import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
// import Moment from 'react-moment';
import moment from "moment";

import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";

// Picker
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import { makeStyles } from "@material-ui/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveIcon from "@material-ui/icons/Remove";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import axios from "axios";
import API, { API_Param } from "../../API";
import {
  getStartupsAPI,
  createStartupAPI,
  getStartupAPI,
  createStartupCoFounderAPI,
  getCoFounderAPI,
  editStartupCoFounderAPI,
} from "../../APIs/startupApis";

import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
} from "../../APIs/ApiUtils";

import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";

// COMPONENTS
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { Trans } from "react-i18next";
import i18n from "../../locales/locales_config";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

const requiredParam = [
  "name",
  "designation",
  "partnershippercentage",
  "aboutcofounder",
  "profileimage",
  "otherdocument",
];

class EditCoFounder extends Component {
  constructor() {
    super();
    this.state = {
      volunteersTable: [],
      isSubmitLoading: false,
      isError: false,

      designationTable: [
        { value: "", label: "Select Designation", selected: "selected" },
        { value: "COFOUNDER", label: "Co-Founder" },
        { value: "CEO", label: "CEO" },
        { value: "CTO", label: "CTO" },
      ],
    };
  }

  componentDidMount() {
    this.setStateVariables("name", {
      checkEmpty: true,
      // minLength: 5,
      maxLength: 20,
    });

    this.setStateVariables(
      "designation",
      {
        checkEmpty: true,
      },
      "SelectField",
    );

    this.setStateVariables(
      "partnershippercentage",
      { checkEmpty: false },
      "NumberField",
    );
    this.setStateVariables("aboutcofounder", { checkEmpty: false });

    this.setStateVariables("profileimage", { checkEmpty: false }, "FileField");
    this.setStateVariables("otherdocument", { checkEmpty: false }, "FileField");

    this.handleGetStartup();
    this.handleGetCoFounder();
  }

  setStateVariables = (
    param,
    validation = { checkEmpty: false },
    type = "TextField",
    value = "",
    error = false,
  ) => {
    let paramLabel = param + "Label";
    let paramID = param + "ID";
    let paramPlaceHolder = param + "PlaceHolder";
    let paramValue = param + "Value";
    let paramErrorStatus = param + "ErrorStatus";
    let paramErrorMsg = param + "ErrorMsg";
    let paramFieldType = param + "FieldType";
    let paramFieldValidation = param + "FieldValidation";
    let paramFile = param + "File";

    let transLabel = "CommonField." + param + "Label";
    let transPlaceHolder = "CommonField." + param + "PlaceHolder";
    let transErrorMsg = "CommonField." + param + "ErrorMsg]";

    this.setState({
      [paramLabel]: <Trans i18nKey={transLabel}></Trans>,
      [paramID]: param,
      [paramPlaceHolder]: <Trans i18nKey={transPlaceHolder}></Trans>,
      [paramValue]: value,
      [paramErrorStatus]: error,
      [paramErrorMsg]: <Trans i18nKey={transErrorMsg}></Trans>,
      [paramFieldType]: type,
      [paramFieldValidation]: validation,
    });
    if (type == "FileField")
      this.setState({
        [paramFile]: "",
      });
  };

  render() {
    const { t } = this.props;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: 20,
      }),
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 200,
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      },
    };

    return (
      <>
        {this.state.loader ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={4}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>EditCoFounder.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/profile/startup/edit/" + this.props.match.params.itemId);
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid item xs={12} className={{ justifyContent: "center" }}>
                <Paper style={{ padding: 16 }}>
                  <h3 className="">
                  Personal Info
                  </h3>
                  <Divider style={{ margin: "5px 0 10px 0" }} />
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="name"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomSelectField
                      required="required"
                        paramValue="designation"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={this.state.designationTable}
                      />
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="partnershippercentage"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="aboutcofounder"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>


                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <h3 className="mt-4">
                        Documents
                      </h3>
                      <Divider />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <div>
                        Profile Image
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      {
                        <CustomUploadButton
                          paramValue="profileimage"
                          state={this.state}
                          showDownload={true}
                          acceptedFiles={[
                            "image/jpeg",
                            "image/png",
                            "image/bmp",
                            "application/pdf",
                          ]}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <div >
                        Other Document
                      </div>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={4}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <CustomUploadButton
                        paramValue="otherdocument"
                        state={this.state}
                        showDownload={true}
                        acceptedFiles={[
                          "image/jpeg",
                          "image/png",
                          "image/bmp",
                          "application/pdf",
                        ]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid container alignItems="flex-end" spacing={2}>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      <div>
                        {this.state.isSubmitLoading ? (
                          <CircularProgress size={26} />
                        ) : (
                          <Button
                            onClick={this.handleSubmit}
                            // disabled={
                            //   loginValue.length === 0 ||
                            // }
                            variant="contained"
                            color="primary"
                            type="submit"
                            style={{
                              justifySelf: "center",
                              alignSelf: "center",
                            }}
                          >
                            Update Co-Founder
                          </Button>
                        )}
                      </div>
                    </Grid>
                    <Grid item style={{ marginTop: 25 }} xs={6}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  handleGetStartup = async () => {
    let RC = false;
    this.setState({ loader: true, cofounderInfo: [] });
    let startup_id = this.props.match.params.itemId;

    let cofounderInfo = await getStartupAPI(startup_id);
    if (cofounderInfo != undefined) {
      console.log("Startup Found Successfully");
      this.setState({
        cofounderInfo: cofounderInfo,
        loader: false,
      });
    } else {
      console.log("Startup Not found");
      this.setState({ loader: false });
    }
  };

  handleGetCoFounder = async () => {
    let RC = false;
    this.setState({ loader: true, cofounderInfo: [] });
    let cofounder_id = this.props.match.params.cofounderId;

    let cofounderInfo = await getCoFounderAPI(cofounder_id);
    if (cofounderInfo != undefined) {
      let tmp = {};
      Object.entries(cofounderInfo).map(([key, value]) => {
        if (requiredParam.includes(key)) tmp[key + "Value"] = value;
      });
      console.log("Startup Found Successfully", tmp);

      this.setState({
        ...tmp,
        loader: false,
      });
    } else {
      console.log("Startup Not found");
      this.setState({ loader: false });
    }
  };


  handleSubmit = () => {
    let param = {};

    // GET THE VALUES ONLY
    Object.entries(this.state).map(([key, value]) => {
      if (key.includes("Value") && value != "")
        param[key.replace("Value", "")] = value;
    });

    console.log("<INFO> Param Value ", param);
    let errors = ValidateFields(this);
    console.log("Error exist of Not ", errors);

    if (errors) {
      return false;
    }
    this.setState({ isSubmitLoading: true });

    this.handleEditCoFounder(param);
  };

  handleParam = (e, paramKey, directValue = false) => {
    let value = e;
    if (!directValue)
      if (e.target != null && e.target.value != undefined)
        value = e.target.value;

    console.log("Inside Handle Param ", value, paramKey);

    let paramKeyError = paramKey + "ErrorStatus";

    this.setState((prevState, props) => ({
      ...prevState,
      [paramKey + "Value"]: value,
      [paramKeyError]: false,
    }));

    let isExist = isErrorExist(this.state);
    if (this.state.isError && !isExist) this.setState({ isError: false });
  };

  handleFileClose = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: false,
    });
  };

  handleFileSave = (param, files) => {
    console.log("Current File List :: ", param, files[0]);
    this.setState({
      ...this.state,
      [param + "Value"]: files[0],
      [param + "MediaOpen"]: false,
    });
  };

  handleFileOpen = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: true,
    });
  };

  // handleEditStartupFamily = async (param) => {
  //   console.log("createStartup API Started");
  //   let rc = await editStartupCoFounderAPI(this.props.history, param);
  //   if (rc == true) {
  //     console.log("Co-Founder Updated Successfully");
  //     this.setState({ isSubmitLoading: false });
  //     this.props.history.push("/profile/startup/edit/:startupId");
  //   } else {
  //     this.setState({ isSubmitLoading: false });
  //     console.log("Startup Not Added");
  //   }
  //   console.log("createStartup API ENDED");
  // };

  handleEditCoFounder = async (param) => {
    console.log("createStartup API Started");
    let cofounder_id = this.props.match.params.cofounderId;
    console.log("<MEMBER ID> :: ", cofounder_id);

    let rc = await editStartupCoFounderAPI(cofounder_id, param);
    if (rc == true) {
      console.log("Startup Co-Founder Added Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/profile/startup/edit/" + this.props.match.params.itemId);
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Startup Co-Founder  Not Added");
    }
    console.log("createStartupCoFounder API ENDED");
  };

}

export default EditCoFounder;
