import React, { Fragment } from "react";
import { HashRouter, Route, Switch, Redirect, Link } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";

import MemberImage from "../../assets/images/Member-1.jpg";

import Footer from "../../components/footer";
import Header from "../../components/header";

function Homepage(props) {
  return (
    <Fragment>
      <div className="">
        <div className="container">
          <div className="row">
            <div className="col-6">
              <div className="cause-wrap individual">
                <figure className="m-0">
                  <img src={MemberImage} alt="" />
                </figure>

                <div className="cause-content-wrap">
                  <header className="entry-header d-flex flex-wrap align-items-center">
                    <h3 className="entry-title w-100 m-0">
                      <a href="#">Ram Narayan Singh</a>
                    </h3>
                  </header>

                  <div className="entry-content">
                    <p className="m-0">
                      <div>
                        <b>Hand Skill:</b> Farming
                      </div>
                    </p>
                    <p className="m-0">
                      <div>
                        <b>Proposal:</b> Goat Farming
                      </div>
                    </p>
                  </div>

                  <div className="fund-raised w-100">
                    <div className="fund-raised-bar-4 barfiller">
                      {/* <div className="tipWrap">
                            <span className="tip"></span>
                          </div> */}

                      {/* <span className="fill" data-percentage="83"></span> */}
                      <LinearProgress variant="determinate" value={20} />
                    </div>

                    <div className="fund-raised-details d-flex flex-wrap justify-content-between align-items-center">
                      <div className="fund-raised-total mt-2">
                        Raised: ₹10,000
                      </div>

                      <div className="fund-raised-goal mt-2">Goal: ₹70,000</div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <Link
                        to="Member"
                        className="btn gradient-bg mt-2 px-3 py-2"
                      >
                        View Profile
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="/profile"
                        className="btn gradient-bg mt-2 px-3 py-2"
                      >
                        Donate Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="cause-wrap individual">
                <figure className="m-0">
                  <img src={MemberImage} alt="" />
                </figure>

                <div className="cause-content-wrap">
                  <header className="entry-header d-flex flex-wrap align-items-center">
                    <h3 className="entry-title w-100 m-0">
                      <a href="#">Ram Narayan Singh</a>
                    </h3>
                  </header>

                  <div className="entry-content">
                    <p className="m-0">
                      <div>
                        <b>Hand Skill:</b> Farming
                      </div>
                    </p>
                    <p className="m-0">
                      <div>
                        <b>Proposal:</b> Goat Farming
                      </div>
                    </p>
                  </div>

                  <div className="fund-raised w-100">
                    <div className="fund-raised-bar-4 barfiller">
                      {/* <div className="tipWrap">
                            <span className="tip"></span>
                          </div> */}

                      {/* <span className="fill" data-percentage="83"></span> */}
                      <LinearProgress variant="determinate" value={20} />
                    </div>

                    <div className="fund-raised-details d-flex flex-wrap justify-content-between align-items-center">
                      <div className="fund-raised-total mt-2">
                        Raised: ₹10,000
                      </div>

                      <div className="fund-raised-goal mt-2">Goal: ₹70,000</div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-6">
                      <Link
                        to="Member"
                        className="btn gradient-bg mt-2 px-3 py-2"
                      >
                        View Profile
                      </Link>
                    </div>
                    <div className="col-6">
                      <Link
                        to="/profile"
                        className="btn gradient-bg mt-2 px-3 py-2"
                      >
                        Donate Now
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>            
          </div>
        </div>
      </div>
    </Fragment>
  );

  function handleBecomeVolunteer() {
    props.history.push("/addvolunteer");
  }
}

export default Homepage;
