import React, { Fragment, useState, useEffect } from "react";
import { HashRouter, Route, Switch, Redirect, Link, useParams, useHistory, } from "react-router-dom";

import Image1 from "../../assets/images/latest_1.jpg";
import latest_1 from "../../assets/images/latest_1.jpg";


import Footer from "../../components/footer";
import Header from "../../components/header";
import PageSideBar from "../../components/PageSidebar.js";

// CUSTOM COMPONENTS
import CircularStatic from '../../components/Controls/CircularProgressBar';
import CustomPaginationBar from "../../components/Controls/CustomPaginationBar";

import {
	deleteBlogAPI,
	getBlogsAPI,
	getBlogs,
	getBlogsPaginationAPI
} from "../../APIs/blogApis";


import {
	getBlogCategoriesAPI
} from "../../APIs/blogcategoryApis";

import {
	trimString,
	getGeneralSettings

} from "../../APIs/ApiUtils";
// APIs


import queryString from "query-string";

import { API_Param } from "../../API";

function Homepage() {
	window.scrollTo(0, 0);
	const history = useHistory();
	const [blogCategoriesTable, setBlogCategoriesTable] = useState(undefined);
	const [blogsTable, setBlogsTable] = useState(undefined);
	const [isFormLoading, setIsFormLoading] = useState(false);
	const [isSubmitLoading, setIsSubmitLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [curPage, setCurPage] = useState(undefined);
	const [curCategory, setCurCategory] = useState(undefined);
	const [generalSettings, setGeneralSettings] = useState(undefined);
	const [pagination, setPagination] = useState(undefined);

	useEffect(() => {
		handleGetBlogs();
		handleGetBlogCategories();
		handleSiteInfo();

	}, []);


	return (
		<>
			{isFormLoading ? (
				<div className={{ alignContents: "center", }}>
					<CircularStatic value={25} />
				</div>
			) : (
				<Fragment>
					<div className="single-page about-page">
						<Header />
						{/* <!-- Home --> */}
						<div className="home innerpage">
							<div className="home_background_container prlx_parent">
								<div className="home_background prlx" ></div>
							</div>
							<div className="home_content">
								<h1>Blogs</h1>
							</div>
						</div>

						<div className="news">
							<div className="container">
								<div className="row">

									{/* <!-- News Column --> */}
									<div className="col-lg-8">
										<div className="news_posts">
											{
												blogsTable?.map((row) => {
													{
														return (
															<div className="news_post">
																<div className="news_post_image">
																	<img src={row.profilethumbnail ?? latest_1} alt="https://unsplash.com/@dsmacinnes" />
																</div>
																<div className="news_post_top d-flex flex-column flex-sm-row">
																	<div className="news_post_date_container">
																		<div className="news_post_date d-flex flex-column align-items-center justify-content-center mb-3">
																			<div>{row.day}</div>
																			<div>{row.month}</div>
																		</div>
																	</div>
																	<div className="news_post_title_container">
																		<div className="news_post_title">
																			<Link to={"/singleblog/" + row.id} >
																				{row.title}
																			</Link>
																		</div>
																		<div className="news_post_meta">
																			<span className="news_post_author"><a href="#">By Admin</a></span>
																			{/* <span>|</span> */}
																			{/* <span className="news_post_comments"><a href="#">3 Comments</a></span> */}
																		</div>
																	</div>
																</div>
																{/* <p>{trimString(row.detail, 250)}</p> */}
																{/* <div className="news_post_text">
																	<p dangerouslySetInnerHTML={{__html: row.detail}} ></p>																	
																</div> */}
																<div className="news_post_button text-center trans_200">
																	<Link to={"/singleblog/" + row.id}>Read More</Link>
																</div>
															</div>
														)
													}
												})
											}
											{
												(blogsTable==undefined || blogsTable.length==0) &&
												<h2>No Blog Found For Current Search</h2>
											}
										</div>
										<div className="paginationBar">
											<ul className="pagination modal-4">
											{pagination != undefined && pagination.map((item, index) => {
												return (<CustomPaginationBar category={curCategory} totalPages={pagination.length} item={item} id={index} key={index} getBlogs={handleGetBlogs} pagination={pagination} page={curPage} />)
											})}
											</ul>
										</div>
										{/* <!-- Page Nav --> */}

										{/* <div className="news_page_nav">
											<ul>
												<li className="active text-center trans_200"><Link onClick={() => { handleNextBtn() }}>Next</Link></li>
												<li className="text-center trans_200"><Link onClick={()=>{ handleUpdatePage() }}>02</Link></li>
												<li className="text-center trans_200"><Link onClick={()=>{ handleUpdatePage() }}>03</Link></li>
											</ul>
										</div> */}
									</div>

									{/* <!-- Sidebar Column --> */}
									<div className="col-lg-4">
										<div className="sidebar">
											{/* <!-- Tags --> */}
											<div className="sidebar_section">
												<div className="sidebar_section_title mt-4">
													<h3>Categories</h3>
												</div>
												<div className="tags d-flex flex-row flex-wrap">
													{
														blogCategoriesTable != undefined &&
														blogCategoriesTable.map((row) => {
															{
																return (
																	<div className="tag" key={row.id}>
																		<Link onClick={() => { handleGetBlogs(row.id);setCurCategory(row.id) }} >{row.title}</Link>
																	</div>
																)
															}
														})}
												</div>
											</div>
											<PageSideBar />
										</div>
									</div>
								</div>
							</div>
						</div>

						<Footer />
					</div>
				</Fragment>
			)}
		</>
	);

	async function handleSiteInfo() {
		let RC = false;
		setIsFormLoading(true);
		let generalSettings = await getGeneralSettings();
		if (generalSettings != undefined) {
			console.log("General settings found successfully ::", generalSettings);
			setGeneralSettings(generalSettings);
			setIsFormLoading(false);
		} else {
			console.log("General settings Not found");
			setIsFormLoading(false);
		}
	}

	async function handleGetBlogs(category = "",curPage=undefined) {
		let RC = false;
		setIsFormLoading(true);
		let page = curPage != undefined ? curPage : 1;
		let limit = 3;
		category = category != undefined && category != "" ? category : "";

		window.location.href = "#/news/?category=" + category;


		let blogs = await getBlogsPaginationAPI(category, limit, page);
		if (blogs != undefined && blogs.data.length > 0) {
			console.log("Blogs Found Successfully ::", blogs);
			setBlogsTable(blogs.data);
			setPagination(blogs.links)
			setIsFormLoading(false);
			setCurPage(page);
		} else {
			console.log("Blog Not found");
			setIsFormLoading(false);
		}
	}

	async function handleGetBlogCategories() {
		let RC = false;
		setIsFormLoading(true);
		let categories = await getBlogCategoriesAPI();
		if (categories != undefined && categories.length > 0) {
			console.log("categories Found Successfully ::", categories.length);
			setBlogCategoriesTable(categories);
			setIsFormLoading(false);
		} else {
			console.log("categories Not found");
			setIsFormLoading(false);
		}
	}

	function handleUpdatePage() {
		let queries = queryString.parse(history.location.search);
		console.log("Query String :: ", queries);
		let page = queries.page != undefined ? queries.page : 1;
		let limit = queries.limit != undefined ? queries.limit : 9;
		let category = queries.category != undefined ? queries.category : "";

		// history.push("/news/?category="+category+"&page="+page+"&limit="+limit);
		window.location.href = API_Param.BASE_URL + "news?category=" + category + "&limit=" + limit + "&page=" + page
		window.location.reload();
	}

	function handleNextBtn() {
		let queries = queryString.parse(history.location.search);
		let page = (queries.page != undefined) ? queries.page : 1;
		let limit = (queries.limit != undefined) ? queries.limit : 9;
		let category = queries.category != undefined ? queries.category : "";

		page = +page + 1;
		let searchString = "?limit=" + limit + "&page=" + page;
		console.log("<search> :: ", searchString);
		console.log("<search> :: ", window.location.href);

		window.location.href = API_Param.BASE_URL + "news?category=" + category + "&limit=" + limit + "&page=" + page
		window.location.reload();
	}

}

export default Homepage;


