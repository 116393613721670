import React, { Fragment, useState, useEffect } from "react";

//COMPONENTS
import Header from "../../components/header";
import Footer from "../../components/footer.js";
import Slider from "../../components/swiper.js";
import SuccessStories from "./components/success_stories";
import WhyUs from "./components/whyus";
import Testimonials  from "./components/testimonials";
import HeroBoxes  from "./components/heroboxes";
import Services from "./components/services";
import Loader  from "./components/loader";
import ContactUs  from "./components/contact_us";
import FeaturedMentors from "./components/featured_mentors";
import MileStones  from "./components/milestones";
import VideoModal from "./components/VideoModal";
// APIs
import {
	getGeneralSettings
  } from "../../APIs/ApiUtils";

// import Testimonials from "../components/Homepage/Testimonials";
// CUSTOM COMPONENTS
import CircularStatic from '../../components/Controls/CircularProgressBar';

// MEDIA FILES

function Homepage(props) {
  window.scrollTo(0, 0);

	const [generalSettings, setGeneralSettings] = useState(undefined);
	const [isFormLoading, setIsFormLoading] = useState(false);
  const [open,setOpen] = useState(true);
  const [flag,setFlag] = useState(true);
  function close(){
    console.log("open react player:: "+open)
    setOpen(false)
    setFlag(false)
  }
	useEffect(() => {
		handleSiteInfo();
	}, []);
  
	async function handleSiteInfo() {
	  let RC = false;
	  setIsFormLoading(true);
	  let generalSettings = await getGeneralSettings();
	  if (generalSettings != undefined ) {
		console.log("General settings found successfully ::", generalSettings);
		setGeneralSettings(generalSettings);
		setIsFormLoading(false);
	  } else {
		console.log("General settings Not found");
		setIsFormLoading(false);
	  }
	}

  
  return (
    <>
    {isFormLoading ? (
      <div className={{ alignContents: "center", }}>
        <CircularStatic value={25} />
      </div>
    ) : (
      <Fragment>
        <VideoModal value={open} flag={flag} close={close}/>
        <Header generalSettings={generalSettings} />
        <Slider marginManager={open} generalSettings={generalSettings} />
        <HeroBoxes  generalSettings={generalSettings} />
        <Services generalSettings={generalSettings}  />
        <WhyUs  generalSettings={generalSettings} />  
        <Testimonials generalSettings={generalSettings}  />   
        {/* <MileStones  generalSettings={generalSettings} />        */}
        {/* <FeaturedMentors generalSettings={generalSettings}  /> */}
        <SuccessStories generalSettings={generalSettings}  />
        <ContactUs  generalSettings={generalSettings} />      
        <Footer generalSettings={generalSettings}  />
      </Fragment>
    )}
    </>
  );
}

export default Homepage;
