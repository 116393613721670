import React, { useState, useEffect, useRef  } from "react";
import {  Link,  useHistory,  useLocation } from "react-router-dom";

// context
import { useUserDispatch,  useUserState,  loginUser,  registerUser,  isEmailExist,  isPhoneExist,
  verifyOTPs,  signOut,  resendOTPs } from "../context/userContext";

// MATERIAL UI
import logo from "./../assets/images/logo.png";
import iconLogin from "./../assets/images/icons/icon-login.svg";
import CustomMenus from "./../components/Controls/CustomMenu";
import ScrollButton from "./../components/ScrollButton";

const Header = (props) => {
  const [deleteDialog, deleteDialogUI] = useState(false);
  const history = useHistory();
  const headerElement = useRef();
  const burgerElement = useRef();
  const burgerMenuOpen = useRef();
  const burgerMenuClose = useRef();
  
  //global
  var [hambActive, setHambActive] = useState(false);
  var [menuActive, setMenuActive] = useState(false);

  var userDispatch = useUserDispatch();
  var { isAuthenticated } = useUserState();

  // var header = document.querySelectorAll(["classname=header"]);
  
  var [hamburgerOpen, setHamburgerOpen] = useState("");
  var [navigationShow, setNavigationShow] = useState("");

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener('scroll', setHeader);
    setHeader();
  }, []);

	function setHeader()
	{
		if(window.innerWidth < 992)
		{
			if(window.pageYOffset > 100)
			{
        if(headerElement.current != undefined)
          headerElement.current.className = "header d-flex flex-row scrolled";				  
			}
			else
			{
        if(headerElement.current != undefined)
          headerElement.current.className = "header d-flex flex-row";
			}
		}
		else
		{
      // console.log("setHeader :: window.pageYOffset = ", window.pageYOffset);

			if(window.pageYOffset > 100)
			{
        if(headerElement.current != undefined)
          headerElement.current.className = "header d-flex flex-row scrolled";
			}
			else
			{
        if(headerElement.current != undefined)
          headerElement.current.className = "header d-flex flex-row";
			}
		}
	}

  function HeaderView() {
    const location = useLocation();
    console.log(location.pathname);
    return location.pathname;
  }

  async function handleLogoutUser() {
    let rc = true;
    rc = await signOut(userDispatch, history);
  }

	function openMenu()
	{		
    if(!menuActive)
	  {
      // event.stopPropagation();
      var currentClass = burgerMenuOpen.current.className;
      burgerMenuOpen.current.className = currentClass +  ' active';
      // burgerMenuOpen.addClass('active');
      setHambActive(true);
      setMenuActive(true);
    } else {
      closeMenu();
    }
	}

	function closeMenu()
	{    
    // event.stopPropagation();
    var currentClass = burgerMenuOpen.current.className;
    burgerMenuOpen.current.className = "menu_container menu_mm"
    setHambActive(false);
    setMenuActive(false);
	}

  function  openMenuItem(iData) {
    // event.stopPropagation();
    closeMenu(); 
    history.push(iData);
  }

  return (
    <div className="signin-dialog">

      {/* <!-- Header --> */}
      {/* <NotificationBar message="hello uqair" /> */}

      <header ref={headerElement} className="header d-flex flex-row justify-content-between">
        <div className="header_content d-flex flex-row align-items-center ">
          {/* <!-- Logo --> */}
          <div className="logo_container">
            <div className="logo">
              <Link to="/">
                <img src={logo} height={'100%'} width={'100%'} alt="" />
                {/* <span>STARTUPBASKET</span>   */}
              </Link>        
            </div>
          </div>

          {/* <!-- Main Navigation --> */}
          <nav className="main_nav_container">
            <div className="main_nav">
              <ul className="main_nav_list">
                <li className={ HeaderView() == "/startups" ? "current-menu-item main_nav_item" : "main_nav_item" } >
                  <Link to="/startups">Startups</Link>
                </li>
                <li className={ HeaderView() == "/services" ? "current-menu-item main_nav_item" : "main_nav_item" } >
                  <Link to="/services">Services</Link>
                </li>            
                {/* <li className={ HeaderView() == "/members" ? "current-menu-item main_nav_item" : "main_nav_item" } >
                  <Link to="/members">Partners</Link>
                </li>               */}
                <li className={ HeaderView() == "/news" ? "current-menu-item main_nav_item" : "main_nav_item" } >
                  <Link to="/news">blogs</Link>
                </li>  
                <li className={ HeaderView() == "/aboutus" ? "current-menu-item main_nav_item" : "main_nav_item" } >
                  <Link to="/aboutus">About Us</Link>
                </li>
                {/* <li className={ HeaderView() == "/contactus" ? "current-menu-item main_nav_item" : "main_nav_item" } >
                  <Link to="/contactus">contact</Link>
                </li>                               */}
                {/* <li className={ HeaderView() == "/login" ? "current-menu-item main_nav_item" : "main_nav_item" } >
                  <Link to="/login">login</Link>
                </li>                               */}

              </ul>
            </div>
          </nav>
        </div>

        {isAuthenticated ? (
            <CustomMenus handleLogoutUser={handleLogoutUser} />
        ) : (
          <Link to="/login" >
            <div className="header_side d-flex flex-row justify-content-center align-items-center">
                <img src={iconLogin} alt="" />
                <span>Login/Register</span>
            </div>
            </Link>
        )}

        {/* <!-- Hamburger --> */}
        <div ref={burgerElement} className="hamburger_container" onClick={openMenu}>
          <i className="fa fa-bars trans_200"></i>
        </div>

      </header>

      {/* <!-- Menu --> */}
      <div className="menu_container menu_mm" ref={burgerMenuOpen}>

        {/* <!-- Menu Close Button --> */}
        <div className="menu_close_container" ref={burgerMenuClose} onClick={closeMenu}>
          <div className="menu_close"></div>
        </div>

        {/* <!-- Menu Items --> */}
        <div className="menu_inner menu_mm">
          <div className="menu menu_mm">
            <ul className="menu_list menu_mm">
              <li className="menu_item menu_mm">
                <Link to="/login" >
                  <div className="button button_primary d-inline-flex p-1 ">
                      <img src={iconLogin} alt="" width="30" className="mx-2" />
                      <span className="text-light mx-2">Login/Register</span>
                  </div>
                </Link>
              </li>
              <li className="menu_item menu_mm"><div onClick={()=>{ openMenuItem( "/startups") }} >Startups</div></li>
              <li className="menu_item menu_mm"><div onClick={()=>{ openMenuItem( "/services") }}>Services</div></li>
              {/* <li className="menu_item menu_mm"><div onClick={()=>{ openMenuItem( "/members") }}>Partners</div></li> */}
              <li className="menu_item menu_mm"><div onClick={()=>{ openMenuItem( "/news") }}>News</div></li>
              <li className="menu_item menu_mm"><div onClick={()=>{ openMenuItem( "/aboutus") }}>About Us</div></li>
              <li className="menu_item menu_mm"><div onClick={()=>{ openMenuItem( "/contactus") }}>Contact</div></li>


            </ul>
          </div>
        </div>

      </div>
      <ScrollButton />

    </div>
    
  );
};

export default Header;
