import React from "react";

import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";

export default function CustomTextField(props) {
  return (
    <FormControl fullWidth>
      {
        (props.type == "number") ?
          <TextField
            fullWidth
            type="number"
            required={props.required}
            min={0}
            max={100}
            label={props.state[props.paramValue + "Label"]}
            id={props.state[props.paramValue + "ID"]}
            name={props.state[props.paramValue + "ID"]}
            value={props.state[props.paramValue + "Value"]}
            placeholder={props.state[props.paramValue + "PlaceHolder"]}
            onChange={(e) => {
              props.onChange(e, props.paramValue);
            }}
            inputProps={{
              min: 0,
              max: 999999999
              // min:props.state[props.paramValue + "FieldValidation"].minLength,
              // max:props.state[props.paramValue + "FieldValidation"].maxLength 
            }}
            error={props.state[props.paramValue + "ErrorStatus"]}
            helperText={
              props.state[props.paramValue + "ErrorStatus"]
                ? props.state[props.paramValue + "ErrorMsg"]
                : ""
            }
          />

          : (props.type == "select") ?
            <TextField
              fullWidth
              type={props.type ? props.type : "text"}
              required={props.required}
              label={props.state[props.paramValue + "Label"]}
              id={props.state[props.paramValue + "ID"]}
              name={props.state[props.paramValue + "ID"]}
              value={props.state[props.paramValue + "Value"]}
              placeholder={props.state[props.paramValue + "PlaceHolder"]}
              onChange={(e) => {
                props.onChange(e, props.paramValue);
              }}
              error={props.state[props.paramValue + "ErrorStatus"]}
              helperText={
                props.state[props.paramValue + "ErrorStatus"]
                  ? props.state[props.paramValue + "ErrorMsg"]
                  : ""
              }
            >
              {props.options.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </TextField>
            :
            <TextField
              fullWidth
              type={props.type ? props.type : "text"}
              required={props.required}
              label={props.state[props.paramValue + "Label"]}
              id={props.state[props.paramValue + "ID"]}
              name={props.state[props.paramValue + "ID"]}
              value={props.state[props.paramValue + "Value"]}
              placeholder={props.state[props.paramValue + "PlaceHolder"]}
              onChange={(e) => {
                props.onChange(e, props.paramValue);
              }}
              error={props.state[props.paramValue + "ErrorStatus"]}
              helperText={
                props.state[props.paramValue + "ErrorStatus"]
                  ? props.state[props.paramValue + "ErrorMsg"]
                  : ""
              }
            />
      }
    </FormControl>
  );
}
