import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";

import { displayResponseError } from "./ApiUtils";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export {
  createBlogCategoryAPI,
  deleteBlogCategoryAPI,
  editBlogCategoryAPI,
  getBlogCategoryAPI,
  getBlogCategoriesAPI,
};


async function getBlogCategoriesAPI() {
  let blogcategorys = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("blogcategory");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.blogcategory.map((blogcategory) => {
        let tmp = {};
        Object.entries(blogcategory).map(([key, value]) => {
          tmp[key] = value;
        });
        blogcategorys.push(tmp);
      });
      return blogcategorys;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return blogcategorys;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}




async function getBlogCategoryAPI(blogcategory_id) {
  let blogcategory = undefined;
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("blogcategory/" + blogcategory_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response);
      blogcategory = response.data.response.blogcategory;
      if (response.data.response.volunteer != undefined && response.data.response.volunteer[0] != undefined)
        blogcategory["volunteer_id"] = response.data.response.volunteer[0].id;
      return blogcategory;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return blogcategory;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function createBlogCategoryAPI(param) {
  let token = localStorage.getItem("id_token");
  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("blogcategory", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.blogcategoryactivated == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("BlogCategory Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editBlogCategoryAPI(blogcategory_id, param) {
  let token = localStorage.getItem("id_token");

  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    if (value != null) formData.append(key, value);
  });
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("blogcategory/" + blogcategory_id + "?_method=PUT", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.blogcategoryactivated == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("BlogCategory Edited Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteBlogCategoryAPI(blogcategory_id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("blogcategory/" + blogcategory_id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}
