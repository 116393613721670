import React from "react";
import { Route, Switch, Redirect, withRouter } from "react-router-dom";
import classnames from "classnames";
import { Box, IconButton, Link } from "@material-ui/core";
import Icon from "@mdi/react";

//icons
import {
  mdiFacebook as FacebookIcon,
  mdiTwitter as TwitterIcon,
  mdiGithub as GithubIcon,
} from "@mdi/js";

// styles
import useStyles from "./styles";

// components
import Header from "../header";
import Footer from "../footer";
import Sidebar from "../sidebar";

// pages
import StartupDashboard from "../../pages/profile-startup/dashboard";
import InvestorDashboard from "../../pages/profile-investor/dashboard";
import MemberDashboard from "../../pages/profile-member/dashboard";

import StartupProfileInfo from "../../pages/profile-startup/profile_info";
import MemberProfileInfo from "../../pages/profile-member/profile_info";
import InvestorProfileInfo from "../../pages/profile-investor/profile_info";

import StartupEditProfile from "../../pages/profile-startup/edit_profile";
import MemberEditProfile from "../../pages/profile-member/edit_profile";
import InvestorEditProfile from "../../pages/profile-investor/edit_profile";

import StartupOrders from "../../pages/profile-startup/orders";
import PartnerOrders from "../../pages/profile-member/bookings";
import InvestorOrders from "../../pages/profile-investor/orders";

// import StartupSchedule from "../../pages/profile-startup/schedule";
import PartnerSchedule from "../../pages/profile-member/schedule";
// import InvestorSchedule from "../../pages/profile-investor/schedule";

import Donation from "../../pages/profile-startup/order";

import Notifications from "../../pages/profile-startup/notifications";
import Shortlist from "../../pages/profile-startup/shortlisted";
import StartupSchedule from "../../pages/profile-member/schedule";
import EditStartupDocument from "../../pages/profile-startup/editDocument";
import AddStartupDocument from "../../pages/profile-startup/addDocuments";
import EditCoFounder from "../../pages/profile-startup/editCoFounder";
import AddCoFounder from "../../pages/profile-startup/addCoFounder";

import AddMemberService from "../../pages/profile-member/addServices";
import StartupWallet from "../../pages/profile-startup/viewWallets";

// context
import { useLayoutState } from "../../context/layoutContext";

function Layout(props) {
  var classes = useStyles();

  // global
  var layoutState = useLayoutState();

  return (
    <>
    <Header/>
    <div className={classes.root + " container"}>
        
        <Sidebar />
        <div
          className={classnames(classes.content, {
            [classes.contentShift]: layoutState.isSidebarOpened,
          }) + " p-1 p-md-4"}
        >
          <Switch>
            <Route path="/profile/dashboard" component={StartupDashboard} />
            <Route path="/profile/orders" component={StartupOrders} />
            <Route path="/profile/order" component={Donation} />
            
            <Route path="/profile/startup/dashboard/:itemId" component={StartupDashboard} />
            <Route path="/profile/startup/view/:itemId" component={StartupProfileInfo} />
            <Route path="/profile/startup/edit/:itemId" component={StartupEditProfile} />
            <Route path="/profile/startup/orders/:itemId" component={StartupOrders} />
            <Route path="/profile/startup/order/:bookingId" component={Donation} />
            <Route path="/profile/startup/document/add/:itemId" component={AddStartupDocument}/>
            <Route path="/profile/startup/document/edit/:itemId/:documentId" component={EditStartupDocument}/>
            <Route path="/profile/startup/cofounder/add/:itemId" component={AddCoFounder} />
            <Route path="/profile/startup/cofounder/edit/:itemId/:cofounderId" component={EditCoFounder} />

            <Route path="/profile/investor/dashboard/:itemId" component={InvestorDashboard} />
            <Route path="/profile/investor/view/:itemId" component={InvestorProfileInfo} />
            <Route path="/profile/investor/edit/:itemId" component={InvestorEditProfile} />
            <Route path="/profile/investor/orders/:itemId" component={InvestorOrders} />
            <Route path="/profile/investor/order/:bookingId" component={Donation} />

            <Route path="/profile/partner/dashboard/:itemId" component={MemberDashboard} />
            <Route path="/profile/partner/view/:itemId" component={MemberProfileInfo} />
            <Route path="/profile/partner/edit/:itemId" component={MemberEditProfile} />
            <Route path="/profile/partner/orders/:itemId" component={PartnerOrders} />
            <Route path="/profile/partner/order/:bookingId" component={Donation} />
            <Route path="/profile/partner/schedule/:itemId" component={PartnerSchedule} />
            <Route path="/profile/partner/service/add/:itemId" component={AddMemberService} />
            
            <Route path="/profile/startup/wallets/:itemId" component={StartupWallet} />

            <Route path="/profile/startup/notifications/:itemId" component={Notifications} />
            {/* <Route path="/profile/Members" component={Members} /> */}
            {/* <Route path="/profile/Member" component={Member} /> */}
            <Route path="/profile/shortlist" component={Shortlist} />
          </Switch>
        </div>
    </div>
    <Footer />
    </>
  );
}

export default withRouter(Layout);
