import React, { Fragment, useEffect } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
  useHistory,
} from "react-router-dom";

import Footer from "../../components/footer";
import Header from "../../components/header";

import PageSideBar from "../../components/PageSidebar.js";

import SingleStartup from "./components/single-startup";

function Homepage() {
  const history = useHistory();

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("id_token");
    window.scrollTo(0, 0);

    // if (!isLoggedIn)
    //   history.push("/login");

  }, []);

  return (
    <Fragment>

        <SingleStartup />



       
    </Fragment>
  );
}

export default Homepage;
