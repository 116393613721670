import React, { useState } from "react";
import reactDom from "react-dom";
import shortid from "shortid";


export default function CustomUploadOnly(props) {




    return (

        <div className="fileupload-view">
            <div className="row justify-content-center m-0">
                <div className="col-md-12">
                    <div className="card">
                        <div className="card-body">
                            <div className="kb-data-box">
                                <div className="kb-modal-data-title">
                                    <div className="kb-data-title">
                                        <h6>Upload File</h6>
                                    </div>
                                </div>
                                <form onSubmit={props.FileUploadSubmit}>
                                    <div className="kb-file-upload">
                                        <div className="file-upload-box">
                                            <input type="file" id="fileupload" className="file-upload-input" onChange={props.InputChange} />
                                            <span>Drag and drop or <span className="file-link">Choose your files</span></span>
                                        </div>
                                    </div>
                                    <div className="kb-attach-box mb-3">
                                        {
                                            props?.selectedfile?.map((data, index) => {
                                                const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                return (
                                                    <div className="file-atc-box" key={id}>
                                                        {
                                                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                                <div className="file-image"><i className="far fa-file-alt"></i></div>
                                                        }
                                                        <div className="file-detail">
                                                            <h6>{filename}</h6>
                                                            <p></p>
                                                            <p><span>Size : {filesize}</span><span className="ml-2">Modified Time : {datetime}</span></p>
                                                            <div className="file-actions">
                                                                <button type="button" className="file-action-btn" onClick={() => props.DeleteSelectFile(id)}>Delete</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    {/* <div className="kb-buttons-box">
                                        <button type="submit" className="button button_primary form-submit">Upload</button>
                                    </div> */}
                                </form>
                                {props?.Files?.length > 0 ?
                                    <div className="kb-attach-box">
                                        {
                                            props?.Files?.map((data, index) => {
                                                const { id, filename, filetype, fileimage, datetime, filesize } = data;
                                                return (
                                                    <div className="file-atc-box" key={index}>
                                                        {
                                                            filename.match(/.(jpg|jpeg|png|gif|svg)$/i) ?
                                                                <div className="file-image"> <img src={fileimage} alt="" /></div> :
                                                                <div className="file-image"><i className="far fa-file-alt"></i></div>
                                                        }
                                                        <div className="file-detail">
                                                            <h6>{filename}</h6>
                                                            <p><span>Size : {filesize}</span><span className="ml-3">Modified Time : {datetime}</span></p>
                                                            <div className="file-actions">
                                                                <button className="file-action-btn" onClick={() => props.DeleteFile(id)}>Delete</button>
                                                                <a href={fileimage} className="file-action-btn" download={filename}>Download</a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )
                                            })
                                        }
                                    </div>
                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    );
}