import React, { Fragment, useState, useEffect, useParams } from "react";
import { Link, useHistory,} from "react-router-dom";
import queryString from "query-string";

// COMPONENTS

// MEDIA FILES
import FeaturedStartup from "../assets/images/logos/Logo-4.png";
import Event01 from "../assets/images/photos/sidebar-activities-image.jpg";
import Event02 from "../assets/images/photos/sidebar-events-image.jpg";
import latest_1 from "../assets/images/latest_1.jpg";

// FOR APIS
import {
  getStartupsPaginationAPI
} from "../APIs/startupApis";

import {
	deleteBlogAPI,
	getBlogsAPI,
	getBlogs,
	getBlogsPaginationAPI
} from "../APIs/blogApis";

function PageSidebar() {

  const history = useHistory();
  const [startupsTable, setStartupsTable] = useState(undefined);
  const [blogsTable, setBlogsTable] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);

  useEffect(() => {
    handleGetBlogs();
    handleGetStartups();
  }, []);


  return (
    <Fragment>
      <div className="sidebar">
        {/* <!-- Latest Posts --> */}
        <div className="sidebar_section">
          <div className="sidebar_section_title">
            <h3>Latest posts</h3>
          </div>
          <div className="latest_posts my-3">
            {/* <!-- Latest Post --> */}
            {
            blogsTable != undefined &&
            blogsTable.map((row) => {
              if(row.isfeatured == 1 || row.isfeatured == "1")
              {															
              return (													
              <div className="latest_post">
                <div className="latest_post_image">
                <Link to={"/singleblog/" + row.id}><img src={row.profilethumbnail??latest_1} alt="https://unsplash.com/@dsmacinnes" /></Link>
                </div>
                <div className="latest_post_title">																
                  <Link to={"/singleblog/" + row.id}>{row.title}</Link>
                </div>
                <div className="latest_post_meta">
                  <span className="latest_post_author">By Admin</span>
                  {/* 
                  <span>|</span>
                  <span className="latest_post_comments"><a href="#">3 Comments</a></span> 
                  */}
                </div>
              </div>
            )}})}
          </div>
        </div>

        <div className="popular-posts">
          <h3 className="popular-posts-tittle">Success Stories</h3>

          <ul className="p-0">
          {startupsTable != undefined &&
            startupsTable.map((row) => {{
              return ( 
                <Link to={"/startup/" + row.id}>
                <li className="d-flex flex-wrap justify-content-between align-items-center mb-2">
                  <figure>
                      <div className="img-wrap">
                        <img src={row.profilethumbnail} alt="" />
                      </div>
                  </figure>

                  <div className="entry-content">
                    <h3 className="entry-title">
                      <Link to={"/startup/" + row.id}>
                        {row.companyname}
                      </Link>
                    </h3>
                  </div>
                </li>
                </Link>  
              )}})}
          </ul>
        </div>

        {/* <div className="upcoming-events">
          <h2>Activities/Events</h2>

          <ul className="p-0">
            <li className="d-flex flex-wrap justify-content-between align-items-center">
              <figure>
                <Link to="/BattlingMalnutrition">
                  <div className="img-wrap pr-2">
                    <img src={Event01} alt="" />
                  </div>
                </Link>
              </figure>

              <div className="entry-content  pl-2">
                <h3 className="entry-title">
                  <Link to="/BattlingMalnutrition">Event on Startup Registration Process </Link>
                </h3>

                <p>
                  Help break the vicious cycle of malnutrition by sponsoring the
                  monthly diet of one child....
                </p>
              </div>
            </li>

            <li className="d-flex flex-wrap justify-content-between align-items-center">
              <figure>
                <Link to="/CovidReliefOperations">
                  <div className="img-wrap pr-2">
                    <img src={Event02} alt="" />
                  </div>
                </Link>
              </figure>

              <div className="entry-content  pl-2">
                <h3 className="entry-title">
                  <Link to="/CovidReliefOperations">
                    Interview with CEO of XYZ Tech
                  </Link>
                </h3>

                <p>
                  We distributed around 40 Oxygen concentrators with voltage
                  stabilizers in different villages.
                </p>
              </div>
            </li>
          </ul>
        </div> */}

        {/* <div className="featured-cause">
          <h2>Featured Causes</h2>

          <div className="cause-wrap mt-4">
            <figure className="m-0 position-relative">
              <Link to="/focusedareas">
                <img src={FeaturedStartup} alt="" />
              </Link>
            </figure>

            <div className="cause-content-wrap p-2">
              <header className="entry-header d-flex flex-wrap align-items-center mt-0">
                <h3 className="entry-title w-100 m-0">
                  <Link to="/focusedareas">
                    Featured Startup Information
                  </Link>
                </h3>
              </header>

              <div className="entry-content">
                <p className="mb-3">
                quidem est gravitate proximus, memoria recens est et futuri atque inpendentis torquet timor? Idque testamento cavebit is, qui nobis quasi oraculum ediderit nihil post mortem ad nos pertinere? Cum autem dispicere coepimus et sentire quid, simus et quid ab animantibus ceteris differamu                </p>
              </div>
            </div>
          </div>
        </div> */}
      </div>
    </Fragment>
  );


  async function handleGetStartups() {
    let RC = false;
    setIsFormLoading(true);
    let page = 1;
    let limit = 3;

    // let queries = queryString.parse(history.location.search);
    // let page = queries.page != undefined ? queries.page : 1;
    // let limit = queries.limit != undefined ? queries.limit : 9;
    let startups = await getStartupsPaginationAPI(limit, page, { isFeatured: true} );
    if (startups != undefined) {
      console.log("<handleGetStartups> Startups Found Successfully ::", startups.length);
      setStartupsTable(startups.data);
      setIsFormLoading(false);
      setPage(page);
      setLimit(limit);
    } else {
      console.log("<handleGetStartups> Startup Not found");
      setIsFormLoading(false);
    }
  }

  async function handleGetBlogs() {
		let RC = false;
		setIsFormLoading(true);

		let queries = queryString.parse(history.location.search);
		// console.log("Query String :: ", queries);
		let page = 1;
		let limit = 3;
		let category = queries.category != undefined ? queries.category : "";


		let blogs = await getBlogsPaginationAPI(category, limit, page);
		if (blogs != undefined && blogs.data.length > 0) {
			console.log("Blogs Found Successfully ::", blogs);
			setBlogsTable(blogs.data);
			setIsFormLoading(false);
			setPage(page);
			setLimit(limit);
		} else {
			console.log("Blog Not found");
			setIsFormLoading(false);
		}
	}

}

export default PageSidebar;
