import React, { Fragment, useEffect } from "react";
import { HashRouter, Route, Switch, Redirect, Link } from "react-router-dom";


import Footer from "../../components/footer";
import Header from "../../components/header";
import PageSideBar from "../../components/PageSidebar.js";
import Services from "./components/services";
import IMAGE_BG from "../../assets/images/teachers_background.jpg";


function Homepage() {
  useEffect(()=>{
    window.scrollTo(0, 0);
  },[])
  return (
    <Fragment>
      <div className="single-page about-page">
        <Header />
        {/* <!-- Home --> */}
        <div className="home innerpage">
          <div className="home_background_container prlx_parent">
            <div className="home_background prlx" >                    
            <img src={IMAGE_BG} />
            </div>
          </div>
          <div className="home_content">
            <h1>What We Do</h1>
          </div>
        </div>

        {/* <!-- Popular --> */}

        <div className="popular page_section">
            {/* <div className="row">
              <div className="col">
                <div className="section_title text-center">
                  <h1>Our Services</h1>
                </div>
              </div>
            </div> */}
            <Services />
        </div>
        <Footer />
      </div>
    </Fragment>
  );
}

export default Homepage;
