
import API, { API_Param } from "../API";
import {handleAPIError, handleAPISuccess} from "../helpers";

export {
  getServicesAPI,
  getServiceAPI,
  getServiceMembersAPI,
  getParentLevelServicesAPI,
  getChildServicesAPI,
};

async function getServicesAPI(history, iFeaturedOnly = false) {
  let services = [];
  var response = '' ; 
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    response = await API.get("service", { params: {isFeatured: iFeaturedOnly} });
    if (response.data.status == "S_OK") {
      return handleAPISuccess(response);
    } else {
      return handleAPIError(response);
    }
  } catch (error) {
    return handleAPIError(error.response);
  }
}

async function getServiceMembersAPI(iParam) {
  let members = [];
  var response = '' ; 
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    response = await API.get("service/members/" + iParam.service_id + "?page="+iParam.page);
    if (response.data.status == "S_OK") {
      return handleAPISuccess(response);
    } else {
      return handleAPIError(response);
    }
  } catch (error) {
    return handleAPIError(error.response);
  }
}

async function getServiceAPI(service_id) {
  let service = undefined;
  let token = localStorage.getItem("id_token");
  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("service/" + service_id);
    if (response.data.status == "S_OK") {
      return handleAPISuccess(response);
    } else {
      return handleAPIError(response);
    }
  } catch (error) {
    return handleAPIError(error.response);
  }
}

async function getParentLevelServicesAPI(history, iFeaturedOnly = false) {
  let services = [];
  var response = '' ; 
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    response = await API.get("/get-service-parent-only", { params: {isFeatured: iFeaturedOnly} });
    if (response.data.status == "S_OK") {
      return handleAPISuccess(response);
    } else {
      return handleAPIError(response);
    }
  } catch (error) {
    return handleAPIError(error.response);
  }
}

async function getChildServicesAPI(history, iParam) {
  let services = [];
  var response = '' ; 
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    response = await API.get("/get-child-by-service/" + iParam.parentId, { params: iParam });
    if (response.data.status == "S_OK") {
      return handleAPISuccess(response);
    } else {
      return handleAPIError(response);
    }
  } catch (error) {
    return handleAPIError(error.response);
  }
}