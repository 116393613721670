import React, { Fragment, useState, useEffect } from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { displayResponseError } from "../APIs/ApiUtils";
import queryString from "query-string";

// FOR APIS
import {
  getGeneralSettings
} from "./../APIs/ApiUtils";

export {
  SiteInfoProvider,
  useSiteInfoContext,
  SiteInfoContext
};

var SiteInfoContext = React.createContext();

function SiteInfoProvider({ children }) {
  var [siteInfo, setSiteInfo] = useState("Himanshu");

  useEffect(() => {
    handleGetFooterInfo();
  }, []);

  return (
    <SiteInfoContext.Provider value={siteInfo}>
        {children}
    </SiteInfoContext.Provider>
  );

  async function handleGetFooterInfo() {
    let RC = false;
    // setIsFormLoading(true);
    let generalSettings = await getGeneralSettings();
    if (generalSettings != undefined ) {
      console.log("General settings found successfully ::", generalSettings);
      setSiteInfo(generalSettings);
      // setIsFormLoading(false);
    } else {
      console.log("General settings Not found");
      // setIsFormLoading(false);
    }
  }



}

function useSiteInfoContext() {
  var context = React.useContext(SiteInfoContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

// ###########################################################






