import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";

import { displayResponseError } from "./ApiUtils";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export {
  getStartupsPaginationAPI,
  createStartupAPI,
  createStartupDocumentAPI,
  createStartupCoFounderAPI,
  deleteCoFounderAPI,
  deleteStartupAPI,
  deleteStartupDocumentAPI,  
  editStartupAPI,
  editStartupDocumentAPI,
  editStartupCoFounderAPI,
  getCoFounderAPI,
  getCoFoundersAPI,
  getStartupAPI,
  getStartupDocumentAPI,
  getStartupDocumentByIDAPI,
  getStartupsAPI,
  getStartupsByVolunteerAPI,
  getStartupBookingsAPI ,
  getTagsAPI,
  getOpenAPIResponse,
  submitCustomFormAPI
};


async function getOpenAPIResponse(iSearchParam = "") {
  let startups = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("openai" , { params: {search: iSearchParam} });    
    return response.data;     
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return undefined;
  }
}


async function getStartupsPaginationAPI(limit, page, iParam ) {
  let startups = [];
  let token = localStorage.getItem("id_token");
  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("startup/pagination/" + limit + "/" + page + "?page=" + page , { params: iParam });
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response.startups);      
      return response.data.response.startups;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return startups;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getTagsAPI() {
  let tags = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("tag");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);    
      response.data.response.Tags.map((tag) => {
        let tmp = tag;        
        tmp['isChecked'] = false;   
        // tmp['label'] = tag["name"];   
        tags.push(tmp);
      });      
      return tags;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return tags;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getStartupsAPI(iParam) {
  let startups = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("startup", { params: iParam });
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.startups.map((startup) => {
        let tmp = {};
        Object.entries(startup).map(([key, value]) => {
          tmp[key] = value;
        });
        startups.push(tmp);
      });
      console.log("Startups Table Data :: ",startups);
      return startups;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return startups;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getStartupsByVolunteerAPI(volunteer_id) {
  let startups = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("volunteer/startups/" + volunteer_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      // response.data.response.volunteer.startups.map((startup) => {
      //   let tmp = {};
      //   Object.entries(startup).map(([key, value]) => {
      //     tmp[key] = value;
      //   });
      //   startups.push(tmp);
      // });
      return response.data.response.volunteer;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return startups;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getStartupAPI(startup_id) {
  let startup = undefined;
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("startup/" + startup_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response);
      startup = response.data.response.startup;
      return startup;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return startup;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function createStartupAPI(param) {
  let token = localStorage.getItem("id_token");
  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("startup", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Startup Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editStartupAPI(startup_id, param) {
  let token = localStorage.getItem("id_token");

  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    if (value != null) formData.append(key, value);
  });
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("startup/" + startup_id + "?_method=PUT", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Startup Info updated successfully");
        return response.data.data;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteStartupAPI(startup_id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("startup/" + startup_id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getCoFoundersAPI(startup_id) {
  let members = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("startup/member/" + startup_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.members.map((member) => {
        let tmp = {};
        Object.entries(member).map(([key, value]) => {
          tmp[key] = value;
        });
        members.push(tmp);
      });
      return members;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return members;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getCoFounderAPI(member_id) {
  let startup = undefined;

  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("cofounder/" + member_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response);
      startup = response.data.response.cofounder;
      return startup;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return startup;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function createStartupCoFounderAPI(startup_id, param) {
  let token = localStorage.getItem("id_token");

  console.log("<PARAMETER> :: ", param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log("<FORMDATA> :: ", formData);

  formData.append("cofounderable_type", "App\\Models\\Persona\\startup");
  formData.append("cofounderable_id", startup_id);

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("cofounder", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("CoFounder/Team Member Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editStartupCoFounderAPI(member_id, param) {
  let token = localStorage.getItem("id_token");
  console.log("<MEMBER ID> :: ", member_id);
  console.log("<PARAMETER> :: ", param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log("<FORMDATA> :: ", formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post(
      "cofounder/" + member_id + "?_method=PUT",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("CoFounder/Team Member Updated Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteCoFounderAPI(member_id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("cofounder/" + member_id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getStartupDocumentAPI(startup_id) {
  let startups = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  let response = await API.get("startup/document/" + startup_id);
  if (response.status == 200) {
    console.log("Response from  API :: ", response.data);
    response.data.response.documents.map((startup) => {
      let tmp = {};
      Object.entries(startup).map(([key, value]) => {
        tmp[key] = value;
      });
      startups.push(tmp);
    });
    return startups;
  } else {
    console.log("API Request Failed With Status :: ", response.status);
    return startups;
  }
}

async function getStartupDocumentByIDAPI(document_id) {
  let document = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  let response = await API.get("document/" + document_id);
  if (response.status == 200) {
    console.log("Response from  API :: ", response.data);
    document = response.data.response.document;
    return document;
  } else {
    console.log("API Request Failed With Status :: ", response.status);
    return document;
  }
}

async function createStartupDocumentAPI(startup_id,param) {
  let token = localStorage.getItem("id_token");
  localStorage.getItem("CURRENT_INVESTOR");

  console.log(param);

  let formData = new FormData();

  // Object.entries(prices).map(([key, value]) => [key, value * 2])
  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });
  formData.append("documentable_type", "App\\Models\\Persona\\Startup");
  formData.append("documentable_id", startup_id);

  console.log(formData);

  try {
    API.defaults.timeout = 600000;
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("document", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Startup Document Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editStartupDocumentAPI(document_id , param) {
  let token = localStorage.getItem("id_token");
  console.log(param);
  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log("formData", formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post(
      "document/" + document_id + "?_method=PUT",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Document edited successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteStartupDocumentAPI(document_id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("document/" + document_id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getStartupBookingsAPI($params){

  let startups = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("/startup/bookings/" + $params.startup_id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response.bookings);
      return response.data.response.bookings;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return startups;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}



async function submitCustomFormAPI(persona_id, param) {
  let token = localStorage.getItem("id_token");

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("startup/customform/" + persona_id, formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Startup Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}