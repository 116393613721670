import React from "react";
import axios from "axios";
import {
  useHistory,
} from "react-router-dom";

export const API_Param = {
  // URL: "http://127.0.0.1:8000/api/",
  // BASE_URL: "http://localhost:3000/#/",
  URL: "https://server.startupbasket.net/api/",
  BASE_URL: "https://client.startupbasket.net/#/",  
  token: "",
};

var axiosApiInstance = axios.create({
  baseURL: API_Param.URL,
  timeout: 60000,
  headers: {
    "content-type": "application/json",
    // 'content-type': 'multipart/form-data'
  },
});

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => {
    console.log("<INFO> Response :: ", response);       
    return response;
  },
  async function (error) {
    console.log("<ERROR> Response Error :: ", error);
    const originalRequest = error.config;
    if (error.response != undefined && error.response.status === 403) {
      // let token = localStorage.setItem("id_token", "");
      // window.location.reload();
      // return <Redirect to="/app/dashboard" />;
    }
    return Promise.reject(error);
  },
);
export default axiosApiInstance;



