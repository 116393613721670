import React, { Fragment, useState, useEffect } from "react";

import { Link, useHistory, useParams } from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";

import MemberImage from "../../assets/images/Member-1.jpg";
import CloudUploadIcon from "@material-ui/icons/CloudDownload";
import { Button } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import Footer from "../../components/footer";
import Header from "../../components/header";

import ReactPlayer from "react-player";
import {
  Grid,
  CircularProgress,
  TextField,
  Fade,
  FormControl,
  InputLabel,
  Input,
} from "@material-ui/core";

import {
  deleteMemberAPI,
  getMembersAPI,
  getMemberAPI,
} from "../../APIs/memberApis";

import {
  getBookingByIdAPI,
} from "../../APIs/bookingApis";

import {
  getImageAPI,
  convertTime
} from "../../APIs/ApiUtils";


import {
  getLocaleShortDateString,
  currencySymbol
} from "../../helpers";




function Homepage(props) {
  window.scrollTo(0, 0);

  const history = useHistory();
  const params = useParams();

  useEffect(() => {
    handleGetBooking();
  }, []);

  const [MemberID, setMemberID] = useState(0);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [bookingInfo, setBookingInfo] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [donorname, setDonorName] = useState("");
  const [orderAmount, setDonationAmount] = useState("1000");
  const [orderType, setDonationType] = useState("");
  const [transactionID, setTransactionID] = useState("");
  const [orderID, setOrderID] = useState("");
  const [orderTime, setDonationTime] = useState("");


  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center" }}>
          <CircularProgress variant="indeterminate" value={75} />
        </div>
      ) : (
        <Fragment>
          <div className="single-page about-page">
            <Header />
            {/* <!-- Home --> */}
            <div className="home innerpage">
              <div className="home_background_container prlx_parent">
                <div className="home_background prlx" ></div>
              </div>
              <div className="home_content">
                <h1>Payment</h1>
              </div>
            </div>

            <div className="page-Member payment-success">
              <div className="container">
                <div className="main-body">
                  <div className="row gutters-sm">
                    <div className="col-md-4 mb-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex flex-column align-items-center text-center">
                            <img src={profilePicture} alt="" className="rounded-circle" width="150" />
                          </div>
                          <div className="mt-3 align-items-center text-center">
                            <Link to={"/profile/startup/orders/" + bookingInfo?.booking?.payment?.user_id} className="button button_primary_outline p-1 mb-3" >
                              <span>My Bookings</span>
                            </Link>
                            <div className="row mt-2">
                              <div className="col-md-6 col-lg-3">
                                <h6 className="mb-0"><small>Service Name</small></h6>
                              </div>
                              <div className="col-md-6 col-lg-9 text-secondary">
                                {bookingInfo['booking']['service']['title']}
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-md-6 col-lg-3">
                                <h6 className="mb-0"><small>Partner Name</small></h6>
                              </div>
                              <div className="col-md-6 col-lg-9 text-secondary">
                                {bookingInfo['booking']['appointment']['member']['membername']}
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-md-6 col-lg-3">
                                <h6 className="mb-0"><small>From</small></h6>
                              </div>
                              <div className="col-md-6 col-lg-9 text-secondary">
                                {bookingInfo['booking']['user']['name']}
                              </div>
                            </div>
                            <hr />
                            <div className="row">
                              <div className="col-md-6 col-lg-3">
                                <h6 className="mb-0"><small>Amount</small></h6>
                              </div>
                              <div className="col-md-6 col-lg-9 text-secondary">
                                {currencySymbol() + bookingInfo['booking']['payment']['amount']}
                              </div>
                            </div>
                            <hr />

                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="card mb-3">
                        <div className="card-body">
                          <div>
                            <div className="text-success h5 text-center">
                              <div className="pt-4">
                                Your Payment is successful, Your appointment booked with
                                "
                                {bookingInfo['booking']['appointment']['member']['membername']}
                                ".
                              </div>
                            </div>
                          </div>
                          <div className="mt-4">
                            <table className="table table-striped">
                              <tbody>
                                <tr>
                                  <th scope="row">Booked By</th>
                                  <td>
                                    {bookingInfo['booking']['user']['name']}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Order ID</th>
                                  <td>{bookingInfo['booking']['payment']['order_id']}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Amount Paid</th>
                                  <td>{currencySymbol() + bookingInfo['booking']['payment']['amount']}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Transaction ID</th>
                                  <td>{bookingInfo['booking']['payment']['transaction_id']}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Session Date</th>
                                  <td>{getLocaleShortDateString(bookingInfo['booking']['appointment']['date'])}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Session Timing</th>
                                  <td>{"From " + (bookingInfo['booking']['appointment']['starttime']).slice(0, 5) + " To " + (bookingInfo['booking']['appointment']['endtime']).slice(0, 5)}</td>
                                </tr>
                                <tr>
                                  <th scope="row">Status</th>
                                  <td>{bookingInfo['booking']['payment']['status']}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </Fragment>
      )}
    </>
  );

  async function handleGetBooking() {
    let RC = false;
    setIsFormLoading(true);
    let id = params.bookingId;
    let bookingInfo = await getBookingByIdAPI(id);
    if (bookingInfo != undefined) {
      console.log("Member Found Successfully in response", JSON.stringify(bookingInfo));

      let param = {
        filePath: bookingInfo['booking']['appointment']['member']["memberprofilefile"],
        fileName: "profileimage",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);

      setBookingInfo(bookingInfo);
      setIsFormLoading(false);
      setProfilePicture(blob);
    } else {
      console.log("Booking Not found");
      setIsFormLoading(false);
    }
  }


}

export default Homepage;
