import React, { Fragment, Component } from "react";
import ReactDOM, { } from "react-dom";
// import Moment from 'react-moment';
import moment from "moment";
import ProfileIcon from "@material-ui/icons/Person";
import CompanyIcon from "@material-ui/icons/Business";
import DocumentsIcon from "@material-ui/icons/NoteAdd";
import Footer from "../../components/footer";
import Header from "../../components/header";
import shortid from "shortid";

import { MuiPickersUtilsProvider, KeyboardDatePicker, } from "@material-ui/pickers";
import {
  Tab,
  Tabs,
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";

// Picker
import DateFnsUtils from "@date-io/date-fns";
import {
  TimePicker,
  DatePicker,
} from "@material-ui/pickers";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import { makeStyles } from "@material-ui/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
// CUSTOM COMPONENTS
import CircularStatic from '../../components/Controls/CircularProgressBar';

import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveIcon from "@material-ui/icons/Remove";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";
import { InfoOutlined } from "@material-ui/icons";
// FOR APIS
import axios from "axios";
import API, { API_Param } from "../../API";
import {
  getMembersAPI,
  getMemberAPI,
  createMemberAPI,
  editMemberAPI,
  getProfilesAPI,
  getMemberServicesAPI,
} from "../../APIs/memberApis";



import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadOnly from "../../components/Controls/CustomUploadOnly";

// COMPONENTS
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { Trans } from "react-i18next";
import i18n from "../../locales/locales_config";
//const userimage = require('./../../assets/images/userimage.png')
import userimage from "./../../assets/images/userimage.png";
import {
  getStartupsAPI,
  getStartupAPI,
  createStartupAPI,
  editStartupAPI,
  getCoFoundersAPI,
  deleteCoFounderAPI,
  createStartupDocumentAPI,
  getStartupDocumentAPI,
  EditStartupDocumentAPI,
  deleteStartupDocumentAPI,
  getTagsAPI,
  getOpenAPIResponse,
  submitCustomFormAPI
} from "../../APIs/startupApis";
import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
  getImageAPI,
  isMembershipPaid,
  getCategoriesAPI,
} from "../../APIs/ApiUtils";
var requiredParam = [
  "is_corporated",
  "companyname",
  "category_id",
  "custom_category",
  "city",
  "state",
  "pincode",
  "startuplinkdinprofile",
  "incorporationdate",
  "noofcofounder",
  "companysize",
  "companyurl",
  "contactphone",
  "productoffering",
  "fundingtype",
  // 'revenuemodaltype',
  // 'bussinessmodaltype',
  "capitalraised",
  "revenue",
  "currentstage",
  "ask",

];

const fundingtype = [
  {
    value: 'Debt',
    label: 'Debt',
  },
  {
    value: 'Equity',
    label: 'Equity',
  }
];


export default class FundRaisingForm extends Component {
  constructor() {
    super();
    this.state = {
      profilesList: [],
      catagoriesList: [],
      isFormLoading: false,
      isSubmitLoading: false,
      isError: false,
      tabIndex: 0,
      deleteDialogCoFounder: false,
      deleteDialogMemberDocument: false,
      cofounders: [],
      documents: [],
      profilePicture: "",
      Files1: [],
      selectedfile1: [],
      Files2: [],
      selectedfile2: [],

    };
  }

  componentDidMount() {
    window.scrollTo(0, 0);
    let isLoggedIn = localStorage.getItem("id_token");
    if (!isLoggedIn)
      this.props.history.push("/login?redirect=/fund-raising-form");
    else {
        this.initialize();
    }
  }

  initialize = async () => {

    this.setStateVariables("is_corporated",
      { checkEmpty: false },
      "RadioField",
      0
    );
    this.setStateVariables("companyname", {
      checkEmpty: true,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("category_id", {
      checkEmpty: true,
      minLength: 0,
      maxLength: 20,
    }, "SelectField");
    this.setStateVariables("custom_category", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("city", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 20,
    });
    this.setStateVariables("state", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 20,
    });
    this.setStateVariables("pincode", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 20,

    });
    this.setStateVariables("startuplinkdinprofile", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("incorporationdate", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("noofcofounder", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("companysize", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 200,
    });
    this.setStateVariables("companyurl", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("contactphone", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 10,
    });
    this.setStateVariables("productoffering", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 120,
    });
    this.setStateVariables("fundingtype", { checkEmpty: false, minLength: 0, maxLength: 120, }, "SelectField");
    // this.setStateVariables("revenuemodaltype", { checkEmpty: false, minLength: 0, maxLength: 120, });
    // this.setStateVariables("bussinessmodaltype", {
    //   checkEmpty: false,
    //   minLength: 0,
    //   maxLength: 120,
    // });
    this.setStateVariables("capitalraised", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 10,
    });
    this.setStateVariables("revenue", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 10,
    });
    this.setStateVariables("currentstage", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 250,
    });
    this.setStateVariables("ask", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 250,
    });
    this.setStateVariables(
      "pitch_deck",
      { checkEmpty: false },
      "FileField"
    );
    this.setStateVariables("financial_statements", { checkEmpty: false }, "FileField");


    // await this.handleGetAllProfiles();
    // await this.handleGetAllMemberServices();
    await this.handleGetAllCategories();
    await this.handleGetStartup();


  };

  handleTabChange = (event, newValue) => {
    // setTabIndex(newValue);
    this.setState({
      tabIndex: newValue,
    });
  };
  render() {
    return (
      <Fragment>
        <Header />
        <div className="container" style={{ marginTop: '100px' }}>
          <div className="single-page about-page">
            {this.state.isFormLoading ? (
              <div className={{ alignContents: "center" }}>
                <CircularStatic value={25} />
              </div>
            ) : (
              <div>
                <Grid container spacing={16}>
                  <Grid
                    item
                    xs={12}
                    mt={4}
                    className={{ justifyContent: "center" }}
                  >
                    <div className="p-0 p-lg-4">
                      <div>
                        <Typography
                          color="primary"
                          variant="h5"
                          size="sm"
                        // style={{ borderBottom: "1px solid #000" }}
                        >
                          Please fill the following form with required documents
                        </Typography>
                        <Divider style={{ margin: "5px 0 15px 0" }} />
                        <Grid
                          container
                          alignItems="flex-start"
                          spacing={2}
                          mb={4}
                        >
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                required="required"
                                paramValue="companyname"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {

                              <CustomSelectField paramValue="category_id" required="required" state={this.state} onChange={this.handleParam} ItemValues={(this.state.catagoriesList != undefined) ? this.state.catagoriesList : []} />
                            }
                          </Grid>

                          {this.state.category_idValue == "20" && (
                            <Grid
                              item
                              xs={12}
                              sm={6}
                              md={6}
                              className={{ justifyContent: "flex-start" }}
                            >
                              {
                                <CustomTextField
                                  required={(this.state.category_idValue == "20") ? "required" : ""}
                                  paramValue="custom_category"
                                  state={this.state}
                                  onChange={this.handleParam}
                                />
                              }
                            </Grid>
                          )}

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                required="required"
                                paramValue="city"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            <CustomTextField
                              paramValue="state"
                              state={this.state}
                              onChange={this.handleParam}
                            />
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="pincode"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="startuplinkdinprofile"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                <KeyboardDatePicker
                                  format="yyyy-MM-dd"
                                  openTo="year"
                                  views={["year", "month", "date"]}
                                  paramValue="incorporationdate"
                                  minDate={new Date("1920-03-01")}
                                  selected={this.state.incorporationdateValue}
                                  value={this.state.incorporationdateValue}
                                  label="Date Founded"
                                  onChange={(e) => {
                                    let value = moment(e).format("yyyy-MM-DD");
                                    console.log("Current Date", e, value);
                                    this.handleParam(value, "incorporationdate", true);
                                  }}
                                  KeyboardButtonProps={{
                                    "aria-label": "change date",
                                  }}
                                  error={this.state.incorporationdateErrorStatus}
                                  helperText={
                                    this.state.incorporationdateErrorStatus
                                      ? this.state.incorporationdateErrorMsg
                                      : ""
                                  }
                                />
                              </MuiPickersUtilsProvider>
                            }
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="noofcofounder"
                                state={this.state}
                                type="number"
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="companysize"
                                state={this.state}
                                type="number"

                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="companyurl"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="contactphone"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="productoffering"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomSelectField paramValue="fundingtype" state={this.state} onChange={this.handleParam} ItemValues={fundingtype} />

                              // <CustomTextField
                              //   paramValue="fundingtype"
                              //   state={this.state}
                              //   onChange={this.handleParam}
                              //   options={fundingtype}
                              //   type="select"
                              // />
                            }
                          </Grid>
                          {/* <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="revenuemodaltype"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid> */}
                          {/* <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="bussinessmodaltype"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid> */}
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="capitalraised"
                                state={this.state}
                                type="number"

                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="revenue"
                                state={this.state}
                                type="number"

                                onChange={this.handleParam}
                              />
                            }
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="currentstage"
                                state={this.state}
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>

                          <Grid
                            item
                            xs={12}
                            className={{ justifyContent: "flex-start" }}
                          >
                            {
                              <CustomTextField
                                paramValue="ask"
                                state={this.state}
                                type="number"
                                onChange={this.handleParam}
                              />
                            }
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start", }}
                          >
                            <div style={{ display: "flex", padding: "5px", justifyContent: "center", color: "white", alignItems: "center", height: "50px", width: "100%", fontSize: "1rem", margin: "auto", backgroundColor: "#254E58" }}>
                              Pitch Deck (pdf, pptx only)
                              <div style={{ marginLeft: "15px" }}>
                                <Tooltip arrow classes={{ tooltip: 'custom-tooltip' }}
                                  title="Problem statement, Solution, Team, Target Market, Financial Projections, Ask">
                                  <InfoOutlined />
                                </Tooltip>
                              </div>
                            </div>
                            <div className="col-sm-12 col-md-12 p-0">
                              <CustomUploadOnly
                                paramValue="pitch_deck"
                                selectedfile={this.state?.selectedfile1}
                                Files={this.state.Files1}
                                InputChange={(e) => { this.InputChange(e, 1) }}
                                DeleteSelectFile={this.DeleteSelectFile}
                                DeleteFile={this.DeleteFile}
                                FileUploadSubmit={this.FileUploadSubmit}
                              />
                            </div>
                          </Grid>
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={6}
                            className={{ justifyContent: "flex-start" }}
                          >
                            <div style={{ display: "flex", padding: "5px", justifyContent: "center", color: "white", alignItems: "center", height: "50px", width: "100%", fontSize: "1rem", margin: "auto", backgroundColor: "#254E58" }}>
                              Financial Statements(pdf, pptx, xlsx only)
                            </div>
                            <div className="col-sm-12 col-md-12 p-0">
                              <CustomUploadOnly
                                paramValue="financial_statements"
                                selectedfile={this.state?.selectedfile2}
                                Files={this.state.Files2}
                                InputChange={(e) => { this.InputChange(e, 2) }}
                                DeleteSelectFile={this.DeleteSelectFile}
                                DeleteFile={this.DeleteFile}
                                FileUploadSubmit={this.FileUploadSubmit}
                              />
                            </div>
                          </Grid>

                        </Grid>
                        <Grid container alignItems="flex-end" spacing={2}>
                          <Grid item style={{ marginTop: 25 }} xs={12}>
                            <div className="d-flex justify-content-center">
                              <Button
                                onClick={this.handleSubmit}
                                variant="contained"
                                color="primary"
                                type="submit"
                                style={{
                                  justifySelf: "center",
                                  alignSelf: "center",
                                }}
                              >
                                {this.state.isSubmitLoading ? (
                                  <div>
                                    <CircularProgress
                                      size="30px"
                                      color="secondary"
                                      style={{ marginRight: "5px" }}
                                    />
                                    {"Submit"}
                                  </div>
                                ) : (
                                  "Submit"
                                )}
                              </Button>
                            </div>
                          </Grid>

                          <Grid item style={{ marginTop: 25 }} xs={6}>
                            {this.state.isError ? (
                              <Typography style={{ color: "red" }}>
                                Error coming, Please check form
                              </Typography>
                            ) : (
                              ""
                            )}
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  </Grid>
                </Grid>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </Fragment>
    );
  }


  handleGetStartup = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, startupInfo: {}, startupId: this.props.match.params.startupId });
    let startup_id = localStorage.getItem("persona_id");
    let startupInfo = await getStartupAPI(startup_id);
    if (startupInfo != undefined) {
      let tmp = {};
      Object.entries(startupInfo).map(([key, value]) => {
        if (requiredParam.includes(key)) tmp[key + "Value"] = value;
      });
      console.log(
        "Startup Found Successfully in edit response",
        tmp,
      );

      let param = {
        filePath: tmp["profileimageValue"],
        fileName: "profileimage",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);
      this.setState({
        ...tmp,
        isFormLoading: false,
        profilePicture: blob,
      });

    } else {
      console.log("Startup Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleGetAllCategories = async () => {
    let RC = false;
    let arrCategories = [];

    this.setState({ isFormLoading: true, catagoriesList: {} });
    let categories = await getCategoriesAPI();
    if (categories != undefined) {

      categories.forEach(element => {
        let tmp = {};
        tmp['value'] = element['id'];
        tmp['label'] = element['title'];
        arrCategories.push(tmp);
      });
      console.log("Categories found successfully", arrCategories);

      this.setState({
        catagoriesList: arrCategories,
        // isFormLoading: false,
      });

    } else {
      console.log("Categories Not found");
      // this.setState({ isFormLoading: false });
    }
  };

  handleChange = (event) => {
    let tmpProfilesList = [];
    event.target.value.forEach((profileID) => {
      let newelement = this.state.profilesList.find((obj) => {
        console.log(
          "Current event target value :: ",
          obj.id,
          " :: ",
          profileID
        );
        if (obj.id == profileID) return obj;
      });
      console.log("newelement ::", newelement);
      tmpProfilesList.push(newelement);
    });
    this.setState({ profilesValue: tmpProfilesList });


  };

  handleSubmitForm = async (param) => {
    console.log("create fund raising API Started");
    var rc = false;
    // let persona_id = this.props.params.persona_id;
    let persona_id = this.props.match.params.startupId;

    rc = await submitCustomFormAPI(persona_id, param);
    if (rc == true) {
      console.log("Form created Successfully");
      this.setState({ isSubmitLoading: false });
      toast.success("Your form successffuly submitted");
      this.props.history.push("/service/10"); // ZKC , HARDCODING
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Form not submitted");
    }
    console.log("Create Form API ENDED");
  };

  handleSubmit = () => {
    let param = {};

    // GET THE VALUES ONLY
    Object.entries(this.state).map(([key, value]) => {
      if (key.includes("Value")) {
        param[key.replace("Value", "")] = value;
      }
    });

    console.log("<INFO> Param Value ", param);
    let errors = ValidateFields(this);
    console.log("Error exist of Not ", errors);

    if (errors) {
      return false;
    }
    this.setState({ isSubmitLoading: true });

    this.handleSubmitForm(param);
  };

  handleParam = (e, paramKey, directValue = false) => {
    let value = e;
    if (!directValue)
      if (e.target != null && e.target.value != undefined)
        value = e.target.value;

    let paramKeyError = paramKey + "ErrorStatus";

    console.log("<handlePrama> Paramkey -->> Event ", [paramKey + "Value"], value);

    this.setState((prevState, props) => ({
      ...prevState,
      [paramKey + "Value"]: value,
      [paramKeyError]: false,
    }));

    let isExist = isErrorExist(this.state);
    if (this.state.isError && !isExist) this.setState({ isError: false });
  };

  handleFileClose = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: false,
    });
  };

  handleFileSave = (param, files) => {
    console.log("Current File List :: ", param, files[0]);
    this.setState({
      ...this.state,
      [param + "Value"]: files[0],
      [param + "MediaOpen"]: false,
    });
  };

  handleFileOpen = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: true,
    });
  };

  filesizes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  }

  InputChange = (e, btnid) => {
    console.log("<InputChanges> print event and btnId", e.target.files.length, e, btnid);
    // --For Multiple File Input
    let images = [];
    for (let i = 0; i < e.target.files.length; i++) {
      images.push(e.target.files[i]);
      let reader = new FileReader();
      let file = e.target.files[i];
      console.log("<InputChanges> file name ", file.name);

      reader.onloadend = () => {
        if (btnid === 1) {
          this.setState(prevState => ({
            pitch_deckValue: file,
            selectedfile1: [
              {
                id: shortid.generate(),
                filename: file?.name,
                filetype: file?.type,
                fileimage: reader?.result,
                datetime: file?.lastModifiedDate?.toLocaleString('en-IN'),
                filesize: this.filesizes(file?.size)
              }
            ]
          }));
        } else if (btnid === 2) {
          this.setState(prevState => ({
            financial_statements: file,
            selectedfile2: [
              {
                id: shortid.generate(),
                filename: file?.name,
                filetype: file?.type,
                fileimage: reader?.result,
                datetime: file?.lastModifiedDate?.toLocaleString('en-IN'),
                filesize: this.filesizes(file?.size)
              }
            ]
          }));
        }
      }
      if (file) {
        reader.readAsDataURL(file);
      }
    }
  }

  DeleteSelectFile = (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      this.setState(prevState => ({
        selectedfile1: prevState.selectedfile1.filter(data => data.id !== id),
      }));
    } else {
      // alert('No');
    }
  }


  FileUploadSubmit = async (e) => {
    e.preventDefault();

    // form reset on submit 
    e.target.reset();

    if (this.state.selectedfile1.length > 0) {
      this.setState(prevState => ({
        Files1: [
          ...prevState.Files1,
          ...prevState.selectedfile1
        ],
        selectedfile1: []
      }));

    } else {
      alert('Please select file')
    }
  }

  DeleteFile = async (id) => {
    if (window.confirm("Are you sure you want to delete this Image?")) {
      this.setState(prevState => ({
        Files1: prevState.Files1.filter(data => data.id !== id),
      }));

    } else {
      // alert('No');
    }
  }

  /// upload functions end
  handleDeleteDialogDocument = (value, tableMeta, updateValue) => {
    this.setState({ deleteDialogMemberDocument: true });
  };

  handleCloseMemberDocument = () => {
    this.setState({ deleteDialogMemberDocument: false });
  };

  handleCloseCoFounder = () => {
    this.setState({ deleteDialogCoFounder: false });
  };

  handleDeleteDialogMember = (value, tableMeta, updateValue) => {
    this.setState({ deleteDialogCoFounder: true });
  };

  setStateVariables = (
    param,
    validation = { checkEmpty: false },
    type = "TextField",
    value = "",
    error = false
  ) => {
    let paramLabel = param + "Label";
    let paramID = param + "ID";
    let paramPlaceHolder = param + "PlaceHolder";
    let paramValue = param + "Value";
    let paramErrorStatus = param + "ErrorStatus";
    let paramErrorMsg = param + "ErrorMsg";
    let paramFieldType = param + "FieldType";
    let paramFieldValidation = param + "FieldValidation";

    let transLabel = "CommonField." + param + "Label";
    let transPlaceHolder = "CommonField." + param + "PlaceHolder";
    let transErrorMsg = "CommonField." + param + "ErrorMsg]";
    console.log("trans Lable:: ", <Trans i18nKey={transLabel}></Trans>)
    console.log("trans placeholder:: ", <Trans i18nKey={transPlaceHolder}></Trans>)
    this.setState({
      [paramLabel]: <Trans i18nKey={transLabel}></Trans>,
      [paramID]: param,
      [paramPlaceHolder]: <Trans i18nKey={transPlaceHolder}></Trans>,
      [paramValue]: value,
      [paramErrorStatus]: error,
      [paramErrorMsg]: <Trans i18nKey={transErrorMsg}></Trans>,
      [paramFieldType]: type,
      [paramFieldValidation]: validation,
    });
  };
}
