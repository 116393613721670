
import API, { API_Param } from "../API";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";

export{
    getBlogCommentsAPI,
    getTreeAPI,
    postBlogCommentsAPI
}


async function getBlogCommentsAPI(blog_Id) {
    let blog_Comments = [];
    let token = localStorage.getItem("id_token");
  
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    try {
      let response = await API.get("comment");
      if (response.status == 200) {
        console.log("Response from  API :: ", response.data);
        response.data.response.Comments.map((comment) => {
          if(comment.blog_id === blog_Id && comment.parent_id === null){
            blog_Comments.push(comment)
          }
        })
        return blog_Comments;
      } else {
        console.log("API Request Failed With Status :: ", response.status);
        return blog_Comments;
      }
    } catch (error) {
      console.log("API Request Failed With Status :: ", error);
      toast.error("Error : " + error);
      return false;
    }
  }
  
async function getTreeAPI(comment_Id) {
    let token = localStorage.getItem("id_token");
    let tree = [];
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    try {
      let response = await API.get("comment/tree/"+comment_Id);
      if (response.status == 200) {
        console.log("Response from  API :: ", response.data);
        tree.push(response.data.tree);
        return tree;
      } else {
        console.log("API Request Failed With Status :: ", response.status);
        return true;
      }
    } catch (error) {
      console.log("API Request Failed With Status :: ", error);
      toast.error("Error : " + error);
      return false;
    }
  }
  
async function postBlogCommentsAPI(icomment,idescription,iuser_id,iparent_id,iblog_id) {
    let token = localStorage.getItem("id_token");
    let param = (iparent_id==="")?{
      comment:icomment,
      description:idescription,
      user_id:iuser_id,
      blog_id:iblog_id
    }:{
      comment:icomment,
      description:idescription,
      user_id:iuser_id,
      parent_id:iparent_id,
      blog_id:iblog_id
    };
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    try {
      let response = await API.post("comment",param);
      if (response.status == 200) {
        console.log("Response from  API :: ", response.data);
        return response.data.response;
      } else {
        console.log("API Request Failed With Status :: ", response.status);
      }
    } catch (error) {
      console.log("API Request Failed With Status :: ", error);
      toast.error("Error : " + error);
      return false;
    }
  }
  