import React, { Component } from 'react';
import { HashRouter, Route, Switch, Redirect, Link } from "react-router-dom";
// import OwlCarousel from 'react-owl-carousel';  
// import 'owl.carousel/dist/assets/owl.carousel.css';  
// import 'owl.carousel/dist/assets/owl.theme.default.css'; 
import Slider_1 from "./..//assets/images/slider/Slider_Banner_1.jpg";
import Slider_2 from "./..//assets/images/slider/Slider_Banner_2.jpg";
import Slider_3 from "./..//assets/images/slider/Slider_Banner_3.jpg";
import ReactDOM from 'react-dom';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
function Slider(props) {

  function onChange() {

  }

  function onClickItem() {

  }
  function onClickThumb() {

  }


  return (
    <div>
      <div className='' >
        <div className="hero_slider_container" style={{marginTop: props.marginManager===true ? "-16.9%" : "-1.5%"}}>
          {/* <OwlCarousel 
                    items={1} className="owl-theme hero_slider" 
                    dots={false} margin={0} loop autoPlay={true} nav>  */}
          <Carousel showArrows={true} infiniteLoop={true} >
            <div className="hero_slide">
              <div className="hero_slide_background">
                <img src={Slider_1} />
              </div>
              <div className="hero_slide_container  align-items-left ">
                <div className="hero_slide_content cls-slider-1">
                  {/* <h1>Welcome to  <span>StartupBasket</span></h1> */}
                  <div className="hero-content-text text-left">
                    {/* Dedicated marketplace to meet every need of startups<br/> */}
                    {/* Basket of services to meet every need of a startup<br/>
                        One stop solution for every startup */}
                  </div>
                  {/* <Link to="/services" className="hero-content-button" >Know More</Link> */}
                </div>
              </div>
            </div>
          </Carousel>
        </div>
      </div>
    </div>
  );

}

export default Slider;
