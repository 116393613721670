import React from 'react';

interface AppointmentProps {
  isSelected: boolean;
  isReserved?: boolean;
  isEnabled: boolean;
  periods?: number;
  time: string;
  selectAppointment: () => void;
}

const Appointment = ({
  isSelected = false,
  isReserved,
  isEnabled,
  periods,
  time,
  selectAppointment
}: AppointmentProps) => {
  const handleClick = () => {
    !isReserved && selectAppointment();
  };

  const className =
    'appointment' +
    (isSelected ? ' appointment--selected' : '') +
    (!isSelected && isEnabled && !isReserved ? ' appointment--enabled' : '') +
    (isReserved ? ' appointment--reserved' : '');
  const style = {
    height: `calc(2rem*${periods || 1} + 0.2rem*(${periods || 1} - 1))`
  };
  let timeStr = ('0' + time).slice(-11) ;
  let timeStr1 = timeStr.slice(0,5) ;
  let timeStr2 = timeStr.slice(-3) ;
  // let formatedTime =  (timeStr.length==11)?'0' +timeStr.slice(0,5):'0' +timeStr.slice(0,4);
  return (
    <div style={style} className={className} onClick={handleClick}>
      <span className=''>{timeStr1 + timeStr2}</span>
    </div>
  );
};

export { Appointment };
