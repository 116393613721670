import React, { Fragment } from "react";
import {
  Link,
  useHistory,
} from "react-router-dom";


// MEDIA FILES
import Hero_IMG1 from "../../../assets/images/earth-globe.svg";
import Hero_IMG2 from "../../../assets/images/books.svg";
import Hero_IMG3 from "../../../assets/images/professor.svg";

function HeroBoxes() {
  return (
    <Fragment>
        { /* Hero Boxes */ }
        {/* <div className="hero_boxes">
          <div className="hero_boxes_inner">
            <div className="container">
              <div className="row">

                <div className="col-lg-4 d-none d-lg-block hero_box_col" >
                  <div className="hero_box d-flex flex-row align-items-center justify-content-start">
                    <img src={Hero_IMG1} className="svg" alt="" />
                    <div className="hero_box_content">
                      <h2 className="hero_box_title">Our Services</h2>
                      <Link to="/services" className="hero_box_link">view more</Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 d-none d-lg-block hero_box_col" >
                  <div className="hero_box d-flex flex-row align-items-center justify-content-start">
                    <img src={Hero_IMG2} className="svg" alt="" />
                    <div className="hero_box_content">
                      <h2 className="hero_box_title">Our Startups</h2>
                      <Link to="/startups" className="hero_box_link">view more</Link>
                    </div>
                  </div>
                </div>

                <div className="col-lg-4 d-none d-lg-block hero_box_col" >
                  <div className="hero_box d-flex flex-row align-items-center justify-content-start">
                    <img src={Hero_IMG3} className="svg" alt="" />
                    <div className="hero_box_content">
                      <h2 className="hero_box_title">Our Partners</h2>
                      <Link to="/members" className="hero_box_link">view more</Link>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </div> */}

    </Fragment>
  );
}

export default HeroBoxes;
