import React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "relative",
  },
  bottom: {
    color: "#88BDBC",
  },
  top: {
    color: "#254E58",
    animation: "$colorChange 1s infinite alternate",
    animationDuration: "550ms",
    position: "absolute",
    left: 0
  },
  progressTxt: {
    color: "#254E58",
    animation: "$colorChange 1s infinite alternate",
    animationDuration: "250ms",
    marginBottom:"25px"
  },
  circle: {
    strokeLinecap: "round"
  },
  "@keyframes colorChange": {
    "0%": {
      color: "#254E58",
    },
    "25%": {
      color: "#88BDBC",
    },
    "75%": {
      color: "#88BDBC1A",
    },
  },
}));
function CircularProgressWithLabel(props) {
  const classes = useStyles();
  return (
    <>
    <div className="cls-loader" >
       <CircularProgress
        variant="determinate"
        className={classes.bottom}
        size="150px"
        thickness={2}
        value={100}
        />
      <CircularProgress
        variant="indeterminate"
        className={classes.top}
        classes={{
          circle: classes.circle
        }}
        size="150px"
        thickness={2}
        value={25}
      />
      {/* 
      <Box
        position="relative"
        display="inline-flex"
        alignItems="center"
        justifyContent="center"
      >
        <CircularProgress variant="determinate" {...props} />
        <Box
          top={0}
          left={0}
          bottom={0}
          right={0}
          position="absolute"
          display="flex"
          alignItems="center"
          justifyContent="center"
        >
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            style={{fontSize:"20px"}}
          >{`${Math.round(props.value)}%`}</Typography>
        </Box>
      </Box> */}
    </div>
    <Box
        sx={{
          top: -220,
          left: 0,
          bottom: 0,
          right: 0,
          position: 'absolute',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography className={`${classes.progressTxt}`} variant="h3" component="div">
          {`${props.value.toFixed(2)}Ms`}
        </Typography>
      </Box>
    </>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   */
  value: PropTypes.number.isRequired,
};

export default function CircularStatic(props) {
  const [progress, setProgress] = React.useState(0);

  React.useEffect(() => {
    const timer = setInterval(() => {
      setProgress((prevProgress) => (prevProgress + 0.10));
    }, 100);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}
