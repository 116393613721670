import React, { Fragment, useEffect, useState } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
  useHistory,
  useParams
} from "react-router-dom";

import PageSideBar from "../../../components/PageSidebar";
import ReactPlayer from 'react-player';
import { Tab, Tabs, Typography } from '@material-ui/core';
import MUIDataTable from "mui-datatables";
import Footer from "../../../components/footer";
import Header from "../../../components/header";


// MEDIA FILES
import milestone_1 from "../../../assets/images/milestone_1.svg";
import milestone_2 from "../../../assets/images/milestone_2.svg";
import milestone_3 from "../../../assets/images/milestone_3.svg";
import milestone_4 from "../../../assets/images/milestone_4.svg";

import IconHighlight01 from "../../../assets/images/milestone_1.svg";
import IconHighlight02 from "../../../assets/images/milestone_2.svg";
import IconHighlight03 from "../../../assets/images/milestone_3.svg";
import IconHighlight04 from "../../../assets/images/milestone_4.svg";

import ServiceImage from "../../../assets/images/User-Profile/User-1.jpg";
import Img01 from "../../../assets/images/User-Profile/User-1.jpg"
import Img02 from "../../../assets/images/User-Profile/User-2.jpg"
import Img03 from "../../../assets/images/User-Profile/User-3.jpg"
import Img04 from "../../../assets/images/User-Profile/User-4.jpg"
import imgBackgroundMilestones from "../../../assets/images/milestones_background.jpg";
import IconsService01 from "../../../assets/images/icons/icon-money-01.svg"
import IconsService02 from "../../../assets/images/icons/icon-money-02.svg"
import IconsService03 from "../../../assets/images/icons/icon-money-03.svg"
import IconsService04 from "../../../assets/images/icons/icon-money-04.svg"

import ProfileIcon from "@material-ui/icons/Person";
import CompanyIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/GroupAdd";
import DocumentsIcon from "@material-ui/icons/NoteAdd";

// FOR APIS
import {
  getStartupAPI,
  getCoFoundersAPI,
  getStartupDocumentAPI
} from "../../../APIs/startupApis";

import {
  getImageAPI,
} from "../../../APIs/ApiUtils";
import { createMarkup } from "../../../helpers";


function Homepage() {
  const history = useHistory();
  const params = useParams();

  const [startupInfo, setStartupInfo] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [profileThumbnail, setProfileThumbnail] = useState(undefined);
  const [cofoundersInfo, SetCofoundersInfo] = useState(undefined);
  const [documentsInfo, SetDocumentsInfo] = useState(undefined);

  useEffect(() => {
    window.scrollTo(0, 0);
    let isLoggedIn = localStorage.getItem("id_token");
    if (!isLoggedIn)
      history.push("/login");
    else {
      handleGetStartup();
      handleGetCoFounders();
      handleGetStartupDocument();
    }

  }, []);


  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <Fragment>
      <div className="single-page about-page">
        <Header />
      <div className="home innerpage">
      <div className="home_background_container prlx_parent">
          <div className="home_background prlx" >
                {/* <div
                  style={{
                    height: "450px",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <div
                    style={{
                      height: "90px",
                      width: "290px",
                      backgroundColor: "yellow",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <h1
                      style={{
                        color: "white",
                        fontWeight: "bold",
                        fontSize: 58,
                      }}
                    >
                      About Us
                    </h1>
                  </div>
                </div> */}
              </div>
          </div>
        <div className="home_content">
          <h1>{ startupInfo?.companyname } </h1>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col-12 p-1">
            <div className="container single-startup">
              {/* <!-- ======= About Section ======= --> */}
              <section id="about" className="about-mf sect-pt4 route">
                <div className="container">
                  <div className="row">
                    <div className="col-sm-12">
                      <div className="box-shadow-full">
                        <div className="row">
                          <div className="col-md-5">
                            <div className="row">
                              {/* <div className="col-12">
                          <h2 className="mb-5"><i className="fa fa-quote-left text-primary pr-3"></i>{"  "}<i>{(startupInfo != undefined && startupInfo.tagline) ? startupInfo.tagline : "This is a Tagline"}</i>{"  "}<i className="fa fa-quote-right text-primary ml-3 align-right"></i></h2>
                        </div> */}
                              <div className="col-12">
                                <ReactPlayer className="react-player" url='https://www.youtube.com/watch?v=ysz5S6PUM-U' />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-7">
                            <div className="about-me pt-4 pt-md-0">
                              <div className="title-box-2">
                                <div className="row">
                                  <div className="col">
                                    <div className="section_title text-center">
                                      <h1>Highlights</h1>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <p className="text-dark" dangerouslySetInnerHTML={createMarkup(startupInfo?.aboutcompany)} ></p>

                              {/* <ul className="highlight-list">
                          <li>
                            <p className="text-dark">
                              <img src={IconHighlight01} className="list-icon" /> Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Curabitur arcu erat, accumsan id
                              imperdiet.
                            </p>
                          </li>
                          <li>
                            <p className="text-dark">
                              <img src={IconHighlight02} className="list-icon" /> Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Curabitur arcu erat, accumsan id
                              imperdiet.
                            </p>
                          </li>
                          <li>
                            <p className="text-dark">
                              <img src={IconHighlight03} className="list-icon" /> Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Curabitur arcu erat, accumsan id
                              imperdiet.
                            </p>
                          </li>
                          <li>
                            <p className="text-dark">
                              <img src={IconHighlight04} className="list-icon" /> Curabitur non nulla sit amet nisl tempus convallis quis ac lectus. Curabitur arcu erat, accumsan id
                              imperdiet.
                            </p>
                          </li>
                        </ul> */}



                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </section>

              <section >
                <Tabs
                  value={tabIndex}
                  onChange={handleChange}
                  variant="scrollable"
                  scrollButtons="auto"
                  aria-label="scrollable auto tabs example"
                >
                  <Tab style={{ color: (tabIndex == 0) ? '#88BDBC' : "#aaaaaa" }} icon={<ProfileIcon />} label="Overview" />
                  <Tab style={{ color: (tabIndex == 1) ? '#88BDBC' : "#aaaaaa" }} icon={<CompanyIcon />} iconPosition="start" label="Company Details" />
                  <Tab style={{ color: (tabIndex == 2) ? '#88BDBC' : "#aaaaaa" }} icon={<PersonIcon />} iconPosition="end" label="Co-Founder/Team" />
                  {/* <Tab style={{ color: (tabIndex == 3) ? '#88BDBC' : "#aaaaaa" }} icon={<DocumentsIcon />} iconPosition="bottom" label="Reports & Feedback" /> */}
                </Tabs>
                <div className="profile-tab mt-5">
                  <div
                    role="tabpanel"
                    hidden={tabIndex !== 0}
                    id={`simple-tabpanel-0`}
                    aria-labelledby={`simple-tab-0`}
                  >
                    <>
                      <div className="bg-white p-3">
                        <div className="row">
                          <div className="col-md-3">
                            <div className="row">
                              <div className="col-sm-12 col-md-12">
                                <div className="about-img">
                                  <img src={profileThumbnail} className="rounded b-shadow-a" alt="" />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-9">
                            <div className="row">
                              <div className="col-md-4">
                                <label>Company Name</label>
                              </div>
                              <div className="col-md-8">
                                <p>{ startupInfo?.companyname}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <label>Contact Name</label>
                              </div>
                              <div className="col-md-8">
                                <p>{ startupInfo?.contactname}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <label>Contact Email</label>
                              </div>
                              <div className="col-md-8">
                                <p>{ startupInfo?.contactemail}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <label>URL</label>
                              </div>
                              <div className="col-md-8">
                                <a href={startupInfo?.producturl}><p>{ startupInfo?.producturl}</p></a>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <label>Address</label>
                              </div>
                              <div className="col-md-8">
                                <p>{ startupInfo?.address_id}</p>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-4">
                                <label>About Company</label>
                              </div>
                              <div className="col-md-8">
                                <p dangerouslySetInnerHTML={createMarkup( startupInfo?.aboutcompany)}></p>
                              </div>
                            </div>
                          </div>
                        </div>
                        {/* <div className="mt-5 mb-5">

                          <div className="skill-mf">
                            <h2 className="text-dark mt-5">Financials</h2>
                            <div className="row mt-5">
                              <div className="col-md-4">
                                <span>Revenue</span> <span className="pull-right">$0</span>
                                <div className="progress">
                                  <div className="progress-bar" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <span> Net Loss </span> <span className="pull-right">$-200</span>
                                <div className="progress">
                                  <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <span> Short Term Debt </span> <span className="pull-right">$0</span>
                                <div className="progress">
                                  <div className="progress-bar" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <span> Raised in 2022 </span> <span className="pull-right">$5645</span>
                                <div className="progress">
                                  <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </div>

                              <div className="col-md-4">
                                <span> Cash on Hand </span> <span className="pull-right">$0</span>
                                <div className="progress">
                                  <div className="progress-bar" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </div>
                              <div className="col-md-4">
                                <span>  Debt Ratio:  </span> <span className="pull-right">43%</span>
                                <div className="progress">
                                  <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div> */}
                      </div>
                    </>
                  </div>
                  <div
                    role="tabpanel"
                    hidden={tabIndex !== 1}
                    id={`simple-tabpanel-1`}
                    aria-labelledby={`simple-tab-1`}
                  >
                    <>
                      <div className="bg-white p-3">
                        <div className="row">
                          <div className="col-md-4">
                            <label>Company Name</label>
                          </div>
                          <div className="col-md-8">
                            <p>{ startupInfo?.companyname}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label>About Compnay</label>
                          </div>
                          <div className="col-md-8">
                            <p>{ startupInfo?.aboutcompany}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label>Operating Country</label>
                          </div>
                          <div className="col-md-8">
                            <p>{ startupInfo?.operatingin}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label>URL</label>
                          </div>
                          <div className="col-md-8">
                            <p>{ startupInfo?.producturl}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-4">
                            <label>Incorporation date</label>
                          </div>
                          <div className="col-md-8">
                            <p>{ startupInfo?.incorporationdate}</p>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <label>Registration Address</label><br />
                            <p>{ startupInfo?.address_id}</p>
                          </div>
                        </div>
                      </div>
                    </>
                  </div>
                  <div
                    role="tabpanel"
                    hidden={tabIndex !== 2}
                    id={`simple-tabpanel-2`}
                    aria-labelledby={`simple-tab-2`}
                  >
                    <>
                      <div className="bg-white p-3">
                        <MUIDataTable
                          title="Co-Founders"
                          data={cofoundersInfo}
                          columns={[
                            {
                              name: "id",
                              options: {
                                filter: false,
                                sort: false,
                                empty: true,
                                display: "excluded",
                              },
                            },
                            {
                              name: "name",
                              label: "Name",
                            },
                            {
                              name: "designation",
                              label: "Designation",
                            },
                            {
                              name: "partnershippercentage",
                              label: "Equity",
                            },
                            {
                              name: "aboutcofounder",
                              label: "About",
                            }
                          ]}
                          options={{
                            tableBodyMaxHeight: "400px",
                            selectableRows: false,
                            filter: false,
                            print: false,
                            download: false,
                            elevation: 0,
                            fixedHeader: true,
                            filter: false,
                            filterType: "dropdown",
                            responsive: "standard",
                            expandableRows: false,
                          }}
                        // components={components}
                        />

                      </div>
                    </>
                  </div>
                  {/* <div
                    role="tabpanel"
                    hidden={tabIndex !== 3}
                    id={`simple-tabpanel-3`}
                    aria-labelledby={`simple-tab-3`}
                  >
                    <>
                      <div className="bg-white p-3">

                        <MUIDataTable
                          title="Uploaded Documents"
                          data={documentsInfo}
                          columns={[
                            {
                              name: "id",
                              options: {
                                filter: false,
                                sort: false,
                                empty: true,
                                display: "excluded",
                              },
                            },
                            {
                              name: "title",
                              label: "Title",
                            },
                            {
                              name: "description",
                              label: "Description",
                            },
                          ]}
                          options={{
                            tableBodyMaxHeight: "400px",
                            selectableRows: false,
                            fixedHeader: true,
                            filter: false,
                            print: false,
                            download: false,
                            elevation: 0,
                            filterType: "dropdown",
                            responsive: "standard",
                            expandableRows: false,
                          }}
                        />
                      </div>
                    </>
                  </div> */}
                </div>
              </section>

              {/* <section id="services" className="services-mf pt-5 route">
          <div className="container">
            <div className="row">
              <div className="col">
                <div className="section_title text-center">
                  <h1>Testimonials</h1>
                </div>
              </div>
            </div>
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="carousel">
                    <div className="testimonial">
                      <div className="pic">
                        <img src={Img01} alt="" />
                      </div>
                      <div className="testimonial-content">
                        <p className="description">
                          Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam amet animi blanditiis consequatur debitis dicta distinctio, enim error eum iste libero modi nam natus perferendis possimus quasi sint sit tempora voluptatem. Est, exercitationem id ipsa ipsum laboriosam perferendis temporibus!
                        </p>
                        <h3 className="testimonial-title">Williamson
                          <small className="type">President of XYZ</small>
                          <small className="post">Investor</small>
                        </h3>
                      </div>
                    </div>
                    <div className="testimonial">
                      <div className="pic">
                        <img src={Img01} alt="" />
                      </div>
                      <div className="testimonial-content">
                        <p className="description">
                          Lorem ipsum dolor sit amet, consectetur adipisicing elit. A aliquam amet animi blanditiis consequatur debitis dicta distinctio, enim error eum iste libero modi nam natus perferendis possimus quasi sint sit tempora voluptatem. Est, exercitationem id ipsa ipsum laboriosam perferendis temporibus!
                        </p>
                        <h3 className="testimonial-title">kristiana
                          <small className="type">CEO of XYZ</small>
                          <small className="post">Partner</small>
                        </h3>
                      </div>
                    </div>
                  </div>
                  <div className="row text-center">
                    <div className="col-12">
                      <div onClick={() => { }} className="button button_primary trans_200" >
                        <span className="p-2" >Show More</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

              {/* <!-- ======= Services Section ======= --> */}
              {/* <section id="services" className="services-mf pt-5 route">
          <div className="container">
            <div className="row">
              <div className="col-sm-12">
                <div className="title-box text-center">
                  <div className="row">
                    <div className="col">
                      <div className="section_title text-center">
                        <h1>Key Information</h1>
                      </div>
                    </div>
                  </div>
                  <p className="subtitle-a">
                    Lorem ipsum, dolor sit amet consectetur adipisicing elit.
                  </p>
                  <div className="line-mf"></div>
                </div>
              </div>
            </div>
            <div className="row single-service ">
              <div className="row single-service-listing">
                <div className="col-lg-12 ">
                  <div className="row service-related-study">
                    <div className="col-lg-3 ">
                      <div className="card">
                        <img className="card-icon-top" src={IconsService01} alt="https://unsplash.com/@kellybrito" />
                        <div className="card-body pb-2">
                          <div className="card-title text-center"><a href="#">Information 01</a></div>
                          <div className="card-text">n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n het...</div>
                          <button className="button button_primary" onClick={() => {} } type="button" value="Submit"><span>View</span></button>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-3 ">
                      <div className="card">
                        <img className="card-icon-top" src={IconsService02} alt="https://unsplash.com/@kellybrito" />
                        <div className="card-body pb-2">
                          <div className="card-title text-center"><a href="#">Information 02</a></div>
                          <div className="card-text">n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n het...</div>
                        </div>
                        <button className="button button_primary " onClick={() => {} } type="button" value="Submit">
                                <span>View</span>
                                    </button>  
                      </div>

                    </div>
                    <div className="col-lg-3 ">
                      <div className="card">
                        <img className="card-icon-top" src={IconsService03} alt="https://unsplash.com/@kellybrito" />
                        <div className="card-body pb-2">
                          <div className="card-title text-center"><a href="#">Information 03</a></div>
                          <div className="card-text">n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n het...</div>
                        </div>
                        <button className="button button_primary " onClick={() => {} } type="button" value="Submit">
                                <span>View</span>
                                    </button>   
                      </div>

                    </div>
                    <div className="col-lg-3 ">
                      <div className="card">
                        <img className="card-icon-top" src={IconsService04} alt="https://unsplash.com/@kellybrito" />
                        <div className="card-body pb-2">
                          <div className="card-title text-center"><a href="#">Information 04</a></div>
                          <div className="card-text">n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n his igitur partibus duabus nihil erat, quod Zeno commutare gestiret n het...</div>
                        </div>
                        <button className="button button_primary " onClick={() => {} } type="button" value="Submit">
                                <span>View</span>
                                    </button>   
                      </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}

            </div>
          </div>
        </div>
      </div>
      <Footer />
      </div>
    </Fragment>

  );


  function createMarkup(iData) {
    return {
      __html: (iData != undefined) ? iData : '',
    }
  }

  async function handleGetCoFounders() {
    let RC = false;
    setIsFormLoading(true);
    let startup_id = params.startupId;
    let cofounders = await getCoFoundersAPI(startup_id);
    if (cofounders != undefined) {
      console.log("Co-Founders Found Successfully", cofounders);
      SetCofoundersInfo(cofounders);
      setIsFormLoading(false);
    } else {
      console.log("Co-Founders Not found");
      setIsFormLoading(false);
    }
  };

  async function handleGetStartupDocument() {
    let RC = false;
    setIsFormLoading(true);
    let startup_id = params.startupId;
    let documents = await getStartupDocumentAPI(startup_id);
    if (documents != undefined) {
      console.log("Documents Found Successfully", documents);
      SetDocumentsInfo(documents);
      setIsFormLoading(false);
    } else {
      console.log("Startup Document Not found");
      setIsFormLoading(false);
    }
  };


  async function handleGetStartup() {
    let RC = false;
    setIsFormLoading(true);
    let startup_id = params.startupId;
    let startup = await getStartupAPI(startup_id);
    if (startup != undefined) {
      console.log("<handleGetStartups> Startups Found Successfully ::", startup);
      setStartupInfo(startup);
      setIsFormLoading(false);
      setProfileThumbnail(startup['profilethumbnail']);
    } else {
      console.log("<handleGetStartups> Startup Not found");
      setIsFormLoading(false);
    }
  }
}

export default Homepage;
