import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";

import Footer from "../../components/footer";
import Header from "../../components/header";

import Faq from "react-faq-component";

// APIs
import {
	getFAQsAPI
  } from "../../APIs/faqsApis";

const data = {
  rows: [
    {
      title: "What is StartupBasket",
      content:
        "Team StartupBasket comprises of Individuals who learn from ethnic & rural communities & device ideas & strategies which these communities consider the way forward & go by their definitions of development in sync with ancient ethnic Indian learning systems, which the colonialists made every effort to dismantle. These ethnic communities have been living in forests & rural settings since time immemorial & have acquired skills to coexist with other life forms, be it plant life or animal life. Every global environment conference ends with unanimously agreeing to live alongside trees, animals, fresh air & water. However, this isn’t easy for modern urban dwellers surrounded by machines & devices & instant, readymade, polluting & decadent life styles. Hence, living with nature is a life style skill that we need to learn from forest dwelling & farming communities. StartupBasket does not believe much in shifting rural brothers & sisters to urban areas where they end up living in slums & are never able to comprehend nor enjoy the fruits of urban dwellings. Modern education & mass media have confused them into believing that their forest & farm lives are not smart & savvy enough for the progressive modern world hence trying to make them believe in ideas which they never believed in all these years. However, at StartupBasket we ensure that rural communities learn modern technical & business skills being in villages or forest environments & improve their livelihood levels adequately to be able to live healthy & dignified lives within their villages.",
    },
    {
      title: "How do you ensure village enterprise projects are successful ?",
      content:
        "We organise intensive training & hand holding support for new business entrepreneurs. The sessions include long active listening sessions where in, we listen to the apprehensions & reservations of budding rural entrepreneurs. Following which we, clarify business concepts & processes & create tailor-made  work arounds & solutions. We also involve the women & young girls of the household to be a part of the entire venture depending upon their skills, abilities, interests & aptitude. Every rural entrepreneur we support is assigned a successful.",
    },
    {
      title: "Besides donating is there any other way can I associate ?",
      content:
        "Yes. Depending upon your interest & aptitude you can choose projects to work upon remotely or with us on the ground. The epicentre of our operations is Bhopal. Bhopal is directly connected via air from Mumbai & Delhi. Many trains from South to North India run via Bhopal.",
    },
    {
      title: "Can I meet the children & families that I am donating for?",
      content:
        "Yes. We can organise virtual meetings & one on one meetings when you visit us",
    },
    {
      title: "Why have you chosen Madhya Pradesh to start this Project ?",
      content:
        "Madhya Pradesh is a Central Province of India . Strategically, creating partnerships & networks is easier being in the centre & then spreading out to different parts of the country . Besides that MP has the largest tribal population in India  of about 10.3 million , which need much attention & effective  grass roots campaigns to change the present unfortunate circumstances they are made to live in. ",
    },
    {
      title: "Why is enhancing Rural Enterprise your prime focus area?",
      content:
        "We all wish to ensure that children have safe & bright futures & focus on their education & health but most NGOs miss the point that this can be achieved when the entire family can fulfil its basic needs & not just the children living in it, which is possible by making families financially independent.This is where you can pitch in & become Hit Rakshaks ( Patrons ) & personally connect with rural families with entrepreneurial  zeal that are trying to do everything to ensure lives of dignity & respect for themselves.",
    },
    {
      title: "Which Rural enterprises does StartupBasket primarily focus on ?",
      content: (
        <div>
          <p>
            Though we do not follow a hard straight jacket approach, as
            different geographies & communities have different preferences &
            expertises. However, the following are the common ventures in almost
            all communities :
          </p>
          <ul>
            <li>Cow rearing</li>
            <li>Buffalo rearing</li>
            <li>Grocery Store</li>
            <li>Goat rearing</li>
            <li>Poultry Farming</li>
            <li>Bangle Shop </li>
            <li>Tailoring Shops </li>
            <li>Flour Mill (atta Chakki)</li>
            <li>Vegetable Shop </li>
            <li>Fishery </li>
            <li>Carpentry</li>
            <li>Puncture Repair shop</li>
            <li>Brick manufacturing unit</li>
            <li>Taxi</li>
            <li>Welding shop</li>
            <li>Construction work</li>
          </ul>
          <p>
            These ventures are finalised after long in-depth discussion with
            local communities & successful business leaders from rural & urban
            areas.
          </p>
        </div>
      ),
    },
    {
      title:
        "How are individuals and families selected for rural entrepreneurship projects?",
      content:
        "Although all the below poverty line families need support but we identify family members who have discipline, motivation and zeal to succeed in order to ensure for themselves lives of respect and dignity. ",
    },
  ],
};

const styles = {
  bgColor: "transparent",
  titleTextColor: "#F88253",
  rowTitleColor: "#F88253",
  // titleTextSize: '48px',
  // rowTitleTextSize: 'medium',
  // rowContentColor: 'grey',
  rowContentTextSize: "17px",
  rowContentPaddingTop: "20px",
  rowContentPaddingBottom: "25px",
  rowContentPaddingLeft: "15px",
  // rowContentPaddingRight: '150px',
  // arrowColor: "red",
  //transitionDuration: "1s",
  // timingFunc: "ease"
};

const config = {
  animate: true,
  // arrowIcon: "V",
  tabFocus: true,
};

function ContactUs() {
	const [FAQs, setFAQs] = useState(undefined);
	const [isFormLoading, setIsFormLoading] = useState(false);
	
	useEffect(() => {
		handleGetFAQs();
	}, []);
  
	async function handleGetFAQs() {
	  let RC = false;
	  setIsFormLoading(true);
	  let FAQs = await getFAQsAPI();
	  if (FAQs != undefined ) {
      console.log("General settings found successfully ::", FAQs);
      let tmpFAQs = [];
      FAQs.map((row, index) => {
        tmpFAQs.push({data: row.question, content: row.answer});
      });
      setFAQs(FAQs);
      setIsFormLoading(false);
	  } else {
      console.log("General settings Not found");
      setIsFormLoading(false);
	  }
	}

  return (
    <Fragment>
      <div className="single-page about-page">
        <Header />

        <div className="page-header">
          <div className="container">
            <div className="row">
              <div className="col-12">
                <h1>FAQs</h1>
              </div>
            </div>
          </div>
        </div>

        <div className="welcome-wrap">
          <div className="container">
            <div className="row">
              <div className="col-12 col-lg-6 order-2 order-lg-1">
                <div className="welcome-content">
                  <header className="entry-header">
                    <h2 className="entry-title">How it Works/FAQs</h2>
                  </header>

                  <div className="entry-content mt-5">
                    <p></p>

                    <p></p>
                  </div>
                </div>
              </div>

              <div className="col-12 col-lg-6 order-1 order-lg-2">
                {/* <img src={welcome} alt="welcome" /> */}
              </div>
            </div>
            <p>
              <div>
                <Faq data={data} styles={styles} config={config} />
              </div>
            </p>
          </div>
        </div>
        <div className="help-us">
          <div className="container">
            <div className="row">
              <div className="col-12 d-flex flex-wrap justify-content-between align-items-center">
                <h2>Help us so we can help others</h2>

                <Link className="btn orange-border" to="/Members">
                  Donate now
                </Link>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </div>
    </Fragment>
  );
}

export default ContactUs;
