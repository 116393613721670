import React, { Fragment, useState, useEffect } from "react";

import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
  useHistory,
  useParams
} from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";

import logo from "../../assets/images/logo.png";

import Footer from "../../components/footer";
import Header from "../../components/header";
import { ToastContainer, toast } from "react-toastify";

import ReactPlayer from "react-player";
import {
  Grid,
  CircularProgress,
  TextField,
  Fade,
  FormControl,
  InputLabel,
  Typography,
  Input,
} from "@material-ui/core";

import { getStartupAPI } from "../../APIs/startupApis";

import { getImageAPI } from "../../APIs/ApiUtils";

import { createOrderAPI, updateOrderAPI, createPaymentAPI } from "../../APIs/paymentApis";

import { addDonationAPI } from "../../APIs/donorApis";
import { DateTimePicker } from "@material-ui/pickers";
import queryString from "query-string";
import {useLocation} from 'react-router-dom';
import { FormatListBulletedRounded, FormatListBulletedSharp } from "@material-ui/icons";
import {
  getMembershipCharges,
  getCouponDiscount
} from "../../APIs/ApiUtils";

import {getLocalDateTimeString, currencySymbol} from "../../helpers";


function Homepage(props) {

  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  

  useEffect(() => {
    window.scrollTo(0, 0);

    handleGetStartup();
    // handleOrderCreation();
    setMembershipInfo(getMembershipCharges());
    setCouponInfo(getCouponDiscount());
    
  }, []);

  const [startupID, setStartupID] = useState(5);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [startupInfo, setStartupInfo] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [deleteDialog, deleteDialogUI] = useState(false);
  const [membershipInfo, setMembershipInfo] = useState(undefined);
  const [couponInfo, setCouponInfo] = useState(undefined);

  const [membershipType, setMembershipType] = useState("");
  const [membershipMethod, setMembershipMethod] = useState(undefined);
  const [paymentID, setPaymentID] = useState(undefined);
  const [orderID, setOrderID] = useState(undefined);
  const [orderInfo, setOrderInfo] = useState(undefined);
  const [paymentInfo, setPaymentInfo] = useState(undefined);
  

  function handleClose() {
    deleteDialogUI(false);
  }
  function handleProfileView() {
    deleteDialogUI(true);
  }
  return (
    <>
        {/* <!-- Home --> */}
        <div className="home innerpage">
        <div className="home_background_container prlx_parent">
            <div className="home_background prlx" ></div>
        </div>
        <div className="home_content">
            <h1>Membership</h1>
        </div>
        </div>
      {isFormLoading ? (
        <div className={{ alignContents: "center" }}>
          <CircularProgress variant="indeterminate" value={75} />
        </div>
      ) : (
        <Fragment>
          <div className="single-page">
            <Header />
            <div>
              <div className="container">
                <div className="main-body mb-5">
                  <div className="row">
                    <div className="col-lg-9">
                    <div className="cls-membership-page ">
                    <div className="row">
                      <div className="profile-nav col-md-3">
                          <div className="panel">
                              <div className="user-heading round text-center">
                                  <a href="#">
                                      <img src={startupInfo.profilethumbnail} alt="" />
                                  </a>
                                  <h2 className="text-dark">{startupInfo.companyname}</h2>
                                  <p className="text-center">{startupInfo.contactname}</p>
                              </div>


                              <ul className="nav nav-pills nav-stacked">
                                  <li className="active "><Link to={"/profile/startup/view/" + startupInfo.id}> <i className="fa fa-user"></i> Profile</Link></li>
                                  <li className="ml-3"><Link to={"/profile/startup/edit/" + startupInfo.id}> <i className="fa fa-edit"></i> Edit profile</Link></li>
                              </ul>
                          </div>
                      </div>
                      <div className="profile-info col-md-9">
                          <div className="panel">
                              <div className="bio-graph-heading">
                                  Aliquam ac magna metus. Nam sed arcu non tellus fringilla fringilla ut vel ispum. Aliquam ac magna metus.
                              </div>
                              <div className="panel-body bio-graph-info mt-2">
                                  <h1>Profile Info</h1>
                                  <div className="row">
                                      <div className="bio-row">
                                          <div><small>Company Name:</small></div> <p>{startupInfo.companyname}</p>
                                      </div>
                                      <div className="bio-row">
                                          <div><small>Contact Name:</small> </div>  <p>{startupInfo.contactname}</p>
                                      </div>
                                      <div className="bio-row">
                                          <div><small>Origin:</small> </div> <p>{startupInfo.operatingin}</p>
                                      </div>
                                      <div className="bio-row">
                                          <div><small>Incorporation Date:</small></div> <p>{startupInfo.incorporationdate}</p>
                                      </div>
                                      <div className="bio-row">
                                          <div><small>Category:</small></div> <p>{startupInfo.operatingin}</p>
                                      </div>
                                      <div className="bio-row">
                                          <div><small>Email:</small></div> <p>{startupInfo.contactemail}</p>
                                      </div>
                                      <div className="bio-row">
                                          <div><small>Mobile:</small></div> <p>{startupInfo.contactphone}</p>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div>
                    </div>
                    </div>


                    </div>                    
                    <div className="col-lg-3">
                      <div className="card position-sticky top-0">
                        <div className="p-3 bg-light bg-opacity-10">
                          <h6 className="card-title mb-3">Order Summary</h6>
                          <div className="d-flex justify-content-between mb-1 small">
                            <span>Subtotal</span> <span>{currencySymbol() + membershipInfo?.amount}</span>
                          </div>
                          <div className="d-flex justify-content-between mb-1 small">
                            <span>Coupon (Code: NEWUSER)</span> <span className="text-danger">-{currencySymbol() + couponInfo?.amount}</span>
                          </div>
                          <div className="d-flex justify-content-between mb-4 small">
                            <span>TOTAL</span> <strong className="text-dark">{currencySymbol() + (membershipInfo?.amount - couponInfo?.amount) }</strong>
                          </div>
                          <div className="form-check mb-1 small">
                            <input className="form-check-input" type="checkbox" value="" id="tnc" />
                            <label className="form-check-label" for="tnc">
                              I agree to the <a href="#">terms and conditions</a>
                            </label>
                          </div>
                          <div className="form-check mb-3 small">
                            <input className="form-check-input" type="checkbox" value="" id="subscribe" />
                            <label className="form-check-label" for="subscribe">
                              Get emails about product updates and events. If you change your mind, you can unsubscribe at any time. <a href="#">Privacy Policy</a>
                            </label>
                          </div>
                          {isSubmitLoading ? (
                            <div className={{ alignContents: "center" }}>
                              <CircularProgress
                                variant="indeterminate"
                                value={75}
                              />
                              Payment Under Progress
                            </div>
                          ) : (
                            <button onClick={displayRazorpay} className="btn button_primary w-100 mt-2">Proceed With Payment</button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>
            <Footer />
          </div>
        </Fragment>
      )}
    </>
  );

  async function handlePaymentBtn() {}

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function handleAddMembership(order_id, payment_id) {
    let RC = false;

    let data = {
      status: "SUCCESS",
    };
    console.log("Order Id :: ", order_id);

    let orderInfo = await updateOrderAPI(order_id, data);
    if (orderInfo != undefined) {
      console.log(
        "Membership updated Successfully in response",
        orderInfo
      );
      setIsSubmitLoading(false);
      //toast.success("Thanks for Membership payment");
      // PUSH TO PAYMENT SUCCESS
      history.push("/membership/paymentstatus/" + startupID + "/"+membershipInfo?.amount+"/"+payment_id+"/"+order_id+"/"+Date());
    } else {
      console.log("Membership Not found");
    }
  }

  async function displayRazorpay() {

    setIsFormLoading(true);

    let dataOrder = {
      amount: membershipInfo?.amount,
      user_id: localStorage.getItem("persona_id"),
      user_type:localStorage.getItem("role"),
      currency: "INR",
      type: "EXTERNAL"
    };

    // creating a new order
    const result = await createOrderAPI(dataOrder);
    console.log("<INFO> Response of addNewOrder", result["order"]);
    if (!result) {
      alert("Payment Gateway error. Are you online?");
      setIsFormLoading(false);

      return;
    }
    else{
      setOrderInfo(result["order"]);
      setIsFormLoading(false);

    }

    let order_id = result["order"]["id"];
    setOrderID(order_id);

    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Payment Gateway failed to load. Are you online?");
      return;
    }
    let queries = queryString.parse(history.location.search);

    // let membershipInfo = queries.membershipInfo;
    // let membershipType = queries.membershipType;

    let membershipType = "";

    setMembershipType(membershipType);

    let data = {
      receipt: "order_startup_" + startupID,
      amount: membershipInfo?.amount * 100,
      currency: membershipInfo?.currency,
    };

    const options = {
      key: "rzp_test_te58r0UFlw7ZyG", // Enter the Key ID generated from the Dashboard
      amount: data.amount.toString(),
      currency: data.currency,
      name: "StartupBasket",
      description: "Membership Access",
      image: { logo },
    //   order_id: order_id,
      handler: async function (response) {
        console.log("<INFO> Response payment ID", response.razorpay_payment_id);
        console.log("<INFO> Response ORDER ID", response.razorpay_order_id);
        console.log(
          "<INFO> Response subscription ID",
          response.razorpay_signature
        );
        setPaymentID(response.razorpay_payment_id);

        const data = {
          user_id: localStorage.getItem("persona_id"),
          amount: membershipInfo.amount,
          taxes: 10,
          charges: 5,
          transaction_id: response.razorpay_payment_id,
          order_id: order_id,
          status:'SUCCESS'
          // razorpayPaymentId: response.razorpay_payment_id,
          // razorpayOrderId: response.razorpay_order_id,
          // razorpaySignature: response.razorpay_signature,
        };
        setIsSubmitLoading(true);

        const result = await createPaymentAPI(data);
        if (result) {
          setPaymentInfo(result);
          handleAddMembership(order_id, response.razorpay_payment_id);
        } else {
          console.log("Create Payment API Failed");
        }
      },
      prefill: {
        name: localStorage.getItem("user_name"),
        email: localStorage.getItem("user_email"),
        contact: localStorage.getItem("user_phone"),
      },
      notes: {
        address: "E-2/63, Arera Colony, Bhopal, INDIA",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  async function handleGetStartup() {
    let RC = false;
    setStartupInfo({});
    setIsFormLoading(true);
    let startup_id = params.startupId ; 
    let membershipType = "";

    let startupInfo = await getStartupAPI(startup_id);
    if (startupInfo != undefined) {
      console.log(
        "Startup Found Successfully in response",
        JSON.stringify(startupInfo)
      );

      let param = {
        filePath: startupInfo["profileimage"],
        fileName: "profileimage",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);


      setStartupID(startup_id);
      setMembershipType(membershipType);
      setStartupInfo(startupInfo);
      setProfilePicture(blob);
      setIsFormLoading(false);

    } else {
      setIsFormLoading(false);

      console.log("Startup Not found");
    }
  }

  async function handleOrderCreation() {

    setIsFormLoading(true);

    let data = {
      amount: membershipInfo?.amount * 100,
      user_id: localStorage.getItem("persona_id"),
      user_type:localStorage.getItem("role"),
      currency: "INR",
    };

    // creating a new order
    const result = await createOrderAPI(data);
    console.log("<INFO> Response of addNewOrder", result["order"]);
    if (!result) {
      alert("Payment Gateway error. Are you online?");
      setIsFormLoading(false);

      return;
    }
    else{
      setOrderInfo(result["order"]);
      setIsFormLoading(false);

    }

    let order_id = result["order"]["id"];
    setOrderID(order_id);
    setIsFormLoading(false);

  }


}

export default Homepage;
