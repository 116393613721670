import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
// import Moment from 'react-moment';
import moment from "moment";

import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";

// Picker
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import { makeStyles } from "@material-ui/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveIcon from "@material-ui/icons/Remove";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import axios from "axios";
import API, { API_Param } from "../../API";

import {
  deleteDonorAPI,
  getDonorsAPI,
  getDonorAPI,
  createDonorAPI,
  editDonorAPI,
  getOrdersAPI,
} from "../../APIs/donorApis";

import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
  getImageAPI,
} from "../../APIs/ApiUtils";

import userimage from "./../../assets/images/userimage.png";

import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";

// COMPONENTS
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { Trans } from "react-i18next";
import i18n from "../../locales/locales_config";
import { BluetoothDisabledRounded } from "@material-ui/icons";

// FOR APIS
import {
  getStartupBookingsAPI,
} from "../../APIs/startupApis";



const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = makeStyles((theme) => ({
  tableOverflow: {
    overflow: "auto",
  },
}));

class EditDonor extends Component {

  constructor() {
    super();
    this.state = {
      donorInfo: [],
      orderList: undefined,
      isSubmitLoading: false,
      isError: false,
      profilePicture: "",
    };
  }

  render() {
    const { t } = this.props;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: 20,
      }),
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 200,
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      },
    };

    return (
      <>
        {this.state.isFormLoading ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={2}>
              <Grid item xs={12} className={{ justifyContent: "center" }}>
                <Paper style={{ padding: 16 }}>
                  <MUIDataTable
                    title="Orders"
                    data={this.state.orderList}
                    columns={[
                      {
                        name: "id",
                        options: {
                          filter: false,
                          sort: false,
                          empty: true,
                          display: "excluded",
                        },
                      },
                      {
                        name: "created_at",
                        label: "When",
                        options: {
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return <div>{value}</div>;
                          },
                        },
                      },
                      {
                        name: "amount",
                        label: "Amount",
                      },
                      {
                        name: "status",
                        label: "Status",
                      },

                      {
                        name: "Actions",
                        options: {
                          filter: false,

                          sort: false,
                          empty: true,
                          customBodyRender: (value, tableMeta, updateValue) => {
                            return this.customActionRenderDocument(
                              value,
                              tableMeta,
                              updateValue
                            );
                          },
                        },
                      },
                    ]}
                    options={{
                      tableBodyMaxHeight: "400px",
                      selectableRows: false,
                      fixedHeader: true,
                      filter: false,
                      print: false,
                      download: false,
                      elevation: 0,
                      filterType: "dropdown",
                      responsive: "standard",
                      expandableRows: false,
                    }}
                    // components={components}
                  />
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );







    
  }

  componentDidMount() {
    this.setStateVariables("name", {
      checkEmpty: true,
      minLength: 2,
      maxLength: 20,
    });
    // this.setStateVariables("married", { checkEmpty: false }, "RadioField");

    this.setStateVariables("phone", {
      checkEmpty: true,
      minLength: 2,
      maxLength: 20,
    });
    this.setStateVariables("alt_phone", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 20,
    });
    this.setStateVariables("email", {
      checkEmpty: true,
      minLength: 0,
      // maxLength: 12,
    });
    this.setStateVariables("alt_emailid", {
      checkEmpty: false,
      // minLength: 0,
      // maxLength: 12,
    });

    this.setStateVariables("address", {
      checkEmpty: false,
      minLength: 0,
      maxLength: 150,
    });
    this.setStateVariables("city", { checkEmpty: false }, "SelectField");
    this.setStateVariables("state", { checkEmpty: false }, "SelectField");
    this.setStateVariables("country", { checkEmpty: false }, "SelectField");
    this.setStateVariables(
      "pincode",
      { checkEmpty: false, maxLength: 20 },
      "NumberField"
    );

    this.setStateVariables("gender", { checkEmpty: false }, "RadioField");

    this.setStateVariables("age", { checkEmpty: false });
    this.setStateVariables(
      "dob",
      { checkEmpty: true, minAge: 18, maxAge: 100 },
      "DateField"
    );

    this.setStateVariables("profile_info", { checkEmpty: false });

    this.setStateVariables("profileimage", { checkEmpty: false }, "FileField");
    this.setStateVariables("status", { checkEmpty: true }, "SelectField");

    this.setStateVariables("occupation", {
      checkEmpty: false,

      maxLength: 20,
    });
    this.setStateVariables("worked_at", {
      checkEmpty: false,

      maxLength: 20,
    });
    this.handleGetBooking();
  }

  setStateVariables = (
    param,
    validation = { checkEmpty: false },
    type = "TextField",
    value = "",
    error = false
  ) => {
    let paramLabel = param + "Label";
    let paramID = param + "ID";
    let paramPlaceHolder = param + "PlaceHolder";
    let paramValue = param + "Value";
    let paramErrorStatus = param + "ErrorStatus";
    let paramErrorMsg = param + "ErrorMsg";
    let paramFieldType = param + "FieldType";
    let paramFieldValidation = param + "FieldValidation";

    let transLabel = "CommonField." + param + "Label";
    let transPlaceHolder = "CommonField." + param + "PlaceHolder";
    let transErrorMsg = "CommonField." + param + "ErrorMsg]";

    this.setState({
      [paramLabel]: <Trans i18nKey={transLabel}></Trans>,
      [paramID]: param,
      [paramPlaceHolder]: <Trans i18nKey={transPlaceHolder}></Trans>,
      [paramValue]: value,
      [paramErrorStatus]: error,
      [paramErrorMsg]: <Trans i18nKey={transErrorMsg}></Trans>,
      [paramFieldType]: type,
      [paramFieldValidation]: validation,
    });
  };

  handleGetBooking = async () => {
    let RC = false;
    let persona_id = localStorage.getItem("persona_id");
    let param = { startup_id: persona_id, persona_type : "startup" };

    this.setState({ isFormLoading: true, orderList: {} });
    let orderList = await getStartupBookingsAPI(param);
    if (orderList != undefined) {
      console.log("Booking Found Successfully", orderList);
      this.setState({
        isFormLoading: false,
        orderList: orderList,
      });
    } else {
      console.log("Orders Not found");
      this.setState({ isFormLoading: false });
    }
  };


  handleSubmit = () => {
    let param = {};

    // GET THE VALUES ONLY
    Object.entries(this.state).map(([key, value]) => {
      if (key.includes("Value")) param[key.replace("Value", "")] = value;
    });

    console.log("<INFO> Param Value ", param);
    let errors = ValidateFields(this);
    console.log("Error exist of Not ", errors);

    if (errors) {
      return false;
    }
    this.setState({ isSubmitLoading: true });

    this.handleEditDonor(param);
  };

  handleParam = (e, paramKey, directValue = false) => {
    let value = e;
    if (!directValue)
      if (e.target != null && e.target.value != undefined)
        value = e.target.value;

    let paramKeyError = paramKey + "ErrorStatus";

    this.setState((prevState, props) => ({
      ...prevState,
      [paramKey + "Value"]: value,
      [paramKeyError]: false,
    }));

    let isExist = isErrorExist(this.state);
  };

  handleViewBooking = (value, tableMeta, updateValue) => {
    this.props.history.push("/profile/startup/order/" + tableMeta.rowData[0]
    );
  };

  customActionRenderDocument = (value, tableMeta, updateValue) => {
    return (
      <Grid container spacing={4}>
        <Grid item xs={6}>
          <IconButton
            color="primary"
            aria-label="upload picture"
            onClick={() =>
              this.handleViewBooking(value, tableMeta, updateValue)
            }
          >
            <Tooltip title="View Booking" aria-label="add">
              <Visibility />
            </Tooltip>
          </IconButton>
        </Grid>
      </Grid>
    );
  };
}

export default EditDonor;
