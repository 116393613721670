import React from "react";
import { Link } from "react-router-dom";
import Img01 from "../../assets/images/User-Profile/User-1.jpg"
import { getLocaleShortDateString, trimString } from "./../../helpers";


export default function CStartup(props) {
    return (
        <div className="cls-card-wapper">
            <div className="row">
                <div className="col-xs-12 text-center">
                    <div className="cls-logo-wrap">
                        <img className="img-circle" src={props.data.profilethumbnail} alt="..." />
                    </div>
                    <div className="text-title my-2">{props.data.companyname}</div>
                </div>
                <div className="col-xs-12 text-center">
                    {/* <div className="text-caption">{props.data.contactname}&nbsp;<small >{"(" + props.data.contactemail + ")"}</small></div> */}
                    {/* <p className="fs-mini text-muted">
                        <time>Started At - {props.data.incorporationdate}</time> &nbsp;
                        <i className="fa fa-map-marker text-danger"></i> &nbsp; {props.data.operatingin}
                    </p> */}
                </div>
                <hr className="text-center"></hr>
                <div className="text-text text-center m2-3">
                    {trimString(props.data.aboutcompany, 50)}
                </div>
                <Link to={"/startup/" + props.data.id} className="button button_primary  btn-sm text-center">
                    <span className="fa"> 
                    Profile 
                    </span>
                </Link>
                {/* <div className="row">
                    <div className="col-xs-12 text-center mb-2">
                        <ul className="tags text-dark ">
                            {(props.data.tags.map(tag => {
                                return <li><span>{tag.name}</span></li>
                            }))}
                        </ul>
                    </div>
                </div> */}

            </div>
        </div>
    )
}
