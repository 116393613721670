import React, {useEffect} from "react";
import {
  useHistory,
  useParams
} from "react-router-dom";

import { withStyles, useTheme } from "@material-ui/core/styles";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import InboxIcon from "@material-ui/icons/MoveToInbox";
import DraftsIcon from "@material-ui/icons/Drafts";
import SendIcon from "@material-ui/icons/Send";
import LogoutIcon from '@material-ui/icons/ExitToApp';
import iconAccount from "./../../assets/images/icons/icon-account.svg";

const StyledMenu = withStyles({
  paper: {
    border: "1px solid #d3d4d5",
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "center",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "center",
    }}
    {...props}
  />
));

const StyledMenuItem = withStyles((theme) => ({
  root: {
    "&:focus": {
      backgroundColor: theme.palette.primary.main,
      "& .MuiListItemIcon-root, & .MuiListItemText-primary": {
        color: theme.palette.common.white,
      },
    },
  },
}))(MenuItem);

export default function CustomizedMenus(props) {
  const history = useHistory();
  const params = useParams();
  var theme = useTheme();
  const [anchorEl, setAnchorEl] = React.useState(null);

  useEffect(() => {
    let id_token = localStorage.getItem("id_token");
    let user_role = localStorage.getItem("user_role");
    let persona_id = localStorage.getItem("persona_id")

    if (!id_token || !user_role || !persona_id  ) {
      props.handleLogoutUser();
    }
  }, []);

  const handleClick = (event) => {
    let id_token = localStorage.getItem("id_token");
    if (!id_token  ) {
      history.entries = [];
      history.index = -1;
      history.push("/login",{});
    } else {
      setAnchorEl(event.currentTarget);
    }
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDashboardClick = () => {
    history.push("/profile/" + localStorage.getItem("user_role").toLowerCase() + "/dashboard/" + localStorage.getItem("persona_id").toLowerCase());
    // (localStorage.getItem("user_role")==="STARTUP")?"/profile/viewstartupprofile":"/profile/investor/view/:investorId"
    // switch(localStorage.getItem("user_role")){
    //   case "STARTUP" : return history.push("/profile/startup/dashboard/:startupId")
    //   case "INVESTOR" : return history.push("/profile/investor/dashboard/:investorId")
    //   case "PARTNER" : return history.push("/profile/member/dashboard/:memberId")
    // }
  }

  const handleOrdersClick = () => { 
    history.push("/profile/" + localStorage.getItem("user_role").toLowerCase() + "/orders/" + localStorage.getItem("persona_id").toLowerCase());
   
    // switch(localStorage.getItem("user_role")){
    //   case "STARTUP" : return history.push("/profile/viewstartup/orders/:startupId")
    //   case "INVESTOR" : return history.push("/profile/viewinvestor/order/:investorIds")
    //   case "PARTNER" : return history.push("/profile/viewmember/orders/:memberId")
    // }

  }

  const handleProfileClick = () => {

    let user_role = localStorage.getItem("user_role").toLowerCase();
    let persona_id = localStorage.getItem("persona_id").toLowerCase();

    history.push("/profile/" + user_role + "/view/" + persona_id);
    
    // switch(localStorage.getItem("user_role")){
    //   case "STARTUP" : return history.push("/profile/viewstartupprofile")
    //   case "INVESTOR" : return history.push("/profile/investor/view/:investorId")
    //   case "PARTNER" : return history.push("/profile/member/view/:memberId")
    // }
    // (localStorage.getItem("user_role")==="STARTUP")?:history.push("/profile/investor/view/:investorId");
  }

  return (
    <div>

    <a onClick={handleClick}>
      <div className="header_side d-flex flex-row justify-content-center align-items-center">
        <img src={iconAccount} alt="" />
        <span>Account</span>
      </div>
    </a>

      {/* <Button
        aria-controls="customized-menu"
        aria-haspopup="true"
        variant="contained"
        color="primary"
        onClick={handleClick}  
        style={{border:0}}      
      >
        <VerifiedUserOutlined />
        Account
      </Button> */}
      <StyledMenu
        id="customized-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {/* <StyledMenuItem onClick={handleDashboardClick}>
          <i className={"fa fa-tachometer text-primary"} style={{paddingRight: 10}} />
          <ListItemText primary="Dashboard" />
        </StyledMenuItem> */}
        <StyledMenuItem onClick={handleProfileClick}>
          <i className={"fa fa-user text-primary"} style={{paddingRight: 10}} />
          <ListItemText primary="Profile" />
        </StyledMenuItem>
        <StyledMenuItem onClick={handleOrdersClick}>
          <i className={"fa fa-handshake-o text-primary"} style={{paddingRight: 10}} />
          <ListItemText primary="Bookings" />
        </StyledMenuItem>
        <StyledMenuItem onClick={props.handleLogoutUser}>
          <i className={"fa fa-times-circle text-primary"} style={{paddingRight: 10}} />
          <ListItemText primary="logout" />
        </StyledMenuItem>
      </StyledMenu>
    </div>
  );
}
