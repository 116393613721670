import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";
import { displayResponseError } from "./ApiUtils";
// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export {
  deleteMemberAPI,
  getMembersPaginationAPI,
  getMembersAPI,
  createMemberAPI,
  editMemberAPI,
  getMemberAPI,
  getMemberServicesAPI,
  setMemberServicesAPI,
  getSlotsAPI,
  getMemberBookingsAPI,
  getProfilesAPI
};

async function getProfilesAPI() {
  let profiles = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("profile");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);    
      response.data.response.Profiles.map((profile) => {
        let tmp = profile;        
        tmp['isChecked'] = false;   
        // tmp['label'] = profile["name"];   
        profiles.push(tmp);
      });      
      return profiles;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return profiles;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}


async function getMembersPaginationAPI(history, limit, page, iFeaturedOnly= false) {
  let members = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("member/pagination/" + limit + "/" + page, { params: {isFeatured: iFeaturedOnly} });
    if (response.status == 200 && response.data.status!="failed" ) {
      console.log("Response from  API :: ", response.data);
      response.data.response.members.map((member) => {
        let tmp = {};
        Object.entries(member).map(([key, value]) => {
          tmp[key] = value;
        });
        members.push(tmp);
      });
      return members;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      if(response.data.msg == "Profile not completed")
      {
        switch(localStorage.getItem("user_role")){
          case "STARTUP" : return history.push("/profile/startup/edit/:startupId")
          case "INVESTOR" : return history.push("/profile/investor/edit/:investorId")
          case "PARTNER" : return history.push("/profile/member/edit/:memberId")
        }
      }   
      return members;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}


async function deleteMemberAPI(history, id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("member/" + id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      //toast.success("Member Deleted Successfully");
      return true;
    } else {
      // DISPLAY RESPONSE WITH TOAST MESSAGES
      displayResponseError(response);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function createMemberAPI(param) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("member", param);
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Member Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getMembersAPI(history, iFeaturedOnly = false) {
  let members = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    let response = await API.get("member", {isFeatured: iFeaturedOnly});
    if (response.status == 200 && response.data.status!="failed" ) {
      console.log("Response from  API :: ", response.data);
      response.data.response.members.map((member) => {
        let tmp = {};
        Object.entries(member).map(([key, value]) => {
          tmp[key] = value;
        });
        members.push(tmp);
      });
      return members;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      if(response.data.msg == "Profile not completed")
      {
        switch(localStorage.getItem("user_role")){
          case "STARTUP" : history.push("/profile/editstartupdashboard")
          case "INVESTOR" : history.push("/profile/editinvestordashboard")
          case "PARTNER" : history.push("/profile/editmemberdashboard")
        }
      } 
      return members;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);

    return false;
  }
}

async function editMemberAPI(id_member, param) {
  let token = localStorage.getItem("id_token");
  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post(
      "member/" + id_member + "?_method=PUT",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      },
    );
    if (response != undefined) {
      if (response.status == 200 ) {
        console.log("Response from API :: ", response.data);
        //toast.success("Member Edited Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function downloadFileAPI(param) {
  let token = localStorage.getItem("id_token");
  console.log(param);

  // let formData = new FormData();

  // Object.entries(param).map(([key,value]) => {formData.append(key, value)});

  // console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("download", param, { responseType: "blob" });
    if (response != undefined) {
      console.log("Response from API :: ", response.data);
      if (response.status == 200 && response.data.status == "success") {
        // window.open('/download/'+id);
        //toast.success("Member Document downloaded Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getMemberAPI(id_member) {
  let member = undefined;
  let token = localStorage.getItem("id_token");
  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("member/" + id_member);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response);
      member = response.data.response.member;
      if (response.data.response.member[0] != undefined)
        member["member_id"] = response.data.response.member[0].id;
      return member;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return member;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getMemberServicesAPI(iParams) {
  let members = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    let response = await API.get("member/services/" + iParams.member_id);
    if (response.status == 200 && response.data.status!="failed" ) {
      console.log("Response from  API :: ", response.data);
      response.data.response.services.map((member) => {
        let tmp = {};
        Object.entries(member).map(([key, value]) => {
          tmp[key] = value;
        });
        members.push(tmp);
      });
      return members;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return members;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);

    return false;
  }
}

async function setMemberServicesAPI(iParams) {
  let members = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    let response = await API.post("member/services/"+iParams.member_id,iParams);
    if (response.status == 200 && response.data.status!="failed" ) {
        console.log("Response from API :: ", response.data);
        //toast.success("Member Services Added Successfully");
        return true;
    } else {
        displayResponseError(response);
        return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);

    return false;
  }
}


async function getSlotsAPI(iParams) {
  let slots = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    let response = await API.post("member/slot/" + iParams.member_id, iParams);
    if (response.status == 200 && response.data.status!="failed" ) {
      console.log("Response from  API :: ", response.data);
      response.data.response.slots.map((member) => {
        let tmp = {};
        Object.entries(member).map(([key, value]) => {
          tmp[key] = value;
        });
        slots.push(tmp);
      });
      return slots;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return slots;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getMemberBookingsAPI(iParams) {
  let members = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  try {
    let response = await API.get("member/bookings/" + iParams.member_id);
    if (response.status == 200) {
      if(response.data.status!="SUCCESS")
      {
        console.log("Response from  API :: ", response.data);        
        return response.data.response;
      } else {
        console.log("API Request Failed With Status :: ", response.status);
        return false;
      }
    } 
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error(error);
    return false;
  }
}

