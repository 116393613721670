import React, { Fragment, useState, useEffect } from "react";
import { HashRouter, Route, Switch, Redirect, Link, useHistory, useParams } from "react-router-dom";
import { Tab, Tabs, Box, Typography } from '@material-ui/core';

import ProfileIcon from "@material-ui/icons/Person";
import CompanyIcon from "@material-ui/icons/Business";
import PersonIcon from "@material-ui/icons/GroupAdd";
import DocumentsIcon from "@material-ui/icons/NoteAdd";


import Footer from "../../components/footer";
import Header from "../../components/header";
import PageSideBar from "../../components/PageSidebar.js";
import IMAGE_BG from "../../assets/images/User-Profile/User-1.jpg";

// CUSTOM COMPONENTS
import CircularStatic from '../../components/Controls/CircularProgressBar';

// FOR APIS
import {
  deleteInvestorAPI,
  getInvestorsAPI,
  getInvestorAPI,
} from "../../APIs/investorApis";

import {
  getImageAPI,
} from "../../APIs/ApiUtils";

import queryString from "query-string";

import {
  downloadFile,
  createMarkup
} from "../../helpers";


function Homepage() {
  window.scrollTo(0, 0);
  const history = useHistory();
  const params = useParams();
  const [investorInfo, SetInvestorInfo] = useState(undefined);
  const [profileImage, SetProfileImage] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);
  const [tabIndex, setTabIndex] = React.useState(0);

  useEffect(() => {
    handleGetInvestor();
  }, []);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center", }}>
          <CircularStatic value={25} />
        </div>
      ) : (
        <Fragment>
          <div className="container emp-profile">
            <form method="post">
              <div className="row">
                <div className="col-md-3">
                  <div className="profile-img">
                    <img src={(IMAGE_BG != undefined) ? IMAGE_BG : ''} alt="" />
                    {/* <div className="file btn btn-lg btn-primary">
                          Change Photo
                          <input type="file" name="file"/>
                      </div> */}
                  </div>
                </div>
                <div className="col-md-7">
                  <div className="profile-head">
                    <h5>
                      {(investorInfo != undefined) ? investorInfo.investorname : ''}
                    </h5>
                    <h6>
                      {(investorInfo != undefined) ? investorInfo.investoremail : ''}
                    </h6>

                  </div>

                </div>
                <div className="col-md-2">
                  <button onClick={() => { history.push("/profile/investor/edit/"+investorInfo.id); }} id="contact_send_btn" type="button" className="contact_send_btn trans_200" value="Submit">Update Info</button>
                </div>
              </div>
              <div className="row">
                <div className="col-md-3">
                  <p className="proile-rating">RANKINGS : <span>8/10</span></p>
                  <div className="profile-work">
                    <p>DOCUMENTS</p>
                    <div className="cls-doc-download mb-1" onClick={() => { downloadFile(investorInfo?.ndadoc, "NDA-Document") }}><span className="fa fa-cloud-download text-success"></span>{"  "}NDA Document</div>
                    <div className="cls-doc-download mb-1" onClick={() => { downloadFile(investorInfo?.businessplan, "Business-Plan") }}><span className="fa fa-cloud-download text-success"></span>{"  "}Business Plan</div>
                    <div className="cls-doc-download mb-1" onClick={() => { downloadFile(investorInfo?.contractdoc, "Contract-File") }}><span className="fa fa-cloud-download text-success"></span>{"  "}Contract Document</div>
                  </div>
                </div>
                <div className="col-md-9">
                  <Tabs
                    value={tabIndex}
                    onChange={handleChange}
                    aria-label="icon position tabs example"
                  >
                    <Tab style={{ color: '#88BDBC' }} icon={<ProfileIcon />} label="Profile Info" />
                    <Tab icon={<PersonIcon />} iconPosition="end" label="Co-Founder/Team" />
                    <Tab icon={<DocumentsIcon />} iconPosition="bottom" label="Documents" />
                  </Tabs>
                  <div className="profile-tab">
                    <div
                      role="tabpanel"
                      hidden={tabIndex !== 0}
                      id={`simple-tabpanel-0`}
                      aria-labelledby={`simple-tab-0`}
                    >
                      <Box p={1}>
                        <Typography>
                          <div className="row">
                            <div className="col-md-4">
                              <label>Contact Name</label>
                            </div>
                            <div className="col-md-8">
                              <p>{(investorInfo != undefined) ? investorInfo.investorname : ""}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <label>Contact Email</label>
                            </div>
                            <div className="col-md-8">
                              <p>{(investorInfo != undefined) ? investorInfo.investoremail : ""}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <label>Phone</label>
                            </div>
                            <div className="col-md-8">
                              <p>{(investorInfo != undefined) ? investorInfo.investorphone : ""}</p>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-4">
                              <label>About Founder</label>
                            </div>
                            <div className="col-md-8">
                              <p dangerouslySetInnerHTML={createMarkup(investorInfo?.investorsummary)}></p>
                            </div>
                          </div>
                        </Typography>
                      </Box>
                    </div>
                    <div
                      role="tabpanel"
                      hidden={tabIndex !== 1}
                      id={`simple-tabpanel-1`}
                      aria-labelledby={`simple-tab-1`}
                    >
                      <Box p={1}>
                        <Typography>
                          {"First"}
                        </Typography>
                      </Box>
                    </div>
                    <div
                      role="tabpanel"
                      hidden={tabIndex !== 2}
                      id={`simple-tabpanel-2`}
                      aria-labelledby={`simple-tab-2`}
                    >
                      <Box p={1}>
                        <Typography>
                          {"First"}
                        </Typography>
                      </Box>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </Fragment>
      )}
    </>
  );

  async function handleGetInvestor() {
    let RC = false;
    setIsFormLoading(true);

    let queries = queryString.parse(history.location.search);
    let page = queries.page != undefined ? queries.page : 1;
    let limit = queries.limit != undefined ? queries.limit : 9;
    // localStorage.setItem("CURRENT_MEMBER", 1);
    // let investorID = localStorage.getItem("CURRENT_MEMBER");
    let investor_id = params.itemId;

    let investorInfo = await getInvestorAPI(investor_id);
    if (investorInfo != undefined) {
      console.log("Investor Found Successfully ::");

      let param = {
        filePath: investorInfo["profileimageValue"],
        fileName: "profileimage",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);

      SetProfileImage(blob);
      SetInvestorInfo(investorInfo);
      setIsFormLoading(false);
      setPage(page);
      setLimit(limit);
    } else {
      console.log("Investor Not found");
      setIsFormLoading(false);
    }
  }

  async function handleForwardBtn(id, location) {
    let isLoggedIn = localStorage.getItem("id_token");

    let searchString = "Investor_id=" + id;
    let redirectString = searchString + "&location=" + location;

    console.log("<pathname> :: ", location);
    console.log("<search> :: ", searchString);

    if (isLoggedIn) {
      localStorage.setItem('CURRENT_Investor', id);
      history.push(location);
      // history.push({
      //   pathname: location,
      //   search: "?" + searchString,
      //   state: { Investor_id: id }
      // });
    } else
      history.push({
        pathname: "login",
        search: redirectString,
      });
  }

  function handleNextBtn() {
    let queries = queryString.parse(history.location.search);
    let page = (queries.page != undefined) ? queries.page : 1;
    let limit = (queries.limit != undefined) ? queries.limit : 9;
    page = +page + 1;
    let searchString = "?limit=" + limit + "&page=" + page;
    console.log("<search> :: ", searchString);
    console.log("<search> :: ", window.location.href);


    window.location.href = "http://localhost:3000/#/Investors?limit=" + limit + "&page=" + page
    window.location.reload();
    // + searchString;


    // history.replace({
    //   pathname: "/Investors",
    //   search: searchString,
    // });
    // <Redirect to={searchString} />;
  }

}

export default Homepage;
