import React, { Fragment, useRef, useState, useEffect } from "react";
import { useHistory, Link, useParams } from "react-router-dom";
import queryString from "query-string";
import Calendar from "../../components/Calender/calender";

// FOR APIS
import {
  getStartupBookingsAPI
} from "../../APIs/startupApis";

import {
  getLocaleShortDateString,
  currencySymbol
} from "../../helpers";

// CUSTOM COMPONENTS
import CircularStatic from '../../components/Controls/CircularProgressBar';

// The "main" component, our actual calendar
const Orders = () => {
  window.scrollTo(0, 0);

  const history = useHistory();
  const params = useParams();
  const [eventList, setEventList] = useState(undefined);

  const [orderList, setOrderList] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);

  const example1 = useRef(null);

  useEffect(() => {
 
    console.log("Date has changed... Let's load some fresh data")
    handleGetBooking();
  }, [])

  async function handleGetBooking() {
    let RC = false;

    let persona_id = params.itemId;
    let param = { startup_id: persona_id, persona_type: "startup" };
    setIsFormLoading(true);
    setOrderList(undefined);


    let orderArrs = await getStartupBookingsAPI(param);
    if (orderArrs != undefined) {
      console.log("Booking Found Successfully", orderArrs);


      let eventListTmp = [];

      orderArrs.forEach(booking => {
        eventListTmp.push(
          {
            id: booking.id,
            name: booking?.member.membername + ", " + booking?.appointment?.starttime.slice(0, 5) + " - " + booking?.appointment?.endtime.slice(0, 5),
            dateFrom: booking?.appointment?.date + 'T' + booking?.appointment?.starttime.slice(0, 5),
            dateTo: booking?.appointment?.date + 'T' + booking?.appointment?.endtime.slice(0, 5),
            meta: booking?.service?.title,
            type: booking.status,
            serviceID: booking?.service?.id,
            memberID: booking?.member?.id,
            // url:"/profile/startup/order/"+row.id
          });

        console.log("This is Event List ", eventListTmp);
      });
      setEventList(eventListTmp);
      setOrderList(orderArrs);
      setIsFormLoading(false);
    } else {
      console.log("Orders Not found");
      setIsFormLoading(false);
    }
  };


  return (
    <>
      <>
        {isFormLoading ? (
          <div className={{ alignContents: "center", }}>
            {/* <CircularProgress variant="indeterminate" value={75} /> */}
            <CircularStatic value={25} />
          </div>
        ) : (
          <div className="cls-service-bookings  cls-calender">
            <Calendar
              month={((new Date()).getMonth() + 1)}
              year={(new Date()).getFullYear()}
              preloadedEvents={eventList != undefined ? eventList : []} />

            <div className="container ">
              <div className="row">
                <div className="col-md-12">
                  <div className="card card-white mb-5">
                    <div className="card-heading clearfix border-bottom mb-4">
                      <h4 className="card-title">Booking Requests</h4>
                    </div>
                    <div className="card-body">
                      <div className="row">
                        {
                          orderList != undefined && orderList.map((row) => {
                            return (
                              <div className="col-md-6 mb-4">
                                <div className="media">
                                  <div className="msg-img">
                                    <img src={row?.member.profilethumbnail} alt="" />
                                  </div>
                                  <div className="media-body">
                                    <h3 className="mb-4 mt-2">{row?.member?.membername} <span className="badge badge-primary mx-3">Pending</span><span className="badge badge-danger">Unpaid</span></h3>
                                    <div className="mb-3">
                                      <span className="mr-2 d-block d-sm-inline-block mb-2 mb-sm-0">Booking Date:</span>
                                      <span className="bg-light-blue">{getLocaleShortDateString(row?.appointment?.date)}</span>
                                    </div>
                                    <div className="mb-3">
                                      <span className="mr-2 d-block d-sm-inline-block mb-2 mb-sm-0">Booking Date:</span>
                                      <span className="bg-light-blue">{"from " + row?.appointment.starttime.slice(0, 5) + " to " + row.appointment?.endtime.slice(0, 5)}</span>
                                    </div>
                                    <div className="mb-3">
                                      <span className="mr-2 d-block d-sm-inline-block mb-2 mb-sm-0 text-dark">Service Name:</span>
                                      <span className="bg-light-blue">{row?.service?.title}</span>
                                    </div>
                                    <div className="mb-3">
                                      <span className="mr-2 d-block d-sm-inline-block mb-2 mb-sm-0 text-dark">Price:</span>
                                      <span className="bg-light-blue">{currencySymbol() + row?.payment?.amount}</span>
                                    </div>

                                    <div className="mb-3">
                                      <span className="mr-2 d-block d-sm-inline-block mb-1 mb-sm-0 text-dark">Partner Contact Details:</span>
                                      <div className="border-right pr-2 mr-2">{row?.member?.memberphone}</div>
                                      <div className="border-right pr-2 mr-2">{row?.member?.memberemail}</div>
                                    </div>
                                    <Link to={"/profile/startup/order/" + row.id} className="button button_primary"><span>View Booking</span></Link>
                                  </div>
                                </div>
                                <div className="buttons-to-right">
                                  <a href="#" className="btn-gray mr-2"><i className="far fa-times-circle mr-2"></i> Reject</a>
                                  <a href="#" className="btn-gray"><i className="far fa-check-circle mr-2"></i> Approve</a>
                                </div>
                              </div>
                            )
                          })}
                        {
                          (orderList == undefined || orderList.length == 0) &&
                          <div className="text-secondary">No booking scehduled by you</div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </>
    </>


  )
}


export default Orders;


