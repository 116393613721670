import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "../API";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { displayResponseError } from "../APIs/ApiUtils";
import queryString from "query-string";

export {
  UserProvider,
  useUserState,
  useUserDispatch,
  loginUser,
  registerUser,
  signOut,
  isEmailExist,
  isPhoneExist,
  forgetPassword,
  verifyOTPs,
  resetPassword,
  resendOTPs
};

var UserStateContext = React.createContext();
var UserDispatchContext = React.createContext();

function userReducer(state, action) {
  switch (action.type) {
    case "LOGIN_SUCCESS":
      return { ...state, isAuthenticated: true };
    case "SIGN_OUT_SUCCESS":
      return { ...state, isAuthenticated: false };
    case "REGISTER_SUCCESS":
      return { ...state, isAuthenticated: false };
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function UserProvider({ children }) {
  var [state, dispatch] = React.useReducer(userReducer, {
    isAuthenticated: !!localStorage.getItem("id_token"),
  });

  return (
    <UserStateContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>
        {children}
      </UserDispatchContext.Provider>
    </UserStateContext.Provider>
  );
}

function useUserState() {
  var context = React.useContext(UserStateContext);
  if (context === undefined) {
    throw new Error("useUserState must be used within a UserProvider");
  }
  return context;
}

function useUserDispatch() {
  var context = React.useContext(UserDispatchContext);
  if (context === undefined) {
    throw new Error("useUserDispatch must be used within a UserProvider");
  }
  return context;
}

// ###########################################################

async function loginUser(
  dispatch,
  login,
  password,
  history,
  setIsLoading,
  setError,
  redirect
) {
  setError(false);
  setIsLoading(true);

  if (!!login && !!password) {
    let param = {
      email: login,
      password: password,
      role:"ADMIN"
    };
   
    try {
      let response = await API.post("/auth/login", param);
      console.log("API Request Failed With Status :: ", response);
      if (response.status == 200) {
        if(response.data.status == 'SUCCESS') {
            console.log("Response from Login API :: ", response.data);
            // let permissions = [];
            // let roles = [];
            // response.data.response.permission.map((permission) => {
            //   permissions.push(permission["name"]);
            // });
            // response.data.response.role.map((role) => {
            //   roles.push(role["name"]);
            // });
            // localStorage.setItem("user_roles", roles);
            localStorage.setItem("user_role", response.data.response.user.role);
            localStorage.setItem("id_token", response.data.response.token);
            localStorage.setItem("user_id",response.data.response.user.id);
            localStorage.setItem("user_name",response.data.response.user.name);
            localStorage.setItem("persona_id",response.data.response.user.personable_id);
            localStorage.setItem("user_email",response.data.response.user.email);
            localStorage.setItem("user_phone",response.data.response.user.phone);
            localStorage.setItem("role", response.data.response.user.role);
            // localStorage.setItem("IS_MEMBERSHIP_PAID", startupInfo.ismembershippaid);

            API_Param.token = response.data.response.token;
            //toast.success("User LoggedIn Successfully");
            setError(null);
            setIsLoading(false);
            dispatch({ type: "LOGIN_SUCCESS" });
            if(redirect==undefined)
              history.push("/profile/" + response.data.response.user.role + "/edit/" + response.data.response.user.personable_id);
            else 
              history.push(redirect);
        }  else {
          setError(true);
          setIsLoading(false);
          console.log("API Request Failed With Status :: ", response.status);
          toast.error(response.data.msg);
          if ( response.data.msg == "Account Not Activated"  ) {
            // response.data.response != undefined && (response.data.response.email_verified_at == null ||
            // response.data.response.email_verified_at == undefined            
          
            console.log("Account Not Activated :: ",response.data.response.email_verified_at);

            localStorage.setItem("user_email",response.data.response.user.email,);
            localStorage.setItem("user_phone",response.data.response.user.phone);
            localStorage.setItem("user_id", response.data.response.user.id);
            localStorage.setItem("token", response.data.response.token);
          }
        }
      }
    } catch (error) {
      console.log("API Request Failed With Status :: ", error);
      // dispatch({ type: "LOGIN_FAILURE" });
      toast.error(error);
      setError(true);
      setIsLoading(false);
      return false;
    }
  } else {
    dispatch({ type: "LOGIN_FAILURE" });
    setError(true);
    setIsLoading(false);
    return false;
  }
}

async function registerUser(
  dispatch,
  email,
  password,
  name,
  userType,
  phone,
  history,
  setIsLoading,
  setError
) {
  setError(false);
  setIsLoading(true);

  if (!!email && !!password && !!name) {
    let param = {
      name: name,
      email: email,
      password: password,
      role: userType,
      phone: phone,
    };
    try {
      let response = await API.post("/auth/register", param);
      if (response.status == 200) {        
        setIsLoading(false);  
        if (response.data.status == "SUCCESS") {
          localStorage.setItem("token", response.data.response.token);
          console.log("Response from Login API :: ",response.data.response.token);
          //toast.success("Registered Successfully, Please verify your email send to your email address");
          setError(null);        
          dispatch({ type: "REGISTER_SUCCESS" });
          return true;
        }
        else {
          setError(true);
          toast.error(response.data.msg);
          return false;
        }
      }
    } catch (error) {
      console.log("API Request Failed With Status :: ", error);
      toast.error(error);
      setError(true);
      setIsLoading(false);
      return false;
    }
  } else {
    setError(true);
    setIsLoading(false);
    return false;
  }
}

async function signOut(dispatch, history) {
  let token = localStorage.getItem("id_token");
  // localStorage.removeItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.get("auth/logout");
    if (response.status == 200) {
      if(response.data.status == 'SUCCESS') {      
        console.log("Response from Login API :: ", response.data);
        //toast.success("User logged out successfully");
        localStorage.removeItem("id_token");
        window.location.reload();
      } else {
        console.log("API Request Failed With Status :: ", response.status);
        toast.error(response.data.msg);
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error(error);
    localStorage.removeItem("id_token");
    window.location.reload();
  }
}

async function isEmailExist(email) {

  try{
    let response = await API.get("/auth/email/exist", {
      params: {
        email: email,
      },
    });

    if (response.status == 200)
    {
      console.log("Response from isEmail Exist API :: ", response.data);
      if(response.data.status == 'SUCCESS') {
        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error(error);
    return false;
  }
}

async function isPhoneExist(phone) {

  try
  {
    let response = await API.get("/auth/phone/exist/", {
      params: {
        phone: phone,
      },
    });
    if (response.status == 200)
    {
      console.log("Response from isPhone Exist API :: ", response.data);
      if(response.data.status == 'SUCCESS') {
        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error(error);
    return false;
  }
}



async function forgetPassword(
  dispatch,
  email,
  phone,
  history,
  setIsLoading,
  setError
) {
  let RC = false;

  let response = await API.get("/auth/email/exist", {
                                params: { email: email },
                              });
  if (response.status == 200)
  {
    console.log("Response from isEmail Exist API :: ", response.data);
    if(response.data.status == 'SUCCESS') {
      console.log("Email exist in database, lets send OTP");
      let response = await API.post("auth/forgetpassword",
       {
          email : email,
          // phone: phone,
        }
      );
      if (response.status == 200)
        console.log("Response from forgetpassword API :: ", response.data);
      else 
        console.log("API Request Failed With Status :: ", response.status);
      RC = true;
    } else {
      console.log("Email not exist, Please try with correct email address");
      toast.error("Email not exist, Please try with correct email address");
      return false;
    }
  }
  return RC;
}

async function verifyOTPs(
  dispatch,
  email,
  phone,
  emailOTP,
  phoneOTP,
  history,
  setIsLoading,
  setError
) {
  let RC = false;
  let token = localStorage.getItem("token");
  setIsLoading(true);

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.post("auth/email/verify", {
      email: email,
      phone: phone,
      user_email_otp: emailOTP,
      phone: phoneOTP,
    });
    if (response.status == 200) {
      console.log("Response from Exist API :: ", response.data);
      //toast.success("User Verified Successfully, Please login into your account");
      API.defaults.headers.common = { Authorization: "Bearer " + token };
      let response2 = await API.get("auth/logout");
      // let permissions = [];
      // let roles = [];
      // console.log("Response from Login API :: ", response.data);
      // response.data.response.permission.map((permission) => {
      //   permissions.push(permission["name"]);
      // });

      // response.data.response.role.map((role) => {
      //   roles.push(role["name"]);
      // });

      // localStorage.setItem("user_role", roles);
      // localStorage.setItem("id_token", response.data.response.token);
      // localStorage.setItem(
      //   "user_name",
      //   response.data.response.user.name,
      // );
      
      // localStorage.setItem("role", response.data.response.user.role);
      // localStorage.setItem("user_permission", permissions);
      // API_Param.token = response.data.response.token;
      setError(null);
      setIsLoading(false);
      history.push("/login");
      return true;
    } else {      
      // DISPLAY RESPONSE WITH TOAST MESSAGES
      setIsLoading(false);
      setError(true);
      displayResponseError(response);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    setIsLoading(false);
    toast.error(error);
    return false;
  }

  return RC;
}

async function resetPassword(
  dispatch,
  email,
  phone,
  emailOTP,
  phoneOTP,
  password,
  history,
  setIsLoading,
  setError
) {
  let RC = false;
  setIsLoading(true);
  try {
    let response = await API.post("auth/password/reset", {
      emailOTP,
      // phoneOTP,
      email: email,
      // phone: phone,
      password: password,
      confirm_password: password,
    });
    if (response.status == 200) {
      console.log("Response from Exist API :: ", response.data);
      //toast.success("Password Reset Successfully, Please login with new password");
      setIsLoading(false);      
      history.push('/login');      
      return true;
    } else {
      // DISPLAY RESPONSE WITH TOAST MESSAGES
      setIsLoading(false);
      displayResponseError(response);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    setIsLoading(false);
    return false;
  }
}

async function resendOTPs(
  dispatch,
  email,
  phone,
  history,
  setIsLoading,
  setError
) {
  let RC = false;
  let token = localStorage.getItem("token");
  let user_id = localStorage.getItem("user_id");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("auth/email/otp/" + user_id);
    if (response.status == 200) {
      console.log("Response from Exist API :: ", response.data);
      //toast.success("User OTP Resend Successfully");
      return true;
    } else {
      // DISPLAY RESPONSE WITH TOAST MESSAGES
      displayResponseError(response);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }

  return RC;
}


