import React from "react";
import { Link } from "react-router-dom";
import Img01 from "../../assets/images/User-Profile/User-1.jpg"
import { getLocaleShortDateString, trimString } from "./../../helpers";


export default function SingleMember(props) {
    return (
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center">
                    <div><img src={props.data.profilethumbnail} alt="" className="avatar-md rounded-circle img-thumbnail" /></div>
                    <div className="flex-1 ms-3">
                        <h5 className="cls-name mb-1"><a href="#" className="text-dark">{props.data.membername}</a></h5>
                        <span className="badge badge-soft-success mb-0">{(props.data.memberprofile) ? props.data.memberprofile : "Finance Adviser"}</span>
                    </div>
                </div>
                <div className="mt-3 pt-1 ">
                    <p className="text-excerpt mb-0">
                        {trimString(props.data.memberexcerpt, 100)}
                    </p>
                </div>
                <div className="d-flex gap-2 pt-2">
                    <Link to={"/member/" + props.data.id} className="button button_secondary "><span>
                        <i className="fa fa-user mx-1"></i> Profile</span></Link>
                    {(props.data.serviceid) ? <Link to={"/memberschedule/" + props.data.id + "/" + props.data.serviceid} className="button button_primary "><span><i className="fa fa-calendar-check-o mx-1"></i> Book</span></Link> : ""}
                </div>
            </div>
        </div>
    )
}
