import React, { Fragment, useState, useEffect } from "react";
import { HashRouter, Route, Switch, Redirect, Link } from "react-router-dom";

import CircularProgress from "@material-ui/core/CircularProgress";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

import welcome from "../../assets/images/photos/aboutus-page-image.jpg";
import testimonial1 from "../../assets/images/testimonials/testimonial-1.jpeg";
import testimonial2 from "../../assets/images/testimonials/testimonial-2.jpeg";

import Footer from "../../components/footer.js";
import Header from "../../components/header";

import CircularStatic from "../../components/Controls/CircularProgressBar";
import Header_BG from "../../assets/images/contact_background.jpg";
// APIs
import {
	getGeneralSettings
  } from "../../APIs/ApiUtils";


function AboutUs() {
  window.scrollTo(0, 0);
	const [generalSettings, setGeneralSettings] = useState(undefined);
	const [isFormLoading, setIsFormLoading] = useState(false);
	
	useEffect(() => {
		handleSiteInfo();
	}, []);
  
	async function handleSiteInfo() {
	  let RC = false;
	  setIsFormLoading(true);
	  let generalSettings = await getGeneralSettings();
	  if (generalSettings != undefined ) {
		console.log("General settings found successfully ::", generalSettings);
		setGeneralSettings(generalSettings);
		setIsFormLoading(false);
	  } else {
		console.log("General settings Not found");
		setIsFormLoading(false);
	  }
	}

  return (
    <Fragment>
      <div className="single-page about-page">
        <Header />
        {/* <!-- Home --> */}
        <div className="home innerpage">
          <div className="home_background_container prlx_parent">
            <div className="home_background prlx" >                    
            <img src={Header_BG} />
            </div>
          </div>
          <div className="home_content">
            <h1>About Us</h1>
          </div>
        </div>

          <div className="welcome-wrap">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-12 order-2 order-lg-1">
                  <div className="welcome-content">
                    <header className="entry-header">
                      <h2 className="entry-title">Welcome to StartupBasket</h2>
                    </header>

                    <div className="entry-content mt-4">
                      {/* <p>
                        Team StartupBasket, Ideated in 2017
                      </p>
                      <p>Have supported several startups at early stages by handholding, advising different business strategies and providing complete support system for startups at early stages</p>
                      <p>Over time, have gained expertise to handhold Idea-stage to Revenue-earning/visible startups realise their objectives</p>
                      <p>A Virtual Incubation Platform (VIP) was conceptualised in 2021 which is a unique market place, created to support startups, featuring an array of services including finding funds, resources, market and various other services on demand</p>
                      <p>Our future vision is to scale up the VIP across geographies and help startups across the world</p>
                       */}
                      <p>Since our inception in 2017 as Team StartupBasket, we have been offering guidance, advising on business strategies, and providing comprehensive support to numerous startups in their early stages. As a result, we have accumulated extensive experience in helping startups achieve their objectives from ideation to revenue generation.</p>
                      <p>To further support startups, we developed a Virtual Incubation Platform (VIP) in 2021. This unique marketplace provides a wide range of on-demand services, such as access to funding, resources, and market expertise.</p>
                      <p>Moving forward, our vision is to expand the VIP to serve startups across different regions, providing them with the support they need to succeed globally.</p>

                    
                    </div>
                  </div>
                </div>

                {/* <div className="col-12 col-lg-6 order-1 order-lg-2 ">
                  <img src={welcome} alt="welcome" />
                </div> */}
              </div>
              <div className="welcome-content mt-5 mb-5">
                <h2 className="entry-title">Throughout our journey, Startup Basket has developed expertise in the following areas:</h2>
                <div className="entry-content mt-4 ">
                  
                    <ul>
                      <li>
                       <i className="font-weight-bold">CFO and Finance Effectiveness services</i> 
                       We provide financial management, financial reporting, planning, budgeting, and control services to help startups effectively manage their finances.
                      </li>
                      <li>
                      <i>Capital Market & Fund raising</i> 
                      Our team advises startups on financial resources and helps them raise equity and debt. We have established strong networks with angel investors, angel investor networks, and venture capitalists in India, USA, and Europe.                      </li>
                      <li>
                      <i>Strategic Business Advisory</i> 
                      We offer advisory services to startups to help them achieve their short-term, mid-term, and long-term objectives.                      </li>                      
                      <li>
                      <i>Assurance Services</i> 
                      Our internal audits, process audits, due diligence evaluations, and compliance management services provide assurance to startups and help them stay on track.                      </li>
                      <li>
                      <i>Government Support Schemes</i> 
                      We assist startups in accessing grants, soft loans, and other government support schemes.                      </li>
                      <li>
                      <i>Corporate Advisory</i> 
                      Our corporate restructuring and mergers & amalgamations services help startups navigate complex corporate challenges and achieve their goals.                      </li>                      
                    </ul>
                  <p>
                  </p>
                </div>
              </div>
            </div>
          </div>

          <div className="help-us">
            <div className="container">
              <div className="row">
                <div className="col-12 d-flex flex-wrap justify-content-center align-items-center">
                  <h2 className="m-3">Join us so we can grow together</h2>
                  <Link className="btn orange-border m-3" to="/services">
                    Join now
                  </Link>
                </div>
              </div>
            </div>
          </div>

          {/* <div className="about-testimonial">
            <div className="container">
              <div className="row">
                <div className="col-12 col-md-6 col-lg-5">
                  <div className="testimonial-cont">
                  <div className="entry-footer d-flex flex-wrap align-items-center mt-5">
                      <img src={testimonial1} alt="" />
                      <h4>
                      S Bhanuchandran, <span>(Co-Founder)</span>
                      </h4>
                    </div>                    
                    <div className="entry-content">
                      <p>
                        S Bhanuchandran has more than 2 decades of experience and expertise in business strategies, fund raising, and has been in advisory roles for many of his client companies.  He has been in the consulting business and raising funds for many of his client companies.  He will be leading the Business Strategies and will ensure that the portfolio companies of Startup Basket adopts the best of the strategies in the business in the context of fund raising.
                      </p>
                    </div>
                  </div>
                </div>

                <div className="col-12 col-md-6 offset-lg-2 col-lg-5">
                  <div className="testimonial-cont">
                  <div className="entry-footer d-flex flex-wrap align-items-center mt-5">
                      <img src={testimonial2} alt="" />
                      <h4>
                      Gopinath Mallipatna, <span>(Co-founder)</span>
                      </h4>
                    </div>                    
                    <div className="entry-content">
                      <p>
                      Gopinath is a qualified professional with over 20 years of experience serving in multinational companies in the areas of finance and general management.  He has worked with global brands in the IT & ITES, Heavy Engineering, Biotechnology, Life Sciences, Semiconductors in middle and senior management capacities covering administration, human resources, procurement and logistics, with accounting and finance being the core.                      
                      He is also on the Board of a many startups in the areas of Aerospace, Analytics, etc., and advises them
                      He is on the Board of a prestigious organisation, Institute of Management Accountants, USA
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        <Footer />
      </div>
    </Fragment>
  );
}

export default AboutUs;
