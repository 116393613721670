import React from 'react'
import CustomTree from '../../components/Controls/CustomTree';
import { useState } from 'react';
function Comments(props) {
    var dateString = props.item.created_at;
    var date = new Date(dateString);  
    var monthName = date.toLocaleString('default', { month: 'long' });
    const customTreeKey = `customTree_${props.item.id}`;
  return (
    <li className="comment" key={props.item.id}>
        <div className="comment_container d-flex flex-row">
            <div>
                <div className="comment_image">
                    <img src="images/comment_2.jpg" alt="" />
                </div>
            </div>
            <div className="comment_content">
                <div className="comment_meta">
                    <span className="comment_name"><a href="#">{props.item.user.name}</a></span>
                    <span className="comment_separator">|</span>
                    <span className="comment_date">{`${monthName} ${date.getDate()}, ${date.getFullYear()}`}</span>
                    <span className="comment_separator">|</span>
                    <span className="comment_reply_link" ><a href="#">Reply</a></span>
                    <span className="comment_reply_link"  onClick={props.handleGetTree(props.item.id)}>Get</span>
                </div>
                <p className="comment_text">{props.item.comment}</p>
                <CustomTree key={customTreeKey} treeData={props.treeData}/>
            </div>
        </div>
    </li>
  )
}

export default Comments
