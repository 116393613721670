import React from "react";
import axios from "axios";
import API, { API_Param } from "./../API";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";


export { getBookingsAPI, addBookingAPI, getBookingByIdAPI, editBookingAPI, CreateAppointmentSlotsAPI, cancelStartupBookingsAPI };

async function getBookingsAPI() {
  let bookings = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("booking");
    if (response.status == 200) {
      console.log("Response from  Booking API :: ", response.data);
      return response.data;
    } else {
      console.log("Booking API Request Failed With Status :: ", response.status);
      return response.data;
    }
  } catch (error) {
    console.log("Booking API Request Failed With Status :: ", error);

    return bookings;
  }
}

async function addBookingAPI(iParams) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.post(
      "booking", 
      iParams,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response.status == 200) {
      console.log("Response from  Booking API :: ", response.data);
      return response.data.data;
    } else {
      console.log("Booking API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("Booking API Request Failed With Status :: ", error);
    return false;
  }
}

async function getBookingByIdAPI(iParam) {
  let bookings = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("booking/" + iParam);
    if (response.status == 200) {
      console.log("Response from  getBookingById API :: ", response.data);
      return response.data.response;
    } else {
      console.log(
        "getBookingById API Request Failed With Status :: ",
        response.status,
      );
      return response.data;
    }
  } catch (error) {
    console.log("getBookingById API Request Failed With Status :: ", error);
    return false;
  }
}

async function editBookingAPI(
  id,
  fullname,
  displayname,
  description,
  permissions,
) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.put(
      "booking/" + id,
      {
        name: fullname,
        display_name: displayname,
        description: description,
        permissions: permissions,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response.status == 200) {
      console.log("Response from editBooking API :: ", response.data);
      return response.data;
    } else {
      console.log(
        "editBooking API Request Failed With Status :: ",
        response.status,
      );
      return response.data;
    }
  } catch (error) {
    console.log("editBooking API Request Failed With Status :: ", error);

    return false;
  }
}


async function CreateAppointmentSlotsAPI(iParams) {
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.post(
      "appointments", 
      iParams,
      {
        headers: {
          "Content-Type": "application/json",
        },
      },
    );
    if (response.status == 200) {
      console.log("Response from appointment API :: ", response.data);
      //toast.success("Schedule saved successfully");
 
      return response.data.response;
    } else {
      console.log("Appointment API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("Appointment API Request Failed With Status :: ", error);
    return false;
  }
}


async function  cancelStartupBookingsAPI(params){

  let arrResult = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.post("/booking/cancel/" + params.booking_id, {param:{soft_delete:params.soft_delete}});
    if (response.status == 200) {
      console.log("Response from cancelStartupBookingsAPI API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}


