import React, {useState} from 'react';
import { Link, useHistory } from "react-router-dom";

const JoinUs = () =>{  
  return (
    <div className="help-us">
        <div className="container">
            <div className="row">
                <div className="col-12 d-flex flex-wrap justify-content-center align-items-center">
                <h2 className="m-3">Join us so we can grow together</h2>
                <Link className="btn orange-border m-3" to="/services">
                    Join now
                </Link>
                </div>
            </div>
        </div>
    </div>
  );
}
  
export default JoinUs;

