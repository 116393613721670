import React, { Fragment, useRef, useEffect, useState } from "react";
import { useHistory, Link, useLocation, useParams  } from "react-router-dom";
import queryString from "query-string";

// COMPONENTS
import Footer from "../../components/footer";
import Header from "../../components/header";
import PageSidebar from "../../components/PageSidebar.js";
import Comments from "./Comments";
import CustomTree from "../../components/Controls/CustomTree";
// APIs
import { CauseID_ARR } from "../../Macros";

// IMAGE/MEDIA
import Image1 from "../../assets/images/latest_1.jpg";
import latest_1 from "../../assets/images/latest_1.jpg";
import news_1 from "../../assets/images/latest_1.jpg";

// FOR APIS
import {
    getBlogAPI,
    getBlogsAPI,
    getBlogServicesAPI
  } from "../../APIs/blogApis";
  import {getBlogCommentsAPI} from "../../APIs/commentsApis";
  import {getTreeAPI} from "../../APIs/commentsApis";
  import {postBlogCommentsAPI} from "../../APIs/commentsApis";
import  CircularStatic from '../../components/Controls/CircularProgressBar';
import AnchorLink from "react-anchor-link-smooth-scroll";

import {
	getBlogCategoriesAPI
} from "../../APIs/blogcategoryApis";

import {
    createMarkup,  
  } from '../../helpers';

import {
    getGeneralSettings
} from "../../APIs/ApiUtils";

  
function Homepage() {
    window.scrollTo(0, 0);
    const history = useHistory();
    const location = useLocation();
    const params = useParams();
    const [blogInfo, setBlogInfo] = useState(undefined);
    const [blogComments, setBlogComments] = useState(undefined);
    const [blogsTable, setBlogsTable] = useState(undefined);
    const [blogCategoriesTable, setBlogCategoriesTable] = useState(undefined);
    const [servicesTable, setServicesTable] = useState(undefined);
    const [isFormLoading, setIsFormLoading] = useState(false);
    const [isSubmitLoading, setIsSubmitLoading] = useState(false);
    const [isError, setIsError] = useState(false);
    const [generalSettings, setGeneralSettings] = useState(undefined);
    const [currComment,setCurrComment]=useState(undefined);
    const [treeData,setTreeData]=useState(undefined);
    useEffect(() => {
        handleGetBlog();   
        handleGetComments();
        handleGetBlogCategories();
        handleGetFeaturedBlogs();
        handleSiteInfo();

      }, []);

      async function handleSiteInfo() {
        let RC = false;
        setIsFormLoading(true);
        let generalSettings = await getGeneralSettings();
        if (generalSettings != undefined ) {
          console.log("General settings found successfully ::", generalSettings);
          setGeneralSettings(generalSettings);
          setIsFormLoading(false);
        } else {
          console.log("General settings Not found");
          setIsFormLoading(false);
        }
      }

   return (
    <>
        {isFormLoading ? (
        <div className={{ alignContents: "center",  }}>
            {/* <CircularProgress variant="indeterminate" value={75} /> */}
            <CircularStatic value={25} />
        </div>
        ) : (
        <Fragment>        
            <Header />

            {/* <!-- Home --> */}
            <div className="home innerpage">
                <div className="home_background_container prlx_parent">
                    <div className="home_background prlx" ></div>
                </div>
                <div className="home_content single-blog">
                    <h2>{(blogInfo != undefined)?blogInfo.title:""}</h2>
                </div>
            </div>

            {/* <!-- News --> */}
            <div className="news">
                <div className="container">
                    <div className="row">
                        
                        {/* <!-- News Post Column --> */}

                        <div className="col-lg-8">
                            
                            <div className="news_post_container">
                                {/* <!-- News Post --> */}
                                <div className="news_post">
                                    <div className="news_post_image">
                                        <img src={news_1} alt="https://unsplash.com/@dsmacinnes" />
                                    </div>
                                    <div className="news_post_top d-flex flex-column flex-sm-row">
                                        <div className="news_post_date_container">
                                            <div className="news_post_date d-flex flex-column align-items-center justify-content-center">
                                                <div>18</div>
                                                <div>dec</div>
                                            </div>
                                        </div>

                                        <div className="news_post_title_container">
                                            <div className="news_post_title">
                                                <a href={"/news/"}>{(blogInfo != undefined)?blogInfo.title:""}</a>
                                            </div>
                                            <div className="news_post_meta">
                                                <span className="news_post_author"><a href={"/news/"}>By Admin</a></span>
                                                {/* <span>|</span>
                                                <span className="news_post_comments"><a href="#">3 Comments</a></span> */}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="news_post_quote">
                                        <p className="news_post_quote_text"><span>{(blogInfo != undefined)?blogInfo.excerpt.charAt(0):""}</span>{(blogInfo != undefined)?blogInfo.excerpt.slice(1,blogInfo.excerpt.length):""}</p>
                                    </div>
                                    <div className="news_post_text">
                                        <p dangerouslySetInnerHTML={createMarkup((blogInfo != undefined)?blogInfo.detail:"")}></p>
                                    </div>
                                </div>
                            </div>
                            
                            {/* <!-- Comments --> */}
                            <div className="news_post_comments">
                                <div className="comments_title">Comments</div>
                                <ul className="comments_list">
                                    
                                    {/* <!-- Comment --> */}
                                        {
                                            blogComments != undefined && blogComments.map((item)=>{
                                               
                                            return (
                                                <Comments treeData={treeData} key={`Comments_${item.id}`} handleGetTree={handleGetTree} item={item}/>
                                            )
                                            })
                                        }
                                        
                                    

                                </ul>

                            </div>

                            {/* <!-- Leave Comment --> */}

                            <div className="leave_comment">
                                <div className="leave_comment_title">Leave a comment</div>

                                <div className="leave_comment_form_container">
                                    <form action="post" onSubmit={handlePostBlogComments}>
                                        {/* <input id="comment_form_name" className="input_field contact_form_name" type="text" placeholder="Name" required="required" data-error="Name is required." ></input>
                                        <input id="comment_form_email" className="input_field contact_form_email" type="email" placeholder="E-mail" required="required" data-error="Valid email is required." ></input> */}
                                        <textarea id="comment_form_message" className="text_field contact_form_message" name="message" placeholder="Message" required="required" data-error="Please, write us a message."></textarea>
                                        <button id="comment_send_btn" type="submit" className="button button_primary trans_200" value="Submit"><span>send message</span></button>
                                    </form>
                                </div>
                            </div>

                        </div>

                        {/* <!-- Sidebar Column --> */}

                        <div className="col-lg-4">
                            <div className="sidebar">

                                {/* <!-- Tags --> */}

                                <div className="sidebar_section">
                                    <div className="sidebar_section_title mt-4">
                                        <h3>Tags</h3>
                                    </div>
                                    <div className="tags d-flex flex-row flex-wrap">
												{
												blogCategoriesTable != undefined &&
												blogCategoriesTable.map((row) => {
												{
												return (													
														<div className="tag"><Link to={"/news/?category="+row.id }>{row.title}</Link></div>
												)}})}
												</div>
                                </div>

                                <PageSidebar />

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Footer />
        </Fragment>
        )}
    </>
);

async function handlePostBlogComments(e){
    setIsFormLoading(true);
    let iComment = e.target.message.value;
    let iBlogID = params.blogId;
    let user_id = localStorage.getItem('user_id')
    console.log("params>> ",iComment,"",user_id,"",iBlogID)
    let newComment=await postBlogCommentsAPI(iComment,"blog",user_id,"",iBlogID)
    if(newComment != undefined && newComment != false){
        console.log("newComment Found Successfully ::", newComment);
        setBlogComments((prevComments) => [...prevComments, newComment]);
        setIsFormLoading(false);
    }else{
        console.log("Blog Comments Not found");
        setIsFormLoading(false);
    }
    setCurrComment(undefined)
}
async function handleGetComments(){
    setIsFormLoading(true);
    let iBlogID = params.blogId;
    let blogComments=await getBlogCommentsAPI(iBlogID)
    if(blogComments != undefined){
        console.log("BlogComments Found Successfully ::", blogComments);
        setBlogComments(blogComments);
        setIsFormLoading(false);
    }else{
        console.log("Blog Comments Not found");
        setIsFormLoading(false);
    }
}
async function handleGetTree(commentId){
    setIsFormLoading(true);
    let treeComments=await getTreeAPI(commentId)
    if(treeComments != undefined && treeComments !=true){
        console.log("treeComments Found Successfully ::", treeComments);
        setTreeData(treeComments);
        setIsFormLoading(false);
    }else{
        console.log("Blog Comments Not found");
        setIsFormLoading(false);
    }
    setIsFormLoading(false);
}

async function handleGetBlog() {
    let RC = false;
    setIsFormLoading(true);
    let iBlogID = params.blogId;    
    let blog = await getBlogAPI(iBlogID);
    if (blog != undefined ) {
      console.log("Blogs Found Successfully ::", blog);
      setBlogInfo(blog);
      setIsFormLoading(false);
    } else {
      console.log("Blog Not found");
      setIsFormLoading(false);
    }
}
async function handleGetFeaturedBlogs() {
    let RC = false;
    setIsFormLoading(true);
    let isFeatured = true;

    let blogs = await getBlogsAPI(isFeatured);
    if (blogs != undefined && blogs.length > 0) {
        console.log("Featured Blogs Found Successfully ::", blogs.length);
        setBlogsTable(blogs);
        setIsFormLoading(false);
    } else {
        console.log("Featured Blog Not found");
        setIsFormLoading(false);
    }
}


  async function handleGetBlogCategories() {
    let RC = false;
    setIsFormLoading(true);
    let categories = await getBlogCategoriesAPI();
    if (categories != undefined && categories.length > 0) {
        console.log("categories Found Successfully ::", categories.length);
        setBlogCategoriesTable(categories);
        setIsFormLoading(false);
    } else {
        console.log("categories Not found");
        setIsFormLoading(false);
    }
}


}
export default Homepage;
