import React, { Fragment, useState, useEffect } from "react";

import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
  useHistory,
} from "react-router-dom";
import LinearProgress from "@material-ui/core/LinearProgress";

import logo from "../../assets/images/Member-1.jpg";


import CloudUploadIcon from "@material-ui/icons/CloudDownload";
import { Button } from "@material-ui/core";

import Dialog from "@material-ui/core/Dialog";
import Footer from "../../components/footer";
import Header from "../../components/header";

import ReactPlayer from "react-player";
import {
  Grid,
  CircularProgress,
  TextField,
  Fade,
  FormControl,
  InputLabel,
  Typography,
  Input,
} from "@material-ui/core";

import { getWorkerAPI } from "../../APIs/workerApis";

import { getImageAPI } from "../../APIs/ApiUtils";

import { createOrderAPI, createPaymentAPI } from "../../APIs/paymentApis";

import { addDonationAPI } from "../../APIs/donorApis";
import { DateTimePicker } from "@material-ui/pickers";
import queryString from "query-string";

function Homepage(props) {

  const history = useHistory();

  useEffect(() => {
    window.scrollTo(0, 0);

    handleGetWorker();
  }, []);

  const [MemberID, setMemberID] = useState(0);
  const [isFormLoading, setIsFormLoading] = useState(true);
  const [workerInfo, setWorkerInfo] = useState([]);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [profilePicture, setProfilePicture] = useState("");
  const [deleteDialog, deleteDialogUI] = useState(false);
  const [orderAmount, setDonationAmount] = useState("1000");
  const [orderType, setDonationType] = useState("");
  const [orderMethod, setDonationMethod] = useState(undefined);
  const [paymentID, setPaymentID] = useState(undefined);
  const [orderID, setOrderID] = useState(undefined);

  function handleClose() {
    deleteDialogUI(false);
  }
  function handleProfileView() {
    deleteDialogUI(true);
  }
  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center" }}>
          <CircularProgress variant="indeterminate" value={75} />
        </div>
      ) : (
        <Fragment>
          <div className="single-page about-page">
            <Header />
            <div className="page-Member  userprofile-page">
              <div className="container">
                <div className="main-body">
                  <div className="row gutters-sm">
                    <div className="col-md-4 mb-3">
                      <div className="card">
                        <div className="card-body">
                          <div className="d-flex flex-column align-items-center text-center">
                            <img
                              src={profilePicture}
                              alt=""
                              className="rounded-circle"
                              width="200"
                            />
                            <div className="mt-3">
                              <h5>
                                {workerInfo["firstname"] +
                                  " " +
                                  workerInfo["lastname"]}
                              </h5>
                              <p className="text-secondary mb-1">
                                {workerInfo["occupation"]}
                              </p>
                              <Link
                                to={
                                  "/Member?Member_id=" + MemberID
                                }
                                className="btn gradient-bg mt-2 px-3 py-2"
                              >
                                View Profile
                              </Link>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="card mb-3">
                        <div className="card-body">
                          {isError ? (
                            <Fade in={isError}>
                              <Typography className="text-danger mt-3 mb-3">
                                Select a payment method to proceed
                              </Typography>
                            </Fade>
                          ) : (
                            ""
                          )}

                          <div
                            className="btn-group"
                            data-toggle="buttons"
                            onChange={(e) => {
                              handlePaymentMethod(e.target.value);
                            }}
                          >
                            <label className="btn active">
                              <input
                                type="radio"
                                name="paymentservice"
                                value="INDIAN"
                              />
                              <div
                                className="text-primary ml-2"
                                style={{ height: "100" }}
                              >
                                Online Payment
                              </div>
                              <div className="text-center  mt-4">
                                <small>
                                  For Debit card , Credit card, UPI, Wallet
                                </small>
                              </div>
                            </label>
                            {/* <label className="btn">
                              <input
                                type="radio"
                                name="paymentservice"
                                value="INTERNATIONAL"
                              />
                              <div className="text-primary  ml-2 ">
                                International Payment
                              </div>
                              <div className="text-center text-mute mt-4">
                                <small>For International Credit cards</small>
                              </div>
                            </label> */}
                            <label className="btn">
                              <input
                                type="radio"
                                name="paymentservice"
                                value="BANKTRANSFER"
                              />
                              <div className="text-primary  ml-2">
                                Direct Transfer
                              </div>
                              <div className="text-center text-mute mt-4">
                                <small>
                                  Directly transfer to our Bank Account
                                </small>
                              </div>
                            </label>
                          </div>

                          {isSubmitLoading ? (
                            <div className={{ alignContents: "center" }}>
                              <CircularProgress
                                variant="indeterminate"
                                value={75}
                              />
                              Payment Under Progress
                            </div>
                          ) : (
                            <div
                              onClick={displayRazorpay}
                              className="btn gradient-bg mt-2 px-3 py-2"
                            >
                              Proceed With Payment
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>
        </Fragment>
      )}
    </>
  );

  async function handlePaymentBtn() {}

  function loadScript(src) {
    return new Promise((resolve) => {
      const script = document.createElement("script");
      script.src = src;
      script.onload = () => {
        resolve(true);
      };
      script.onerror = () => {
        resolve(false);
      };
      document.body.appendChild(script);
    });
  }

  async function handleAddDonation(payment_id, order_id) {
    let RC = false;

    let id = localStorage.getItem("donor_id");
    let workers = [MemberID];

    let param = {
      donor_id: id,
      status: "SUCCESS",
      // 'planned_at' : 'nullable|date',
      amount: orderAmount,
      is_recurring: true,
      recurring_notification: orderType,
      transaction_id: payment_id,
      req_tax_benefit: true,
      orderable_type: "App\\Models\\worker",
      orderable_id: MemberID,
    };

    console.log("<Response> Param Variable", param);

    let orderInfo = await addDonationAPI(param);
    if (orderInfo != undefined) {
      console.log(
        "Donation created Successfully in response",
        JSON.stringify(orderInfo)
      );
      setIsSubmitLoading(false);

      // PUSH TO PAYMENT SUCCESS
      history.push({
        pathname: "/paymentsuccess",
        state: {
          Member_id: MemberID,
          orderAmount: orderAmount,
          orderMethod: orderMethod,
          orderType: orderType,
          transactionID: payment_id,
          orderID: order_id,
          orderTime: Date(),
        },
      });
    } else {
      console.log("Donation Not found");
    }
  }

  async function displayRazorpay() {
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Payment Gateway failed to load. Are you online?");
      return;
    }
    let queries = queryString.parse(history.location.search);

    let orderAmount = queries.orderAmount;
    let orderType = queries.orderType;

    setDonationAmount(orderAmount);
    setDonationType(orderType);

    let data = {
      receipt: "order_Member_" + MemberID,
      amount: orderAmount * 100,
      currency: "INR",
    };

    // creating a new order
    const result = await createOrderAPI(data);
    console.log("<INFO> Response of createOrderAPI", result["order_id"]);
    if (!result) {
      alert("Payment Gateway error. Are you online?");
      return;
    }
    let order_id = result["order_id"];
    setOrderID(order_id);

    const options = {
      key: "rzp_test_te58r0UFlw7ZyG", // Enter the Key ID generated from the Dashboard
      amount: data.amount.toString(),
      currency: data.currency,
      name: "StartupBasket",
      description: "Donation",
      image: { logo },
      order_id: result["order_id"],
      handler: async function (response) {
        console.log("<INFO> Response payment ID", response.razorpay_payment_id);
        console.log("<INFO> Response ORDER ID", response.razorpay_order_id);
        console.log(
          "<INFO> Response subscription ID",
          response.razorpay_signature
        );
        setPaymentID(response.razorpay_payment_id);

        const data = {
          orderCreationId: order_id,
          razorpayPaymentId: response.razorpay_payment_id,
          razorpayOrderId: response.razorpay_order_id,
          razorpaySignature: response.razorpay_signature,
        };
        setIsSubmitLoading(true);

        const result = await createPaymentAPI(data);

        console.log("<INFO> Response of createPaymentAPI", result);
        if (!result) {
        }
        handleAddDonation(response.razorpay_payment_id, order_id);
      },
      prefill: {
        name: localStorage.getItem("user_name"),
        email: localStorage.getItem("user_email"),
        contact: localStorage.getItem("user_phone"),
      },
      notes: {
        address: "E-2/63, Arera Colony, Bhopal, INDIA",
      },
      theme: {
        color: "#61dafb",
      },
    };

    const paymentObject = new window.Razorpay(options);
    paymentObject.open();
  }

  async function handlePaymentMethod(value) {
    setDonationMethod(value);
  }

  async function handleGetWorker() {
    let RC = false;
    setIsFormLoading(true);
    setWorkerInfo({});

    let queries = queryString.parse(history.location.search);
    let Member_id = queries.Member_id;

    let workerInfo = await getWorkerAPI(Member_id);
    if (workerInfo != undefined) {
      console.log(
        "Member Found Successfully in response",
        JSON.stringify(workerInfo)
      );

      let param = {
        filePath: workerInfo["profileimage"],
        fileName: "profileimage",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);

      let orderAmount = queries.orderAmount;
      let orderType = queries.orderType;
      setMemberID(Member_id);
      setDonationAmount(orderAmount);
      setDonationType(orderType);
      setWorkerInfo(workerInfo);
      setIsFormLoading(false);
      setProfilePicture(blob);
    } else {
      console.log("Member Not found");
      setIsFormLoading(false);
    }
  }
}

export default Homepage;
