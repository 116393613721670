import React, { Fragment, useState,  useEffect } from "react";
import { HashRouter, Route, Switch, Redirect, Link, useHistory, useParams  } from "react-router-dom";

import { Component } from "react";
import ReactDOM from "react-dom";
import moment from "moment";

import {
  Paper,
  Grid,
} from "@material-ui/core";


import CircularProgress from "@material-ui/core/CircularProgress";
import {
  getBookingByIdAPI,
} from "../../APIs/bookingApis";
import {
  currencySymbol,
  getLocaleShortDateString,
} from "../../helpers";

// COMPONENTS
import PageTitle from "../../components/PageTitle/PageTitle";

// data
import Slide from "@material-ui/core/Slide";

function ViewBooking() {

  const history = useHistory();
  const params = useParams();
  const [bookingId, SetBookingId] = useState(undefined);
  const [bookingInfo, SetBookingInfo] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    handleGetBooking();
  }, []);

  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center" }}>
          <CircularProgress variant="indeterminate" value={75} />
        </div>
      ) : (
        <div>
          <Grid container spacing={1}>
            <Grid item xs={12} className={{ justifyContent: "center" }}>
              <Paper style={{ padding: 16 }}>
                <Grid container alignItems="flex-start" spacing={2}>
                  <Grid
                    item
                    xs={12}
                    sm={12}
                    md={12}
                    className={{
                      justifyContent: "flex-start",
                      bbookingBottom: "1px solid #000",
                    }}
                  >
                    <div className="mt-4">
                      <table className="table table-striped">
                        <tbody>
                          <tr>
                            <th scope="row">Member Name</th>
                            <td>
                              <Link to={ "/member/" + (bookingInfo?.appointment['member']['id']) }  className="text-tertiary">
                                   { bookingInfo?.appointment['member']['membername'] }
                              </Link>
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Status</th>
                            <td>
                              {bookingInfo?.status}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Amount</th>
                            <td>
                              {currencySymbol() + bookingInfo?.payment?.amount}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Transaction ID</th>
                            <td>
                              {bookingInfo?.payment?.transaction_id}
                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Session Date</th>
                            <td>{getLocaleShortDateString(bookingInfo?.appointment['date'])}</td>
                          </tr>
                          <tr>
                            <th scope="row">Session Timing</th>
                            <td>{"From "+(bookingInfo?.appointment['starttime'])?.slice(0,5) + " To " + (bookingInfo?.appointment['endtime'])?.slice(0,5)}</td>
                          </tr>                            
                          <tr>
                            <th scope="row">Service name</th>
                            <td>
                              <Link  to={ "/service/" + (bookingInfo?.service['id']) } className="text-tertiary">
                                  {bookingInfo?.service['title']}
                              </Link>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>
          </Grid>
        </div>
      )}
    </>
  );

  async function handleGetBooking () {
    let RC = false;

    setIsFormLoading(true);
    let booking_id = params.bookingId;
    let bookingInfo = await getBookingByIdAPI(booking_id);

    if (bookingInfo!=undefined) {
      console.log("Booking  Found Successfully in ViewDonation", bookingInfo);
      SetBookingInfo(bookingInfo['booking']);
      setIsFormLoading(false);
    } else {
      console.log("Orders Not found");
      setIsFormLoading(false);
    }
  };

  function viewMember(id) {
    history.push("/member/" + id);
  }
}

export default ViewBooking;

