import React from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as mime from "react-native-mime-types";

import { Trans } from "react-i18next";
import API, { API_Param } from "./../API";

export {
  ValidateFields,
  displayResponseError,
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  setStateVariables,
  downloadFileAPI,
  getImageAPI,
  showImageAPI,
  sendContactUS,
  trimString,
  capitalizeFirst,
  convertTime,
  sendSubscribe,
  browserDetection,
  isMembershipPaid,
  getCategoriesAPI,
  getMembershipCharges,
  getCouponDiscount,
  getGeneralSettings,
  addDays
};

function getMembershipCharges(){
  return {
    currency : "INR",
    amount : 100,
    finalamount : 100,
    symbol : "fa fa-inr"
  };
}

function getCouponDiscount(){
  return {
    Coupon : "NEWUSER",
    discount : 10,
    type: "PERCENTAGE",
    amount : 10,
    maxallow : 20,
  };
}


function displayResponseError(response) {
  console.log(
    "API Request Failed With Status :: ",
    response.status,
    response.data.errors,
  );
  toast.error(response.data.msg);
  if (response.data.errors != undefined) {
    if (
      Object.prototype.toString.call(response.data.errors) === "[object Object]"
    )
      Object.entries(response.data.errors).map(([key, value]) => {
        toast.error("Error : " + value);
      });
    else toast.error(response.data.errors);
  }
}

function isErrorExist(param) {
  let isErrorExist = false;

  Object.entries(param).map(([key, value]) => {
    if (key.includes("ErrorStatus") && value == true) {
      console.log("<ERROR> Error exist for Field ::", key);
      isErrorExist = true;
    }
  });

  if (isErrorExist) {
    console.log("Error Exist in Form");
    return true;
  } else {
    console.log("Error Not Exist in Array");
    return false;
  }
}

function getAge(dateString) {
  var today = new Date();
  var birthDate = new Date(dateString);
  var age = today.getFullYear() - birthDate.getFullYear();
  var m = today.getMonth() - birthDate.getMonth();
  if (m < 0 || (m === 0 && today.getDate() < birthDate.getDate())) {
    age--;
  }
  return age;
}

function checkTextField(param) {
  if (param.validation.checkEmpty && !param.value)
    return { isError: true, errorMsg: "Please enter a value" };
  if (
    param.value != undefined &&
    param.validation.maxLength != undefined &&
    param.value.length > param.validation.maxLength
  )
    return {
      isError: true,
      errorMsg: "Max length allowed is " + param.validation.maxLength,
    };
  if (
    param.value != undefined &&
    param.validation.minLength != undefined &&
    param.value.length < param.validation.minLength
  )
    return {
      isError: true,
      errorMsg: "Min length allowed is " + param.validation.minLength,
    };
  if (param.validation.checkSting && !/[^a-zA-Z]/.test(param.value))
    return { isError: true, errorMsg: "Contain non letter also" };

  return { isError: false, errorMsg: "" };
}

function checkDateField(param) {
  let currentAge = getAge(param.value);

  if (param.validation.checkEmpty && !param.value)
    return { isError: true, errorMsg: "Please enter a value" };
  if (
    param.value != undefined &&
    param.validation.maxAge != undefined &&
    currentAge > param.validation.maxAge
  )
    return {
      isError: true,
      errorMsg: "Maximum Age allowed " + param.validation.maxAge,
    };
  if (
    param.value != undefined &&
    param.validation.minAge != undefined &&
    currentAge < param.validation.minAge
  )
    return {
      isError: true,
      errorMsg: "Minimum Age allowed " + param.validation.minAge,
    };

  return { isError: false, errorMsg: "" };
}

function checkNumberField(param) {
  if (
    param.validation.checkEmpty &&
    (param.value == undefined || param.value == "")
  )
    return { isError: true, errorMsg: "Please enter a value" };
  if (
    param.value != undefined &&
    param.validation.max != undefined &&
    param.value > param.validation.max
  )
    return {
      isError: true,
      errorMsg: "Maximum allowed " + param.validation.max,
    };
  if (
    param.value != undefined &&
    param.validation.min != undefined &&
    param.value < param.validation.min
  )
    return {
      isError: true,
      errorMsg: "Minimum allowed " + param.validation.min,
    };

  return { isError: false, errorMsg: "" };
}

function checkRadioField(param) {
  if (
    param.validation.checkEmpty &&
    (param.value == undefined || param.value == "")
  )
    return { isError: true, errorMsg: "Select an option" };

  return { isError: false, errorMsg: "" };
}

function checkSelectField(param) {
  if (param.validation.checkEmpty && !param.value)
    return { isError: true, errorMsg: "Select an option" };

  return { isError: false, errorMsg: "" };
}

function checkFileField(param) {
  if (param.validation.checkEmpty && !param.value)
    return { isError: true, errorMsg: "Please upload a file" };
  if (
    param.value != undefined &&
    param.validation.maxLength != undefined &&
    param.value.length > param.validation.maxLength
  )
    return {
      isError: true,
      errorMsg: "Max length allowed is " + param.validation.maxLength,
    };
  if (
    param.value != undefined &&
    param.validation.minLength != undefined &&
    param.value.length < param.validation.minLength
  )
    return {
      isError: true,
      errorMsg: "Min length allowed is " + param.validation.minLength,
    };
  if (
    param.value != undefined &&
    param.validation.checkSting &&
    !/[^a-zA-Z]/.test(param.value)
  )
    return { isError: true, errorMsg: "Contain non letter also" };

  return { isError: false, errorMsg: "" };
}

function ValidateFields(thisObject) {
  let errors = false;

  Object.entries(thisObject.state).map(([key, value]) => {
    if (key.includes("FieldType")) {
      let paramName = key.replace("FieldType", "");

      let rc = { isError: false };

      if (thisObject.state[paramName + "FieldType"] == "TextField") {
        rc = checkTextField({
          value: thisObject.state[paramName + "Value"],
          validation: thisObject.state[paramName + "FieldValidation"],
        });
      }

      if (thisObject.state[paramName + "FieldType"] == "NumberField") {
        rc = checkNumberField({
          value: thisObject.state[paramName + "Value"],
          validation: thisObject.state[paramName + "FieldValidation"],
        });
      }

      if (thisObject.state[paramName + "FieldType"] == "RadioField") {
        rc = checkRadioField({
          value: thisObject.state[paramName + "Value"],
          validation: thisObject.state[paramName + "FieldValidation"],
        });
      }

      if (thisObject.state[paramName + "FieldType"] == "SelectField") {
        rc = checkSelectField({
          value: thisObject.state[paramName + "Value"],
          validation: thisObject.state[paramName + "FieldValidation"],
        });
      }

      if (thisObject.state[paramName + "FieldType"] == "DateField") {
        rc = checkDateField({
          value: thisObject.state[paramName + "Value"],
          validation: thisObject.state[paramName + "FieldValidation"],
        });
      }

      if (thisObject.state[paramName + "FieldType"] == "FileField") {
        rc = checkFileField({
          value: thisObject.state[paramName + "Value"],
          validation: thisObject.state[paramName + "FieldValidation"],
        });
      }

      if (rc.isError) {
        console.log("<ERROR> Feild Name : ", paramName);

        let errStatus = paramName + "ErrorStatus";
        let errMsg = paramName + "ErrorMsg";
        thisObject.setState({
          isError: true,
          [errStatus]: true,
          [errMsg]: rc.errorMsg,
        });
        errors = true;
      }
    }
  });
  if (thisObject.state.isError && !errors)
    thisObject.setState({ isError: false });
  return errors;
}

function setStateVariables(
  param,
  validation = { checkEmpty: true },
  type = "TextField",
  value = "",
  error = false,
) {
  let paramLabel = param + "Label";
  let paramID = param + "ID";
  let paramPlaceHolder = param + "PlaceHolder";
  let paramValue = param + "Value";
  let paramErrorStatus = param + "ErrorStatus";
  let paramErrorMsg = param + "ErrorMsg";
  let paramFieldType = param + "FieldType";
  let paramFieldValidation = param + "FieldValidation";

  let transLabel = "CommonField." + param + "Label";
  let transPlaceHolder = "CommonField." + param + "PlaceHolder";
  let transErrorMsg = "CommonField." + param + "ErrorMsg]";

  this.setState({
    [paramLabel]: <Trans i18nKey={transLabel}></Trans>,
    [paramID]: param,
    [paramPlaceHolder]: <Trans i18nKey={transPlaceHolder}></Trans>,
    [paramValue]: value,
    [paramErrorStatus]: error,
    [paramErrorMsg]: <Trans i18nKey={transErrorMsg}></Trans>,
    [paramFieldType]: type,
    [paramFieldValidation]: validation,
  });
}

async function downloadFileAPI(param) {
  let token = localStorage.getItem("id_token");
  console.log(param);
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("download", param, {
      responseType: "blob",
    });
    if (response != undefined) {
      console.log("Response from API :: ", response);
      if (response.status == 200) {
        let blob = response.data;

        if (blob) {
          //  GET FILE EXTENTION FROM MIME
          let ext = mime.extension(response.data.type);
          console.log("<INFO> Extenstion Type ", response.data.type);
          // Create blob link to download
          const url = window.URL.createObjectURL(new Blob([blob]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", param.fileName + "." + ext);

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // Clean up and remove the link
          link.parentNode.removeChild(link);
        }
        //toast.success("Document downloaded successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getImageAPI(param) {
  let token = localStorage.getItem("id_token");
  console.log("get image api param:: "+param);
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("download", param, {
      responseType: "blob",
    });

    if (response != undefined) {
      console.log("Response from getImage API :: ", response);
      if (response.status == 200) {
        let blob = response.data;
        if (blob) {        
          var b64image = await blobToBase64(blob);
          return b64image;
        }
      } else {
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

function blobToBase64(blob) {
  return new Promise((resolve, _) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}

async function showImageAPI(param, iElement) {
  let token = localStorage.getItem("id_token");
  console.log(param);
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("showimage", param, {
      responseType: "blob",
    });

    if (response != undefined) {
      console.log("Response from getImage API :: ", response);
      if (response.status == 200) {
        let blob = response.data;
        return URL.createObjectURL(blob);
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}


async function sendContactUS(param) {  
  try {    
    let response = await API.post("contactus", param);
    if (response != undefined) {
      console.log("Response from Contact US API :: ", response);
      if (response.status == 200) {
        //toast.success("Thank you for your message. Our team will get back to you shortly");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function sendSubscribe(param) {  
  try {    
    let response = await API.post("subscribe", param);
    if (response != undefined) {
      console.log("Response from subscribe API :: ", response);
      if (response.status == 200) {
        //toast.success("Thanks for your subscription");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getAllAdminSettings(param) {  
  try {    
    let response = await API.get("/setting/type/" + param.type) ;
    if (response != undefined) {
      console.log("Response from Admin Settings API :: ", response);
      if (response.status == 200) {        
        //toast.success("Admin settings get successfully");        
        return response.data.response.Settings;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

function trimString(string,length)
{
  return string.length > length ? 
  string.substring(0, length) + '...' :
  string;
}

function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

const capitalizeFirst = str => {
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
};

function convertTime(timeStr , additionHours=0){
    const [time, modifier] = timeStr.split(' ');
    let [hours, minutes, seconds] = time.split(':');
    let hourInt = parseInt(hours, 10);
    hourInt = hourInt + additionHours;
    if (hourInt === '12') {
      hourInt = '00';
    }

    console.log("Time Modifier :: ", modifier);
    if (modifier == 'pm') {
      hourInt = parseInt(hourInt, 10) + 12;
    }
    return `${hourInt}:${minutes}:${seconds}`;
}

function browserDetection() {
  var ua= navigator.userAgent, tem, 
  M= ua.match(/(opera|chrome|safari|firefox|msie|trident(?=\/))\/?\s*(\d+)/i) || [];
  if(/trident/i.test(M[1])){
      tem=  /\brv[ :]+(\d+)/g.exec(ua) || [];
      return 'IE '+(tem[1] || '');
  }
  if(M[1]=== 'Chrome'){
      tem= ua.match(/\b(OPR|Edge)\/(\d+)/);
      if(tem!= null) return tem.slice(1).join(' ').replace('OPR', 'Opera');
  }
  M= M[2]? [M[1], M[2]]: [navigator.appName, navigator.appVersion, '-?'];
  if((tem= ua.match(/version\/(\d+)/i))!= null) M.splice(1, 1, tem[1]);
  return M.join(' ');
}


async function isMembershipPaid(iUserID) {

  try
  {
    let response = await API.get("/user/membership/" + iUserID );
    if (response.status == 200)
    {
      console.log("Response from membership paid API :: ", response.data);
      if(response.data.status == 'SUCCESS') {
        return true;
      } else {
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error(error);
    return false;
  }
}

async function getCategoriesAPI() {
  let categories = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("category");
    if (response.status == 200) {
      categories = response.data.response.categorys;
      console.log("Response from  API :: ", categories);
      return categories;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return categories;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getGeneralSettings() {
  let settings = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("generalsettings");
    if (response.status == 200) {
      settings = response.data.response;
      console.log("Response from  API :: ", settings);
      return settings;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return settings;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}




