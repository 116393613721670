import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";
import google from "../../assets/images/google.svg";
import startupMoto from '../../assets/images/startup-moto.png'
// context
import { EmailOutlined } from "@material-ui/icons";

import {
  useUserDispatch,
  loginUser,
  registerUser,
  isEmailExist,
  isPhoneExist,
  forgetPassword,
  verifyOTPs,
} from "../../context/userContext";

function ForgetPassword(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameValue, setNameValue] = useState("");
  var [email, setEmail] = useState("");
  var [phone, setPhone] = useState("");
  var [emailOTP, setEmailOTP] = useState("123456");
  var [phoneOTP, setPhoneOTP] = useState("");
  var [errorEmail, setErrorEmail] = useState("");
  var [errorPhone, setErrorPhone] = useState("");
  var [errorEmailOTP, setErrorEmailOTP] = useState("");
  var [errorPhoneOTP, setErrorPhoneOTP] = useState("");

  function handleCheckOTPs() {
    setIsLoading(true);
    let RC = verifyOTPs(
      userDispatch,
      email,
      phone,
      emailOTP,
      phoneOTP,
      props.history,
      setIsLoading,
      setError,
    );
    if (RC) setErrorEmailOTP(true);
    setIsLoading(false);
  }

  return (
    <Grid container className={classes.container}>
      <div className={classes.logotypeContainer}>
        <img src={startupMoto} alt="logo" className={classes.logotypeImage} />
        <Typography className={classes.logotypeText}>StartupBasket</Typography>
      </div>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Typography variant="h3" className={classes.greeting}>
              Enter OTP send to your Email ID OR Phone number
            </Typography>
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                Something is wrong with your OTP              </Typography>
            </Fade>
            <TextField
                id="emailOTP"
                InputProps={{
                  classes: { underline: classes.textFieldUnderline, input: classes.textField,},
                }}
                value={emailOTP}
                onChange={(e) => setEmailOTP(e.target.value)}
                margin="normal"
                placeholder="Email OTP"
                type="number"
                fullWidth
              />           
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={!(email.length > 0 || phone.length > 0)}
                  onClick={handleCheckOTPs}
                  variant="contained" color="primary" size="large"
                >
                  Send OTP
                </Button>
              )}
            </div>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(ForgetPassword);
