const en_source = {
  CommonField: {
    category_idLabel: "Select Category",
    category_idPlaceHolder: "Select Category",
    contactnameLabel: "Contact Name",
    contactnamePlaceHolder: "Enter Contact Name",
    contactemailLabel: "Contact Email",
    contactemailPlaceHolder: "Enter Contact Email",
    fathernameLabel: "Father's Name",
    fathernamePlaceHolder: "Enter Father's Name",
    contactphoneLabel: "Phone Number",
    contactphonePlaceHolder: "Enter Phone Number",
    nameLabel: "Name",
    namePlaceHolder: "Enter Your Name",
    contactphoneLabel: "Phone number",
    contactphonePlaceHolder: "Enter Phone number",
    emailLabel: "Email ID",
    emailPlaceHolder: "Enter Email ID",

    
    companyurlLabel: "Company URL",
    companyurlPlaceHolder: "Enter Company URL",

    ageLabel: "Age",
    agePlaceHolder: "Enter age",
    dobLabel: "DOB",
    dobPlaceHolder: "Enter date of birth",
    alt_contactphoneLabel: "Alternate number",
    alt_contactphonePlaceHolder: "Enter alternate contactphone number",
    alt_emailidLabel: "Alternate Email ID",
    alt_emailidPlaceHolder: "Enter alternate email id",
    isproductusedLabel: "Product In Use?",
    isproductusedPlaceHolder: "Enter isproductused",
    
    isalreadyparticipateLabel: "Is Already participate",
    islegalentityLabel: "Is Product In Use",
    isalreadyinvestmentLabel: "Is Already Invested",

    profileimageLabel: "Profile Image",
    profileimagePlaceHolder: "Enter Profile image",
    
    otherdocumentLabel: "Other Document",
    otherdocumentPlaceHolder: "Enter Other Document",

    statusLabel: "Status",
    statusPlaceHolder: "Your status is",
    previouscompanyLabel: "About Previous Incubator",
    previouscompanyPlaceHolder: "Enter Previous Incubator",
    aboutcompanyLabel: "About Company",
    aboutcompanyPlaceHolder: "Enter About Company",
    companynameLabel: "Company Name",
    companynamePlaceHolder: "Enter Company Name",
    taglineLabel: "Tag Line",
    taglinePlaceHolder: "Enter Tag Line",

    

    operatinginLabel: "Operating Country",
    operatinginPlaceHolder: "Enter Operating Country",
    stateLabel: "State",
    statePlaceHolder: "Enter  State",
    cityLabel: "City",
    cityPlaceHolder: "Enter  City",
    is_permanent_addresLabel: "Permanent Address",
    is_permaneant_addresPlaceHolder: "Enter Permanent Address ",
    profile_infoLabel: "Profile Info",
    profile_infoPlaceHolder: "Enter Profile Information",
    aboutcofounderLabel: "About Co-Founder",
    aboutcofounderPlaceHolder: "Enter About Co-Founder",
    worked_atLabel: "Worked At",
    worked_atPlaceHolder: "Enter past working experience",
    adhaar_noLabel: "Adhaar Number",
    adhaar_noPlaceHolder: "Enter Adhaar number",
    address_idLabel: "Family ID",
    address_idPlaceHolder: "Enter Family ID",
    pan_noLabel: "Pan number",
    pan_noPlaceHolder: "Enter PAN Number",
    rationcard_noLabel: "Rationcard Number",
    rationcard_noPlaceHolder: "Enter Rationcard Number",
    adhaar_fileLabel: "Adhaar",
    adhaar_filePlaceHolder: "Enter Adhaar File",
    pan_fileLabel: "PAN",
    pan_filePlaceHolder: "Enter PAN File",
    rationcard_fileLabel: "Rationcard File",
    rationcard_filePlaceHolder: "Enter Rationcard File",

    pan_noLabel: "Pan number",
    pan_noPlaceHolder: "Enter PAN Number",
    pan_noLabel: "Pan number",
    pan_noPlaceHolder: "Enter PAN Number",


    isrevenueLabel: "Generating Revenue",
    isrevenuePlaceHolder: "Enter Generating Revenue",
    producturlLabel: "Product URL",
    producturlPlaceHolder: "Enter Product URL",
    demolinkLabel: "Demo Link",
    demolinkPlaceHolder: "Enter Demo Link",
    is_earningLabel: "Employment Status",
    is_earningPlaceHolder: "Enter Employment Status",
    partnershippercentageLabel: "Partnership Percentage",
    partnershippercentagePlaceHolder: "Enter Partnership Percentage",
    isalcoholicLabel: "Is Alcoholic",
    isalcoholicPlaceHolder: "Is Alcoholic",
    landLabel: "Land availability",
    landPlaceHolder: "Enter Land Availability in Acres",
    yieldLabel: "Yield quantity",
    yieldPlaceHolder: "Enter yield quantity",
    earning_farmingLabel: "Earning by farming",
    earning_farmingPlaceHolder: "Enter earning by farming",
    animalsLabel: "Number of Animals",
    animalsPlaceHolder: "Enter Number of Animals",
    earning_animalsLabel: "Earning(Animals)",
    earning_animalsPlaceHolder: "Enter earnings(Animals)",
    handcrafting_skillLabel: "HandCrafting Skill",
    handcrafting_skillPlaceHolder: "Enter Handcrafting Skill",
    financialcrisis_reasonLabel: "Financial crisis",
    financialcrisis_reasonPlaceHolder: "Enter reason for financial crisis",
    business_purposeLabel: "Business Purposal",
    business_purposePlaceHolder: "Enter Business purpose",
    ayushman_noLabel: "Ayushman no",
    business_purposePlaceHolder: "Enter Ayushman number",
    designationLabel: "Designation",
    designationPlaceHolder: "Enter Designation",
    titleLabel: "Title",
    titlePlaceHolder: "Enter Title",
    titleErrorMsg : "Error in Title",
    descriptionLabel: "Description",
    descriptionPlaceHolder: "Enter Description",
    descriptionErrorMsg : "Error in Description",
    category_idLabel: "Industry Category",
    category_idPlaceHolder: "Enter Industry Category",
    category_idErrorMsg : "Error in Industry Category",
    custom_categoryLabel: "Other Industry Category",
    custom_categoryPlaceHolder: "Other Industry Category",

    offerLabel: "Offer",
    offerPlaceHolder: "Enter Offer",
    offerErrorMsg : "Error in Offer",
    priceLabel: "Price",
    pricePlaceHolder: "Enter Price",
    priceErrorMsg : "Error in Price",

    StartupNotificationLabel: "Show Notification",
    CreateStartupButtonLabel: "Create Startup",

    service_idLabel: "Select Service",
    service_idPlaceHolder: "Enter Service",
    service_idErrorMsg : "Error in Service",

    is_corporatedLabel : "Is Corporated" , 
    cityLabel : "City", 
    stateLabel : "State", 
    pincodeLabel : "Pincode", 
    startuplinkdinprofileLabel : "Startup LinkedIn Profile", 
    noofcofounderLabel : "Number Of Cofounders", 
    companysizeLabel : "Number of Employees", 
    productofferingLabel : "Product/Service Offering(100 characters max)", 
    fundingtypeLabel : "Funding Type", 
    // revenuemodaltypeLabel : "Revenue Model", 
    // bussinessmodaltypeLabel : "Business Model", 
    capitalraisedLabel : "Capital Raised (INR)", 
    revenueLabel : "Revenue (Last Year)", 
    currentstageLabel : "Current Stage Of Product/Service", 
    askLabel : "Ask (INR)", 
    
    
    is_corporatedPlaceholder : "Is Corporated" , 
    cityPlaceholder : "City", 
    statePlaceholder : "State", 
    pincodePlaceholder : "Pincode", 
    startuplinkdinprofilePlaceholder : "Startup LinkedIn Profile", 
    noofcofounderPlaceholder : "Number Of Cofounders", 
    companysizePlaceholder : "Number of Employees", 
    productofferingPlaceholder : "Product/Service Offering(100 characters max)", 
    fundingtypePlaceholder : "Funding Type", 
    // revenuemodaltypePlaceholder : "Revenue Model", 
    // bussinessmodaltypePlaceholder : "Business Model", 
    capitalraisedPlaceholder : "Capital Raised (INR)", 
    revenuePlaceholder : "Revenue (Last Year)", 
    currentstagePlaceholder : "Current Stage Of Product/Service", 
    askPlaceholder : "Ask (INR)", 
    
    

  },
    Members:{
      pageTitle:"Members",
      tableTitle:"Add Members",
      submitBtn:"Create Member",
    },
    AddStartup:{
      pageTitle:"Add Member",      
      submitBtn:"Create Member",
    },
    EditStartup:{
      pageTitle:"Edit Startup",      
      submitBtn:"Update Startup",
    },    
    AddCoFounder:{
      pageTitle:"Add Co-Founder",      
      submitBtn:"Create Co-Founder",
    },
    EditCoFounder:{
      pageTitle:"Edit Co-Founder",      
      submitBtn:"Update Co-Founder",
    },  
    AddStartupDocument:{
      pageTitle:"Add Document",      
      submitBtn:"Create Document",
    },
    EditStartupDocument:{
      pageTitle:"Edit Document",      
      submitBtn:"Update Document",
    },  

    Members:{
      pageTitle:"Members",
      tableTitle:"Add Members",
      submitBtn:"Create Member",
    },
    AddMember:{
      pageTitle:"Add Member",      
      submitBtn:"Create Member",
      membernameLabel:"Name",      
      memberemailLabel:"Email",      
      memberphoneLabel:"Phone",      
      memberspecializationLabel:"Specialization(max 20 Character)",
      memberexcerptLabel:"Short note(max 120 Character)",
      membersummaryLabel:"Summary(max 250 Character)",      	  
      memberaddress_idLabel:"Address",      
      membercompanyLabel:"Company Name",   
      memberlinkedinLabel:"LinkedIn URL",      
      memberfacebookLabel:"Facebook URL",      	  
      memberprofilefileLabel:"Profile Image",   
      membercontractfileLabel:"Contract File",      
      memberndafileLabel:"NDA File"	       , 
      is_companyLabel:"Is a Company or Individual",
      is_corporatedLabel:"Is Your StartUp Corporated",
      startupNameLabel:"StartUp Name",
      startupNamePlaceholder:"Enter startup name",
      taglineLabel:"Tagline",
      industryLabel:"Industry",
      cityLabel:"City",
      stateLabel:"State",
      pincodeLabel:"Pincode",
      startuplinkdinprofileLabel:"StartUp LinkdIn Profile",
      datefoundedLabel:"Date Founded",
      noofcofounderLabel:"No. of Co-Founders",
      companysizeLabel:"Company Size",
      startupwebsiteLabel:"StartUp Webstie",
      phoneLabel:"Phone",
      productofferingLabel:"Product Offering",
      fundingtypeLabel:"Funding Type",
      revenuemodaltypeLabel:"Revenue Model Type",
      bussinessmodaltypeLabel:"Business Model Type",
      capitalraisedLabel:"Capital Raised",
      revenueLabel:"Revenue" , 
      financialLabel:"Financial", 
      currentstageLabel:"Current Stage", 
      seekingLabel:"Seeking Label", 
      askLabel:"Ask", 
      keymemberslinkedinprofileLabel:"Key Members LinkedIn Profile",
      companynameLabel:"Startup Name",
      companynamePlaceHolder:"Enter Startup Name",

      category_idLabel:"Industry Type",
      companyurlLabel:"Website URL",
      contactphoneLabel:"Contact Phone Number",

    },
    EditMember:{
      pageTitle:"Edit Member",      
      submitBtn:"Update Member",
    }, 
    ViewMember:{
      pageTitle:"View Member",      
      viewServices:"View Services",
    },   
    
    AddInvestor:{
      pageTitle:"Add Investor",      
      submitBtn:"Create Investor",
	  investortypeLabel:"Investor Type",
	  investornameLabel:"Name",      
	  investortitleLabel:"Designation",      
	  investoremailLabel:"Email",      
	  investorphoneLabel:"Phone",      
	  investoractivatedLabel:"Activated",      
	  investorsummaryLabel:"Summary",      
	  investorwebsiteLabel:"Company Website",      
	  investorccompanyLabel:"Company Name",      
	  isalreadyparticipateLabel:"Already Participated",      
	  islegalentityLabel:"Is Legal Entity",      
	  isalreadyinvestmentLabel:"Are you Already Invested",      
	  investoraddress_idLabel:"Address",      
	  investorprofileimageLabel:"Profile Image",      
	  investorcontractfileLabel:"ContractFile",      
	  investorcompanylogoLabel:"Compnay Logo"
	  
    },
    EditInvestor:{
      pageTitle:"Edit Investor",      
      submitBtn:"Update Investor",
    },   
    ViewInvestor:{
      pageTitle:"View Investor",      
      submitBtn:"Update Investor",
    },         
    ViewStartup : {
      pageTitle:"View Profile",      
      submitBtn:"Update Profile",
    },
    AddServicePage:{
      pageTitle:"Add Service In Portfolio",      
      submitBtn:"Add Service",
    }
   
};

export { en_source };
