import React, {useState} from 'react';
import FaArrowCircleUp from "@material-ui/icons/ArrowUpward";
// import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
// import { Button } from './Styles';
  
const ScrollButton = () =>{
  
  const [visible, setVisible] = useState(false)
  
  const toggleVisible = () => {
    const scrolled = document.documentElement.scrollTop;
    if (scrolled > 300){
      setVisible(true)
    } 
    else if (scrolled <= 300){
      setVisible(false)
    }
  };
  
  const scrollToTop = () =>{
    window.scrollTo({
      top: 0, 
      behavior: 'smooth'
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };
  
  window.addEventListener('scroll', toggleVisible);
  
  return (
    // <div className="scroll-button " style={{display: visible ? 'inline' : 'none'}}>
    //  <FaArrowCircleUp onClick={scrollToTop} className="scroll-button-icon" />
    //  </div>
    <div onClick={scrollToTop} className="scroll-button" id="btnScrollToTop" title="Go to top" style={{display: visible ? 'inline' : 'none'}}>Top</div>

  );
}
  
export default ScrollButton;