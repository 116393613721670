import React, { Fragment, useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";
import ReCAPTCHA from "react-google-recaptcha";

// COMPONENTS
import Footer from "../../components/footer";
import Header from "../../components/header";

// APIS
import { sendContactUS } from "../../APIs/ApiUtils";

import PlaceholderIcon from "../../assets/images/placeholder.svg";
import SmartphoneIcon from "../../assets/images/smartphone.svg";
import EnvelopeIcon from "../../assets/images/envelope.svg";

import Checkout from "./components/details";

function ContactUs() {
  const [nameUser, setNameUser] = useState(undefined);
  const [nameUserError, setNameUserError] = useState(false);
  const [emailUser, setEmailUser] = useState(undefined);
  const [emailUserError, setEmailUserError] = useState(false);
  const [messageUser, setMessageUser] = useState(undefined);
  const [messageUserError, setMessageUserError] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);

  useEffect(() => {

    

  }, []);

  async function handleSubmit() {
    let RC = false;

    if (nameUser.length == 0) setNameUserError(true);

    if (emailUserError.length == 0) setEmailUserError(true);

    if (messageUser.length == 0) setMessageUserError(true);

    setIsSubmitLoading(true);
    let param = {
      email: emailUser,
      name: nameUser,
      message: messageUser,
    };

    RC = await sendContactUS(param);
    if (RC == true) {
      console.log("Contact us Send Successfully");
      setEmailUser(undefined);
      setNameUser(undefined);
      setMessageUser(undefined);
      setIsSubmitLoading(false);
      return true;
    }
    setIsSubmitLoading(false);
    console.log("ContactUs Failed");
  }

  return (
    <Fragment>
      <div className="single-page about-page">
        <Header />
            {/* <!-- Home --> */}
            <div className="home innerpage">
                <div className="home_background_container prlx_parent">
                    <div className="home_background prlx" ></div>
                </div>
                <div className="home_content">
                    <h1>Checkout</h1>
                </div>
            </div>

            {/* <!-- Checkout --> */}
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <Checkout />
                    </div>
                </div>
            </div>            
        <Footer />
      </div>
    </Fragment>
  );



}

export default ContactUs;
