import React from 'react'
import { useEffect } from 'react';
import { useState } from 'react';
import CircularStatic from './CircularProgressBar';
import { getTreeAPI } from '../../APIs/commentsApis';
export default function CustomTree({treeData}) {
    const [isFormLoading, setIsFormLoading] = useState(false);
  
  return (
    <>
    {isFormLoading ? (
        <div className={{ alignContents: "center",  }}>
            {/* <CircularProgress variant="indeterminate" value={75} /> */}
            <CircularStatic value={25} />
        </div>
        ) : (
    <ul>
      {treeData != undefined && treeData.map((node) => (
        <TreeNode node={node} key={node.id} />
      ))}
    </ul>)}
    </>
  )
}
function TreeNode({ node }) {
    const { children, comment } = node;
    console.log("treenode params :: ",children,comment)
    const [showChildren, setShowChildren] = useState(false);
  
    const handleClick = () => {
      setShowChildren(!showChildren);
    };
    return (
      <>
        <div onClick={handleClick} style={{ marginBottom: "10px" ,Border:"2px solid black"}}>
          <span>{comment}</span>
        </div>
        <ul style={{ paddingLeft: "10px", borderLeft: "1px solid black" }}>
          {showChildren && <CustomTree treeData={children} />}
        </ul>
      </>
    );
  }