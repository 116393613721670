import React from "react";
import { Component } from "react";
import ReactDOM from "react-dom";
// import Moment from 'react-moment';
import moment from "moment";

import {
  Typography,
  Paper,
  TextField,
  Link,
  Radio,
  Select,
  Grid,
  Button,
  CssBaseline,
  RadioGroup,
  FormLabel,
  MenuItem,
  FormGroup,
  FormControl,
  FormControlLabel,
  InputLabel,
  Input,
  FormHelperText,
  Divider,
} from "@material-ui/core";
import { DropzoneDialog } from "material-ui-dropzone";

// Picker
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  TimePicker,
  DatePicker,
  KeyboardDatePicker,
} from "@material-ui/pickers";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// MATERIAL UI DEPENDENCIES
import { makeStyles } from "@material-ui/styles";

import CircularProgress from "@material-ui/core/CircularProgress";
import MUIDataTable from "mui-datatables";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import IconButton from "@material-ui/core/IconButton";
import Tooltip from "@material-ui/core/Tooltip";

// MATERIAL ICONS
import DeleteIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import PersonIcon from "@material-ui/icons/Person";
import KeyboardArrowRightIcon from "@material-ui/icons/KeyboardArrowRight";
import RemoveIcon from "@material-ui/icons/Remove";
import Visibility from "@material-ui/icons/Visibility";
import SearchIcon from "@material-ui/icons/YoutubeSearchedFor";
import PrintIcon from "@material-ui/icons/Receipt";
import UploadIcon from "@material-ui/icons/CloudUploadRounded";
import ViewColumnIcon from "@material-ui/icons/DynamicFeed";
import FilterIcon from "@material-ui/icons/GroupWork";

// FOR APIS
import axios from "axios";
import API, { API_Param } from "../../API";
import {
  getInvestorsAPI,
  getInvestorAPI,
  createInvestorAPI,
  editInvestorAPI,
  // getCoFoundersAPI,
  // deleteCoFounderAPI,
  // createInvestorDocumentAPI,
  // getInvestorDocumentAPI,
  // editInvestorDocumentAPI,
  // deleteInvestorDocumentAPI,
} from "../../APIs/investorApis";

import {
  isErrorExist,
  getAge,
  checkTextField,
  checkDateField,
  checkNumberField,
  checkRadioField,
  checkSelectField,
  ValidateFields,
  getImageAPI,
} from "../../APIs/ApiUtils";

import CustomTextField from "../../components/Controls/CustomTextField";
import CustomSelectField from "../../components/Controls/CustomSelectField";
import CustomRadioButton from "../../components/Controls/CustomRadioButton";
import CustomUploadButton from "../../components/Controls/CustomUploadButton";

// COMPONENTS
import PageTitle from "../../components/PageTitle/PageTitle";
import Widget from "../../components/Widget/Widget";
import Table from "../dashboard/components/Table/Table";

// data
import mock from "../dashboard/mock";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import Slide from "@material-ui/core/Slide";

import { Trans } from "react-i18next";
import i18n from "../../locales/locales_config";
//const userimage = require('./../../assets/images/userimage.png')
import userimage from "./../../assets/images/userimage.png";
var requiredParam = [
  "investortype",
  "investorname",
  "investortitle",
  "investoremail",
  "investorphone",
  "investoractivated",
  "investorsummary",
  "investorwebsite",
  "investorccompany",
  "isalreadyparticipate",
  "islegalentity",
  "isalreadyinvestment",
  // "investoraddress_id",
  "investorprofileimage",
  "investorcontractfile",
  "investorcompanylogo",
];

let categories = [
  {
    value: 1,
    label: "Agriculture",
  },
  {
    value: 2,
    label: "Aerospace",
  },
];

let investortypes = [
  {
    value: "INDIVIDUAL",
    label: "INDIVIDUAL",
  },
  {
    value: "COMPANY",
    label: "COMPANY",
  },
  {
    value: "TEAM",
    label: "TEAM",
  },
];


export default class EditInvestor extends Component {
  constructor() {
    super();
    this.state = {
      volunteersTable: [],
      isFormLoading: false,
      isSubmitLoading: false,
      isError: false,
      deleteDialogCoFounder: false,
      deleteDialogInvestorDocument: false,
      cofounders: [],
      documents: [],
      profilePicture: "",
    };
  }

  componentDidMount() {
    this.setStateVariables("investortype",{ checkEmpty: false },"SelectField",);
    this.setStateVariables("investorname", {checkEmpty: true,minLength: 0,maxLength: 120,});
    this.setStateVariables("investortitle", {checkEmpty: true,minLength: 0,maxLength: 20,});
    this.setStateVariables("investoremail", { checkEmpty: false,minLength: 0,maxLength: 120,});
    this.setStateVariables("investorphone", {checkEmpty: true,minLength: 0,maxLength: 20,});
    this.setStateVariables("investoractivated",{ checkEmpty: false },"SelectField","ACTIVE",);
    this.setStateVariables("investorsummary", {checkEmpty: false, minLength: 0, maxLength: 200,});
    this.setStateVariables("investorwebsite", { checkEmpty: false,minLength: 0, maxLength: 200,});
    this.setStateVariables("investorccompany",      { checkEmpty: false,minLength: 0, maxLength: 120 },    );
    this.setStateVariables("isalreadyparticipate", { checkEmpty: false }, "RadioField","NO");
    this.setStateVariables("islegalentity", { checkEmpty: false }, "RadioField","NO");
    this.setStateVariables("isalreadyinvestment", { checkEmpty: false }, "RadioField","NO");
    // this.setStateVariables("investoraddress_id", { checkEmpty: false, maxLength: 8 });
    this.setStateVariables("investorprofileimage", { checkEmpty: false }, "FileField");    
    this.setStateVariables("investorcontractfile",{ checkEmpty: false },"FileField",);
    this.setStateVariables("investorcompanylogo", { checkEmpty: false }, "FileField");

    this.initialize();
  }

  initialize = async () => {
    await this.handleGetInvestor();
    // await this.handleGetInvestorDocument();

  };

  render() {
    const { t } = this.props;

    const customStyles = {
      option: (provided, state) => ({
        ...provided,
        borderBottom: "1px dotted pink",
        color: state.isSelected ? "red" : "blue",
        padding: 20,
      }),
      control: () => ({
        // none of react-select's styles are passed to <Control />
        width: 200,
      }),
      singleValue: (provided, state) => {
        const opacity = state.isDisabled ? 0.5 : 1;
        const transition = "opacity 300ms";

        return { ...provided, opacity, transition };
      },
    };

    return (
      <>
        {this.state.isFormLoading ? (
          <div className={{ alignContents: "center" }}>
            <CircularProgress variant="indeterminate" value={75} />
          </div>
        ) : (
          <div>
            <Grid container spacing={16}>
              {/* ========= TITLE BAR ==========  */}
              <Grid item xs={12} padding={0}>
                <PageTitle
                  title={<Trans>EditInvestor.pageTitle</Trans>}
                  button={
                    <Button
                      variant="outlined"
                      color="secondary"
                      onClick={() => {
                        this.props.history.push("/profile/investor/view/:investorId");
                      }}
                    >
                      Back
                    </Button>
                  }
                />
              </Grid>

              <Grid
                item
                xs={12}
                mt={4}
                className={{ justifyContent: "center" }}
              >
                <Paper style={{ padding: 16 }}>
                  <Typography
                    color="primary"
                    variant="h5"
                    size="sm"
                    style={{
                      borderBottom: "1px solid #000",
                      marginBottom: "10px",
                    }}
                  >
                    Personal Info
                  </Typography>
                  <Grid container alignItems="flex-start" spacing={2}>
                    <Grid
                      item xs={12} sm={6} md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomSelectField
                        paramValue="investortype"
                        required="required"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={investortypes}
                      />
                    </Grid>
                    <Grid
                      item xs={12} sm={6} md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="investorname"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item
                      align="center"
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <div>
                        <Typography>Profile Picture</Typography>

                        <img
                          src={
                            this.state.profilePicture == " "
                              ? userimage
                              : this.state.profilePicture
                          }
                          height={150}
                          width={150}
                        />
                      </div>
                    </Grid>
                    <Grid
                      item xs={12} sm={6} md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="investoremail"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item xs={12} sm={6} md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          required="required"
                          paramValue="investortitle"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item
                      align="center"
                      xs={12}
                      sm={6}
                      md={4}
                      className={{ justifyContent: "center" }}
                    >
                      {
                        <CustomUploadButton
                          paramValue="investorprofileimage"
                          fileName={this.state.investornameValue + "_investorprofileimage"}
                          state={this.state}
                          showDownload={true}
                          acceptedFiles={[
                            "image/jpeg",
                            "image/png",
                            "image/bmp",
                            "application/pdf",
                          ]}
                          handleFileOpen={this.handleFileOpen}
                          handleFileClose={this.handleFileClose}
                          handleFileSave={this.handleFileSave}
                        />
                      }
                    </Grid>
                    <Grid
                      item xs={12} sm={6} md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="investorphone"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>
                    <Grid
                      item xs={12} sm={6} md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomSelectField
                        paramValue="investoractivated"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "",
                            label: "Select Status",
                            selected: "selected",
                          },
                          {
                            value: "ACTIVE",
                            label: "Active",
                          },
                          {
                            value: "INACTIVE",
                            label: "Inactive",
                          },
                          {
                            value: "PENDING",
                            label: "Pending",
                          },
                        ]}
                      />
                    </Grid>
                    {/* <Grid
                      item xs={12} sm={6} md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="investoraddress_id"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid> */}
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="investorsummary"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>



                    <Grid container alignItems="flex-start" spacing={2} mt={4}>
                    <Grid
                      item
                      mt={8}
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography color="primary" variant="h5" size="sm" mt={4}>
                        Company Details
                      </Typography>
                      <Divider style={{ margin: "5px 0 15px 0" }} />
                    </Grid>

                    <Grid
                      item xs={12} sm={6} md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="investorccompany"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item xs={12} sm={6} md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      {
                        <CustomTextField
                          paramValue="investorwebsite"
                          state={this.state}
                          onChange={this.handleParam}
                        />
                      }
                    </Grid>

                    <Grid
                      item xs={12} sm={6} md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomRadioButton
                        paramValue="islegalentity"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "YES",
                            label: "Yes",
                          },
                          {
                            value: "NO",
                            label: "No",
                          },
                        ]}
                      />
                    </Grid>
                    <Grid
                      item xs={12} sm={6} md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomRadioButton
                        paramValue="isalreadyinvestment"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "YES",
                            label: "Yes",
                          },
                          {
                            value: "NO",
                            label: "No",
                          },
                        ]}
                      />
                    </Grid>
                    <Grid
                      item xs={12} sm={6} md={4}
                      className={{ justifyContent: "flex-start" }}
                    >
                      <CustomRadioButton
                        paramValue="isalreadyparticipate"
                        state={this.state}
                        onChange={this.handleParam}
                        ItemValues={[
                          {
                            value: "YES",
                            label: "Yes",
                          },
                          {
                            value: "NO",
                            label: "No",
                          },
                        ]}
                      />
                    </Grid>





                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography
                        color="primary"
                        variant="h5"
                        size="sm"
                        style={{
                          borderBottom: "1px solid #000",
                          marginTop: 30,
                        }}
                      >
                        Documents
                      </Typography>
                    </Grid>

                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Company Logo
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <CustomUploadButton
                        paramValue="investorcompanylogo"
                        fileName={this.state.investornameValue + "_uploadedform"}
                        maxFileNo={2}
                        state={this.state}
                        showDownload={true}
                        acceptedFiles={[
                          "image/jpeg",
                          "image/png",
                          "image/bmp",
                          "application/pdf",
                        ]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <Typography style={{ color: "black", fontSize: "20" }}>
                        Contract Document
                      </Typography>
                    </Grid>
                    <Grid
                      item
                      xs={6}
                      sm={6}
                      md={3}
                      alignItems="center"
                      className={{ justifyContent: "center" }}
                    >
                      <CustomUploadButton
                        paramValue="investorcontractfile"
                        fileName={this.state.investornameValue + "_rationcard"}
                        state={this.state}
                        showDownload={true}
                        acceptedFiles={[
                          "image/jpeg",
                          "image/png",
                          "image/bmp",
                          "application/pdf",
                        ]}
                        handleFileOpen={this.handleFileOpen}
                        handleFileClose={this.handleFileClose}
                        handleFileSave={this.handleFileSave}
                      />
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="column"
                    justify="center"
                    alignItems="flex-end"
                    mt={2}
                  >
                    <Grid item style={{ marginTop: 25 }} xs={3}>
                      <div>
                        {this.state.isSubmitLoading ? (
                          <CircularProgress size={26} />
                        ) : (
                          <Button
                            onClick={this.handleSubmit}
                            // disabled={
                            //   loginValue.length === 0 ||
                            // }
                            type="submit"
                            variant="contained"
                            color="primary"
                            size="small"
                            style={{
                              justifySelf: "center",
                              alignSelf: "center",
                            }}
                          >
                            Update Investor
                          </Button>
                        )}
                      </div>
                    </Grid>

                    <Grid item style={{ marginTop: 25 }} xs={12}>
                      {this.state.isError ? (
                        <Typography style={{ color: "red" }}>
                          Error coming, Please check form
                        </Typography>
                      ) : (
                        ""
                      )}
                    </Grid>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    justify="space-between"
                    alignItems="center"
                    spacing={2}
                  >
                    <Grid
                      item
                      xs={12}
                      className={{ justifyContent: "space-between" }}
                    >
                      <MUIDataTable
                        title="Other Uploaded Documents"
                        data={this.state.documents}
                        columns={[
                          {
                            name: "id",
                            options: {
                              filter: false,
                              sort: false,
                              empty: true,
                              display: "excluded",
                            },
                          },
                          {
                            name: "title",
                            label: "Title",
                          },
                          {
                            name: "description",
                            label: "Description",
                          },

                          {
                            name: "Actions",
                            options: {
                              filter: false,

                              sort: false,
                              empty: true,
                              customBodyRender: (
                                value,
                                tableMeta,
                                updateValue,
                              ) => {
                                return this.customActionRenderDocument(
                                  value,
                                  tableMeta,
                                  updateValue,
                                );
                              },
                            },
                          },
                        ]}
                        options={{
                          tableBodyMaxHeight: "400px",
                          selectableRows: false,
                          fixedHeader: true,
                          filter: false,
                          print: false,
                          download: false,
                          elevation: 0,
                          filterType: "dropdown",
                          responsive: "standard",
                          expandableRows: false,
                        }}
                        // components={components}
                      />
                      <Grid
                        container
                        direction="column"
                        justify="center"
                        alignItems="flex-end"
                        mt={2}
                      >
                        <Button
                          style={{ marginTop: 10 }}
                          variant="contained"
                          color="primary"
                          size="small"
                          mt={2}
                          onClick={() => {
                            this.props.history.push("/app/startup/document/add/:startupId");
                          }}
                        >
                          Add Document
                        </Button>
                      </Grid>


                    </Grid>
                  </Grid>
                </Paper>
              </Grid>
            </Grid>
          </div>
        )}
      </>
    );
  }

  handleGetInvestor = async () => {
    let RC = false;
    this.setState({ isFormLoading: true, investorInfo: {} });
    let investor_id = this.props.match.params.itemId;

    let investorInfo = await getInvestorAPI(investor_id);
    if (investorInfo != undefined) {
      let tmp = {};
      Object.entries(investorInfo).map(([key, value]) => {
        if (requiredParam.includes(key)) tmp[key + "Value"] = value;
        // if (key == "volunteers") tmp["investortype" + "Value"] = value[0].id;
      });
      console.log(
        "Investor Found Successfully in edit response",
        JSON.stringify(tmp),
      );

      let param = {
        filePath: tmp["investorprofileimageValue"],
        fileName: "investorprofileimage",
      };

      let blob = await getImageAPI(param);
      console.log("response from getImage is" + blob);

      this.setState({
        ...tmp,
        isFormLoading: false,
        profilePicture: blob,
      });
    } else {
      console.log("Investor Not found");
      this.setState({ isFormLoading: false });
    }
  };

  handleEditInvestor = async (param) => {
    console.log("createInvestor API Started");
    let investor_id = this.props.match.params.itemId;
    
    let rc = await editInvestorAPI(investor_id, param);
    if (rc == true) {
      console.log("Investor Updated Successfully");
      this.setState({ isSubmitLoading: false });
      this.props.history.push("/app/investors");
    } else {
      this.setState({ isSubmitLoading: false });
      console.log("Investor Not Updated");
    }
    console.log("createInvestor API ENDED");
  };

  // handleGetCoFounders = async () => {
  //   let RC = false;
  //   this.setState({ isFormLoading: true, investorInfo: {} });
  //   let investor_id = localStorage.getItem("CURRENT_INVESTOR");

  //   let cofounders = await getCoFoundersAPI(investor_id);
  //   if (cofounders != undefined) {
  //     console.log("Co-Founders Found Successfully", cofounders);

  //     this.setState({
  //       cofounders: cofounders,
  //       isFormLoading: false,
  //     });
  //   } else {
  //     console.log("Co-Founders Not found");
  //     this.setState({ isFormLoading: false });
  //   }
  // };

  // handleEditCoFounder = (value, tableMeta, updateValue) => {
  //   localStorage.setItem("CURRENT_CoFounder", tableMeta.rowData[0]);
  //   this.props.history.push("/app/editCoFounder");
  // };

  // handleDeleteCoFounder = async (value, tableMeta, updateValue) => {
  //   console.log("tableMeta :: ", tableMeta.rowData);
  //   this.handleCloseCoFounder();
  //   let rc = await deleteCoFounderAPI(tableMeta.rowData[0]);
  //   if (rc == true) {
  //     console.log("CoFounder Deleted Successfully");
  //     this.handleGetCoFounders();
  //     //toast.success("CoFounder Deleted");

  //     const Page = () => {
  //       <div>
  //         <ToastContainer />
  //         <button onClick={() => toast("Toast Message")}>
  //           show notification
  //         </button>
  //       </div>;
  //     };
  //   } else console.log("CoFounder Not Deleted");
  // };

  // handleCreateInvestorDocument = async (param) => {
  //   console.log("createInvestor API Started");
  //   let investor_id = localStorage.getItem("CURRENT_INVESTOR");
  //   let rc = await createInvestorDocumentAPI(investor_id, param);
  //   if (rc == true) {
  //     console.log("Investor Document Added Successfully");
  //     this.setState({ isSubmitLoading: false });
  //     this.props.history.push("/app/editinvestor");
  //   } else {
  //     this.setState({ isSubmitLoading: false });
  //     console.log("Investor Documfdoent Not Added");
  //   }
  //   console.log("createInvestor API ENDED");
  // };

  // handleGetInvestorDocument = async () => {
  //   let RC = false;
  //   this.setState({ isFormLoading: true, documents: [] });
  //   let investor_id = localStorage.getItem("CURRENT_INVESTOR");

  //   let documents = await getInvestorDocumentAPI(investor_id);
  //   if (documents != undefined) {
  //     console.log("Documents Found Successfully", documents);
  //     this.setState({
  //       documents: documents,
  //       isFormLoading: false,
  //     });
  //   } else {
  //     console.log("Investor Document Not found");
  //     this.setState({ isFormLoading: false });
  //   }
  // };

  // handleEditInvestorDocument = async (value, tableMeta, updateValue) => {
  //   localStorage.setItem("CURRENT_INVESTORDOCUMENT", tableMeta.rowData[0]);
  //   this.props.history.push("/app/editdocument");
  // };

  // handleDeleteInvestorDocument = async (value, tableMeta, updateValue) => {
  //   console.log("tableMeta :: ", tableMeta.rowData);
  //   this.handleCloseInvestorDocument();
  //   let rc = await deleteInvestorDocumentAPI(tableMeta.rowData[0]);
  //   if (rc == true) {
  //     this.handleGetInvestorDocument();

  //     console.log("Investor Document Deleted Successfully");
  //     //toast.success("Document Deleted");

  //     const Page = () => {
  //       <div>
  //         <ToastContainer />
  //         <button onClick={() => toast("Toast Message")}>
  //           show notification
  //         </button>
  //       </div>;
  //     };
  //   } else console.log("Document Not Deleted");
  // };

  handleSubmit = () => {
    let param = {};

    // GET THE VALUES ONLY
    Object.entries(this.state).map(([key, value]) => {
      if (key.includes("Value")) param[key.replace("Value", "")] = value;
    });

    console.log("<INFO> Param Value ", param);
    let errors = ValidateFields(this);
    console.log("Error exist of Not ", errors);

    if (errors) {
      return false;
    }
    this.setState({ isSubmitLoading: true });

    this.handleEditInvestor(param);
  };

  handleParam = (e, paramKey, directValue = false) => {
    let value = e;
    if (!directValue)
      if (e.target != null && e.target.value != undefined)
        value = e.target.value;

    let paramKeyError = paramKey + "ErrorStatus";

    this.setState((prevState, props) => ({
      ...prevState,
      [paramKey + "Value"]: value,
      [paramKeyError]: false,
    }));

    let isExist = isErrorExist(this.state);
    if (this.state.isError && !isExist) this.setState({ isError: false });
  };

  handleFileClose = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: false,
    });
  };

  handleFileSave = (param, files) => {
    console.log("Current File List :: ", param, files[0]);
    this.setState({
      ...this.state,
      [param + "Value"]: files[0],
      [param + "MediaOpen"]: false,
    });
  };

  handleFileOpen = (param) => {
    this.setState({
      ...this.state,
      [param + "MediaOpen"]: true,
    });
  };


  // handleDeleteDialogDocument = (value, tableMeta, updateValue) => {
  //   this.setState({ deleteDialogInvestorDocument: true });
  // };

  // handleCloseInvestorDocument = () => {
  //   this.setState({ deleteDialogInvestorDocument: false });
  // };

  // handleCloseCoFounder = () => {
  //   this.setState({ deleteDialogCoFounder: false });
  // };

  handleDeleteDialogInvestor = (value, tableMeta, updateValue) => {
    this.setState({ deleteDialogCoFounder: true });
  };

  setStateVariables = (
    param,
    validation = { checkEmpty: false },
    type = "TextField",
    value = "",
    error = false,
  ) => {
    let paramLabel = param + "Label";
    let paramID = param + "ID";
    let paramPlaceHolder = param + "PlaceHolder";
    let paramValue = param + "Value";
    let paramErrorStatus = param + "ErrorStatus";
    let paramErrorMsg = param + "ErrorMsg";
    let paramFieldType = param + "FieldType";
    let paramFieldValidation = param + "FieldValidation";

    let transLabel = "AddInvestor." + param + "Label";
    let transPlaceHolder = "AddInvestor." + param + "PlaceHolder";
    let transErrorMsg = "AddInvestor." + param + "ErrorMsg]";

    this.setState({
      [paramLabel]: <Trans i18nKey={transLabel}></Trans>,
      [paramID]: param,
      [paramPlaceHolder]: <Trans i18nKey={transPlaceHolder}></Trans>,
      [paramValue]: value,
      [paramErrorStatus]: error,
      [paramErrorMsg]: <Trans i18nKey={transErrorMsg}></Trans>,
      [paramFieldType]: type,
      [paramFieldValidation]: validation,
    });
  };
}

