import React, { useState } from "react";
import {
  Grid,
  CircularProgress,
  Typography,
  Button,
  Tabs,
  Tab,
  TextField,
  Fade,
} from "@material-ui/core";
import { withRouter } from "react-router-dom";
import classnames from "classnames";

// styles
import useStyles from "./styles";

// logo
import logo from "./logo.svg";
import startupMoto from '../../assets/images/startup-moto.png'
import google from "../../assets/images/google.svg";

// context
import { EmailOutlined } from "@material-ui/icons";

import {
  useUserDispatch,
  loginUser,
  registerUser,
  isEmailExist,
  isPhoneExist,
  resetPassword,
  verifyOTPs,
} from "../../context/userContext";

function ResetPassword(props) {
  var classes = useStyles();

  // global
  var userDispatch = useUserDispatch();

  // local
  var [isLoading, setIsLoading] = useState(false);
  var [error, setError] = useState(null);
  var [message, setMessage] = useState("");
  var [email, setEmail] = useState(props.location.state.email);
  var [phone, setPhone] = useState(props.location.state.phone);
  var [password, setPassword] = useState("");
  var [confirmPassword, setConfirmPassword] = useState("");

  function handleResetPassword() {
    if (password == confirmPassword) {
      let RC = resetPassword(
        userDispatch,
        email,
        phone,
        password,
        props.history,
        setIsLoading,
        setError,
      );
    } else {
      setError(true);
      setMessage("Password does not match");
    }
  }

  console.log(
    "<INFO> Get Props for resetpassword ",
    props.location.state.email,
  );

  return (
    <Grid container className={classes.container}>
      <div className={classes.formContainer}>
        <div className={classes.form}>
          <React.Fragment>
            <Typography variant="h3" className={classes.greeting}>
              Enter New Password
            </Typography>
            <Fade in={error}>
              <Typography color="secondary" className={classes.errorMessage}>
                {message}
              </Typography>
            </Fade>
            <TextField
              id="password"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              onChange={(e) => setPassword(e.target.value)}
              margin="normal"
              placeholder="Enter Password"
              type="password"
              fullWidth
            />
            <TextField
              id="confirmpassword"
              InputProps={{
                classes: {
                  underline: classes.textFieldUnderline,
                  input: classes.textField,
                },
              }}
              onChange={(e) => setConfirmPassword(e.target.value)}
              margin="normal"
              placeholder="Confirm Password"
              type="password"
              fullWidth
            />
            <div className={classes.formButtons}>
              {isLoading ? (
                <CircularProgress size={26} className={classes.loginLoader} />
              ) : (
                <Button
                  disabled={
                    !(password.length > 0 || confirmPassword.length > 0)
                  }
                  onClick={handleResetPassword}
                  variant="contained"
                  color="primary"
                  size="large"
                >
                  Submit
                </Button>
              )}
            </div>
          </React.Fragment>
        </div>
      </div>
    </Grid>
  );
}

export default withRouter(ResetPassword);
