import React, { Fragment,useRef, useState, useEffect } from "react";
import {
  Link,
  useHistory,
} from "react-router-dom";

// MEDIA FILES
import icon_infrastructure_facility from "../../../assets/images/icons/icon_infrastructure_facility.svg";
import icon_service_finance from "../../../assets/images/icons/icon_service_finance.svg";
import icon_brand_building from "../../../assets/images/icons/icon_brand_building.svg";
import icon_business_services from "../../../assets/images/icons/icon_business_services.svg";
import icon_education_mentoring from "../../../assets/images/icons/icon_education_mentoring.svg";
import icon_People_Connect_networking from "../../../assets/images/icons/icon_People_Connect_networking.svg";

// CUSTOM COMPONENTS
import  CircularStatic from '../../../components/Controls/CircularProgressBar';
import { Button,Modal} from 'react-bootstrap';


// FOR APIS
import {
  getServicesAPI,
  getServiceAPI,
  getParentLevelServicesAPI
} from "../../../APIs/serviceApis";
import { element } from "prop-types";


function Module() {
  const history = useHistory();
  const [serviceId, setServiceId] = useState(undefined);
  const [serviceInfo, setServiceInfo] = useState(undefined);
  
  const [servicesTable, setServicesTable] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [page, setPage] = useState(undefined);
  const [limit, setLimit] = useState(undefined);
  const [showServiceDetail, setShowServiceDetail] = useState(false);
  

  useEffect(() => {
    handleGetServices();
    // let queries = queryString.parse(history.location.search);
    // console.log("<QUERY STRING > ::", queries);
    // if (queries.example1) {
    //   example1.current.scrollIntoView();
    // }
  }, []);  

  return (
    <>
      {isFormLoading ? (
        <div className={{ alignContents: "center",  }}>
          {/* <CircularProgress variant="indeterminate" value={75} /> */}
          <CircularStatic value={100} />
        </div>
      ) : (
      <Fragment>
          { /* Services */ }
          <div className="services page_section">

            <div className="container">
              <div className="row">
                <div className="col">
                  <div className="section_title text-center">
                    <h1>Our Services</h1>
                  </div>
                </div>
              </div>

              <div className="row services_row  justify-content-center">
                <Modal dialogClassName='cls-model-services' show={showServiceDetail} onHide={()=>setShowServiceDetail()}>  
                  {/* <Modal.Header >This is a Modal Heading</Modal.Header>   */}
                  <Modal.Body>
                    <section className="section cls-model-services-content gray-bg" id="about">
                        <div className="container">

                            <div className="row align-items-center justify-content-around flex-row-reverse">
                              <div className="col-lg-12 text-center order-1 about-text">
                                <h3 className="dark-color">{(serviceInfo!=undefined)?serviceInfo.title:''}</h3>
                                <h4 className="theme-color">{(serviceInfo!=undefined)?serviceInfo.excerpt:''}</h4>
                              </div>
                            </div>

                            <div className="row align-items-center justify-content-around flex-row-reverse">
                                <div className="col-lg-4 text-center order-1">
                                    <div className="about-img">
                                        <img src={(serviceInfo!=undefined)?serviceInfo.profilethumbnail:''} />
                                    </div>
                                </div>
                                <div className="col-lg-8 order-2">
                                    <div className="about-text">
                                        <p dangerouslySetInnerHTML={createMarkup()} ></p>

                                    </div>
                                </div>
                            </div>

                            <div className="row align-items-center justify-content-around flex-row-reverse">
                              <div className="col-lg-12 text-center order-1 about-text">
                                <div className="btn-bar">
                                    <Link to={"/service/" + serviceId} className="px-btn theme">View More</Link>
                                    <button onClick={()=>setShowServiceDetail(false)} className="px-btn theme-t" href="#">Cancel</button>
                                </div>                              
                              </div>
                            </div>

                        </div>
                    </section>  
                  </Modal.Body>  
                  <Modal.Footer>  
                    {/* <Button onClick={()=>setShowServiceDetail(false)}>Close</Button>  
                    <Button onClick={()=>setShowServiceDetail(false)}>Save</Button>   */}
                  </Modal.Footer>  
                </Modal>  
                {/* //onClick={()=>openServicePopUp(row.id)} */}
                {
                  servicesTable != undefined && servicesTable.map(row => {
                    return ( 
                        <div  className="col-xs-12 col-sm-4 col-md-4 service_item d-flex flex-column align-items-center justify-content-start">
                          <Link to={"/service/" + row.id}>                        
                          {/* <div className="service-item-inner"> */}
                            <div className="icon_container d-flex flex-column justify-content-center">
                              <img src={row.profilethumbnail} alt="" />
                            </div>
                            <h3 className="text-center">{ row.title }</h3>
                            <p  className="text-center">{row.excerpt}</p>
                          {/* </div> */}
                          </Link>
                        </div>

                    )
                  })
                }
              </div>

              <div className="cls-service-popup-box" id="cls-service-popup-box">
              </div>
            </div>
            
            {/* <div className="row text-center my-2">
                <div className="col-12">
                  <Link to="/services" className="button button_primary" >
                    <span className="p-2" >View All Services</span>
                  </Link>
                </div>
              </div> */}

          </div>
      </Fragment>
      )}
    </>
  );

  function openServicePopUp(iServiceId){
    // Get the modal
    // var modalElem = document.getElementsByClassName("cls-model-services");   

    
    setServiceId(iServiceId);
    handleGetService(iServiceId);
    

    setShowServiceDetail(true);
    // this.setState({show:!this.state.show}) 
  }

  async function handleGetServices() {
    let RC = false;
    setIsFormLoading(true);
    let isFeatured = true; 
    // let queries = queryString.parse(history.location.search);
    // let page = queries.page != undefined ? queries.page : 1;
    // let limit = queries.limit != undefined ? queries.limit : 9;
    let {status, response} = await getParentLevelServicesAPI(history, isFeatured);
    if (status == "S_OK" ) {
      console.log("Services Found Successfully ::", response.length);
      setServicesTable(response);
      setIsFormLoading(false);
      // setPage(page);
      // setLimit(limit);
    } else {
      console.log("Service Not found");
      setIsFormLoading(false);
    }
  }


  async function handleGetService(iServiceId) {
    let RC = false;
    setIsFormLoading(true);
    let {status, response} = await getServiceAPI(iServiceId)
    if (status == "S_OK" ) {
      console.log("Service Found Successfully ::", response);
      setServiceInfo(response);
      setIsFormLoading(false);
    } else {
      console.log("Service Not found");
      setIsFormLoading(false);
    }
  }

  function createMarkup() {
    return {
      __html: (serviceInfo!=undefined)?serviceInfo.description:'',
    }
  }

}

export default Module;
