import React, { Fragment,useEffect, useState } from "react";
import {
  HashRouter,
  Route,
  Switch,
  Redirect,
  Link,
  useHistory,useLocation, useParams, withRouter } from "react-router-dom";

import PageSideBar from "../../../components/PageSidebar";

// MEDIA FILES
import milestone_1 from "../../../assets/images/milestone_1.svg";
import milestone_2 from "../../../assets/images/milestone_2.svg";
import milestone_3 from "../../../assets/images/milestone_3.svg";
import milestone_4 from "../../../assets/images/milestone_4.svg";
import ServiceImage from "../../../assets/images/User-Profile/User-1.jpg";
import Img01 from "../../../assets/images/User-Profile/User-1.jpg"
import Img02 from "../../../assets/images/User-Profile/User-2.jpg"
import Img03 from "../../../assets/images/User-Profile/User-3.jpg"
import Img04 from "../../../assets/images/User-Profile/User-4.jpg"
import imgBackgroundMilestones from "../../../assets/images/milestones_background.jpg";
import IconsService01 from "../../../assets/images/icons/icon-money-01.svg"
import IconsService02 from "../../../assets/images/icons/icon-money-02.svg"
import IconsService03 from "../../../assets/images/icons/icon-money-03.svg"
import IconsService04 from "../../../assets/images/icons/icon-money-04.svg"

// FOR APIS
import {
  getMemberAPI,
  getMemberServicesAPI
} from "../../../APIs/memberApis";

import  CircularStatic from '../../../components/Controls/CircularProgressBar';
import AnchorLink from "react-anchor-link-smooth-scroll";

function Homepage() {
  window.scrollTo(0, 0);
  const history = useHistory();
  const location = useLocation();
  const params = useParams();
  

  const [memberID, setMemberID] = useState(undefined);
  const [memberInfo, setMemberInfo] = useState(undefined);
  const [servicesTable, setServicesTable] = useState(undefined);

  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    let isLoggedIn = localStorage.getItem("id_token");
    if (!isLoggedIn)
      history.push("/login");
    else {
      handleGetMember();
      handleGetMemberServices();
    }

  }, []);

  return (
    <>
    {isFormLoading ? (
      <div className={{ alignContents: "center",  }}>
        {/* <CircularProgress variant="indeterminate" value={75} /> */}
        <CircularStatic value={25} />
      </div>
    ) : (
    <Fragment>
        <div className="container single-mentor">
          {/* <!-- ======= About Section ======= --> */}
          <section id="about" className="about-mf sect-pt4 route">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="box-shadow-full">
                    <div className="row">
                      <div className="col-sm-12 col-md-3">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="about-img">
                              <img src={(memberInfo && memberInfo.profilethumbnail)?memberInfo.profilethumbnail:Img01} className="img-fluid rounded b-shadow-a" alt="" />
                            </div>
                          </div>
                          <div className="col-md-12 text-left">
                            <div className="about-info">
                              <p ><span className="title-s">Name: </span> 
                              <span >{(memberInfo)?memberInfo.membername:""}</span></p>
                              {/* <p><span className="title-s">Profile: </span> <div >{(memberInfo)?memberInfo.memberprofile:""}</div></p> */}
                              {/* 
                                <p><span >Email: </span> <span className="title-s">{(memberInfo)?memberInfo.memberemail:""}</span></p>
                                <p><span >Phone: </span> <span className="title-s">{(memberInfo)?memberInfo.memberphone:""}</span></p>
                              */}
                              </div> 
                          </div>
                        </div>
                        {/* <div className="col-md-12 align-right text-center">
                          <AnchorLink href="#service-options" className="button button_primary" >
                              <span>Book Now</span>
                          </AnchorLink> 
                        </div> */}
                      </div>
                      <div className="col-sm-12 col-md-9">
                        <div className="about-me pt-4 pt-md-0">
                          <div className="title-box-2">
                          <div className="row">
                            <div className="col">
                              <div className="section_title text-center">
                                <h1>About Me</h1>
                              </div>
                            </div>
                          </div>
                          </div>
                          <p className="text-dark" dangerouslySetInnerHTML={createMarkup((memberInfo)?memberInfo.membersummary:"")} ></p>
                        </div>
                      </div>
                      {/* <div className="col-md-12 col-lg-6 skill-mf mt-5">
                          <p className="title-s text-dark">Education/Experience</p>
                          <span>PHD</span> <span className="pull-right">XYZ university</span>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <span>Development Manager</span> <span className="pull-right">XYZ Company</span>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow="75" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                          <span>Finance Manager</span> <span className="pull-right">Abc company</span>
                          <div className="progress">
                            <div className="progress-bar" role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100"></div>
                          </div>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- ======= Services Section ======= --> */}
          <section id="service-options" className="services-mf pt-5 route">
            <div className="container">
              <div className="row">
                <div className="col-sm-12">
                  <div className="title-box text-center">
                  <div className="row">
                    <div className="col">
                      <div className="section_title text-center">
                        <h1 >Services Provided</h1>
                      </div>
                    </div>
                  </div>
                    <p className="subtitle-a">
                      {/* Lorem ipsum, dolor sit amet consectetur adipisicing elit. */}
                    </p>
                    <div className="line-mf"></div>
                  </div>
                </div>
              </div>
              <div className="row single-service ">
              <div className="row single-service-listing">
                  <div className="col-lg-12 ">
                      <div className="row service-related-study">
                      {
                      servicesTable != undefined && servicesTable.map(row => {
                        return ( 
                          <div className="col-lg-3 ">
                              <div className="card">
                              <img className="card-icon-top" src={row.profilethumbnail} alt="https://unsplash.com/@kellybrito" />
                              <div className="card-body pb-2">
                                <div className="card-title text-center">{row.title}</div>
                                <div className="card-text">{row.excerpt}</div>
                                <Link to={"/memberschedule/"+ memberID  +"/" + row.id } className="button button_primary_outline trans_200" >
                                  <span>Book Now</span>
                                </Link>                            
                              </div>  
                              </div>
                          </div>
                           )
                          })
                        }           
                      </div>
                  </div>
                </div>
              </div>
            </div>
          </section>

          {/* <!-- Milestones --> */}  
          {/* <div className="milestones">
            <div className="milestones_container p-0 pb-5">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 milestone_col">
                    <div className="milestone text-center">
                      <div className="milestone_icon"><img src={milestone_1} alt="https://www.flaticon.com/authors/zlatko-najdenovski" /></div>
                      <div className="milestone_counter" data-end-value="750">750</div>
                      <div className="milestone_text">Current Startups</div>
                    </div>
                  </div>
                  <div className="col-lg-4 milestone_col">
                    <div className="milestone text-center">
                      <div className="milestone_icon"><img src={milestone_2} alt="https://www.flaticon.com/authors/zlatko-najdenovski" /></div>
                      <div className="milestone_counter" data-end-value="120">120</div>
                      <div className="milestone_text">Certified Startups</div>
                    </div>
                  </div>
                  <div className="col-lg-4 milestone_col">
                    <div className="milestone text-center">
                      <div className="milestone_icon">
                        <img src={milestone_3} alt="https://www.flaticon.com/authors/zlatko-najdenovski" />
                      </div>
                      <div className="milestone_counter" data-end-value="39">39</div>
                      <div className="milestone_text">Approved For Finanace</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

          </div> */}
        </div>      
    </Fragment>
    )}
    </>
  );

  function handleCheckourRedirect() {
    history.push(
    {
      pathname: '/memberschedule',
      state: {member_id: 1}
    });
  }

  
  function createMarkup(iData) {
    return {
      __html: (iData!=undefined)?iData:'',
    }
  }

  async function handleGetMember() {
    let RC = false;
    setIsFormLoading(true);
    let iMemberID = params.memberId;    
    let member = await getMemberAPI(iMemberID);
    if (member != undefined ) {
      console.log("Members Found Successfully ::", member);
      setMemberInfo(member);
      setIsFormLoading(false);
    } else {
      console.log("Member Not found");
      setIsFormLoading(false);
    }
  }

  async function handleGetMemberServices() {
    let RC = false;
    setIsFormLoading(true);
    // let queries = queryString.parse(history.location.search);
    // let page = queries.page != undefined ? queries.page : 1;
    // let limit = queries.limit != undefined ? queries.limit : 9;
    let iParam = { member_id : params.memberId };
    setMemberID(params.memberId);
    let services = await getMemberServicesAPI(iParam);
    if (services != undefined && services.length > 0) {
      console.log("Services Found Successfully ::", services.length);
      setServicesTable(services);
      setIsFormLoading(false);
      console.log("Services Table Data ", servicesTable);
      // setPage(page);
      // setLimit(limit);
    } else {
      console.log("Services Not found");
      setIsFormLoading(false);
    }
  }



}

export default Homepage;
