import React, { Fragment,useEffect, useState } from "react";
import {  Link,  useHistory,useLocation, withRouter , useParams} from "react-router-dom";
import { AppointmentPicker } from './../../components/Appointment-Picker/AppointmentPicker';

// MEDIA FILES
import Img01 from "../../assets/images/User-Profile/User-1.jpg"
import AnchorLink from "react-anchor-link-smooth-scroll";
// CUSTOM COMPONENTS
import  CircularStatic from '../../components/Controls/CircularProgressBar';

// FOR APIS
import {
  getMemberAPI,
  getSlotsAPI,
  getMemberServicesAPI
} from "../../APIs/memberApis";

import {
  convertTime
} from "../../APIs/ApiUtils"

import {
  CreateAppointmentSlotsAPI  
} from "../../APIs/bookingApis";
import { toast } from "react-toastify";

import {getLocaleShortDateString } from "./../../helpers";

const days = [
];

function Homepage() {
  const history = useHistory();
  const location = useLocation();
  const params = useParams();

  const [availableDays, setAvailableDays] = useState(undefined);
  const [selectedSlots, setSelectedSlots] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [currentDate, setCurrentDate] = useState(undefined);
  const [weekNo, setWeekNo] = useState(0);

  const [memberInfo, setMemberInfo] = useState(undefined);
  const [serviceInfo, setServiceInfo] = useState(undefined);
  const [servicesTable, setServicesTable] = useState(undefined);
  const [slotsInfo, setSlotsInfo] = useState(undefined);
  const [appointmentID, setAppointmentID] = useState(undefined);
  const [isFormLoading, setIsFormLoading] = useState(false);
  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isBookDisabled, setIsBookDisabled] = useState(true);
  

  useEffect(() => {
    window.scrollTo(0, 0);
    let tmpStartEndDate = getStartEndDateOfWeek(weekNo);

    setCurrentDate(tmpStartEndDate.currentDateTime);
    setStartDate(tmpStartEndDate.startDate);
    setEndDate(tmpStartEndDate.endDate);
    // setAvailableDays(tmpDays)

    let isLoggedIn = localStorage.getItem("id_token");
    if (!isLoggedIn)
      history.push("/login");
    handleGetSlots(weekNo);
    handleGetMember();
    handleGetMemberServices();
  }, []);


  async function handleNextWeek() { 
    let tmpWeekNo = weekNo;   
    setWeekNo(tmpWeekNo + 1);
    tmpWeekNo = tmpWeekNo + 1;
    let tmpStartEndDate = getStartEndDateOfWeek(tmpWeekNo);
    setCurrentDate(tmpStartEndDate.currentDateTime);
    setStartDate(tmpStartEndDate.startDate);
    setEndDate(tmpStartEndDate.endDate);
    handleGetSlots(tmpWeekNo);       
  }

  async function handlePrevWeek() {
    if(weekNo > 0 ) {   
      let tmpWeekNo = weekNo;   
      setWeekNo(tmpWeekNo - 1);
      tmpWeekNo = tmpWeekNo - 1;
      let tmpStartEndDate = getStartEndDateOfWeek(tmpWeekNo);
      setCurrentDate(tmpStartEndDate.currentDateTime);
      setStartDate(tmpStartEndDate.startDate);
      setEndDate(tmpStartEndDate.endDate);
      handleGetSlots(tmpWeekNo);       
    }
  }

  function getWeekDays(iWeekNo)
  {
    console.log("getWeekDays :: Week Count -> ", iWeekNo);
    let tmpDays = [];
    let tmpDateIncrease = 0;
    let dateVar = new Date();
    if(iWeekNo>0)
      dateVar = new Date(dateVar.setDate(dateVar.getDate() + iWeekNo * 7 ));

    console.log("getWeekDays Date For Current Week -> " , dateVar);  
    
    for (let index = 0; index < 7; index++) {
        var tmpDate = new Date(dateVar.setDate(dateVar.getDate() + tmpDateIncrease));
        let tmpDateString = tmpDate.getFullYear() + '-' + ('0'+(tmpDate.getMonth()+1)).slice(-2) + '-' + (( tmpDate.getDate() < 10) ? '0' + tmpDate.getDate() : tmpDate.getDate()) ;    
        var tmpHours = [];
        for (let indexHours = 0; indexHours < 24; indexHours++) {
          tmpHours.push({ id: String(tmpDateString) + "-" + String(indexHours), number: indexHours, isReserved: false }); 
        }  
        // console.log("Current Available hours List" , tmpHours);
        tmpDays.push({ key: tmpDateString, value: tmpHours }); 
        tmpDateIncrease = 1;
    }
    console.log("getWeekDays :: Days for Week", tmpDays);
    return tmpDays;
  }

  function getStartEndDateOfWeek(iWeekNo){
    console.log("getStartEndDateOfWeek :: Week No" , iWeekNo);
    var today = new Date();
    if(iWeekNo>0)
      today = new Date(today.setDate(today.getDate() + iWeekNo * 7 ));

    let start_date = today.getFullYear() + '-' + ('0'+(today.getMonth()+1)).slice(-2) + '-' + (( today.getDate() < 10) ? '0' + today.getDate() : today.getDate());    
    var date7Days = new Date(today.setDate(today.getDate() + (7)));
    let end_date = date7Days.getFullYear() + '-' + ('0'+(date7Days.getMonth()+1)).slice(-2) +  '-' + (( date7Days.getDate() < 10) ? '0' + date7Days.getDate() : date7Days.getDate());    
    let currentDateTime = new Date(start_date + " 00:00");

    let tmpStartEndDate =  {
      startDate : start_date,
      endDate : end_date,
      currentDateTime : currentDateTime
    }
    console.log("getStartEndDateOfWeek :: Start And End Date Week", tmpStartEndDate);
    return tmpStartEndDate;
  }

  async function handleGetSlots(iWeekNo) {
    let RC = false;
    setIsFormLoading(true);

    let tmpDays = getWeekDays(iWeekNo);
    let tmpStartEndDate = getStartEndDateOfWeek(iWeekNo);
    console.log("HandlegetSlots :: Start Date :: "+ tmpStartEndDate.startDate +" and EndDate :: "+ tmpStartEndDate.endDate +" for query");

    let iParams = {member_id : params.itemId, start_date:tmpStartEndDate.startDate, end_date:tmpStartEndDate.endDate};
    let slots = await getSlotsAPI(iParams);
    if (slots != undefined ) {
      console.log("slots Found Successfully ::", slots);
      setSlotsInfo(slots);
      slots.map(row => {
        let slotDate = row.date;
        tmpDays.map(data => {  
          if(data.key  == slotDate  )
          {
            let arrHours = data.value;
            for (let hourIndex = 0; hourIndex < arrHours.length; hourIndex++) {
              if(arrHours[hourIndex].number == row.slotnumber)
                arrHours[hourIndex] = { id: arrHours[hourIndex].id, number: row.slotnumber, isReserved: 1, isSelected: false };
            }
          }
        });
      });
      let tmpDaysData = []
      tmpDays.map(row => {
        tmpDaysData.push(row.value);
      });

      setAvailableDays(tmpDaysData); 
      console.log("Available Days ::", tmpDaysData);
      setIsFormLoading(false);
    } else {
      console.log("slots Not found");
      setIsFormLoading(false);
    }
  }


  async function handleGetMemberServices() {
    let RC = false;
    setIsFormLoading(true);
    let iParam = { member_id : params.itemId };
    let services = await getMemberServicesAPI(iParam);
    if (services != undefined && services.length > 0) {
      // console.log("Services Found Successfully ::", services.length);
      setServicesTable(services);
      setIsFormLoading(false);
      // console.log("Services Table Data ", servicesTable);
    } else {
      // console.log("Services Not found");
      setIsFormLoading(false);
    }
  }

  async function handleSubmitSlots() {
    let RC = false;
    setIsFormLoading(true);
    let iParam = [];
    selectedSlots.map(slot => {
      iParam.push(slot.value);
    });

    console.log("Slot array :: ", iParam);
    let slots = await CreateAppointmentSlotsAPI(iParam);
    if (slots != undefined && slots.length > 0) {
      console.log("Slots create successfully ::", slots.length);
      setIsFormLoading(false);      
      toast("Slots created successfully");
      window.location.reload();
    } else {
      console.log("Slots not create");
      toast("Slots not create");

      setIsFormLoading(false);
    }
  }

  
  async function handleGetMember() {
    let RC = false;
    setIsFormLoading(true);
    let member = await getMemberAPI(params.itemId);
    if (member != undefined ) {
      // console.log("Members Found Successfully ::", member);
      setMemberInfo(member);
      setIsFormLoading(false);
    } else {
      // console.log("Member Not found");
      setIsFormLoading(false);
    }
  }
  
  async function addAppointmentCallback ({ addedAppointment: { day, number, time, id },addCb}) 
  {
    setIsSubmitLoading(true);
    setIsBookDisabled(false);
    await A({ day, number, time, id }, addCb);
      
  };

  async function A ({ day, number, time, id }, addCb) {
    // await new Promise((resolve) => setTimeout(resolve, 1000));
    console.log(
      `Added appointment ${number}, day ${day}, time ${time}, time ${convertTime(time)}, id ${id}`
    );
    let arrSlots = [];
    var dateParts = day.split("/");
    // month is 0-based, that's why we need dataParts[1] - 1
    var lSelectedDay = new Date(+dateParts[2], dateParts[1] - 1, +dateParts[0])
    let lSelectedDayPHP = lSelectedDay.getFullYear() + '-' + ('0'+(lSelectedDay.getMonth()+1)).slice(-2) +  '-' + (( lSelectedDay.getDate() < 10) ? '0' + lSelectedDay.getDate() : lSelectedDay.getDate());    

    selectedSlots.push({key : id , value : { 
                      slotnumber: number, 
                      member_id: params.itemId, 
                      date: lSelectedDayPHP, 
                      starttime : convertTime(time), 
                      endtime : convertTime(time, 1), 
                      isReserved:0, 
                      isactive:1 } });
    setSelectedSlots(selectedSlots);

    console.log("Current Selected Slots :: ", selectedSlots );  

    addCb(day, number, time, id);
    setIsSubmitLoading(false);
    setAppointmentID(id);
  }    

  async function removeAppointmentCallback ({ day, number, time, id }, removeCb) {
        setIsSubmitLoading(true);

    B({ day, number, time, id }, removeCb);
    
  };
      
  async function B({ day, number, time, id }, removeCb) 
  {
    // await new Promise((resolve) => setTimeout(resolve, 1000));
    console.log(`Removed appointment ${number}, day ${day}, time ${time}, id ${id}`);
    removeCb(day, number);

    // let arrSlots = [];
    // arrSlots = selectedSlots.filter(slot => {
    //   return slot.id !== id;
    // });
    setSelectedSlots(selectedSlots =>
      selectedSlots.filter(slot => {
        console.log(slot.key,id);
        return slot.key != id;
      }),
    );

    console.log("Current Selected Slots :: ", selectedSlots );  

    setIsSubmitLoading(false);
    setAppointmentID(undefined);
  }

  return (
    <>
    {isFormLoading ? (
      <div className={{ alignContents: "center",  }}>
        <CircularStatic value={25} />
      </div>
    ) : (
      <Fragment>
          <div className="container single-mentor">
            {/* <!-- ======= About Section ======= --> */}
            <section id="about" className="about-mf sect-pt4 route">
              <div className="container">
                <div className="">
                  <div className="">
                    <div className="">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="cls-schedule-table ">
                            <div className="flex d-flex justify-content-between">
                              <h2>Your Availability</h2>
                              <div className="float-right">
                                <AnchorLink href="#hash-book-schedule" className="button button_primary" >
                                <span>Availability</span>
                                </AnchorLink>
                              </div>                            
                            </div>
                            <div className="table-responsive">
                            <table className="table project-list-table table-nowrap align-middle table-borderless">
                              <thead>
                                <tr>
                                  <th scope="col">Date</th>
                                  <th scope="col">Time</th>
                                  <th scope="col">Status</th>
                                  <th scope="col">Service</th>
                                  <th scope="col">Action</th>
                                </tr>
                              </thead>
                              <tbody>                          
                                {
                                slotsInfo!=undefined && slotsInfo?.map((slot)=>{
                                return( 
                                  <tr>                                    
                                      <td>{getLocaleShortDateString(slot.date)}</td>                                    
                                      <td>{"From -" + slot.starttime.slice(0,5) + " To -" + slot.endtime.slice(0,5)}</td>
                                      <td><span class={(slot.isReserved==1)?" badge mb-0 p-2 badge-soft-success ":" badge mb-0 p-2 badge-soft-danger "}>{(slot.isReserved==1)?"Booked":"Not Booked"}</span></td>
                                      <td><Link to={"/service/" + slot?.service?.id??""} className="text-body text-tertiary">{slot?.service?.title??"-"}</Link></td>
                                      <td>
                                        <div className="inline">
                                          <Link to="" className="mx-2"><i className="fa fa-eye text-tertiary"></i>{"  "}</Link>
                                          <Link to=""><i className="fa fa-trash text-danger"></i>{"  "}</Link>                                        
                                        </div>
                                      </td>
                                  </tr>
                                )})}
                              </tbody>
                            </table>
                            {
                                (slotsInfo==undefined || slotsInfo.length==0) && <div>
                                  <div>
                                    No appointment slot available for startups booking   
                                  </div>
                                </div>
                            }
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 " id="hash-book-schedule">
                          <div className="cls-book-schedule"  >
                            <div className="row mt-5">
                              <div className="col-md-6 ">
                                <h2 className="mt-3">Select schedule as per your availability</h2>
                              </div>
                              <div className="col-md-6 text-left">
                                <button disabled={isBookDisabled} onClick={handleSubmitSlots} className={(isBookDisabled)?" button button_disable trans_200 ":"button button_primary trans_200 " } >
                                  <span>Save Schedule</span>
                                </button> 
                              </div>
                              { 
                                (isBookDisabled) &&                            
                                <div className="col-md-12 text-left">
                                  <div className="alert alert-danger">
                                    <span className="fa fa-info-circle"></span>{"  "}Please click on Date and Time to set availability
                                  </div>
                                </div>
                              }
                              <div className="col-md-12 text-left">
                                <button onClick={handlePrevWeek} className={"button button_primary_outline trans_200"} >
                                  <span><i className="fa fa-chevron-left mr-2"></i>{" "}Prev Week </span>
                                </button>
                                <button onClick={handleNextWeek} className={"button button_primary_outline trans_200"} >
                                  <span>Next Week <i className="fa fa-chevron-right"></i></span>
                                </button>
                              </div>                          
                              <div className="col-md-12 text-left overflow-auto">
                                <AppointmentPicker
                                addAppointmentCallback={addAppointmentCallback}
                                removeAppointmentCallback={removeAppointmentCallback}
                                initialDay={currentDate}
                                days={(availableDays!=undefined?availableDays:days)}
                                maxReservableAppointments={50}
                                unitTime={60*60*1000}
                                visible
                                selectedByDefault
                                loading={isSubmitLoading}
                                local="en-IN"
                                />  
                              </div>                        
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </section>
          </div>      
      </Fragment>
    )}
    </>
  );
}

export default Homepage;
