import React, { Fragment, useEffect } from "react";
import { HashRouter, Route, Switch, Redirect, Link } from "react-router-dom";


import Footer from "../../components/footer";
import Header from "../../components/header";
import Schedule from "./components/member_schedule";
import IMAGE_BG from "../../assets/images/teachers_background.jpg";


function Homepage() {

  useEffect(() => {
    window.scrollTo(0, 0);

  }, []);


  return (
    <Fragment>
      <div className="single-page about-page">
        <Header />
          <div className="home innerpage">
            <div className="home_background_container prlx_parent">
                <div className="home_background prlx" >
                </div>
            </div>
            <div className="home_content">
                <h1>Schedule</h1>
            </div>
          </div>

          <div className="container">
            <div className="row">
              <div className="col-12">
                <Schedule />
              </div>
            </div>
          </div>
        <Footer />
      </div>
    </Fragment>
  );
}

export default Homepage;
