import React, { Fragment, useEffect, useState } from "react";

// MEDIA FILES
import icon_management_hierarchy from "../../../assets/images/icons/icon_management_hierarchy.svg"
import icon_people from "../../../assets/images/icons/icon_people.svg"
import icon_service_finance from "../../../assets/images/icons/icon_service_finance.svg"
import icon_security from "../../../assets/images/icons/icon_security.svg"
import whychooseusimage from "../../../assets/images/slider/whychooseus.jpg"

// // FOR APIS
// import {
// 	getGeneralSettings
//   } from "../../../APIs/ApiUtils";

function Module({generalSettings}) {
	// const [generalSettings, setGeneralSettings] = useState(undefined);
	// const [isFormLoading, setIsFormLoading] = useState(false);
	
	// useEffect(() => {
	// 	handleSiteInfo();
	// }, []);
  
	// async function handleSiteInfo() {
	//   let RC = false;
	//   setIsFormLoading(true);
	//   let generalSettings = await getGeneralSettings();
	//   if (generalSettings != undefined ) {
	// 	console.log("General settings found successfully ::", generalSettings);
	// 	setGeneralSettings(generalSettings);
	// 	setIsFormLoading(false);
	//   } else {
	// 	console.log("General settings Not found");
	// 	setIsFormLoading(false);
	//   }
	// }
  
  return (
    <Fragment>        
		{/* WHY CHOOSE US */}	
		<div className="whychooseus" >
			<section >
				<div className="text-center mb-2-8 mb-lg-6 mb-5">
				<div className="row">
					<div className="col">
						<div className="section_title text-center">
							<h1>Why StartupBasket</h1>
						</div>
					</div>
				</div>					
				{/* <span>The trusted source for why choose us</span> */}
				</div>
				<div className="row">
					<div className="col-sm-6 col-lg-3 whyUsBox">
						<div className="mb-4">
							<div className="img-wrap"><img src={icon_people} alt="..." className="rounded-circle" /></div>
						</div>
						<h4 className="sub-info text-center">Increased Exposure to Stakeholders</h4>
						{/* <p className="display-30 mb-0"> { generalSettings?.admin_whychooseus01} </p> */}
					</div>
					<div className="col-sm-6 col-lg-3 whyUsBox">
						<div className="mb-4">
						<div className="img-wrap"><img src={icon_service_finance} alt="..." className="rounded-circle" /></div>
						</div>
						<h4 className="sub-info text-center">Access to Business and Investment Market Place</h4>
						{/* <p className="display-30 mb-0">{ generalSettings?.admin_whychooseus02}</p> */}
					</div>
					{/* </div>
					<div className="col-lg-4 d-none d-lg-block">
						<div className="why-choose-center-image">
							<img src={whychooseusimage} alt="..." className="rounded-circle" />
						</div>
					</div> */}
					<div className="col-sm-6 col-lg-3 whyUsBox">
						<div className="mb-4">
						<div className="img-wrap"><img src={icon_management_hierarchy} alt="..." className="rounded-circle" /></div>
						</div>
						<h4 className="sub-info text-center">Structured Hand Holding and Mentoring</h4>
							{/* <p className="display-30 mb-0">{ generalSettings?.admin_whychooseus04}</p> */}
					</div>
					<div className="col-sm-6 col-lg-3 whyUsBox">
						<div className="mb-4">
						<div className="img-wrap"><img src={icon_security} alt="..." className="rounded-circle" /></div>
						</div>
						<h4 className="sub-info text-center">Array of Services</h4>
						{/* <p className="display-30 mb-0">{ generalSettings?.admin_whychooseus04}</p> */}
					</div>
				</div>
			</section>
		</div>
    </Fragment>
  );
}

export default Module;
