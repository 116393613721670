import React, { useState, useEffect } from "react";
import { Drawer, IconButton, List } from "@material-ui/core";
import {
  Home as HomeIcon,
  NotificationsNone as NotificationsIcon,
  FormatSize as TypographyIcon,
  FilterNone as UIElementsIcon,
  BorderAll as TableIcon,
  QuestionAnswer as SupportIcon,
  LibraryBooks as LibraryIcon,
  HelpOutline as FAQIcon,
  Settings as SettingsIcon,
  ArrowBackRounded as ArrowBackIcon,
  ArrowForwardRounded as ArrowForwardIcon,
  Lock as LockIcon,
  People as PeopleIcon,
} from "@material-ui/icons";
import VerifiedUserOutlined from "@material-ui/icons/SupervisedUserCircleSharp";

import { useTheme } from "@material-ui/styles";
import { withRouter } from "react-router-dom";
import classNames from "classnames";

// styles
import useStyles from "./styles";

// components
import SidebarLink from "./sidebarlink";

// context
import {
  useLayoutState,
  useLayoutDispatch,
  toggleSidebar,
} from "../context/layoutContext";

import {
  useParams
} from "react-router-dom";


function Sidebar({ location }) {
  var classes = useStyles();
  var theme = useTheme();
  const params = useParams();
  const structureStartup = [
    // {
    //   label: "Dashboard",
    //   link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/dashboard/" + localStorage.getItem("persona_id"),
    //   icon: "fa-tachometer",
    //   id: 0,    
    // },
    {
      label: "Profile",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/view/" + localStorage.getItem("persona_id"),
      icon: "fa-user",
      id: 1,    
    },
    {
      label: "Edit Profile",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/edit/" + localStorage.getItem("persona_id"),
      icon: "fa-pencil-square-o",
      id: 1,    
    },
    {
      id: 2,
      label: "Bookings",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/orders/" + localStorage.getItem("persona_id"),
      icon: "fa-handshake-o",
    },
    {
      id: 3,
      label: "Wallet",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/wallets/" + localStorage.getItem("persona_id"),
      icon: "fa-calendar",
    },
    {
      id: 4,
      label: "Notifications",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/notifications/" + localStorage.getItem("persona_id"),
      icon: "fa-bell",
    },
  
  ];
  
  const structurePartner = [
    // {
    //   label: "Dashboard",
    //   link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/dashboard/" + localStorage.getItem("persona_id"),
    //   icon: "fa-tachometer",
    //   id: 0,    
    // },
    {
      label: "Profile",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/view/" + localStorage.getItem("persona_id"),
      icon: "fa-user",
      id: 1,    
    },
    {
      label: "Edit Profile",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/edit/" + localStorage.getItem("persona_id"),
      icon: "fa-pencil-square-o",
      id: 1,    
    },
    {
      id: 2,
      label: "Bookings",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/orders/" + localStorage.getItem("persona_id"),
      icon: "fa-handshake-o",
    },
    {
      id: 3,
      label: "Availability",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/schedule/" + localStorage.getItem("persona_id"),
      icon: "fa-calendar",
    },
    {
      id: 4,
      label: "Notifications",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/notifications/" + localStorage.getItem("persona_id"),
      icon: "fa-bell",
    },
  
  ];

  const structureInvestor = [
    // {
    //   label: "Dashboard",
    //   link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/dashboard/" + localStorage.getItem("persona_id"),
    //   icon: "fa-tachometer",
    //   id: 0,    
    // },
    {
      label: "Profile",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/view/" + localStorage.getItem("persona_id"),
      icon: "fa-user",
      id: 1,    
    },
    {
      label: "Edit Profile",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/edit/" + localStorage.getItem("persona_id"),
      icon: "fa-pencil-square-o",
      id: 1,    
    },
    {
      id: 2,
      label: "Bookings",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/orders/" + localStorage.getItem("persona_id"),
      icon: "fa-handshake-o",
    },
    {
      id: 3,
      label: "Schedule",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/schedule/" + localStorage.getItem("persona_id"),
      icon: "fa-calendar",
    },
    {
      id: 4,
      label: "Notifications",
      link: "/profile/" + localStorage.getItem("user_role").toLowerCase() + "/notifications/" + localStorage.getItem("persona_id"),
      icon: "fa-bell",
    },
  
  ];

  var [isSideBarOpen, setIsSideBarOpen] = useState(true);
  var [isSideBarShow, setIsSideBarShow] = useState("show");

  // global
  var layoutDispatch = useLayoutDispatch();

  function toggleSidebar(value) {
    setIsSideBarOpen(value);
    if (value) setIsSideBarShow("show");
    else setIsSideBarShow("");
  }

  return (
    <>
         
      <div className="">
        {/* <div  >
        {isSideBarOpen == true ? 
          <ArrowBackIcon color={theme.palette.primary.main} onClick={() => toggleSidebar(false)} className="drawerIcon" >Back</ArrowBackIcon>
        : 
          <ArrowForwardIcon color={theme.palette.primary.main} onClick={() => toggleSidebar(true)}  className="drawerIcon" >Back</ArrowForwardIcon>
        }
        </div> */}
        <div className="bg-white profile-sidebar d-none d-lg-block">
            <div className="d-flex flex-row justify-content-center align-items-center">
              <i className="fa fa-user" />
              <div>{localStorage.getItem("user_name")}</div>
            </div>
        </div>
        <div className={"top-margin bg-white profile-sidebar " + isSideBarShow}>
          <List>
            { 
              (
                localStorage.getItem("user_role").toLowerCase() === 'startup' && 
                structureStartup.map((link) => (
                  <SidebarLink key={link.id} location={location} {...link} />
                ))
              ) || 
              (
                localStorage.getItem("user_role").toLowerCase() === 'investor' && 
                structureInvestor.map((link) => (
                  <SidebarLink key={link.id} location={location} {...link} />
                ))
              ) || 
              (
                localStorage.getItem("user_role").toLowerCase() === 'partner' && 
                structurePartner.map((link) => (
                  <SidebarLink key={link.id} location={location} {...link} />
                ))
              )}
          </List>
        </div>
      </div>
    </>

  );
}

export default withRouter(Sidebar);
