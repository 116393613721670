import React from "react";
import ReactDOM from "react-dom";
import { ThemeProvider } from "@material-ui/styles";
import { CssBaseline } from "@material-ui/core";

import "bootstrap/dist/css/bootstrap.min.css";
import 'font-awesome/css/font-awesome.css'
import "./assets/style.css";
import Themes from "./themes";
import App from "./components/App";
import { LayoutProvider } from "./context/layoutContext";
import { UserProvider } from "./context/userContext";



ReactDOM.render(
  <LayoutProvider>
    <UserProvider>
      <ThemeProvider theme={Themes.default}>
        <CssBaseline />
        <App />
      </ThemeProvider>
    </UserProvider>
  </LayoutProvider>,
  document.getElementById("root"),
);

