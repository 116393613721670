import React from "react";
// AXIOS
import axios from "axios";
import API, { API_Param } from "./../API";

import { displayResponseError } from "./ApiUtils";

// import needed components, functions and styles
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export {
  deleteWorkerAPI,
  getWorkersAPI,
  getWorkersPaginationAPI,
  createWorkerAPI,
  editWorkerAPI,
  getWorkerAPI,
  createWorkerFamilyMemberAPI,
  getFamilyMembersAPI,
  deleteFamilyMemberAPI,
  getFamilyMemberAPI,
  editWorkerFamilyMemberAPI,
  createWorkerDocumentAPI,
  getWorkerDocumentAPI,
  editWorkerDocumentAPI,
  deleteWorkerDocumentAPI,
  getWorkerDocumentByIDAPI,
  getWorkersByVolunteerAPI,
  getDonationForWorkerAPI,
  // deleteWorkerDocument
};

async function getWorkersPaginationAPI(limit, page) {
  let workers = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("worker/pagination/" + limit + "/" + page);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.workers.map((worker) => {
        let tmp = {};
        Object.entries(worker).map(([key, value]) => {
          tmp[key] = value;
        });
        workers.push(tmp);
      });
      return workers;
    } else {
      console.log("API Request Failed With Status :: ", response);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Error :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getWorkersAPI() {
  let workers = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("worker");
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.workers.map((worker) => {
        let tmp = {};
        Object.entries(worker).map(([key, value]) => {
          tmp[key] = value;
        });
        workers.push(tmp);
      });
      return workers;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return workers;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getWorkersByVolunteerAPI() {
  let workers = [];
  let token = localStorage.getItem("id_token");
  let id = localStorage.getItem("VOLUNTEER_ID");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("volunteer/workers/" + id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      // response.data.response.volunteer.workers.map((worker) => {
      //   let tmp = {};
      //   Object.entries(worker).map(([key, value]) => {
      //     tmp[key] = value;
      //   });
      //   workers.push(tmp);
      // });
      return response.data.response.volunteer;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return workers;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getWorkerAPI(id) {
  let worker = undefined;
  let token = localStorage.getItem("id_token");
  let id_worker = id;

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("worker/" + id_worker);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response);
      worker = response.data.response.worker;
      if (response.data.response.volunteer[0] != undefined)
        worker["volunteer_id"] = response.data.response.volunteer[0].id;
      return worker;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return worker;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function createWorkerAPI(history, param) {
  let token = localStorage.getItem("id_token");
  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("worker", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Member Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editWorkerAPI(id, param) {
  let token = localStorage.getItem("id_token");

  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    if (value != null) formData.append(key, value);
  });
  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("worker/" + id + "?_method=PUT", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Member Edited Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteWorkerAPI(history, id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("worker/" + id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getFamilyMembersAPI() {
  let members = [];
  let token = localStorage.getItem("id_token");
  let id_worker = localStorage.getItem("CURRENT_WORKER");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("worker/member/" + id_worker);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      response.data.response.members.map((member) => {
        let tmp = {};
        Object.entries(member).map(([key, value]) => {
          tmp[key] = value;
        });
        members.push(tmp);
      });
      return members;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return members;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function getFamilyMemberAPI() {
  let worker = undefined;
  let token = localStorage.getItem("id_token");
  let id_worker = localStorage.getItem("CURRENT_FamilyMember");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("familymember/" + id_worker);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data.response);
      worker = response.data.response.familymember;
      return worker;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return worker;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function createWorkerFamilyMemberAPI(history, param) {
  let token = localStorage.getItem("id_token");
  let id_worker = localStorage.getItem("CURRENT_WORKER");

  console.log("<PARAMETER> :: ", param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log("<FORMDATA> :: ", formData);

  formData.append("familymemberable_type", "App\\Models\\worker");
  formData.append("familymemberable_id", id_worker);

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("familymember", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("CoFounder/Team Member Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editWorkerFamilyMemberAPI(history, param) {
  let token = localStorage.getItem("id_token");
  let id_familyMember = localStorage.getItem("CURRENT_FamilyMember");

  console.log("<PARAMETER> :: ", param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log("<FORMDATA> :: ", formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post(
      "familymember/" + id_familyMember + "?_method=PUT",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("CoFounder/Team Member Updated Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteFamilyMemberAPI(history, id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("familymember/" + id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getWorkerDocumentAPI() {
  let workers = [];
  let token = localStorage.getItem("id_token");
  let id_worker = localStorage.getItem("CURRENT_WORKER");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  let response = await API.get("worker/document/" + id_worker);
  if (response.status == 200) {
    console.log("Response from  API :: ", response.data);
    response.data.response.documents.map((worker) => {
      let tmp = {};
      Object.entries(worker).map(([key, value]) => {
        tmp[key] = value;
      });
      workers.push(tmp);
    });
    return workers;
  } else {
    console.log("API Request Failed With Status :: ", response.status);
    return workers;
  }
}

async function getWorkerDocumentByIDAPI() {
  let document = [];
  let token = localStorage.getItem("id_token");
  let id_document = localStorage.getItem("CURRENT_WORKERDOCUMENT");

  API.defaults.headers.common = { Authorization: "Bearer " + token };

  let response = await API.get("document/" + id_document);
  if (response.status == 200) {
    console.log("Response from  API :: ", response.data);
    document = response.data.response.document;
    return document;
  } else {
    console.log("API Request Failed With Status :: ", response.status);
    return document;
  }
}

async function createWorkerDocumentAPI(history, param) {
  let token = localStorage.getItem("id_token");
  let id_worker = localStorage.getItem("CURRENT_WORKER");

  console.log(param);

  let formData = new FormData();

  // Object.entries(prices).map(([key, value]) => [key, value * 2])
  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });
  formData.append("documentable_type", "App\\Models\\worker");
  formData.append("documentable_id", id_worker);

  console.log(formData);

  try {
    API.defaults.timeout = 600000;
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post("document", formData, {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    });
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Member Document Added Successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function editWorkerDocumentAPI(history, param) {
  let token = localStorage.getItem("id_token");
  let id_document = localStorage.getItem("CURRENT_WORKERDOCUMENT");

  console.log(param);

  let formData = new FormData();

  Object.entries(param).map(([key, value]) => {
    formData.append(key, value);
  });

  console.log(formData);

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.post(
      "document/" + id_document + "?_method=PUT",
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    if (response != undefined) {
      if (response.status == 200 && response.data.status == "success") {
        console.log("Response from API :: ", response.data);
        //toast.success("Document edited successfully");
        return true;
      } else {
        // DISPLAY RESPONSE WITH TOAST MESSAGES
        displayResponseError(response);
        return false;
      }
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}

async function deleteWorkerDocumentAPI(history, id) {
  let token = localStorage.getItem("id_token");

  try {
    API.defaults.headers.common = { Authorization: "Bearer " + token };
    let response = await API.delete("document/" + id);
    if (response != undefined && response.status == 200) {
      console.log("Response from API :: ", response.data);
      return true;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return false;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    return false;
  }
}

async function getDonationForWorkerAPI(id) {
  let orders = [];
  let token = localStorage.getItem("id_token");

  API.defaults.headers.common = { Authorization: "Bearer " + token };
  try {
    let response = await API.get("worker/orders/" + id);
    if (response.status == 200) {
      console.log("Response from  API :: ", response.data);
      orders = response.data.response.orders;
      return orders;
    } else {
      console.log("API Request Failed With Status :: ", response.status);
      return orders;
    }
  } catch (error) {
    console.log("API Request Failed With Status :: ", error);
    toast.error("Error : " + error);
    return false;
  }
}
