import React, { Fragment, useState, useEffect, useCallback, } from "react";
import ReactDom from 'react-dom';

import {
  HashRouter, Route, Switch, Redirect, Link, useHistory, useLocation, useParams, withRouter,
} from "react-router-dom";

import {
  Grid, CircularProgress, InputAdornment, IconButton, FormControlLabel, Typography, Button, Tabs, Tab, TextField, Fade, Radio, RadioGroup
} from "@material-ui/core";
import classnames from "classnames";
import Visibility from "@material-ui/icons/Visibility";
import VisibilityOff from "@material-ui/icons/VisibilityOff";

// styles
import useStyles from "./styles";
import Footer from "../../components/footer.js";
import Header from "../../components/header";

import CircularStatic from "../../components/Controls/CircularProgressBar";
import Header_BG from "../../assets/images/contact_background.jpg";

// logo
import logo from "../../assets/images/logo.png";
import startupMoto from '../../assets/images/startup-moto.png'
import google from "../../assets/images/google.svg";
import facebook from "../../assets/images/icons/icon_facebook.svg";

// context
import {
  useUserDispatch,
  useUserState,
  loginUser,
  registerUser,
  isEmailExist,
  isPhoneExist,
  verifyOTPs,
  signOut,
} from "../../context/userContext";

import { validateEmail, validatePassword, validatePhone, validateString } from "./../../helpers";
import queryString from "query-string";
//social logins
// import {LoginSocialGoogle,LoginSocialFacebook} from 'reactjs-social-login';
// import {FacebookLoginButton,GoogleLoginButton} from 'react-social-login-buttons';


function Login(props) {
  var classes = useStyles();
  const history = useHistory();
  const params = useParams();
  //url for socialLogin
  const REDIRECT_URI =
    'https://localhost:3000/#/login';
  // global
  var userDispatch = useUserDispatch();

  // local
  var { isAuthenticated } = useUserState();
  var [isLoadingLogin, setIsLoadingLogin] = useState(false);
  var [errorLogin, setErrorLogin] = useState(null);
  var [isLoadingRegister, setIsLoadingRegister] = useState(false);
  var [errorRegister, setErrorRegister] = useState(null);
  var [activeTabId, setActiveTabId] = useState(0);
  var [nameLogin, setNameLogin] = useState("");
  var [nameRegister, setNameRegister] = useState("");
  var [errorNameRegister, setErrorNameRegister] = useState(false);
  var [emailLogin, setEmailLogin] = useState("");
  var [emailRegister, setEmailRegister] = useState("");
  var [passwordLogin, setPasswordLogin] = useState("");
  var [passwordLoginConfirm, setPasswordLoginConfirm] = useState("");
  var [passwordRegister, setPasswordRegister] = useState("");
  var [passwordRegisterConfirm, setPasswordRegisterConfirm] = useState("");
  var [errorRegisterPassword, setErrorRegisterPassword] = useState(false);
  var [userType, setUserType] = useState("");
  var [phoneRegister, setPhoneRegister] = useState("");
  var [errorEmail, setErrorEmail] = useState("EMPTY");
  var [errorPhone, setErrorPhone] = useState("EMPTY");
  var [registerDone, setRegisterDone] = useState(undefined);
  var [phoneOTP, setPhoneOTP] = useState("");
  var [emailOTP, setEmailOTP] = useState("");
  var [tabIndex, setTabIndex] = useState(0);
  var [redirectURL, setRedirectURL] = useState(0);
  const [provider, setProvider] = useState('');
  const [profile, setProfile] = useState();
  // Add these variables to your component to track the state
  const [showPasswordLogin, setShowPasswordLogin] = useState(false);
  const handleClickShowPasswordLogin = () => setShowPasswordLogin(!showPasswordLogin);
  const handleMouseDownPasswordLogin = () => setShowPasswordLogin(!showPasswordLogin);

  const [showPasswordRegister, setShowPasswordRegister] = useState(false);
  const handleClickShowPasswordRegister = () => setShowPasswordRegister(!showPasswordRegister);
  const handleMouseDownPasswordRegister = () => setShowPasswordRegister(!showPasswordRegister);

  const onLoginStart = useCallback(() => {
    alert('login start');
  }, []);

  const onLogoutSuccess = useCallback(() => {
    setProfile(null);
    setProvider('');
    alert('logout success');
  }, []);


  useEffect(() => {
    window.scrollTo(0, 0);
    let queries = queryString.parse(history.location.search);
    let redirect = queries.redirect != undefined ? queries.redirect : undefined;
    setRedirectURL(redirect);
    console.log("Query String :: ", redirect);

    setRegisterDone(localStorage.getItem("token"));
  }, []);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  const handleUserTypeRadio = (event) => {
    console.log(event.target.value);
    setUserType(event.target.value);
  }

  function handleSwithRegister() {
    const registerControl = document.querySelector("#RegisterTab");
    registerControl.click();
  }

  function handleSwithLogin() {
    const loginControl = document.querySelector("#SigninTab");
    loginControl.click();
  }

  async function checkEmailExist(email) {

    if (!validateEmail(email)) {
      setErrorEmail('INVALID');
      return;
    }

    let rc = await isEmailExist(email);
    console.log("<checkEmailExist> :: ", rc);
    if (rc) {
      setErrorEmail("NOTAVAIL");
      console.log("<ERROR> EMAIL exist in database");
    } else {
      setErrorEmail("VALID");
      console.log("<INFO> EMAIL Id available");
    }
  }

  async function checkPhoneExist(phone) {
    if (!validatePhone(phone)) {
      setErrorPhone('INVALID');
      return;
    }
    let rc = await isPhoneExist(phone);
    console.log("<checkPhoneExist> :: ", rc);
    if (rc) {
      setErrorPhone("NOTAVAIL");
      console.log("<ERROR> Phone exist in database");
    } else {
      setErrorPhone("VALID");
      console.log("<INFO> Phone Id available");
    }
  }



  async function handleLoginUser() {

    let queries = queryString.parse(history.location.search);
    let redirect = queries.redirect != undefined ? queries.redirect : undefined;
    console.log("Query String :: ", redirect);

    let rc = true;
    rc = await loginUser(
      userDispatch,
      emailLogin,
      passwordLogin,
      history,
      setIsLoadingLogin,
      setErrorLogin,
      redirect
    );
    if (localStorage.getItem("token")) {
      setRegisterDone(localStorage.getItem("token"));
      handleSwithRegister();
    }
  }

  async function handleRegisterUser() {
    let rc = true;
    rc = await registerUser(
      userDispatch,
      emailRegister,
      passwordRegister,
      nameRegister,
      userType,
      phoneRegister,
      history,
      setIsLoadingRegister,
      setErrorRegister
    );
    if (rc == true) {
      setRegisterDone(localStorage.getItem("token"));
      handleSwithRegister();
    }
  }

  async function handleVerifyUser() {
    let rc = true;
    rc = await verifyOTPs(
      userDispatch,
      emailRegister,
      phoneRegister,
      emailOTP,
      phoneOTP,
      history,
      setIsLoadingRegister,
      setErrorRegister
    );

    if (rc == true) {
      localStorage.removeItem("token");
      setRegisterDone(undefined);
      setErrorLogin(undefined);
      setErrorRegister(undefined);
      handleSwithLogin();
    }
  }

  return (
    <Fragment>
      <div className="single-page about-page bg-white">
        <Header />
        <div className="row login-page">
          <div className="col-md-12 col-lg-6   p-0" >
            <div className="logo-container">
              <Link to="/">
                <img src={startupMoto} alt="logo" className="logotypeImage" />
                {/* <div className="logo-text">StartupBasket</div> */}
              </Link>
            </div>
          </div>
          <div className="col-md-12 col-lg-6 text-center  p-0" >
            <div className="form-container">
              <div className="card card-signin ">
                <div className="card-body">
                  <Tabs
                    value={activeTabId} indicatorColor="primary" textColor="primary" centered
                    onChange={(e, id) => setActiveTabId(id)} >
                    <Tab
                      label="Sign In"
                      classes={{ root: classes.tab }}
                      id="SigninTab"
                    />
                    <Tab
                      label="Register"
                      classes={{ root: classes.tab }}
                      id="RegisterTab"
                      
                    />
                  </Tabs>
                  {activeTabId === 0 && (
                    <React.Fragment>
                      <Typography variant="h3" className={classes.greeting}>
                        Login  Into StartupBasket
                      </Typography>
                      <div className="row">
                        <div className="col-6">
                          {/* <LoginSocialFacebook
                            appId={"2451491168350129"}
                            fieldsProfile='id,first_name,last_name,middle_name,name,name_format,picture,short_name,email,gender'
                            onLoginStart={onLoginStart}
                            onLogoutSuccess={onLogoutSuccess}
                            redirect_uri={REDIRECT_URI}
                            onResolve={({ provider, data }) => {
                              setProvider(provider);
                              setProfile(data);
                              console.log("provider:: " + provider)
                              console.log("data:: " + data)
                            }}
                            onReject={err => {
                              console.log("err OnReject:: " + err);
                            }}
                          >
                            FacebookLoginButton;
                          </LoginSocialFacebook> */}
                        </div>
                        <div className="col-6">
                          {/* <LoginSocialGoogle
                            client_id={"653010014946-97k8c018jvk73n7tgnnneth0jsg42jrp.apps.googleusercontent.com"}
                            onLoginStart={onLoginStart}
                            redirect_uri={REDIRECT_URI}
                            scope="openid profile email"
                            discoveryDocs="claims_supported"
                            access_type="offline"
                            onResolve={({ provider, data }) => {
                              setProvider(provider);
                              setProfile(data);
                              console.log("provider:: " + provider)
                              console.log("data:: " + JSON.stringify(data))
                            }}
                            onReject={err => {
                              console.log(err);
                            }}
                          >
                            GoogleLoginButton();
                          </LoginSocialGoogle> */}
                        </div>
                      </div>

                      <div className={classes.formDividerContainer}>
                        <div className={classes.formDivider} />
                        <Typography className={classes.formDividerWord}>or</Typography>
                        <div className={classes.formDivider} />
                      </div>
                      {errorLogin ? (
                        <Fade in={errorLogin}>
                          <Typography color="secondary">
                            Something is wrong with your login or password
                          </Typography>
                        </Fade>
                      ) : (
                        ""
                      )}
                      <TextField
                        id="emailLogin"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                        }}
                        value={emailLogin}
                        onChange={(e) => setEmailLogin(e.target.value)}
                        margin="normal"
                        placeholder="Email Address"
                        type="email"
                        fullWidth
                      />
                      <TextField
                        id="passwordLogin"
                        InputProps={{
                          classes: {
                            underline: classes.textFieldUnderline,
                            input: classes.textField,
                          },
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                aria-label="toggle password visibility"
                                onClick={handleClickShowPasswordLogin}
                                onMouseDown={handleMouseDownPasswordLogin}
                              >
                                {showPasswordLogin ? <Visibility /> : <VisibilityOff />}
                              </IconButton>
                            </InputAdornment>
                          )
                        }}
                        value={passwordLogin}
                        onChange={(e) => setPasswordLogin(e.target.value)}
                        margin="normal"
                        placeholder="Password"
                        type={showPasswordLogin ? "text" : "password"}
                        fullWidth
                      />
                      <div className={classes.formButtons}>
                        {isLoadingLogin ? (
                          <CircularProgress
                            size={26}
                            className={classes.loginLoader}
                          />
                        ) : (
                          <Button
                            disabled={
                              emailLogin.length === 0 || passwordLogin.length === 0
                            }
                            onClick={handleLoginUser} variant="contained" color="primary" size="large" >
                            Login
                          </Button>
                        )}
                        <Button
                          color="primary"
                          size="large"
                          className="border border-warning"
                          onClick={() => {
                            props.history.push("/forgetpassword");
                          }}
                        >
                          Forgot Password
                        </Button>
                      </div>
                    </React.Fragment>
                  )}
                  {activeTabId === 1 && (
                    <React.Fragment>
                      {
                        errorRegister ? (
                          <Fade in={errorRegister}>
                            <Typography color="secondary">
                              Something is wrong with your login or password
                            </Typography>
                          </Fade>
                        ) : (
                          ""
                        )}
                      {registerDone ? (
                        <div>
                          <Typography variant="h3" className={classes.greeting}>
                            Verify Account
                          </Typography>
                          <Typography color="secondary">
                            You received an OTP on email/phone, Please enter here to proceed verification
                          </Typography>
                          <Fade in={registerDone}>
                            <div className="mt-5">
                              <TextField
                                id="emailOTP"
                                InputProps={{
                                  classes: {
                                    underline: classes.textFieldUnderline,
                                    input: classes.textField,
                                  },
                                }}
                                value={emailOTP}
                                onChange={(e) => setEmailOTP(e.target.value)}
                                margin="normal"
                                placeholder="Enter Email OTP"
                                type="text"
                                fullWidth
                              />

                              {isLoadingRegister ? (
                                <CircularProgress size={26} />
                              ) : (
                                <Button
                                  disabled={
                                    emailOTP.length < 4
                                  }
                                  onClick={handleVerifyUser}
                                  variant="contained"
                                  color="primary"
                                  size="large"
                                >
                                  Submit
                                </Button>
                              )}
                            </div>
                          </Fade>
                        </div>
                      ) : (
                        <div>
                          <TextField
                            id="nameRegister"
                            InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                            value={nameRegister}
                            onBlur={(e) => validateString(e.target.value) ? setErrorNameRegister(false) : setErrorNameRegister(true)}
                            onChange={(e) => setNameRegister(e.target.value)}
                            margin="normal"
                            placeholder="Full Name"
                            type="text"
                            fullWidth
                            helperText={
                              errorNameRegister ? "Enter valid name" : ""
                            }
                            error={errorNameRegister ? 1 : 0}
                          />
                          <TextField
                            id="emailRegister"
                            InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                            value={emailRegister}
                            onChange={(e) => setEmailRegister(e.target.value)}
                            onBlur={(e) => checkEmailExist(e.target.value)}
                            margin="normal"
                            placeholder="Email Address"
                            type="email"
                            fullWidth
                            helperText={
                              errorEmail == "NOTAVAIL"
                                ? "Email Id not Available"
                                : errorEmail == "VALID"
                                  ? "Perfect! Email Available"
                                  : errorEmail == "INVALID" ? "Email ID is not valid" : ""
                            }
                            error={errorEmail == "VALID" || errorEmail == "EMPTY" ? 0 : 1}
                          />
                          <TextField
                            id="phoneRegister"
                            InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                            }}
                            value={phoneRegister}
                            onChange={(e) => setPhoneRegister(e.target.value)}
                            onBlur={(e) => checkPhoneExist(e.target.value)}
                            margin="normal"
                            placeholder="Phone Number"
                            type="text"
                            fullWidth
                            helperText={
                              errorPhone == "NOTAVAIL"
                                ? "Phone not Available"
                                : errorPhone == "VALID"
                                  ? "Perfect! Phone Available"
                                  : errorPhone == "INVALID" ? "Phone is not valid" : ""
                            }
                            error={errorPhone == "VALID" || errorPhone == "EMPTY" ? 0 : 1}
                          />
                          <TextField
                            id="passwordRegister"
                            InputProps={{
                              classes: {
                                underline: classes.textFieldUnderline,
                                input: classes.textField,
                              },
                              endAdornment: (
                                <InputAdornment position="end">
                                  <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPasswordRegister}
                                    onMouseDown={handleMouseDownPasswordRegister}
                                  >
                                    {showPasswordRegister ? <Visibility /> : <VisibilityOff />}
                                  </IconButton>
                                </InputAdornment>
                              )
                            }}
                            value={passwordRegister}
                            onChange={(e) => setPasswordRegister(e.target.value)}
                            onBlur={(e) => validatePassword(e.target.value) ? setErrorRegisterPassword(false) : setErrorRegisterPassword(true)}
                            margin="normal"
                            placeholder="Password"
                            type={showPasswordRegister ? "text" : "password"}
                            fullWidth
                            // validatePassword
                            helperText={
                              errorRegisterPassword ? "Input Password [6 to 20 characters which contain at least one numeric digit, one uppercase and one lowercase letter]" : ""
                            }
                            error={errorRegisterPassword ? 1 : 0}
                          />



                          <div className="row">
                            <div className="col-12 text-center">
                              <h3 className="mt-5">Who are you? <i id="tooltip" className="fa fa-info-circle" data-toggle="tooltip" data-original-title="Share"></i></h3>
                              <div className="container custom-checkbox">
                                <div className="btn-group" data-toggle="buttons">
                                  <label className="btn">
                                    <input type="checkbox" checked={userType == "STARTUP" ? 'checked' : ''} value="STARTUP" name='startup' onClick={handleUserTypeRadio} />
                                    <i className="fa fa-check-square fa-2x cls-icon-mute"></i>
                                    <i className="fa fa-check-square fa-2x cls-icon-activated"></i><span> STARTUP</span>
                                  </label>
                                  <label className="btn">
                                    <input type="checkbox" checked={userType == "INVESTOR" ? 'checked' : ''} value="INVESTOR" name='investor' onClick={handleUserTypeRadio} />
                                    <i className="fa fa-check-square fa-2x cls-icon-mute"></i>
                                    <i className="fa fa-check-square fa-2x cls-icon-activated"></i><span> INVESTOR</span>
                                  </label>
                                  <label className="btn">
                                    <input type="checkbox" checked={userType == "PARTNER" ? 'checked' : ''} value="PARTNER" name='partner' onClick={handleUserTypeRadio} />
                                    <i className="fa fa-check-square fa-2x cls-icon-mute"></i>
                                    <i className="fa fa-check-square fa-2x cls-icon-activated"></i><span> PARTNER</span>
                                  </label>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className={classes.creatingButtonContainer}>
                            {isLoadingRegister ? (
                              <CircularProgress size={26} />
                            ) : (
                              <Button
                                onClick={handleRegisterUser}
                                disabled={
                                  emailRegister.length === 0 ||
                                  passwordRegister.length === 0 ||
                                  nameRegister.length === 0 ||
                                  userType.length === 0
                                }
                                size="large"
                                variant="contained"
                                color="primary"
                                fullWidth
                                className={classes.createAccountButton}
                              >
                                Submit
                              </Button>
                            )}
                          </div>
                          {/* <div className={classes.formDividerContainer}>
                <div className={classes.formDivider} />
                <Typography className={classes.formDividerWord}>or</Typography>
                <div className={classes.formDivider} />
              </div> */}
                          {/* <Button
                size="large"
                className={classnames(
                  classes.googleButton,
                  classes.googleButtonCreating,
                )}
              >
                <img src={google} alt="google" className={classes.googleIcon} />
                &nbsp;Sign in with Google
              </Button> */}
                        </div>
                      )}
                    </React.Fragment>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </Fragment>
  );
}

export default withRouter(Login);
